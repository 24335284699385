.s-vue-modal__wrapper {
  @include pos(fixed, 9999, 0, 0, 0, 0);
  @include sflx($juscont: center, $alitems: center);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(51, 51, 51, 0.68);
  padding: 2rem 0;
  perspective: 500px;
  transition: opacity $gDuration ease;
}

.modals-open {
  overflow: hidden;
}

.course-section__picture {
  @include sbg($s: contain);
  background-origin: content-box;
  flex-shrink: 0;
  width: 22.2rem;
  min-height: 22.5rem;
  padding: 3rem;
  border-radius: $gBorderRadius 0 0 $gBorderRadius;


  @media screen and (max-width: 599px) {
    width: 100%;
    min-height: 28rem;
    border-radius: $gBorderRadius $gBorderRadius 0 0;
  }
}

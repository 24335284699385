.s-balabaqsha-catalog__filter-wrapper {
  flex-shrink: 0;
  width: 40rem;
  padding-right: 3rem;

  .sn-tabs--vertical {
    height: auto;
  }

  .sn-tabs {
    .sn-tab {
      flex: 1;
    }
  }
}

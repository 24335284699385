.home-section-interest-block-wrapper {
  @include sflx($juscont: center, $alitems: stretch);
  width: 100%;

  .section-interest-block {
    width: 30.4rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }


  @media screen and (max-width: 1349px) {
    flex-wrap: wrap;

    .section-interest-block {
      margin-right: 1.5rem !important;
      margin-bottom: 3rem;
      margin-left: 1.5rem !important;
    }
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: center;

    .section-interest-block {
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0!important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.sn-notifications__list {
  z-index: $z-notify;
  pointer-events: none;
  left: 0;
  right: 0;
  margin-bottom: 1rem;
  position: relative;

  &--center {
    top: 0;
    bottom: 0;
  }

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }
}

body.sn-ios-padding {
  .sn-notifications__list {
    &--center,
    &--top {
      top: $ios-statusbar-height;
      top: env(safe-area-inset-top);
    }

    &--center,
    &--bottom {
      bottom: env(safe-area-inset-bottom);
    }
  }
}

.container-content-head {
  @include sflx();
  width: 100%;
  margin-top: -4rem;
  margin-bottom: 2rem;


  @media screen and ( max-width: 639px ) {
    flex-direction: column;
    align-items: center;

    &.container-content-head--alone {
      padding-top: 3rem;
      margin-top: 0;
    }
  }
  &--without_mb {
    margin-bottom: 0;
  }
}

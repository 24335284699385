.sn-linear-progress__track {
  opacity: 0.4;
  transform-origin: 0 0;
  transition: transform 0.3s;

  &--light {
    background: rgba(0, 0, 0, 0.26);
  }

  &--dark {
    background: rgba(255, 255, 255, 0.6);
  }
}

.sn-timeline__heading {
  position: relative;

  &:first-child {
    .sn-timeline__heading-title {
      padding-top: 0;
    }
  }

  &:last-child {
    .sn-timeline__heading-title {
      padding-bottom: 0;
    }
  }
}

.navigation__item {
  @include sflx($juscont: center, $alitems: center);
  width: 4rem;
  height: 4rem;
  font-size: 1.8rem;
  position:relative;
  text-align: center;
  letter-spacing: 0.09px;
  cursor: pointer;
  transition: background-color .2s ease-in;

  a{
    position: absolute;
    z-index: 2;
    top: 10px ;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    color: #000;
  }

  &.is-active {
    @include pos();
    font-weight: 700;
    color: #02aef0;

    &::before {
      @include pos(absolute, $b: 0, $l: 50%);
      transform: translateX(-50%);
      width: 100%;
      max-width: 2.6rem;
      height: .3rem;
      border-radius: 2px;
      background-color: #02aef0;
      content: '';
    }
  }

  &:hover {
    background-color: darken($cWhite, 5%);
    transition: background-color .2s ease-in;
  }
}
.site-body__left-column {
  @include sflx($dir: column);
  flex-shrink: 0;
  width: 24rem;
  margin-top: -4rem;
  margin-right: 3rem;


  @media screen and ( max-width: 1008px ) {
    display: none;
  }
}
%s-ico {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-family: $fIcons;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

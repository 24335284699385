.s-main-footer__link {
  display: inline-block;
  color: $cWhite;
  cursor: pointer;
  margin-bottom: 2rem;

  &:not( :last-child ) {
    margin-right: 3rem;
    margin-bottom: 2rem;
  }

  &:hover,
  &.is-active {
    span {
      border-bottom: 1px solid darken($cWhite, 1%);
    }
  }

  span {
    border-bottom: 1px solid transparent;
  }
}

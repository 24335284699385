.ol-lbc__option-input-mark {
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  font: 400 1.6rem $fDef;
  color: #3D4651;
  text-align: center;
  background-color: transparent;
  padding: 0;
  border: 1px solid #cccccc;
  border-left: 0;
  border-radius: 0 $gBorderRadius $gBorderRadius 0;
}

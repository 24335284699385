.s-test-process__result-title {
  font-size: 3.6rem;
  text-align: center;
  margin-bottom: 2rem;

  @media screen and ( max-width: 479px ) {
    font-size: 2.8rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.wide-site-wrapper {
  display: table;
  width: 100%;
  font-size: 0;

  .wide-site-block {
    display: inline-block;
    vertical-align: top;
    width: 38rem;
    margin-right: 70 / 1280 * 100%;
    margin-bottom: 7rem;

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    @media screen and (max-width: 1320px) {
      width: 400 / 1280 * 100%;
      margin-right: 40 / 1280 * 100%;
      margin-bottom: 4rem;
    }

    @media screen and (max-width: 1007px) {
      width: 450 / 950 * 100%;
      margin-right: 50 / 950 * 100%;
      margin-bottom: 5rem;

      &:nth-child(2n+2) {
        margin-right: 0;
      }

      &:nth-child(3n+3) {
        margin-right: 50 / 950 * 100%;
      }
    }

    @media screen and (max-width: 699px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }
}

.flex-wrap {
  .wide-site-wrapper {
    @include sflx($wrap: wrap, $alitems: stretch);
  }
}
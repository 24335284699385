.s-chat-popup {
  &__body {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgb(0 21 117 / 90%);
    padding: 1.3rem;
    height: 100%;
    overflow: auto;
  }
}

.home-news-block__title {
  @include pos($z: 2);
  display: block;
  width: 100%;
  max-width: 48rem;
  font-size: 3rem;
  font-family: 'Open Sans Semibold', sans-serif;

  @media screen and (max-width: 559px) {
    max-height: 19.2rem;
    font-size: 2.8rem;
    overflow: hidden;
  }
}
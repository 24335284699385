.test-cont-outer {
  margin-bottom: 4rem;
}

.test-cont-inner {
  @extend %block-inner;

  @media screen and (max-width: 479px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.s-chat-popup {
  &__helper-text {
    color: white;
    box-shadow: 0 5px 40px rgba(0, 0, 0, .26);
    margin-right: 2.5rem;
    position: relative;
    place-self: center;

    &--triangle {
      z-index: 2;
      background-color: #7b90c6;;
      height: 16px;
      width: 16px;
      border: inherit;
      position: absolute;
      top: 13px;
      right: -9px;
      clip-path: polygon(20% 0, 100% 80%, 0 100%);
      transform: rotate(-135deg);
      border-radius: 0 0 0 5px;
    }
  }
}

.sn-rating__icon {
  @include pos(relative);
  color: currentColor;
  opacity: 0.4;
  text-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.12), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.24);
  transition: transform $gDuration ease-in, opacity $gDuration ease-in;

  &--hovered {
    transform: scale(1.3);
  }

  &--exselected {
    opacity: 0.7;
  }

  &--active {
    opacity: 1;
  }

  & + & {
    margin-left: 0.2rem;
  }
}

.growl {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 6px 18px rgba(#000101, .08);
  margin-top: 1.5rem;
  cursor: pointer;
  opacity: 1;
  transition: all .2s ease-in;
  &:hover {
    opacity: .6;
    transition: all .2s ease-in;
  }
  &.growl-notice {
    border-color: #20c091;
    background-color: #eefffa;
    .growl-message {
      color: #20c091;
      &:before {
        content: '\E71F';
      }
    }
    .growl-close {
      color: #20c091;
    }
  }
  &.growl-warning {
    border-color: #fdba71;
    background-color: #fff8e5;
    .growl-message {
      color: #fb8303;
      &:before {
        content: '\E75A';
        left: 2.75rem;
      }
    }
    .growl-close {
      color: #fb8303;
    }
  }
  &.growl-error {
    border-color: #fa9797;
    background-color: #faeaeb;
    .growl-message {
      color: #eb4c4c;
      &:before {
        content: '\E720';
      }
    }
    .growl-close {
      color: #eb4c4c;
    }
  }

  &.growl-medium {
    width: auto;
  }
}

.growl-message {
  font: 1.4rem/1.2em $font_default;
  padding: 2rem 2rem 2rem 8rem;
  &:before {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -1.7rem;
    left: 3rem;
    font: 3.4rem/1em $font_themify;
  }
}

.ol-lbc__option-input {
  @include placeholder(#C0C0C0);
  width: 100%;
  height: 4rem;
  font: 400 1.6rem $fDef;
  color: #3D4651;
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
}

.child-profile__buttons-wrapper {
  @include sflx($wrap: wrap, $juscont: flex-end);
  width: 100%;
  padding-top: 3rem;

  > * {
    min-width: 20rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    .str-ico {
      width: 100%;
      text-align: center;
    }
  }


  @media screen and (max-width: 767px) {
    justify-content: center;

    > * {
      margin-right: 1rem !important;
      margin-left: 1rem !important;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}
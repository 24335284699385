.s-photo-album__modal-container {
  @include pos($z: 9);
  @include sbg($s: contain);
  max-width: 100%;
  min-width: 27rem;
  min-height: 27rem;
  max-height: 100%;
  background-color: $cWhite;
  will-change: contents;
  overflow: hidden;
  // 3D acceleration
  transform: translateZ(0);
  transition: all 0.5s ease-in;

  img {
    display: block;
    max-width: 100%;
    max-height: calc(100vh - 4.8rem);
    //transition: all 0.5s ease-in;
    width: 100%;
    height: 100%;
  }

  .sn-spinner {
    @include pos(absolute, $t: 0, $r: 0, $b: 0, $l: 0);
    margin: auto;
  }
}

.kindergarten-head-inform__blocks {
  @include sflx($wrap: wrap);
  flex-shrink: 0;
  order: 3;
  width: 36.9rem;

  > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  .inform-block {
    width: 17.7rem;
    height: 17.7rem;
    margin-bottom: 1.5rem;
  }


  @media screen and (max-width: 1265px) {
    @include sflx($dir: column);
    width: 17.7rem;

    > *:not(:last-child) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 1009px) {
    order: 2;
    flex-direction: row;
    width: 36.9rem;

    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 3rem 0;
  }

  @media screen and (max-width: 479px) {
    @include sflx($dir: column);
    width: 17.7rem;

    > *:not(:last-child) {
      margin-right: 0;
    }
  }
}
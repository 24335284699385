.sn-btn__content {
  transition: opacity 0.3s;

  &::before {
    content: '';
  }

  &--hidden {
    opacity: 0;
  }

  .s-ico {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  &--stack {
    .s-ico {
      margin-top: unset;
      margin-bottom: 0.5rem;
    }
  }

  div {
    display: inline;
    line-height: 1.2em;
  }
}

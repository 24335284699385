%s-str-ico {
  @include pos();
  display: inline-block;
  line-height: 1em;
  font-style: normal;

  &::before {
    @extend %s-ico;
    @include pos(absolute, $t: 50%);
    transform: translateY(-50%);
    font-size: 1.2em;
  }

  span {
    line-height: 1.3em;
  }
}

%s-str-ico-fa {
  @include pos();
  display: inline-block;
  line-height: 1em;
  font-style: normal;

  &::before {
    @extend .s-ico-fa;
    @include pos(absolute, $t: 50%);
    transform: translateY(-50%);
    font-size: 1.2em;
  }

  span {
    line-height: 1.3em;
  }
}

%s-str-ico-fab {
  @include pos();
  display: inline-block;
  line-height: 1em;
  font-style: normal;

  &::before {
    @extend .s-ico-fab;
    @include pos(absolute, $t: 50%);
    transform: translateY(-50%);
    font-size: 1.2em;
  }

  span {
    line-height: 1.3em;
  }
}

.s-str-ico,
.s-str-ico-rev {
  @extend %s-str-ico;

  &--wider {
    width: 100%;
  }
}

.s-str-ico-fa,
.s-str-ico-fa-rev {
  @extend %s-str-ico-fa;

  &--wider {
    width: 100%;
  }
}

.s-str-ico-fab,
.s-str-ico-fab-rev {
  @extend %s-str-ico-fab;

  &--wider {
    width: 100%;
  }
}

.s-str-ico,
.s-str-ico-fa,
.s-str-ico-fab {
  padding-left: 2em;

  &::before {
    left: 0;
  }
}

.s-str-ico-rev,
.s-str-ico-fa-rev,
.s-str-ico-fab-rev {
  padding-right: 2em;

  &::before {
    right: 0;
  }
}

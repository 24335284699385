.s-file__btn {
  @extend .s-btn, .s-btn--border;
  @include sflx($d: inline-flex, $alitems: center, $juscont: center);
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
  background-color: $cWhite;
  border-color: $cBorder;
  transition: all $gDuration ease-in;

  &:hover {
    transition: all $gDuration ease-in;
  }

  &--thm-remove {
    color: $cRed;

    &:hover {
      color: $cWhite;
      background-color: darken($cRed, 5%);
      border-color: darken($cRed, 5%);
    }
  }

  &--thm-show {
    color: $cBlue;

    &:hover {
      color: $cWhite;
      background-color: darken($cBlue, 5%);
      border-color: darken($cBlue, 5%);
    }
  }

  &--thm-download {
    color: $cOrange;

    &:hover {
      color: $cWhite;
      background-color: darken($cOrange, 5%);
      border-color: darken($cOrange, 5%);
    }
  }

  &--thm-edit {
    color: $cBlack;

    &:hover {
      color: $cWhite;
      background-color: darken($cBlack, 5%);
      border-color: darken($cBlack, 5%);
    }
  }

  &--thm-select {
    color: $cGreen;

    &::after {
      font-family: $fIcons;
      content: map-get($listOfIcons, 'check');
    }
  }

  &--thm-add {
    width: 5rem;
    height: 5rem;
    font-size: 2.4rem;
    color: #AEB3C6;
    background-color: transparent;
    border-color: #AEB3C6;
    border-radius: 50%;
  }
}

.organization-photo-container {
  max-width: 25em;
  padding-right: 3em;
  flex-shrink: 0;
  // order: 1;

  //@media screen and (max-width: 1265px) {
  //  padding-right: 0;
  //}
  //
  //@media screen and (max-width: 359px) {
  //  .picture-panel {
  //    margin-left: -.75rem;
  //  }
  //}
}

.organization-photo-block {
  position: relative;
  display: flex;
  flex-direction: column;
}

.organization-mini-photo-block {
  display: flex;
  align-items: center;
  margin-top: 1em;
  justify-content: center;
}

.organization-mini-photo {
  margin-right: 0.5em;

  &:hover {
    opacity: 0.9;
  }
}

.organization-prev-btn,
.organization-next-btn {
  color: #AEB3C6;

  &::after {
    font-family: snation;
    font-size: 3em;
  }
}

.org-edu-langs-list {
  padding-left: 0.9em! important;

  ul {
    list-style: none;

    li {
      padding-left: 20px;
      position: relative;
      margin: 5px;

      &::before {
        content: '\e64c';
        font-family: snation;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.organization-prev-btn {
  margin-right: 0.5em;

  &::after {
    content: '\e64a';
  }
}

.organization-next-btn {
  &::after {
    content: '\e649';
  }
}

.organization-table-container {
  //padding-right: 3em;
  flex: 1 1 auto;
  order: 2;
  padding-right: 3rem;
  padding-left: 3rem;

  @media screen and (max-width: 1265px) {
    padding-right: 0;
    padding-left: 0;
    margin-right: 2rem;
    // order: 3;

    @media screen and (max-width: 1009px) {
      order: 3;
    }
  }
}

.table__profile-info--new {
  tbody {
    tr {
      &:nth-child( even ) {
        //background: white;
      }

      td {
        padding: 1rem;

        &:first-child {
          border-right: none;
          vertical-align: baseline;
          width: 45%;
        }
      }
    }
  }
}

.organization-right-container {
  display: flex;
  flex-direction: column;
  @include sflx($wrap: wrap);
  flex-shrink: 0;
  order: 3;
  width: 36.9rem;

  > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  .inform-block {
    width: 17.7rem;
    height: 17.7rem;
    margin-bottom: 1.5rem;
  }


  @media screen and (max-width: 1265px) {
    @include sflx($dir: column);
    width: 17.7rem;
    order: 2;
    flex-direction: row;
  }

  @media screen and (max-width: 767px) {
    padding: 3rem 0;
    width: 17.7rem;
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
}

.organization-right-row {
  display: flex;
  margin-bottom: 2em;
}

.organization-right-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 3px grey;
  width: 17rem;
  height: 17rem;
  margin-right: 2rem;
  text-align: center;
  padding: 1.4rem;
}

.organization-right-text {
  font-size: 1.4rem;
}

.organization-right-header {
  font-size: 2.5rem;
}

.organization-right-lang-img-container {
  display: flex;
  //flex-direction: column;
  margin-top: 0.5rem;
}

.organization-right-langs-text {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.organization-now-is-unavailable {
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 2rem;
  color: white;
  background-color: #3045A7;
  border-radius: 5px;
}

#rating-progressbar {
  margin-top: 2rem;
  background-color: #DFE2ED;
  border-radius: 13px;
  width: 80%;
}

#rating-progressbar-inner {
  background-color: #10206D;
  width: 71%;

  /* Adjust with JavaScript */
  height: 1rem;
  border-radius: 10px;
}

#fill-progressbar {
  margin-top: 2rem;
  background-color: #DFE2ED;
  border-radius: 13px;
  width: 80%;
}

#fill-progressbar-inner {
  background-color: #10206D;
  width: 93%;

  /* Adjust with JavaScript */
  height: 1rem;
  border-radius: 10px;
}

.organization-description {
  font-weight: 300;
  line-height: 1.4em;
  overflow: hidden;
}

.organization-read-more-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

#org_read_more_btn {
  color: black;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#org_read_more_icon {
  &.read-more_down-icon {
    &::after {
      content: '\e64b';
      font-family: snation;
      font-size: 2rem;
    }
  }

  &.read-more_up-icon {
    &::after {
      content: '\e648';
      font-family: snation;
      font-size: 2rem;
    }
  }
}

.organization-address-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12pt;
  margin-bottom: 2rem;
}

.organization-address-element {
  margin-bottom: 1rem;
}

.org-button-edit {
  background: #F5942E;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;

  &__border {
    border: 0.5px solid #F5942E;
  }

  &:hover {
    background: darken(#F5942E, 5%);
  }

  &::before {
    content: '\e61c';
    font-family: snation;
    left: 1rem;
    position: absolute;
  }
}

.form-container-up {
  display: flex;

  .organization-photo-container {
    .organization-photo-block {
      max-width: 22rem;
    }
  }

  .form-image {
    width: 30%;
    min-width: 20rem;
    position: relative;
    padding-right: 50px;

    .child-card__photo {
      border: 1px solid #AEB3C6;
      background-color: #F7F9FC;
      margin-bottom: 10px;
    }

    .form-image-add {
      position: absolute;
      font-family: inherit;
      background-color: #F7F9FC;
      text-align: center;
      height: 50px;
      width: 50px;
      color: #243D9B;
      border: 2px solid darken(#F7F9FC, 10%);
      font-family: 'Open Sans', snation, sans-serif;
      font-size: 45px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      left: 33%;
      top: 8%;

      &::before {
        content: '\e61a';
      }
    }

    .organization-photo__edit {
      margin-bottom: 20px;
    }

    .modal-inform {
      width: 100%;
    }
  }
}

.form-right-container {
  width: 100%;
}

.organization-photo {
  &__edit {
    border: 1px solid #8A9DA8;
    border-radius: 5px;
    background: 50% 50% / auto 100% no-repeat scroll padding-box border-box rgba(0, 0, 0, 0);
    width: 22rem;
    height: 28.1rem;
  }

  &__default {
    background-color: #F7F9FC;
    background-size: 80%;
    background-image: url('../images/avatar_rectangle.svg');
  }
}

.org_delete_img_btn {
  border-radius: 25px;
  background: #3045A7;
  color: white;
  display: none;
  transform: scale(0.8);

  &:hover {
    background: darken(#3045A7, 5%);
  }
}

.college-block__image-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6.6rem;
  width: 6.6rem;
  border: 1px solid #8A9DA8;
  border-radius: 5px;
  background-color: #F7F9FC;

  &:hover {
    .org_delete_img_btn {
      display: block;
    }
  }

  &.is-active {
    border: 3px solid #3c6;
  }

  &--add {
    border: 1px dashed #8A9DA8;
  }
}

.profile-content__org-btns-block {
  .button {
    margin-right: 0.1rem! important;
  }
}

.organization-mini-photo-container {
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;

  &::after {
    content: '';
    flex-basis: 30%;
  }
}

.button_type_add-edit {
  border: 1px solid #DFE2ED;
  border-radius: 25px;
  transform: scale(0.8);

  &::after {
    color: #495CB2;
  }
}
@media screen and ( max-width: 1700px ) {
}
@media screen and ( max-width: 1600px ) {
  .profile-content__org-btns-block {
    .button {
      font-size: 12px;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      top: 1.15em;

      &_theme_white--new {
        padding-top: 1.3em! important;
      }

      &_theme_aqua--new {
        height: auto;
      }
    }
  }
}
@media screen and ( max-width: 1400px ) {
  .organization-right-row {
    flex-direction: column;
    margin-bottom: 0;

    .organization-right-col {
      margin-bottom: 2em;
    }
  }
}
@media screen and ( max-width: 1350px ) {
  .profile-content__org-btns-block {
    .button {
      min-width: auto! important;

      &::before {
        display: none;
      }
    }
  }
}
@media screen and ( max-width: 1200px ) {
  .organization-photo__edit {
    margin-bottom: 1em;
  }

  .organization-photo-container {
    padding-right: 2rem;
  }

  .organization-right-row {
    flex-direction: row;
    margin-bottom: 2em;

    .organization-right-col {
      margin-bottom: 0;
    }
  }
}
@media screen and ( max-width: 815px ) {
  .profile-content__org-btns-block {
    .button {
      width: 48%;
      border-radius: 5px;

      &::before {
        display: block;
      }
    }
  }

  .form-container-up {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-top-outer-new-profile {
    background: none;
  }

  .organization-photo-container {
    padding-right: 0;
    width: 22rem;
    margin-bottom: 2em;
  }

  .form-right-container {
    width: 100%;
  }
}
@media screen and ( max-width: 767px ) {
  .organization-table-container {
    padding-right: 0;

    .table__profile-info {
      display: table;
    }
  }

  .profile-content__line-between:last-child {
    .button_theme_white--up {
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}
@media screen and ( max-width: 620px ) {
  .organization-photo__edit {
    margin-top: 2rem;
  }
}
@media screen and ( max-width: 500px ) {
  .organization-right-row {
    flex-direction: column;
    margin-bottom: 0;

    .organization-right-col {
      margin-bottom: 2rem;
    }
  }
}
@media screen and ( max-width: 400px ) {

}
@media screen and ( max-width: 330px ) {
  .profile-content__org-btns-block {
    .button {
      &::before {
        display: none;
      }
    }
  }

  .profile-content__line-between:last-child {
    .button_theme_white--up {
      min-width: 100%! important;
    }
  }
}

.button_theme_white--orange {
  color: orange! important;
}

.button_theme_white--red {
  color: red! important;
}

@media screen and (max-width: $gMinWidthTablet - 1px) {
  .sn-notify__wrapper {
    @include pos(fixed, $b: 0, $l: 0);
    top: unset;
    right: unset;
    align-items: unset;
    width: 100%;
    place-content: flex-end;
    padding: 0 2rem;
  }

  .sn-notify {
    max-width: unset;
    margin: 0.5rem 0;
  }
}

.sn-btn__progress {
  height: 100%;
  background: rgba($cWhite, 0.25);
  transform-origin: top left;
  transition: transform 0.3s;

  &--dark {
    background: rgba($cBlack, 0.2);
  }
}

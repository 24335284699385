.form__registration {
  width: 100%;
  max-width: 54.4rem;
  margin: 0 auto;
  .form__bottom {
    .form__info {
      width: 50%;
      text-align: right;
    }
    .button {
      width: 100%;
      max-width: 24.4rem;
      font-family: $font_sans_sb;
    }
  }
  .form__checkbox {
    &--offerta {
      input[ type='checkbox' ] {
        cursor: pointer;
      }
      label {
        cursor: pointer;
        span {
          color: $c_blue;
          border-bottom: 1px dashed $c_blue;
        }
      }
    }
  }
}
.form__auth {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  background-color: $c_white;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 6px;
  padding: 5rem 6rem;
  .form__bottom {
    &:last-child {
      margin-bottom: 0;
    }
    .button {
      min-width: 19rem;
    }
  }
  .button {
    min-width: 24.6rem;
  }
}


@media screen and (max-width: 599px) {
  .form__registration {
    .form__bottom {
      .form__info {
        width: 100%;
        text-align: left;
        margin-bottom: 3rem;
      }
      .button {
        max-width: none;
      }
    }
  }
  .form__auth {
    padding: 2rem;
    .form__bottom {
      .form__checkbox {
        margin-bottom: 3rem;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .form__auth {
    box-shadow: none;
    padding: 0;
  }
}
[v-cloak] {
  display: none !important;;
}

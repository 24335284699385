%cabinet-page {
  @include pos();
  @include sflx($dir: column);
  flex: 1 0 auto;
  width: 100%;
  max-width: $gWidthMax;

  .cabinet-head-wrapper {
    min-height: 18rem;
  }


  @media screen and (max-width: 479px) {
    .cabinet-head-wrapper {
      min-height: unset;
    }
  }
}

.interest-block__school {
  @extend .interest-block;
  overflow: visible;
  box-shadow: none;
  opacity: 1;
  transition: opacity $animateSpeed ease-in;

  &.opacity {
    opacity: 0.5;
  }

  .interest-block__description {
    border-radius: 5px;
    overflow: visible;
    box-shadow: 0 0 1.6rem rgba(54, 76, 167, 0.59);
  }

  .interest-block__desc-image-center {
    transition: transform $animateSpeed ease-in;
    width: 100%;
    height: 8.7rem;
    background: 50% 50% no-repeat;
  }

  &:first-child {
    order: 1;
    opacity: 0.85;

    .interest-block__desc-image-center {
      background-image: url('../images/school/interest-block-img-1.png');
    }
  }

  &:nth-child( 2 ) {
    order: 3;
    margin-right: 0;
    opacity: 0.85;

    .interest-block__desc-image-center {
      background-image: url('../images/school/interest-block-img-2.png');
    }
  }

  &:nth-child( 3 ) {
    order: 2;
    margin-right: 0;

    .interest-block__desc-image-center {
      background-image: url('../images/school/interest-block-img-3.png');
    }

    .interest-block__description {
      &.is-active {
        transform: translateY(-0.75rem);

        &::before {
          position: absolute;
          z-index: -1;
          left: 50%;
          top: calc(100% - 0.75rem);
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          background-color: $cBlue;
          box-shadow: -0.3rem -0.3rem 1.2rem -0.3rem rgba(104, 124, 143, 0.43);
          transform: translateX(-50%) rotate(45deg);
          content: '';
        }
      }
    }
  }

  &:not( :last-child ) {
    margin-right: 0;
  }

  &:hover {
    box-shadow: none;
    opacity: 1;
    transition: opacity $animateSpeed ease-in;

    .interest-block__description {
      box-shadow: 0 0 4.6rem rgba(54, 76, 167, 0.59);
    }

    .interest-block__desc-title {
      transform: translateY(2rem);
      transition: transform $animateSpeed ease-in;
    }

    .interest-block__desc-image-center {
      transform: translateY(-2rem);
      transition: transform $animateSpeed ease-in;
    }

    .interest-block__desc-link {
      transform: translateY(-2.2rem);
    }

    &:nth-child( 3 ) {
      .interest-block__desc-title {
        transform: unset;
        transition: unset;
      }

      .interest-block__desc-image-center {
        transform: unset;
        transition: unset;
      }

      .interest-block__desc-link {
        transform: unset;
        display: none;
      }
    }
  }
}

.s-home-container .home-interest-block-wrapper--school .interest-block__school {
  &:not( :last-child ) {
    margin-right: 0;
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1009px,
  xl: 1600px
);

$container-max-widths: (
  sm: 536px,
  md: 728px,
  lg: 989px,
  xl: 1560px
);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/mixins/grid-framework';
@import '~bootstrap/scss/mixins/grid';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';

.home-news-block__footer {
  @include pos($z: 2);
  @include sflx($juscont: flex-end);
  width: 100%;
  padding-top: 2rem;
  border-top: 1px solid rgba($cWhite, .45);
  margin-top: 2rem;

  > * {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  .str-ico {
    padding-left: 2rem;
  }
}
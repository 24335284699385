.college-library-card--popover {
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($cBlack, 0.5);
  transition: all $gDuration;
}

.college-library-card {
  width: 18rem;
  height: 24.5rem;
  background-color: $cPaleGray;
  border: 1px solid $cBorder;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    .college-library-card--popover {
      display: flex;
      opacity: 1;
      transition: all $gDuration;
    }
  }
}

.test-cont__description {
  font-size: 1.8rem;
  line-height: 1.2em;
  font-family: $fSansL;
  text-align: center;
}

.test-cont__description_mrgn_b {
  margin-bottom: 3rem;
}

@media screen and (max-width: 479px) {
  .test-cont__description {
    font-size: 1.6rem;
  }

  .test-cont__description_mrgn_b {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1009px) {
  .container-content-head {
    margin-top: unset;
  }
  .container-content-head__right-wrapper {
    width: 100%;
  }

  .container-content-head__tab-item {
    &:not(.container-content-head__tab-item--only-icon) {
      margin-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 auto;
      border-radius: 5px;
    }
  }

  .container-content-head__tabs--free {
    flex-wrap: wrap;

    .container-content-head__tab-item {
      &:not(.container-content-head__tab-item--only-icon) {
        flex: 1 1 auto;
      }

      margin: 10px;
    }
  }

  .container-content-head__tabs-wrapper--q-btns-mod {
    > .sn-btn {
      margin-left: 0;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 auto;
      border-radius: 5px;
    }
  }
  .container-content-head__tabs-wrapper--q-btns-mod.container-content-head__tabs--free {
    flex-wrap: wrap;

    > .sn-btn {
      margin: 10px;
    }
  }
}


@media screen and (max-width: 639px) {
  .container-content-head__right-wrapper {
    width: 100%;
  }

  .container-content-head__tab-item {
    margin-left: 0;
  }

  .container-content-head__tabs-wrapper--q-btns-mod {
    > .sn-btn {
      margin-left: 10px;
    }
  }
}

.home-vacancies-outer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.vacancies-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.vacancies__left-wrapper {
  flex: 1 0 auto;
  width: 26rem;
  max-width: 26rem;
  margin-right: 3rem;
}
.vacancies__content {
  flex: 1 1 auto;
}
.vacancies__filter {
  background-color: #edf1f6;
  border-radius: 6px;
  padding: 1.5rem;
  .button {
    width: 100%;
  }
  .filter__wrapper {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.filter__wrapper {
  margin-bottom: 3.5rem;
}
.filter__label {
  font: 1.6rem/1.2em $font_sans_sb;
  color: $c_black;
  margin-bottom: 1rem;
  text-align: left;
}

.vacancies__no-result-wrapper {
  @extend .block;
  padding: 3rem;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vacancies__no-result-image,
.vacancies__no-result-message {
  width: 100%;
  max-width: 28rem;
}
.vacancies__no-result-image {
  text-align: center;
  img {
    max-width: 100%;
  }
}
.vacancies__no-result-message {
  font: 2.2rem/1.2em $font_default;
  color: $c_grey;
  span {
    font-family: $font_sans_sb;
  }
}


@media screen and (max-width: 899px) {
  .vacancies-container {
    flex-direction: column;
  }
  .vacancies__left-wrapper {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-bottom: 3rem;
    .vacancies__filter {
      width: 100%;
      max-width: 60rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .filter__wrapper {
        margin-right: 1.5rem;
        margin-left: 1.5rem;
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .home-vacancies-outer {
    padding-top: 3rem;
  }
  .vacancies__filter {
    flex-direction: column;
    .filter__wrapper {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }
  .vacancies__no-result-wrapper {
    flex-direction: column;
  }
  .vacancies__no-result-image {
    margin-bottom: 2rem;
  }
}

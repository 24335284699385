.s-stat {
  flex: 1 1 0;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  border-radius: $gBorderRadius;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: 1%;
  margin-right: 1%;
  @include media-breakpoint-down(sm) {
    display: flex;
    padding: 1.5rem;
    align-items: center;
    box-shadow: none;
    border: 0.1rem solid $cBorder;
    margin-bottom: 0.5rem;
  }
}
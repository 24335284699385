.modal-doc-attach {
  @extend %modal;

  .modal-dialog {
    max-width: 117.3rem;
  }

  .modal-body {
    padding: 2rem 3rem 4rem;

    .select2-container {
      height: 4.5rem;
      .select2-selection--single {
        height: 4.5rem;
      }
    }

    .tbl-cont {
      margin-bottom: 3rem;
    }

    .form__string {
      &:last-child {
        .btn {
          &:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
    }
  }
}
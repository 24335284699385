.site-page-1280 {
  @extend %site-page;

  .cabinet-content-container, // legacy
  .container-content-container {
    max-width: 128rem;
  }
}

.site-page__course {
  .container-content-head {
    align-items: flex-start;
    margin-bottom: 0;

    .container-content-head__picture,
    .container-content-head__tabs-wrapper {
      margin-bottom: 2rem;
    }
  }

  @media screen and ( max-width: 639px ) {
    .container-content-head {
      margin-top: 2rem;
    }
  }
}

.site-page__faq {
  @extend %site-page;

  .container-content-container {
    max-width: 128rem;
  }
}

.site-page__kindergarten,
.site-page__kindergarten-inner {
  @extend %site-page;

  .container-head-wrapper {
    min-height: 18rem;
  }

  .container-content-container {
    @include pos();
    max-width: 128rem;
  }
}

.site-page__kindergarten-inner {
  .container-head-wrapper {
    padding-bottom: 6rem;
  }

  .cabinet-head__title {
    font-size: 2.6rem;
    font-weight: 400;
    text-transform: none;
  }
}


@media screen and (max-width: 1009px) {
  .site-page__kindergarten {
    .container-body-top-wrapper {
      flex-wrap: wrap;

      .kindergarten-filter_t_type {
        max-width: 100%;
        order: 3;
        margin-top: 2rem;
        margin-right: 0;
      }

      .kindergarten-search {
        order: 2;
      }

      .kindergarten-filter_t_region {
        order: 1;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .site-page__kindergarten {
    .container-body-top-wrapper {
      flex-direction: column;

      .kindergarten-filter_t_type {
        margin-right: 0;
      }

      .kindergarten-search {
        order: 1;
        max-width: 100%;
        margin-right: 0;
      }

      .kindergarten-filter_t_region {
        order: 2;
        max-width: 100%;
        margin-top: 2rem;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .site-page__kindergarten {
    .container-head-wrapper {
      min-height: unset;
    }
  }
}

.sn-separator {
  border: none;
  background: $separator-color;
  margin: 0;
  transition: background 0.3s, opacity 0.3s;

  &--dark {
    background: $separator-dark-color;
  }

  &--horizontal {
    display: block;
    height: 0.1rem;
    min-height: 0.1rem;
    width: 100%;

    &.sn-separator--spaced {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }

    &.sn-separator--inset {
      margin-left: 1.6rem;
      margin-right: 1.6rem;
      width: calc(100% - 3.2rem);
    }

    &.sn-separator--item-inset {
      margin-left: 7.2rem;
      margin-right: 0;
      width: calc(100% - 7.2rem);
    }

    &.sn-separator--item-thumbnail-inset {
      margin-left: 11.6rem;
      margin-right: 0;
      width: calc(100% - 11.6rem);
    }
  }

  &--vertical {
    width: 0.1rem;
    min-width: 0.1rem;
    height: inherit;
    min-height: 100%;

    &.sn-separator--spaced {
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    &.sn-separator--inset {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
}

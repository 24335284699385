.sn-slider__pin {
  top: 0;
  width: 26px;
  height: 26px;
  margin-top: -2px;
  margin-left: -2px;
  background: currentColor;
  transform: rotate(-45deg) scale3d(0, 0, 0) translate3d(0, 0, 0);
  transition: transform 100ms ease-out;
  will-change: left;
  border-radius: 50% 50% 50% 0%;
  z-index: 1;
}

.tof-grid {

}

.tof-grid__index,
.tof-grid__spec {
  position: absolute !important;
  z-index: 4;
}

.tof-grid__index {
  left: 0;
  width: 10.8rem;
  text-align: left;
  background-clip: padding-box;
  border-right: 1px solid $tBorderSection !important;
  &__border-white {
    border-right: 1px solid white !important;
  }
}

.tof-grid__spec {
  left: 10.8rem;
  width: 41.5rem;
  text-align: left;
  background-clip: padding-box;
  border-right: 5px solid $tBorderSection !important;
  &__border-white {
    border-right: 5px solid white !important;
  }
}

.tof-grid__control {
  width: 45.5rem;
  background-clip: padding-box;
  border-right: 1px solid $tBorderSection !important;
}

.tof-grid__score {
  width: 6rem;
  text-align: center;
  vertical-align: middle;
  background-clip: padding-box;

  &:not(:last-child) {
    border-right: 1px solid $tBorderSection !important;
  }
}

.tof-grid__courses {
  width: 14rem;
  background-clip: padding-box;
  border-right: 1px solid $tBorderLocal !important;

  &:last-child {
    border-right: 0 !important;
  }
}

$listOfCourseLength: (
  (1, 14rem),
  (2, 28rem),
  (3, 42rem),
  (4, 56rem),
);

@each $name, $width in $listOfCourseLength {
  .tof-grid__course-#{$name} {
    width: $width;
  }
}

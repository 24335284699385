.kindergarten__map-wrapper {
  @include pos();
  display: block;
  width: 100%;
  height: 16.5rem;
  border: 1px solid #bdc7cd;
  border-radius: $gBorderRadius;
  margin-bottom: 3rem;
  overflow: hidden;
  transition: height .2s ease-in;
}

.kindergarten__map {
  @include pos(absolute, 1, 0, 0);
  width: 100%;
  height: 30rem;
  border-radius: $gBorderRadius;
  overflow: hidden;
}

.kindergarten__map-overlay {
  @include pos(absolute, 2, 0, 0, 0, 0);
  background-color: rgba($cBlack, .2);
  cursor: pointer;
}
.s-submenu-cabinet__item {
  position: relative;
  @include sflx(
    $dir: row,
    $wrap: nowrap,
    $alitems: center,
    $juscont: flex-start
  );
  width: 100%;
  padding: 1.05rem 2.5rem;
  color: $cBlack;
  overflow: hidden;

  &:not( :last-child ) {
    border-bottom: 1px solid $cHoverDark;
  }

  &:hover {
    background-color: $cHoverDark;
  }

  &.is-open,
  &.is-active {
    background-color: $cHoverDark;
  }

  &.is-active {
    & > span {
      font-weight: 600;
    }
  }

  & > span {
    margin-left: 0.5rem;
  }
}

.s-chat-popup {
  &__unread-counter {
    color: white;
    background: #F5942E;
    border: 0.5px solid #7b90c6;;
    border-radius: 100%;
    min-width: 3rem;
    height: 3rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0.5rem;
  }
}

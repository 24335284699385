.reviews-block {
  @extend .block;
  display: inline-flex;
  flex-direction: column;
  color: $c_black;
}
.reviews-block__wrapper {
  flex: 1 0 auto;
  padding-bottom: 3rem;
}
.reviews-block__image {
  @include sbg($size: 60%);
  width: 12rem;
  height: 12rem;
  min-width: 12rem;
  min-height: 12rem;
  border-radius: 50%;
  background-image: url(../images/avatar_ellipse.svg);
  border: 1px solid #e0e3ed;
  background-color: #f5f6f8;
  background-size: cover;
}
.reviews-block__name,
.reviews-block__title,
.reviews-block__desc {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

/*.reviews-block__desc .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}*/
.reviews-block__name {
  font: 1.8rem/1.2em $font_sans_sb;
}
.reviews-block__title {
  display: inline-flex;
  align-items: center;
  padding-top: 3rem;
}
.reviews-block__desc {
  font: 300 1.6rem/1.4em $font_default;
  padding-top: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.content-outer {
  .reviews-block__wrapper {
    display: inline-flex;
    flex-flow: row nowrap;
  }
  .reviews-block__title {
    flex-flow: column wrap;
    min-width: 30rem;
  }
  .reviews-block__name {
    padding-top: 1rem;
    text-align: center;
  }
  .content__about-wrapper {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
}
@media screen and (max-width: 1379px) {
  .reviews-block__title {
    flex-flow: column wrap;
    width: 100%;
  }
  .reviews-block__name {
    padding-top: 1rem;
    text-align: center;
  }
  .content-outer {
    .reviews-block__title {
      width: auto;
    }
  }
}
@media screen and (max-width: 767px) {
  .reviews-block__title,
  .reviews-block__desc {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .reviews-block__title {
    font-size: 1.6rem;
  }
  .reviews-block__desc {
    font-size: 1.5rem;
  }
  .content-outer {
    .reviews-block__wrapper {
      flex-flow: column wrap;
    }

    .reviews-block__title {
      min-width: 0;
    }
  }
}

.wide-site-block {
	display: block;
	color: $cBlack;
	background-color: $cWhite;
	padding: 2rem 3rem;
	border-radius: 6px;
	box-shadow: 0 0 10px rgba(#687c8f, .28);
	transition: box-shadow .2s ease-in;

	&:hover {
		box-shadow: 0 0 15px rgba(#687c8f, .48);
		transition: box-shadow .2s ease-in;
	}
}
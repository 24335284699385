.interest-block__desc-link {
  @include pos(absolute, 1, $b: 5rem, $l: 0);
  width: 100%;
  color: $cWhite;
  text-align: center;
  opacity: 0;
  transform: translateY(0);
  transition: all $animateSpeed ease-in;
  will-change: contents;

  .btn_thm_white {
    &:hover {
      color: #3045a7;
      background-color: $cWhite;
    }
  }
}
.table-on-fire__inner {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-left: 52rem;

  &--double-scroll {
    display: block;
    height: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-left: 52rem;
    border: none 0 black;

    .double-scroll--content {
      overflow-x: scroll;
      overflow-y: hidden;
      height: 1rem;
    }
  }
}
.news-outer {
  margin-bottom: 1rem;
  .news-inner {
    background-color: $c_white;
    box-shadow: 0 0 10px rgba(#687c8f, .28);
    border-radius: 6px;
    padding: 3rem 2rem;
  }
}
.news-content-wrapper {
  width: 100%;
  max-width: 84.8rem;
  margin: 0 auto;
}
.news-content__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 4rem;
}

.news-content__body {
  font-weight: 300;
  line-height: 1.4em;
  text-align: justify;
  word-break: break-word;
}

.news-content__image {
  flex: 1 0 auto;
  width: 31.6rem;
  @include resp_height($height: 236 / 316 * 100%);
  @include sbg($size: cover);
  border-radius: 6px;
}
.news-content__description {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 3rem;
}
.news-content__title {
  font: 3.2rem/1.2em $font_default;
  color: $c_black;
  text-transform: uppercase;
}
.news-content__info {
  span {
    margin-right: 3rem;
    &:last-child {
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 1329px) {
  .news-outer {
    margin-bottom: 0;
    .news-inner {
      box-shadow: none;
    }
  }
}
@media screen and (max-width: 767px) {
  .news-outer {
    .news-inner {
      padding-top: 0;
    }
  }
  .news-content__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .news-content__image {
    width: 100%;
    order: 2;
  }
  .news-content__description {
    width: 100%;
    order: 1;
    margin-bottom: 3rem;
    padding-left: 0;
  }
  .news-content__title {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 479px) {
  .news-content__title {
    font-size: 2.2rem;
  }
}

%magic-title {
  overflow: hidden;

  &:hover {
    overflow: visible;
  }

  span {
    display: block;
  }
}
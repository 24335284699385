.home-commission-result-outer {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.commission-result-array {
  .commission-result-block {
    display: inline-flex;
    width: 297.5 / 1280 * 100%;
    margin-right: 30 / 1280 * 100%;
    margin-bottom: 3rem;
    &:nth-child(4n+4) {
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 999px) {
  .commission-result-array {
    .commission-result-block {
      width: 48%;
      margin-right: 4%;
      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 639px) {
  .commission-result-array {
    .commission-result-block {
      width: 100%;
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 360px) {
  .home-commission-result-outer {
    .home-commission-result-inner {
      .button_type_move {
        width: 100%;
      }
    }
  }
}

.swipe-left {
  transition: all 0.2s linear;

  &-leave-active {
    position: absolute;
    transition: all 0.2s linear;
  }

  &-leave-to {
    transform: translateX(-100%);
  }

  &-enter-active {
    transition: all 0.2s linear;
  }

  &-enter {
    transform: translateX(100%);
  }
}

.swipe-right {
  transition: all 0.2s linear;

  &-leave-active {
    position: absolute;
    transition: all 0.2s linear;
  }

  &-leave-to {
    transform: translateX(100%);
  }

  &-enter-active {
    transition: all 0.2s linear;
  }

  &-enter {
    transform: translateX(-100%);
  }
}

.footer__inner {
  @extend %block-inner;
  @include sflx();
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

}

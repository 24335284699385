%s-alert {
  padding: 1.1rem;
  font-size: 1.5rem;
  border-radius: $gBorderRadius;
}

$listOfAlertColors: (
  ('warning', $cAlertYellow, $cYellowIcons),
  ('error', $cAlertRed, $cRed),
  ('success', $cAlertGreenNew, $cAlertIconGreen),
  ('grey', $cAlertGrey, $cAlertIconGrey),
  ('blue', $cLighterBlue, $cLightBlue),
  ('primary', $cAlertBlue),
);

@each $name, $firstColor, $secondColor in $listOfAlertColors {
  .s-alert-#{$name} {
    @extend %s-alert;
    background-color: $firstColor;

    .s-str-ico,
    .s-str-ico-rev,
    .s-ico {
      &::before {
        font-size: 1.5em;
      }
    }

    .s-str-ico {
      padding-left: 2.1em;
    }

    .s-str-ico-rev {
      padding-right: 2.1em;
    }
    @if ($secondColor) {
      .s-str-ico,
      .s-str-ico-rev,
      .s-ico {
        &::before {
          color: $secondColor;
        }
      }
    }
  }
}

.s-form__inform {
  @extend .s-alert-warning;
  @extend .mb-25;
  font: 1.6rem/1.2em 'Open Sans', sans-serif;
  color: #000000;
  padding-left: 4rem;


  .s-str-ico {
    padding-left: 3.2rem;
    line-height: 1.7rem;
    &::before {
      color: $cYellowIcons;
      font-size: 1.5em;
    }
  }

  i {
    color: $cRed;
    font-style: normal;
  }
}


.s-form__inform--red {
  @extend .s-alert-error;
  @extend .s-form__inform;
}

.s-form__inform--green {
  @extend .s-alert-success;
  @extend .s-form__inform;
}

.s-form__inform--grey {
  @extend .s-alert-grey;
  @extend .s-form__inform;
}

.s-form__inform--blue {
  @extend .s-alert-blue;
  @extend .s-form__inform;
}

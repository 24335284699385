.preform__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 3rem;

  .time-block {
    flex-shrink: 0;
  }

  .modal-inform {
    margin-bottom: 0;
  }

  @media screen and (max-width: 639px) {
    flex-direction: column;

    .modal-inform {
      font-size: 1.5rem;
      text-align: left;
    }
    .time-block {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
}

.form__college-request {
  max-width: 76.8rem;
  margin: 0 auto;
  .form__radio {
    display: inline-block;
    width: 20rem;
    vertical-align: middle;
  }
  .button {
    min-width: 24.4rem;
  }
}
.form__string-triple {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  .__string {
    width: 430 / 768 * 100%;
  }
  .__date {
    display: flex;
    width: 338 / 768 * 100%;
    min-width: 33.8rem;
    justify-content: flex-start;
    align-items: flex-end;
    span {
      margin-left: 3rem;
      &:first-of-type {
        position: relative;
        &:after {
          content: '–';
          position: absolute;
          z-index: 1;
          bottom: 2rem;
          right: -1.8rem;
        }
      }
    }
    }
}

.form__statement {
  max-width: 64.8rem;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  .form__string {
    &:last-child {
      text-align: center;
    }
    .form__checkbox {
      text-align: center;
    }
  }
  .form__bottom {
    align-items: flex-start;
    .form__checkbox {
      width: 50%;
      label {
        font-family: $font_sans_sb;
        span {
          position: absolute;
          font-family: $font_default;
        }
      }
    }
    button {
      min-width: 24.4rem;
      font-family: $font_sans_sb;
    }
  }
}
.statement__upload-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  input[type="file"] {
    display: none;
  }
  .button {
    width: 312 / 648 * 100%;
    &:first-child {
      margin-right: 24 / 648 * 100%;
    }
  }
  .button_type_remove, .button_type_file {
    border: 1px solid $c_black;
    color: $c_black;
    font-family: $font_sans_sb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
      content: '\E646';
      color: $c_black;
    }
    i {
      &:after {
        content: '\E646';
        color: $c_black;
      }
    }
  }
}
.warning__text {
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
}
.warning__error {
  @extend .warning__text;
  font-family: $font_sans_sb;
  color: $c_red;
}
.statement__info {
  font: bold 1.6rem/1.2em $font_default;
  color: $c_red;
  text-align: center;
}


@media screen and (max-width: 999px) {
  .form__statement {
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .profile-content__line-between {
    .warning__text {
      width: 100%;
      text-align: left;
      margin-bottom: 3rem;
    }
    .modal-inform {
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: 699px) {
  .statement__upload-wrapper {
    flex-direction: column;
    .button {
      width: 100% !important;
      margin-left: 0 !important;
      &:first-of-type {
        margin-right: 0 !important;
        margin-bottom: 2rem !important;
      }
    }
  }
}


@media screen and (max-width: 599px) {
  .form__string-triple {
    flex-direction: column;
    .__string {
      width: 100%;
      margin-bottom: 3rem;
    }
    .__date {
      width: 100%;
      span {
        width: 50%;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .form__statement {
    .form__bottom {
      .form__checkbox {
        width: 100%;
        margin-bottom: 2rem;
        input[type=checkbox] {
          + label {
            &:before {
              bottom: auto;
              top: 0.2rem;
            }
            span {
              display: block;
              position: relative;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .form__college-request {
    .form__radio {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 399px) {
  .form__string-triple {
    .__date {
      flex-direction: column;
      min-width: unset;
      span {
        width: 100%;
        margin-left: 0;
        &:first-of-type {
          margin-bottom: 3rem;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
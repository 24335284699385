.cabinet-menu__item-gov-service {
  @extend .cabinet-menu__item;
  padding-left: 6rem;
  padding-right: 4.6rem;

  &::before {
    @include pos(absolute, $l: 1rem, $t: 0);
    width: 3.5rem;
    height: 100%;
    display: block;
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    filter: invert(27%) sepia(11%) saturate(7402%) hue-rotate(207deg) brightness(93%) contrast(96%);
  }

  &--first-class {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/school-statement.png");
    }
  }

  &--daryn {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/special-school.png");
    }
  }

  &--transfer-class {
    &::before {
      background-image: url("../indv/mektep/images/feature-block--07.svg");
    }
  }

  &--transfer-school {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/school-transfer.png");
    }
  }

  &--camps {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/camp-direction.png");
    }
  }

  &--free-food {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/free-food.png");
    }
  }

  &--delivery {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/free-pickup.png");
    }
  }

  &--additional-edu {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/additional-education.png");
    }
  }

  &--payment {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/payment.png");
    }
  }

  &--documents-duplicate {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/documents-duplicate.png");
    }
  }

  &--individual-education {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/individual-education.png");
    }
  }

  &--external-study {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/external-study.png");
    }
  }

  &--rehabilitation {
    &::before {
      background-image: url("../indv/mektep/images/new-feature-blocks/rehabilitation.png");
    }
  }
}

.sn-tab__content {
  height: inherit;
  padding: 0.4rem 0;
  min-width: 4rem;

  &--inline {
    .sn-tab__icon + .sn-tab__label {
      padding-left: 0.8rem;
    }
  }

  .sn-chip--floating {
    top: 0;
    right: -1.6rem;
  }
}

.s-plain-text {
  line-height: 1.5em;

  ol {
    margin-bottom: 2rem;

    li::before {
      content: none;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 4rem;
  }

  ol,
  ul {
    li {
      margin-bottom: 1rem;
    }
  }
}

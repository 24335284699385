.about-inner {
  padding: 3rem 0;
}

.about-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 4.5rem;
}

.about__image {
  height: 6.9rem;

  img {
    height: 100%;
  }
}

.about__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.about__row--last {
  justify-content: flex-end;
}

.about__row--statistic {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.about__block--statistic {
  display: flex;
  flex-direction: column;
  width: 25.5rem;
  height: 15rem;
  max-width: 100%;
  justify-content: space-around;
  box-shadow: 0 0 10px rgba(104, 124, 143, 0.28);
  padding: 2rem;
  border-radius: 6px;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
}

.s-logotype {
  margin-right: 4.5rem;
}

.about__header {
  color: #3045a7;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
}

.about__text {
  color: #3045a7;
  font-size: 1.8rem;
  text-align: center;
}

.about__hr {
  width: 4rem;
  height: .5rem;
  background: no-repeat 50% 50% url("../images/basqarma/line.png");
}


.statistic_iframe--element {
  width: 100%;
  height: 80vh;
  min-height: 500px;
}

.tooltip-container {
  position: absolute;
  top: 4%;
  left: 40%;
}

.tooltip__item {
  width: 28.3rem;
  max-width: 100%;
  height: 14.2rem;
  text-align: center;
  color: white;
  padding: 4rem;
  background: no-repeat 50% 50% url("../images/basqarma/tooltip.png");
}

.statistic_iframe {
  position: relative;

  iframe:hover + .tooltip-container {
    display: none;
  }
}

.about__block {
  font-size: 1.8rem;
  border-radius: 6px;
  width: 30.337rem;
  max-width: 100%;
  height: 12rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  flex-direction: column;
}

.about__block--mini-text {
  font-size: 1.4rem;
}

.about__back-btn {
  margin-left: 35rem
}

.about__block--image {
  height: 6.9rem;
  width: 23.9rem;
  max-width: 100%;
  background: no-repeat 50% 50%;
  text-align: center;

  &--balabaqsha {
    background-image: url("../images/basqarma/sitcen_balabaqsha.png");
  }

  &--jumys {
    background-image: url("../images/basqarma/sitcen_jumys.png");
  }

  &--college {
    background-image: url("../images/basqarma/sitcen_college.png");
  }

  &--mektep {
    background-image: url("../images/basqarma/sitcen_mektep.png");
  }

  img {
    height: 100%;
  }
}

.about__block--image--path {
  width: 100%;
  height: 13.3rem;
  background: no-repeat url("../images/basqarma/path.png");
  background-size: 100% 100%;
}

.about__block--image--adaptive-path {
  width: 33.2rem;
  height: 111rem;
  background: no-repeat 50% 50% url("../images/basqarma/adaptive-path.png");
  background-size: contain;
  background-position-y: 15.5rem;
  display: none;
}

.about__info {
  width: 23.9rem;
  max-width: 100%;
  text-align: center;
}

@media screen and (max-width: 1500px) {
  .about__back-btn {
    margin-left: 27rem
  }
}

@media screen and (max-width: 1280px) {
  .about__block--image--jumys {
    width: 18rem;
  }
  .about__row {
    justify-content: space-around;
  }
  .about__block {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1080px) {
  .s-logotype {
    margin-right: 1.5rem;
  }
}

//@media screen and (max-width: 1020px) {
//  .container-content-head__tab-item {
//    margin-left: 2rem;
//  }
//}

@media screen and (max-width: 1000px) {
  .about__block--image--path {
    display: none;
  }
  .tooltip__item {
    display: none;
  }
  .about__block--image--adaptive-path {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about__block--image--adaptive-container {
    margin-bottom: 16.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .about__block--image--jumys {
    width: 23.9rem;
  }
  .about__row--adaptive-hide {
    display: none;
  }
  .statistic_iframe--element {
    height: auto;
    min-height: 500px;
  }
}

@media screen and (max-width: 600px) {
  .about__back-btn {
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .about-container {
    width: auto;
    margin-right: -2rem;
    margin-left: -2rem;
    margin-bottom: unset;
  }
  .about__block--statistic {
    margin-right: 0;
  }
}

@media screen and (max-width: 325px) {
  .about__block--image--adaptive-container {
    margin-bottom: 15.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  // Обнуление стилей таблицы
  .s-table {
    &:not( .s-table--without-head ) {
      display: block;

      .s-table__head {
        display: none;
      }

      .s-table__body {
        display: block;
        width: 100%;

        tr {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }

        td {
          display: flex;
          width: 100%;
          height: unset;
        }
      }

      .s-table__col--to-center,
      .s-table__col--to-right {
        text-align: left;
      }
    }
  }


  // Обнуление стилей desktop
  .s-table--bordered {
    &:not( .s-table--without-head ) {
      @include pos();

      &::before {
        @include pos(absolute, $t: 0, $l: calc(40% + 0.3rem));
        width: 0.1rem;
        height: 100%;
        background-color: $cBorder;
        content: '';
      }

      th,
      td {
        &:not( :last-child ) {
          border-right: unset;
        }

        &[ data-table-head ] {
          &::before {
            margin-right: 2rem;
          }
        }
      }
    }
  }

  // Адаптивная таблица
  .s-table__body {
    [ data-table-head ] {
      @include sflx($alitems: center);
      flex: 1 0 auto;
      padding-left: 42%;
      position: relative;

      &::before {
        display: block;
        position: absolute;
        min-width: 40%;
        width: 40%;
        max-width: 40%;
        text-align: right;
        left: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        content: attr(data-table-head);
      }
    }
  }
}


@media screen and ( max-width: 480px ) {
  .s-table--bordered {
    &::before {
      display: none;
    }
  }

  .s-table__body {
    [ data-table-head ] {
      flex-direction: column !important;
      align-items: flex-start;
      font-weight: 600;
      padding-left: 1rem;

      &::before {
        min-width: unset;
        position: unset;
        padding-left: unset;
        padding-right: 2rem;
        width: 100%;
        max-width: unset;
        font-weight: 400;
        text-align: left;
        padding-bottom: 1rem;
      }
    }
  }

  // Обнуление стилей для таблиц без шапки
  .s-table--without-head {
    .s-table__body,
    tr,
    td {
      display: block;
      width: 100%;
    }

    td {
      height: unset;
    }

    &.s-table--bordered {
      td {
        &:not( :last-child ) {
          border-right: unset;
        }
      }
    }

    .s-table__col--to-center,
    .s-table__col--to-right {
      text-align: left;
    }
  }
}

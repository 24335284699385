.s-form__loop {
  @include pos();
  display: block;
  width: 100%;

  .s-form__input {
    padding-right: 4rem;
  }

  &::after {
    @include pos(absolute, 1, 1rem, $r: 1rem);
    font-weight: 400;
    font-size: 1.8rem;
    font-family: $fIcons;
    color: $cGreyText;
    content: '\E610';
  }

  &--white {
    &::after {
      color: $cWhite;
    }
  }
}

.s-form__loop--validate {
  &::after {
    top: 1rem;
  }
}

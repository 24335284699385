.s-steps__item-icon {
  @include pos($z: 1);
  @include sflx($juscont: center, $alitems: center);
  width: 4rem;
  height: 4rem;
  font-size: 1.8rem;
  color: #3C4F6D;
  background-color: #D9E3ED;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s,
    color 0.1s linear 0s,
    background-color 0.1s linear 0s;
}

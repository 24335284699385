@import url('~normalize.css');
@import url('~owl.carousel/dist/assets/owl.carousel.css');
@import url('~select2/dist/css/select2.css');
@import url('~cropperjs/dist/cropper.css');
@import url('~jquery.growl/stylesheets/jquery.growl.css');
@import '~vue-select/src/scss/vue-select.scss';
@import 'templates';
@import 'mixins';
@import 'animations';
@import 'configuration';
@import 'grid/bootstrap-grid';
@import 'components';
@import 'composite';
@import 'views';
@import 'quasar';
@import 'common';

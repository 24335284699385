.college-library-filter--wrapper {
  display: flex;
}

.college-library-filter {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.college-library-filter--input {
  flex-grow: 1;
  margin-left: 2rem;
}

.college-library-filter--select {
  width:20%;
  flex-grow: 2;
  margin-left: 2rem;
}

.college-library-filter--btn-toggle {
  flex-shrink: 0;
}

.kindergarten-block__picture {
  @include sbg($s: cover);
  flex-shrink: 0;
  display: block;
  width: 20rem;
  max-height: 23rem;
  border-radius: $gBorderRadius;

  &::before {
    display: block;
    margin-top: 230 / 200 * 100%;
    content: '';
  }


  @media screen and (max-width: 479px) {
    width: 100%;
    max-height: unset;
  }
}
.course-section {
  @include sflx();
  color: $cBlack;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);
  transition: box-shadow .2s ease-in;

  &:hover {
    box-shadow: 0 0 30px rgba(74, 102, 126, 0.38);
    transition: box-shadow .2s ease-in;
  }


  @media screen and (max-width: 599px) {
    flex-direction: column;
    width: 100%;
    max-width: 36rem;

    .course-section__picture,
    .course-section__content {
      padding: 1.5rem;
    }

    .course-section__picture {
      background-origin: unset;
    }
  }
}

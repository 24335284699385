.s-form__search {
  @include pos();

  .s-form__input {
    padding-right: 5rem;
  }

  .s-ico {
    @include pos(absolute, $t: 0, $r: 0);
    width: 4rem;
    height: 100%;

    &::before {
      display: block;
      transform: scale(1);
      transition: transform $gDuration ease-in;
    }

    &:hover,
    &:focus {
      &::before {
        transform: scale(1.36);
        transition: transform $gDuration ease-in;
      }
    }
  }
}

@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-table-schedule {
    table-layout: unset;

    td,
    th {
      min-width: 14.4rem;
    }
  }

  .s-table-schedule__card {
    min-width: 14.2rem;
  }
}

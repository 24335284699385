.s-main-header__section {
  max-width: 20rem !important;
}

@media screen and (max-width: 1170px) {
  .s-main-header__section {
    &:not( :last-child ) {
      margin-right: 0;
    }
  }

  .s-main-header--guest-mode {
    .s-main-header__region {
      display: none;
    }
  }
}


@media screen and (max-width: $gMinWidthDesktop - 1px) {
  .s-main-header {
    &:not(.s-main-header--without-adaptive) {
      padding: 1rem 2rem;
    }
  }

  .s-main-header__left-panel {
    padding: 0;
  }

  .s-main-header__right-panel {
    &:not(.s-main-header__right-panel--without-adaptive) {
      display: none !important;
    }
  }

  .s-main-header__burger {
    display: flex;
  }
}


@media screen and (max-width: $gMinWidthTablet - 1px) {
  .s-main-header__phone {
    display: none;
  }
}

html {
  font-size: $gFontSize;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include sflx($dir: column, $alitems: center);
  @include selection();
  font-size: 1.6rem;
  font-family: $fDef;
  color: $cBlack;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  a {
    color: $cBlack;
    text-decoration: none;

    &[ href^='http' ]:not( [ class ] ),
    &[ href^='/' ]:not( [ class ] ) {
      color: $cBlue;
      border-bottom: 1px solid transparent;
      transition: border-color $gDuration ease-in;

      &:hover {
        border-color: darken($cBlue, 1%);
        transition: border-color $gDuration ease-in;
      }
    }
  }

  [ disabled ] {
    cursor: not-allowed;
  }

  .hidden {
    display: none !important;
  }
}

strong {
  font-weight: 600;
}


*::-webkit-scrollbar {
  background-color: #f4f4f4;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #25367E;
  border: 2px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #25367E;
  border: 0 solid #f4f4f4;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar {
  background-color: #f4f4f4;
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #25367E;
  border: 2px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #25367E;
  border: 0 solid #f4f4f4;
}

*::-webkit-scrollbar-button {
  display: none;
}

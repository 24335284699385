.s-circular-progress-bar {
  &__container {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    overflow: hidden;

    svg {
      filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.25));

      circle {
        fill: none;
        stroke-width: 25px;
      }
    }
  }
}

.sn-toggle__thumb {
  width: 2.5rem;
  height: 2.5rem;
  border: 1.5rem solid;
  border-radius: 50%;
  border-color: $cWhite;
  box-shadow: 0 0.3rem 0.1rem -0.2rem rgba($cBlack, 0.2),
    0 0.2rem 0.2rem 0 rgba($cBlack, 0.14),
    0 0.1rem 0.5rem 0 rgba($cBlack, 0.12);

  .s-ico {
    font-size: 1.4rem;
    width: 2.5rem;
    height: 0;
    line-height: 0;
    color: $cBlack;
    opacity: 0.54;
  }
}

.inform-block__value {
  @include sflx($juscont: center, $alitems: center);
  width: 100%;

  > *:not(:last-child) {
    margin-right: 1.5rem;
  }

  span {
    font-weight: 600;
    font-size: 3.5rem;
    text-transform: uppercase;

    i {
      font-style: normal;
      font-size: 1.8rem;
      text-transform: none;
    }
  }

  ul {
    li {
      font-size: 1.7rem;
      font-weight: 600;

      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }

  img {
    border-radius: 50%;
    box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);
  }
}

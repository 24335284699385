// Global variables
$gFontSize: 10px;
$gBorderRadius: 5px;
$gMinWidthMobile: 320px;
$gMinWidthTablet: 768px;
$gMinWidthDesktop: 1009px;
$gWidthDef: 1400px;
$gWidthMid: 1600px;
$gMaxWidth: 2560px;
$gDuration: 0.2s;
$gShadow: 0 0.1rem 0.5rem rgba($cBlack, 0.2),
  0 0.2rem 0.2rem rgba($cBlack, 0.14),
  0 0.3rem 0.1rem -0.2rem rgba($cBlack, 0.12);
$gShadowHover: 0 0.3rem 0.5rem -0.1rem rgba($cBlack, 0.2),
  0 0.5rem 0.8rem rgba($cBlack, 0.14),
  0 0.1rem 1rem rgba($cBlack, 0.12);
$gShadowActive: 0 0.3rem 0.5rem -0.1rem rgba($cBlack, 0.2),
  0 0.5rem 0.8rem rgba($cBlack, 0.14),
  0 0.1rem 10.4rem rgba($cBlack, 0.12);
$gLoaderPlaceholder: '../../../images/placeholder.svg';

// Fonts variables
$fDef: 'Open Sans', sans-serif;
$fFAIcons: 'Font Awesome 5 Pro';
$fFABrandsIcons: 'Font Awesome 5 Brands';
$fIcons: snation;
$lh: 1.4em;


// Common variables
$listOfIcons: (
  // Themify
  'wand': '\E600',
  'volume': '\E601',
  'user': '\E602',
  'unlock': '\E603',
  'unlink': '\E604',
  'trash': '\E605',
  'thought': '\E606',
  'target': '\E607',
  'tag': '\E608',
  'tablet': '\E609',
  'star': '\E60A',
  'spray': '\E60B',
  'signal': '\E60C',
  'shopping-cart': '\E60D',
  'shopping-cart-full': '\E60E',
  'settings': '\E60F',
  'search': '\E610',
  'zoom-in': '\E611',
  'zoom-out': '\E612',
  'cut': '\E613',
  'ruler': '\E614',
  'ruler-pencil': '\E615',
  'ruler-alt': '\E616',
  'bookmark': '\E617',
  'bookmark-alt': '\E618',
  'reload': '\E619',
  'plus': '\E61A',
  'pin': '\E61B',
  'pencil': '\E61C',
  'pencil-alt': '\E61D',
  'paint-roller': '\E61E',
  'paint-bucket': '\E61F',
  'na': '\E620',
  'mobile': '\E621',
  'minus': '\E622',
  'medall': '\E623',
  'medall-alt': '\E624',
  'marker': '\E625',
  'marker-alt': '\E626',
  'arrow-up': '\E627',
  'arrow-right': '\E628',
  'arrow-left': '\E629',
  'arrow-down': '\E62A',
  'lock': '\E62B',
  'location-arrow': '\E62C',
  'link': '\E62D',
  'layout': '\E62E',
  'layers': '\E62F',
  'layers-alt': '\E630',
  'key': '\E631',
  'import': '\E632',
  'image': '\E633',
  'heart': '\E634',
  'heart-broken': '\E635',
  'hand-stop': '\E636',
  'hand-open': '\E637',
  'hand-drag': '\E638',
  'folder': '\E639',
  'flag': '\E63A',
  'flag-alt': '\E63B',
  'flag-alt-2': '\E63C',
  'eye': '\E63D',
  'export': '\E63E',
  'exchange-vertical': '\E63F',
  'desktop': '\E640',
  'cup': '\E641',
  'crown': '\E642',
  'comments': '\E643',
  'comment': '\E644',
  'comment-alt': '\E645',
  'close': '\E646',
  'clip': '\E647',
  'angle-up': '\E648',
  'angle-right': '\E649',
  'angle-left': '\E64A',
  'angle-down': '\E64B',
  'check': '\E64C',
  'check-box': '\E64D',
  'camera': '\E64E',
  'announcement': '\E64F',
  'brush': '\E650',
  'briefcase': '\E651',
  'bolt': '\E652',
  'bolt-alt': '\E653',
  'blackboard': '\E654',
  'bag': '\E655',
  'move': '\E656',
  'arrows-vertical': '\E657',
  'arrows-horizontal': '\E658',
  'fullscreen': '\E659',
  'arrow-top-right': '\E65A',
  'arrow-top-left': '\E65B',
  'arrow-circle-up': '\E65C',
  'arrow-circle-right': '\E65D',
  'arrow-circle-left': '\E65E',
  'arrow-circle-down': '\E65F',
  'angle-double-up': '\E660',
  'angle-double-right': '\E661',
  'angle-double-left': '\E662',
  'angle-double-down': '\E663',
  'zip': '\E664',
  'world': '\E665',
  'wheelchair': '\E666',
  'view-list': '\E667',
  'view-list-alt': '\E668',
  'view-grid': '\E669',
  'uppercase': '\E66A',
  'upload': '\E66B',
  'underline': '\E66C',
  'truck': '\E66D',
  'timer': '\E66E',
  'ticket': '\E66F',
  'thumb-up': '\E670',
  'thumb-down': '\E671',
  'text': '\E672',
  'stats-up': '\E673',
  'stats-down': '\E674',
  'split-v': '\E675',
  'split-h': '\E676',
  'smallcap': '\E677',
  'shine': '\E678',
  'shift-right': '\E679',
  'shift-left': '\E67A',
  'shield': '\E67B',
  'notepad': '\E67C',
  'server': '\E67D',
  'quote-right': '\E67E',
  'quote-left': '\E67F',
  'pulse': '\E680',
  'printer': '\E681',
  'power-off': '\E682',
  'plug': '\E683',
  'pie-chart': '\E684',
  'paragraph': '\E685',
  'panel': '\E686',
  'package': '\E687',
  'music': '\E688',
  'music-alt': '\E689',
  'mouse': '\E68A',
  'mouse-alt': '\E68B',
  'money': '\E68C',
  'microphone': '\E68D',
  'menu': '\E68E',
  'menu-alt': '\E68F',
  'map': '\E690',
  'map-alt': '\E691',
  'loop': '\E692',
  'location-pin': '\E693',
  'list': '\E694',
  'light-bulb': '\E695',
  'Italic': '\E696',
  'info': '\E697',
  'infinite': '\E698',
  'id-badge': '\E699',
  'hummer': '\E69A',
  'home': '\E69B',
  'help': '\E69C',
  'headphone': '\E69D',
  'harddrives': '\E69E',
  'harddrive': '\E69F',
  'gift': '\E6A0',
  'game': '\E6A1',
  'filter': '\E6A2',
  'files': '\E6A3',
  'file': '\E6A4',
  'eraser': '\E6A5',
  'envelope': '\E6A6',
  'download': '\E6A7',
  'direction': '\E6A8',
  'direction-alt': '\E6A9',
  'dashboard': '\E6AA',
  'control-stop': '\E6AB',
  'control-shuffle': '\E6AC',
  'control-play': '\E6AD',
  'control-pause': '\E6AE',
  'control-forward': '\E6AF',
  'control-backward': '\E6B0',
  'cloud': '\E6B1',
  'cloud-up': '\E6B2',
  'cloud-down': '\E6B3',
  'clipboard': '\E6B4',
  'car': '\E6B5',
  'calendar': '\E6B6',
  'book': '\E6B7',
  'bell': '\E6B8',
  'basketball': '\E6B9',
  'bar-chart': '\E6BA',
  'bar-chart-alt': '\E6BB',
  'back-right': '\E6BC',
  'back-left': '\E6BD',
  'arrows-corner': '\E6BE',
  'archive': '\E6BF',
  'anchor': '\E6C0',
  'align-right': '\E6C1',
  'align-left': '\E6C2',
  'align-justify': '\E6C3',
  'align-center': '\E6C4',
  'alert': '\E6C5',
  'alarm-clock': '\E6C6',
  'agenda': '\E6C7',
  'write': '\E6C8',
  'window': '\E6C9',
  'widgetized': '\E6CA',
  'widget': '\E6CB',
  'widget-alt': '\E6CC',
  'wallet': '\E6CD',
  'video-clapper': '\E6CE',
  'video-camera': '\E6CF',
  'vector': '\E6D0',
  'themify-logo': '\E6D1',
  'themify-favicon': '\E6D2',
  'themify-favicon-alt': '\E6D3',
  'support': '\E6D4',
  'stamp': '\E6D5',
  'split-v-alt': '\E6D6',
  'slice': '\E6D7',
  'shortcode': '\E6D8',
  'shift-right-alt': '\E6D9',
  'shift-left-alt': '\E6DA',
  'ruler-alt-2': '\E6DB',
  'receipt': '\E6DC',
  'pin2': '\E6DD',
  'pin-alt': '\E6DE',
  'pencil-alt2': '\E6DF',
  'palette': '\E6E0',
  'more': '\E6E1',
  'more-alt': '\E6E2',
  'microphone-alt': '\E6E3',
  'magnet': '\E6E4',
  'line-double': '\E6E5',
  'line-dotted': '\E6E6',
  'line-dashed': '\E6E7',
  'layout-width-full': '\E6E8',
  'layout-width-default': '\E6E9',
  'layout-width-default-alt': '\E6EA',
  'layout-tab': '\E6EB',
  'layout-tab-window': '\E6EC',
  'layout-tab-v': '\E6ED',
  'layout-tab-min': '\E6EE',
  'layout-slider': '\E6EF',
  'layout-slider-alt': '\E6F0',
  'layout-sidebar-right': '\E6F1',
  'layout-sidebar-none': '\E6F2',
  'layout-sidebar-left': '\E6F3',
  'layout-placeholder': '\E6F4',
  'layout-menu': '\E6F5',
  'layout-menu-v': '\E6F6',
  'layout-menu-separated': '\E6F7',
  'layout-menu-full': '\E6F8',
  'layout-media-right-alt': '\E6F9',
  'layout-media-right': '\E6FA',
  'layout-media-overlay': '\E6FB',
  'layout-media-overlay-alt': '\E6FC',
  'layout-media-overlay-alt-2': '\E6FD',
  'layout-media-left-alt': '\E6FE',
  'layout-media-left': '\E6FF',
  'layout-media-center-alt': '\E700',
  'layout-media-center': '\E701',
  'layout-list-thumb': '\E702',
  'layout-list-thumb-alt': '\E703',
  'layout-list-post': '\E704',
  'layout-list-large-image': '\E705',
  'layout-line-solid': '\E706',
  'layout-grid4': '\E707',
  'layout-grid3': '\E708',
  'layout-grid2': '\E709',
  'layout-grid2-thumb': '\E70A',
  'layout-cta-right': '\E70B',
  'layout-cta-left': '\E70C',
  'layout-cta-center': '\E70D',
  'layout-cta-btn-right': '\E70E',
  'layout-cta-btn-left': '\E70F',
  'layout-column4': '\E710',
  'layout-column3': '\E711',
  'layout-column2': '\E712',
  'layout-accordion-separated': '\E713',
  'layout-accordion-merged': '\E714',
  'layout-accordion-list': '\E715',
  'ink-pen': '\E716',
  'info-alt': '\E717',
  'help-alt': '\E718',
  'headphone-alt': '\E719',
  'hand-point-up': '\E71A',
  'hand-point-right': '\E71B',
  'hand-point-left': '\E71C',
  'hand-point-down': '\E71D',
  'gallery': '\E71E',
  'face-smile': '\E71F',
  'face-sad': '\E720',
  'credit-card': '\E721',
  'control-skip-forward': '\E722',
  'control-skip-backward': '\E723',
  'control-record': '\E724',
  'control-eject': '\E725',
  'comments-smiley': '\E726',
  'brush-alt': '\E727',
  'youtube': '\E728',
  'vimeo': '\E729',
  'twitter': '\E72A',
  'time': '\E72B',
  'tumblr': '\E72C',
  'skype': '\E72D',
  'share': '\E72E',
  'share-alt': '\E72F',
  'rocket': '\E730',
  'pinterest': '\E731',
  'new-window': '\E732',
  'microsoft': '\E733',
  'list-ol': '\E734',
  'linkedin': '\E735',
  'layout-sidebar-2': '\E736',
  'layout-grid4-alt': '\E737',
  'layout-grid3-alt': '\E738',
  'layout-grid2-alt': '\E739',
  'layout-column4-alt': '\E73A',
  'layout-column3-alt': '\E73B',
  'layout-column2-alt': '\E73C',
  'instagram': '\E73D',
  'google': '\E73E',
  'github': '\E73F',
  'flickr': '\E740',
  'facebook': '\E741',
  'dropbox': '\E742',
  'dribbble': '\E743',
  'apple': '\E744',
  'android': '\E745',
  'save': '\E746',
  'save-alt': '\E747',
  'yahoo': '\E748',
  'wordpress': '\E749',
  'vimeo-alt': '\E74A',
  'twitter-alt': '\E74B',
  'tumblr-alt': '\E74C',
  'trello': '\E74D',
  'stack-overflow': '\E74E',
  'soundcloud': '\E74F',
  'sharethis': '\E750',
  'sharethis-alt': '\E751',
  'reddit': '\E752',
  'pinterest-alt': '\E753',
  'microsoft-alt': '\E754',
  'linux': '\E755',
  'jsfiddle': '\E756',
  'joomla': '\E757',
  'html5': '\E758',
  'flickr-alt': '\E759',
  'email': '\E75A',
  'drupal': '\E75B',
  'dropbox-alt': '\E75C',
  'css3': '\E75D',
  'rss': '\E75E',
  'rss-alt': '\E75F',
  // Font Awesome 5 pro
  'eat': '\E926',
  'lager': '\E927',
  'bus': '\E928',
  'human': '\E929',
  'attention': '\e92a',
  'check-circle': '\e92b',
  'clock': '\e92c',
  'waiting': '\e92d',
  // Custom SVG
  //'profile': '\E900',
  //'vacancies_director': '\E901',
  //'statistics': '\E902',
  //'notifications': '\E903',
  //'exit': '\E904',
  //'create_vacancy': '\E905',
  //'vacancies_personal': '\E906',
  //'commissions': '\E907',
  //'my_resume': '\E908',
  //'my_portfolio': '\E909',
  //'my_vacancies': '\E90a',
  //'commissions_methodist': '\E90b',
  //'voting': '\E90c',
  //'add_users': '\E90d',
  //'list_users': '\E90e',
  //'school_profile': '\E90f',
  //'schedule': '\E910',
  //'open_competition': '\E911',
  //'invitations': '\E912',
  //'candidates': '\E913',
  'contingent': '\E914',
  'groups': '\E915',
  //'kindergarten_profile': '\E916',
  //'bulletin_of_free_places': '\E917',
  'personal': '\E918',
  //'applications': '\E919',
  //'my_kids': '\E91a',
  //'documents': '\E91b',
  //'applications_by_state_order': '\E91c',
  //'adopted_children': '\E91d',
  //'emergency': '\E91e',
  //'vseobuch': '\E91f',
  //'reporting': '\E920',
  //'government_services': '\E921',
  //'language_selection': '\E922',
  //'region_selection': '\E923',
  //'menu': '\E924',
  'telephone': '\E925',
);

$listOfIconsFA: (
  '500px': '\f26e',
  'abacus': '\f640',
  'accessible-icon': '\f368',
  'accusoft': '\f369',
  'acorn': '\f6ae',
  'acquisitions-incorporated': '\f6af',
  'ad': '\f641',
  'address-book': '\f2b9',
  'address-card': '\f2bb',
  'adjust': '\f042',
  'adn': '\f170',
  'adobe': '\f778',
  'adversal': '\f36a',
  'affiliatetheme': '\f36b',
  'air-freshener': '\f5d0',
  'alarm-clock': '\f34e',
  'algolia': '\f36c',
  'alicorn': '\f6b0',
  'align-center': '\f037',
  'align-justify': '\f039',
  'align-left': '\f036',
  'align-right': '\f038',
  'alipay': '\f642',
  'allergies': '\f461',
  'amazon': '\f270',
  'amazon-pay': '\f42c',
  'ambulance': '\f0f9',
  'american-sign-language-interpreting': '\f2a3',
  'amilia': '\f36d',
  'analytics': '\f643',
  'anchor': '\f13d',
  'android': '\f17b',
  'angel': '\f779',
  'angellist': '\f209',
  'angle-double-down': '\f103',
  'angle-double-left': '\f100',
  'angle-double-right': '\f101',
  'angle-double-up': '\f102',
  'angle-down': '\f107',
  'angle-left': '\f104',
  'angle-right': '\f105',
  'angle-up': '\f106',
  'angry': '\f556',
  'angrycreative': '\f36e',
  'angular': '\f420',
  'ankh': '\f644',
  'app-store': '\f36f',
  'app-store-ios': '\f370',
  'apper': '\f371',
  'apple': '\f179',
  'apple-alt': '\f5d1',
  'apple-crate': '\f6b1',
  'apple-pay': '\f415',
  'archive': '\f187',
  'archway': '\f557',
  'arrow-alt-circle-down': '\f358',
  'arrow-alt-circle-left': '\f359',
  'arrow-alt-circle-right': '\f35a',
  'arrow-alt-circle-up': '\f35b',
  'arrow-alt-down': '\f354',
  'arrow-alt-from-bottom': '\f346',
  'arrow-alt-from-left': '\f347',
  'arrow-alt-from-right': '\f348',
  'arrow-alt-from-top': '\f349',
  'arrow-alt-left': '\f355',
  'arrow-alt-right': '\f356',
  'arrow-alt-square-down': '\f350',
  'arrow-alt-square-left': '\f351',
  'arrow-alt-square-right': '\f352',
  'arrow-alt-square-up': '\f353',
  'arrow-alt-to-bottom': '\f34a',
  'arrow-alt-to-left': '\f34b',
  'arrow-alt-to-right': '\f34c',
  'arrow-alt-to-top': '\f34d',
  'arrow-alt-up': '\f357',
  'arrow-circle-down': '\f0ab',
  'arrow-circle-left': '\f0a8',
  'arrow-circle-right': '\f0a9',
  'arrow-circle-up': '\f0aa',
  'arrow-down': '\f063',
  'arrow-from-bottom': '\f342',
  'arrow-from-left': '\f343',
  'arrow-from-right': '\f344',
  'arrow-from-top': '\f345',
  'arrow-left': '\f060',
  'arrow-right': '\f061',
  'arrow-square-down': '\f339',
  'arrow-square-left': '\f33a',
  'arrow-square-right': '\f33b',
  'arrow-square-up': '\f33c',
  'arrow-to-bottom': '\f33d',
  'arrow-to-left': '\f33e',
  'arrow-to-right': '\f340',
  'arrow-to-top': '\f341',
  'arrow-up': '\f062',
  'arrows': '\f047',
  'arrows-alt': '\f0b2',
  'arrows-alt-h': '\f337',
  'arrows-alt-v': '\f338',
  'arrows-h': '\f07e',
  'arrows-v': '\f07d',
  'artstation': '\f77a',
  'assistive-listening-systems': '\f2a2',
  'asterisk': '\f069',
  'asymmetrik': '\f372',
  'at': '\f1fa',
  'atlas': '\f558',
  'atlassian': '\f77b',
  'atom': '\f5d2',
  'atom-alt': '\f5d3',
  'audible': '\f373',
  'audio-description': '\f29e',
  'autoprefixer': '\f41c',
  'avianex': '\f374',
  'aviato': '\f421',
  'award': '\f559',
  'aws': '\f375',
  'axe': '\f6b2',
  'axe-battle': '\f6b3',
  'baby': '\f77c',
  'baby-carriage': '\f77d',
  'backpack': '\f5d4',
  'backspace': '\f55a',
  'backward': '\f04a',
  'bacon': '\f7e5',
  'badge': '\f335',
  'badge-check': '\f336',
  'badge-dollar': '\f645',
  'badge-percent': '\f646',
  'badger-honey': '\f6b4',
  'balance-scale': '\f24e',
  'balance-scale-left': '\f515',
  'balance-scale-right': '\f516',
  'ball-pile': '\f77e',
  'ballot': '\f732',
  'ballot-check': '\f733',
  'ban': '\f05e',
  'band-aid': '\f462',
  'bandcamp': '\f2d5',
  'barcode': '\f02a',
  'barcode-alt': '\f463',
  'barcode-read': '\f464',
  'barcode-scan': '\f465',
  'bars': '\f0c9',
  'baseball': '\f432',
  'baseball-ball': '\f433',
  'basketball-ball': '\f434',
  'basketball-hoop': '\f435',
  'bat': '\f6b5',
  'bath': '\f2cd',
  'battery-bolt': '\f376',
  'battery-empty': '\f244',
  'battery-full': '\f240',
  'battery-half': '\f242',
  'battery-quarter': '\f243',
  'battery-slash': '\f377',
  'battery-three-quarters': '\f241',
  'bed': '\f236',
  'beer': '\f0fc',
  'behance': '\f1b4',
  'behance-square': '\f1b5',
  'bell': '\f0f3',
  'bell-school': '\f5d5',
  'bell-school-slash': '\f5d6',
  'bell-slash': '\f1f6',
  'bells': '\f77f',
  'bezier-curve': '\f55b',
  'bible': '\f647',
  'bicycle': '\f206',
  'bimobject': '\f378',
  'binoculars': '\f1e5',
  'biohazard': '\f780',
  'birthday-cake': '\f1fd',
  'bitbucket': '\f171',
  'bitcoin': '\f379',
  'bity': '\f37a',
  'black-tie': '\f27e',
  'blackberry': '\f37b',
  'blanket': '\f498',
  'blender': '\f517',
  'blender-phone': '\f6b6',
  'blind': '\f29d',
  'blog': '\f781',
  'blogger': '\f37c',
  'blogger-b': '\f37d',
  'bluetooth': '\f293',
  'bluetooth-b': '\f294',
  'bold': '\f032',
  'bolt': '\f0e7',
  'bomb': '\f1e2',
  'bone': '\f5d7',
  'bone-break': '\f5d8',
  'bong': '\f55c',
  'book': '\f02d',
  'book-alt': '\f5d9',
  'book-dead': '\f6b7',
  'book-heart': '\f499',
  'book-medical': '\f7e6',
  'book-open': '\f518',
  'book-reader': '\f5da',
  'book-spells': '\f6b8',
  'book-user': '\f7e7',
  'bookmark': '\f02e',
  'books': '\f5db',
  'books-medical': '\f7e8',
  'boot': '\f782',
  'booth-curtain': '\f734',
  'bow-arrow': '\f6b9',
  'bowling-ball': '\f436',
  'bowling-pins': '\f437',
  'box': '\f466',
  'box-alt': '\f49a',
  'box-ballot': '\f735',
  'box-check': '\f467',
  'box-fragile': '\f49b',
  'box-full': '\f49c',
  'box-heart': '\f49d',
  'box-open': '\f49e',
  'box-up': '\f49f',
  'box-usd': '\f4a0',
  'boxes': '\f468',
  'boxes-alt': '\f4a1',
  'boxing-glove': '\f438',
  'brackets': '\f7e9',
  'brackets-curly': '\f7ea',
  'braille': '\f2a1',
  'brain': '\f5dc',
  'bread-loaf': '\f7eb',
  'bread-slice': '\f7ec',
  'briefcase': '\f0b1',
  'briefcase-medical': '\f469',
  'broadcast-tower': '\f519',
  'broom': '\f51a',
  'browser': '\f37e',
  'brush': '\f55d',
  'btc': '\f15a',
  'bug': '\f188',
  'building': '\f1ad',
  'bullhorn': '\f0a1',
  'bullseye': '\f140',
  'bullseye-arrow': '\f648',
  'bullseye-pointer': '\f649',
  'burn': '\f46a',
  'buromobelexperte': '\f37f',
  'burrito': '\f7ed',
  'bus': '\f207',
  'bus-alt': '\f55e',
  'bus-school': '\f5dd',
  'business-time': '\f64a',
  'buysellads': '\f20d',
  'cabinet-filing': '\f64b',
  'calculator': '\f1ec',
  'calculator-alt': '\f64c',
  'calendar': '\f133',
  'calendar-alt': '\f073',
  'calendar-check': '\f274',
  'calendar-day': '\f783',
  'calendar-edit': '\f333',
  'calendar-exclamation': '\f334',
  'calendar-minus': '\f272',
  'calendar-plus': '\f271',
  'calendar-star': '\f736',
  'calendar-times': '\f273',
  'calendar-week': '\f784',
  'camera': '\f030',
  'camera-alt': '\f332',
  'camera-retro': '\f083',
  'campfire': '\f6ba',
  'campground': '\f6bb',
  'canadian-maple-leaf': '\f785',
  'candle-holder': '\f6bc',
  'candy-cane': '\f786',
  'candy-corn': '\f6bd',
  'cannabis': '\f55f',
  'capsules': '\f46b',
  'car': '\f1b9',
  'car-alt': '\f5de',
  'car-battery': '\f5df',
  'car-bump': '\f5e0',
  'car-crash': '\f5e1',
  'car-garage': '\f5e2',
  'car-mechanic': '\f5e3',
  'car-side': '\f5e4',
  'car-tilt': '\f5e5',
  'car-wash': '\f5e6',
  'caret-circle-down': '\f32d',
  'caret-circle-left': '\f32e',
  'caret-circle-right': '\f330',
  'caret-circle-up': '\f331',
  'caret-down': '\f0d7',
  'caret-left': '\f0d9',
  'caret-right': '\f0da',
  'caret-square-down': '\f150',
  'caret-square-left': '\f191',
  'caret-square-right': '\f152',
  'caret-square-up': '\f151',
  'caret-up': '\f0d8',
  'carrot': '\f787',
  'cart-arrow-down': '\f218',
  'cart-plus': '\f217',
  'cash-register': '\f788',
  'cat': '\f6be',
  'cauldron': '\f6bf',
  'cc-amazon-pay': '\f42d',
  'cc-amex': '\f1f3',
  'cc-apple-pay': '\f416',
  'cc-diners-club': '\f24c',
  'cc-discover': '\f1f2',
  'cc-jcb': '\f24b',
  'cc-mastercard': '\f1f1',
  'cc-paypal': '\f1f4',
  'cc-stripe': '\f1f5',
  'cc-visa': '\f1f0',
  'centercode': '\f380',
  'centos': '\f789',
  'certificate': '\f0a3',
  'chair': '\f6c0',
  'chair-office': '\f6c1',
  'chalkboard': '\f51b',
  'chalkboard-teacher': '\f51c',
  'charging-station': '\f5e7',
  'chart-area': '\f1fe',
  'chart-bar': '\f080',
  'chart-line': '\f201',
  'chart-line-down': '\f64d',
  'chart-network': '\f78a',
  'chart-pie': '\f200',
  'chart-pie-alt': '\f64e',
  'chart-scatter': '\f7ee',
  'check': '\f00c',
  'check-circle': '\f058',
  'check-double': '\f560',
  'check-square': '\f14a',
  'cheese': '\f7ef',
  'cheese-swiss': '\f7f0',
  'cheeseburger': '\f7f1',
  'chess': '\f439',
  'chess-bishop': '\f43a',
  'chess-bishop-alt': '\f43b',
  'chess-board': '\f43c',
  'chess-clock': '\f43d',
  'chess-clock-alt': '\f43e',
  'chess-king': '\f43f',
  'chess-king-alt': '\f440',
  'chess-knight': '\f441',
  'chess-knight-alt': '\f442',
  'chess-pawn': '\f443',
  'chess-pawn-alt': '\f444',
  'chess-queen': '\f445',
  'chess-queen-alt': '\f446',
  'chess-rook': '\f447',
  'chess-rook-alt': '\f448',
  'chevron-circle-down': '\f13a',
  'chevron-circle-left': '\f137',
  'chevron-circle-right': '\f138',
  'chevron-circle-up': '\f139',
  'chevron-double-down': '\f322',
  'chevron-double-left': '\f323',
  'chevron-double-right': '\f324',
  'chevron-double-up': '\f325',
  'chevron-down': '\f078',
  'chevron-left': '\f053',
  'chevron-right': '\f054',
  'chevron-square-down': '\f329',
  'chevron-square-left': '\f32a',
  'chevron-square-right': '\f32b',
  'chevron-square-up': '\f32c',
  'chevron-up': '\f077',
  'child': '\f1ae',
  'chimney': '\f78b',
  'chrome': '\f268',
  'church': '\f51d',
  'circle': '\f111',
  'circle-notch': '\f1ce',
  'city': '\f64f',
  'claw-marks': '\f6c2',
  'clinic-medical': '\f7f2',
  'clipboard': '\f328',
  'clipboard-check': '\f46c',
  'clipboard-list': '\f46d',
  'clipboard-list-check': '\f737',
  'clipboard-prescription': '\f5e8',
  'clipboard-user': '\f7f3',
  'clock': '\f017',
  'clone': '\f24d',
  'closed-captioning': '\f20a',
  'cloud': '\f0c2',
  'cloud-download': '\f0ed',
  'cloud-download-alt': '\f381',
  'cloud-drizzle': '\f738',
  'cloud-hail': '\f739',
  'cloud-hail-mixed': '\f73a',
  'cloud-meatball': '\f73b',
  'cloud-moon': '\f6c3',
  'cloud-moon-rain': '\f73c',
  'cloud-rain': '\f73d',
  'cloud-rainbow': '\f73e',
  'cloud-showers': '\f73f',
  'cloud-showers-heavy': '\f740',
  'cloud-sleet': '\f741',
  'cloud-snow': '\f742',
  'cloud-sun': '\f6c4',
  'cloud-sun-rain': '\f743',
  'cloud-upload': '\f0ee',
  'cloud-upload-alt': '\f382',
  'clouds': '\f744',
  'clouds-moon': '\f745',
  'clouds-sun': '\f746',
  'cloudscale': '\f383',
  'cloudsmith': '\f384',
  'cloudversify': '\f385',
  'club': '\f327',
  'cocktail': '\f561',
  'code': '\f121',
  'code-branch': '\f126',
  'code-commit': '\f386',
  'code-merge': '\f387',
  'codepen': '\f1cb',
  'codiepie': '\f284',
  'coffee': '\f0f4',
  'coffee-togo': '\f6c5',
  'coffin': '\f6c6',
  'cog': '\f013',
  'cogs': '\f085',
  'coins': '\f51e',
  'columns': '\f0db',
  'comment': '\f075',
  'comment-alt': '\f27a',
  'comment-alt-check': '\f4a2',
  'comment-alt-dollar': '\f650',
  'comment-alt-dots': '\f4a3',
  'comment-alt-edit': '\f4a4',
  'comment-alt-exclamation': '\f4a5',
  'comment-alt-lines': '\f4a6',
  'comment-alt-medical': '\f7f4',
  'comment-alt-minus': '\f4a7',
  'comment-alt-plus': '\f4a8',
  'comment-alt-slash': '\f4a9',
  'comment-alt-smile': '\f4aa',
  'comment-alt-times': '\f4ab',
  'comment-check': '\f4ac',
  'comment-dollar': '\f651',
  'comment-dots': '\f4ad',
  'comment-edit': '\f4ae',
  'comment-exclamation': '\f4af',
  'comment-lines': '\f4b0',
  'comment-medical': '\f7f5',
  'comment-minus': '\f4b1',
  'comment-plus': '\f4b2',
  'comment-slash': '\f4b3',
  'comment-smile': '\f4b4',
  'comment-times': '\f4b5',
  'comments': '\f086',
  'comments-alt': '\f4b6',
  'comments-alt-dollar': '\f652',
  'comments-dollar': '\f653',
  'compact-disc': '\f51f',
  'compass': '\f14e',
  'compass-slash': '\f5e9',
  'compress': '\f066',
  'compress-alt': '\f422',
  'compress-arrows-alt': '\f78c',
  'compress-wide': '\f326',
  'concierge-bell': '\f562',
  'confluence': '\f78d',
  'connectdevelop': '\f20e',
  'container-storage': '\f4b7',
  'contao': '\f26d',
  'conveyor-belt': '\f46e',
  'conveyor-belt-alt': '\f46f',
  'cookie': '\f563',
  'cookie-bite': '\f564',
  'copy': '\f0c5',
  'copyright': '\f1f9',
  'corn': '\f6c7',
  'couch': '\f4b8',
  'cow': '\f6c8',
  'cpanel': '\f388',
  'creative-commons': '\f25e',
  'creative-commons-by': '\f4e7',
  'creative-commons-nc': '\f4e8',
  'creative-commons-nc-eu': '\f4e9',
  'creative-commons-nc-jp': '\f4ea',
  'creative-commons-nd': '\f4eb',
  'creative-commons-pd': '\f4ec',
  'creative-commons-pd-alt': '\f4ed',
  'creative-commons-remix': '\f4ee',
  'creative-commons-sa': '\f4ef',
  'creative-commons-sampling': '\f4f0',
  'creative-commons-sampling-plus': '\f4f1',
  'creative-commons-share': '\f4f2',
  'creative-commons-zero': '\f4f3',
  'credit-card': '\f09d',
  'credit-card-blank': '\f389',
  'credit-card-front': '\f38a',
  'cricket': '\f449',
  'critical-role': '\f6c9',
  'croissant': '\f7f6',
  'crop': '\f125',
  'crop-alt': '\f565',
  'cross': '\f654',
  'crosshairs': '\f05b',
  'crow': '\f520',
  'crown': '\f521',
  'crutch': '\f7f7',
  'crutches': '\f7f8',
  'css3': '\f13c',
  'css3-alt': '\f38b',
  'cube': '\f1b2',
  'cubes': '\f1b3',
  'curling': '\f44a',
  'cut': '\f0c4',
  'cuttlefish': '\f38c',
  'd-and-d': '\f38d',
  'd-and-d-beyond': '\f6ca',
  'dagger': '\f6cb',
  'dashcube': '\f210',
  'database': '\f1c0',
  'deaf': '\f2a4',
  'debug': '\f7f9',
  'deer': '\f78e',
  'deer-rudolph': '\f78f',
  'delicious': '\f1a5',
  'democrat': '\f747',
  'deploydog': '\f38e',
  'deskpro': '\f38f',
  'desktop': '\f108',
  'desktop-alt': '\f390',
  'dev': '\f6cc',
  'deviantart': '\f1bd',
  'dewpoint': '\f748',
  'dharmachakra': '\f655',
  'dhl': '\f790',
  'diagnoses': '\f470',
  'diamond': '\f219',
  'diaspora': '\f791',
  'dice': '\f522',
  'dice-d10': '\f6cd',
  'dice-d12': '\f6ce',
  'dice-d20': '\f6cf',
  'dice-d4': '\f6d0',
  'dice-d6': '\f6d1',
  'dice-d8': '\f6d2',
  'dice-five': '\f523',
  'dice-four': '\f524',
  'dice-one': '\f525',
  'dice-six': '\f526',
  'dice-three': '\f527',
  'dice-two': '\f528',
  'digg': '\f1a6',
  'digital-ocean': '\f391',
  'digital-tachograph': '\f566',
  'diploma': '\f5ea',
  'directions': '\f5eb',
  'discord': '\f392',
  'discourse': '\f393',
  'disease': '\f7fa',
  'divide': '\f529',
  'dizzy': '\f567',
  'dna': '\f471',
  'do-not-enter': '\f5ec',
  'dochub': '\f394',
  'docker': '\f395',
  'dog': '\f6d3',
  'dog-leashed': '\f6d4',
  'dollar-sign': '\f155',
  'dolly': '\f472',
  'dolly-empty': '\f473',
  'dolly-flatbed': '\f474',
  'dolly-flatbed-alt': '\f475',
  'dolly-flatbed-empty': '\f476',
  'donate': '\f4b9',
  'door-closed': '\f52a',
  'door-open': '\f52b',
  'dot-circle': '\f192',
  'dove': '\f4ba',
  'download': '\f019',
  'draft2digital': '\f396',
  'drafting-compass': '\f568',
  'dragon': '\f6d5',
  'draw-circle': '\f5ed',
  'draw-polygon': '\f5ee',
  'draw-square': '\f5ef',
  'dreidel': '\f792',
  'dribbble': '\f17d',
  'dribbble-square': '\f397',
  'dropbox': '\f16b',
  'drum': '\f569',
  'drum-steelpan': '\f56a',
  'drumstick': '\f6d6',
  'drumstick-bite': '\f6d7',
  'drupal': '\f1a9',
  'duck': '\f6d8',
  'dumbbell': '\f44b',
  'dumpster': '\f793',
  'dumpster-fire': '\f794',
  'dungeon': '\f6d9',
  'dyalog': '\f399',
  'ear': '\f5f0',
  'ear-muffs': '\f795',
  'earlybirds': '\f39a',
  'ebay': '\f4f4',
  'eclipse': '\f749',
  'eclipse-alt': '\f74a',
  'edge': '\f282',
  'edit': '\f044',
  'egg': '\f7fb',
  'egg-fried': '\f7fc',
  'eject': '\f052',
  'elementor': '\f430',
  'elephant': '\f6da',
  'ellipsis-h': '\f141',
  'ellipsis-h-alt': '\f39b',
  'ellipsis-v': '\f142',
  'ellipsis-v-alt': '\f39c',
  'ello': '\f5f1',
  'ember': '\f423',
  'empire': '\f1d1',
  'empty-set': '\f656',
  'engine-warning': '\f5f2',
  'envelope': '\f0e0',
  'envelope-open': '\f2b6',
  'envelope-open-dollar': '\f657',
  'envelope-open-text': '\f658',
  'envelope-square': '\f199',
  'envira': '\f299',
  'equals': '\f52c',
  'eraser': '\f12d',
  'erlang': '\f39d',
  'ethereum': '\f42e',
  'ethernet': '\f796',
  'etsy': '\f2d7',
  'euro-sign': '\f153',
  'exchange': '\f0ec',
  'exchange-alt': '\f362',
  'exclamation': '\f12a',
  'exclamation-circle': '\f06a',
  'exclamation-square': '\f321',
  'exclamation-triangle': '\f071',
  'expand': '\f065',
  'expand-alt': '\f424',
  'expand-arrows': '\f31d',
  'expand-arrows-alt': '\f31e',
  'expand-wide': '\f320',
  'expeditedssl': '\f23e',
  'external-link': '\f08e',
  'external-link-alt': '\f35d',
  'external-link-square': '\f14c',
  'external-link-square-alt': '\f360',
  'eye': '\f06e',
  'eye-dropper': '\f1fb',
  'eye-evil': '\f6db',
  'eye-slash': '\f070',
  'facebook': '\f09a',
  'facebook-f': '\f39e',
  'facebook-messenger': '\f39f',
  'facebook-square': '\f082',
  'fantasy-flight-games': '\f6dc',
  'fast-backward': '\f049',
  'fast-forward': '\f050',
  'fax': '\f1ac',
  'feather': '\f52d',
  'feather-alt': '\f56b',
  'fedex': '\f797',
  'fedora': '\f798',
  'female': '\f182',
  'field-hockey': '\f44c',
  'fighter-jet': '\f0fb',
  'figma': '\f799',
  'file': '\f15b',
  'file-alt': '\f15c',
  'file-archive': '\f1c6',
  'file-audio': '\f1c7',
  'file-certificate': '\f5f3',
  'file-chart-line': '\f659',
  'file-chart-pie': '\f65a',
  'file-check': '\f316',
  'file-code': '\f1c9',
  'file-contract': '\f56c',
  'file-csv': '\f6dd',
  'file-download': '\f56d',
  'file-edit': '\f31c',
  'file-excel': '\f1c3',
  'file-exclamation': '\f31a',
  'file-export': '\f56e',
  'file-image': '\f1c5',
  'file-import': '\f56f',
  'file-invoice': '\f570',
  'file-invoice-dollar': '\f571',
  'file-medical': '\f477',
  'file-medical-alt': '\f478',
  'file-minus': '\f318',
  'file-pdf': '\f1c1',
  'file-plus': '\f319',
  'file-powerpoint': '\f1c4',
  'file-prescription': '\f572',
  'file-signature': '\f573',
  'file-spreadsheet': '\f65b',
  'file-times': '\f317',
  'file-upload': '\f574',
  'file-user': '\f65c',
  'file-video': '\f1c8',
  'file-word': '\f1c2',
  'files-medical': '\f7fd',
  'fill': '\f575',
  'fill-drip': '\f576',
  'film': '\f008',
  'film-alt': '\f3a0',
  'filter': '\f0b0',
  'fingerprint': '\f577',
  'fire': '\f06d',
  'fire-alt': '\f7e4',
  'fire-extinguisher': '\f134',
  'fire-smoke': '\f74b',
  'firefox': '\f269',
  'fireplace': '\f79a',
  'first-aid': '\f479',
  'first-order': '\f2b0',
  'first-order-alt': '\f50a',
  'firstdraft': '\f3a1',
  'fish': '\f578',
  'fish-cooked': '\f7fe',
  'fist-raised': '\f6de',
  'flag': '\f024',
  'flag-alt': '\f74c',
  'flag-checkered': '\f11e',
  'flag-usa': '\f74d',
  'flame': '\f6df',
  'flask': '\f0c3',
  'flask-poison': '\f6e0',
  'flask-potion': '\f6e1',
  'flickr': '\f16e',
  'flipboard': '\f44d',
  'flower': '\f7ff',
  'flower-daffodil': '\f800',
  'flower-tulip': '\f801',
  'flushed': '\f579',
  'fly': '\f417',
  'fog': '\f74e',
  'folder': '\f07b',
  'folder-minus': '\f65d',
  'folder-open': '\f07c',
  'folder-plus': '\f65e',
  'folder-times': '\f65f',
  'folder-tree': '\f802',
  'folders': '\f660',
  'font': '\f031',
  'font-awesome': '\f2b4',
  'font-awesome-alt': '\f35c',
  'font-awesome-flag': '\f425',
  'font-awesome-logo-full': '\f4e6',
  'fonticons': '\f280',
  'fonticons-fi': '\f3a2',
  'football-ball': '\f44e',
  'football-helmet': '\f44f',
  'forklift': '\f47a',
  'fort-awesome': '\f286',
  'fort-awesome-alt': '\f3a3',
  'forumbee': '\f211',
  'forward': '\f04e',
  'foursquare': '\f180',
  'fragile': '\f4bb',
  'free-code-camp': '\f2c5',
  'freebsd': '\f3a4',
  'french-fries': '\f803',
  'frog': '\f52e',
  'frosty-head': '\f79b',
  'frown': '\f119',
  'frown-open': '\f57a',
  'fulcrum': '\f50b',
  'function': '\f661',
  'funnel-dollar': '\f662',
  'futbol': '\f1e3',
  'galactic-republic': '\f50c',
  'galactic-senate': '\f50d',
  'gamepad': '\f11b',
  'gas-pump': '\f52f',
  'gas-pump-slash': '\f5f4',
  'gavel': '\f0e3',
  'gem': '\f3a5',
  'genderless': '\f22d',
  'get-pocket': '\f265',
  'gg': '\f260',
  'gg-circle': '\f261',
  'ghost': '\f6e2',
  'gift': '\f06b',
  'gift-card': '\f663',
  'gifts': '\f79c',
  'gingerbread-man': '\f79d',
  'git': '\f1d3',
  'git-square': '\f1d2',
  'github': '\f09b',
  'github-alt': '\f113',
  'github-square': '\f092',
  'gitkraken': '\f3a6',
  'gitlab': '\f296',
  'gitter': '\f426',
  'glass': '\f804',
  'glass-champagne': '\f79e',
  'glass-cheers': '\f79f',
  'glass-martini': '\f000',
  'glass-martini-alt': '\f57b',
  'glass-whiskey': '\f7a0',
  'glass-whiskey-rocks': '\f7a1',
  'glasses': '\f530',
  'glasses-alt': '\f5f5',
  'glide': '\f2a5',
  'glide-g': '\f2a6',
  'globe': '\f0ac',
  'globe-africa': '\f57c',
  'globe-americas': '\f57d',
  'globe-asia': '\f57e',
  'globe-europe': '\f7a2',
  'globe-snow': '\f7a3',
  'globe-stand': '\f5f6',
  'gofore': '\f3a7',
  'golf-ball': '\f450',
  'golf-club': '\f451',
  'goodreads': '\f3a8',
  'goodreads-g': '\f3a9',
  'google': '\f1a0',
  'google-drive': '\f3aa',
  'google-play': '\f3ab',
  'google-plus': '\f2b3',
  'google-plus-g': '\f0d5',
  'google-plus-square': '\f0d4',
  'google-wallet': '\f1ee',
  'gopuram': '\f664',
  'graduation-cap': '\f19d',
  'gratipay': '\f184',
  'grav': '\f2d6',
  'greater-than': '\f531',
  'greater-than-equal': '\f532',
  'grimace': '\f57f',
  'grin': '\f580',
  'grin-alt': '\f581',
  'grin-beam': '\f582',
  'grin-beam-sweat': '\f583',
  'grin-hearts': '\f584',
  'grin-squint': '\f585',
  'grin-squint-tears': '\f586',
  'grin-stars': '\f587',
  'grin-tears': '\f588',
  'grin-tongue': '\f589',
  'grin-tongue-squint': '\f58a',
  'grin-tongue-wink': '\f58b',
  'grin-wink': '\f58c',
  'grip-horizontal': '\f58d',
  'grip-lines': '\f7a4',
  'grip-lines-vertical': '\f7a5',
  'grip-vertical': '\f58e',
  'gripfire': '\f3ac',
  'grunt': '\f3ad',
  'guitar': '\f7a6',
  'gulp': '\f3ae',
  'h-square': '\f0fd',
  'h1': '\f313',
  'h2': '\f314',
  'h3': '\f315',
  'hacker-news': '\f1d4',
  'hacker-news-square': '\f3af',
  'hackerrank': '\f5f7',
  'hamburger': '\f805',
  'hammer': '\f6e3',
  'hammer-war': '\f6e4',
  'hamsa': '\f665',
  'hand-heart': '\f4bc',
  'hand-holding': '\f4bd',
  'hand-holding-box': '\f47b',
  'hand-holding-heart': '\f4be',
  'hand-holding-magic': '\f6e5',
  'hand-holding-seedling': '\f4bf',
  'hand-holding-usd': '\f4c0',
  'hand-holding-water': '\f4c1',
  'hand-lizard': '\f258',
  'hand-middle-finger': '\f806',
  'hand-paper': '\f256',
  'hand-peace': '\f25b',
  'hand-point-down': '\f0a7',
  'hand-point-left': '\f0a5',
  'hand-point-right': '\f0a4',
  'hand-point-up': '\f0a6',
  'hand-pointer': '\f25a',
  'hand-receiving': '\f47c',
  'hand-rock': '\f255',
  'hand-scissors': '\f257',
  'hand-spock': '\f259',
  'hands': '\f4c2',
  'hands-heart': '\f4c3',
  'hands-helping': '\f4c4',
  'hands-usd': '\f4c5',
  'handshake': '\f2b5',
  'handshake-alt': '\f4c6',
  'hanukiah': '\f6e6',
  'hard-hat': '\f807',
  'hashtag': '\f292',
  'hat-santa': '\f7a7',
  'hat-winter': '\f7a8',
  'hat-witch': '\f6e7',
  'hat-wizard': '\f6e8',
  'haykal': '\f666',
  'hdd': '\f0a0',
  'head-side': '\f6e9',
  'head-side-brain': '\f808',
  'head-side-medical': '\f809',
  'head-vr': '\f6ea',
  'heading': '\f1dc',
  'headphones': '\f025',
  'headphones-alt': '\f58f',
  'headset': '\f590',
  'heart': '\f004',
  'heart-broken': '\f7a9',
  'heart-circle': '\f4c7',
  'heart-rate': '\f5f8',
  'heart-square': '\f4c8',
  'heartbeat': '\f21e',
  'helicopter': '\f533',
  'helmet-battle': '\f6eb',
  'hexagon': '\f312',
  'highlighter': '\f591',
  'hiking': '\f6ec',
  'hippo': '\f6ed',
  'hips': '\f452',
  'hire-a-helper': '\f3b0',
  'history': '\f1da',
  'hockey-mask': '\f6ee',
  'hockey-puck': '\f453',
  'hockey-sticks': '\f454',
  'holly-berry': '\f7aa',
  'home': '\f015',
  'home-alt': '\f80a',
  'home-heart': '\f4c9',
  'home-lg': '\f80b',
  'home-lg-alt': '\f80c',
  'hood-cloak': '\f6ef',
  'hooli': '\f427',
  'hornbill': '\f592',
  'horse': '\f6f0',
  'horse-head': '\f7ab',
  'hospital': '\f0f8',
  'hospital-alt': '\f47d',
  'hospital-symbol': '\f47e',
  'hospital-user': '\f80d',
  'hospitals': '\f80e',
  'hot-tub': '\f593',
  'hotdog': '\f80f',
  'hotel': '\f594',
  'hotjar': '\f3b1',
  'hourglass': '\f254',
  'hourglass-end': '\f253',
  'hourglass-half': '\f252',
  'hourglass-start': '\f251',
  'house-damage': '\f6f1',
  'house-flood': '\f74f',
  'houzz': '\f27c',
  'hryvnia': '\f6f2',
  'html5': '\f13b',
  'hubspot': '\f3b2',
  'humidity': '\f750',
  'hurricane': '\f751',
  'i-cursor': '\f246',
  'ice-cream': '\f810',
  'ice-skate': '\f7ac',
  'icicles': '\f7ad',
  'id-badge': '\f2c1',
  'id-card': '\f2c2',
  'id-card-alt': '\f47f',
  'igloo': '\f7ae',
  'image': '\f03e',
  'images': '\f302',
  'imdb': '\f2d8',
  'inbox': '\f01c',
  'inbox-in': '\f310',
  'inbox-out': '\f311',
  'indent': '\f03c',
  'industry': '\f275',
  'industry-alt': '\f3b3',
  'infinity': '\f534',
  'info': '\f129',
  'info-circle': '\f05a',
  'info-square': '\f30f',
  'inhaler': '\f5f9',
  'instagram': '\f16d',
  'integral': '\f667',
  'intercom': '\f7af',
  'internet-explorer': '\f26b',
  'intersection': '\f668',
  'inventory': '\f480',
  'invision': '\f7b0',
  'ioxhost': '\f208',
  'island-tropical': '\f811',
  'italic': '\f033',
  'itunes': '\f3b4',
  'itunes-note': '\f3b5',
  'jack-o-lantern': '\f30e',
  'java': '\f4e4',
  'jedi': '\f669',
  'jedi-order': '\f50e',
  'jenkins': '\f3b6',
  'jira': '\f7b1',
  'joget': '\f3b7',
  'joint': '\f595',
  'joomla': '\f1aa',
  'journal-whills': '\f66a',
  'js': '\f3b8',
  'js-square': '\f3b9',
  'jsfiddle': '\f1cc',
  'kaaba': '\f66b',
  'kaggle': '\f5fa',
  'key': '\f084',
  'key-skeleton': '\f6f3',
  'keybase': '\f4f5',
  'keyboard': '\f11c',
  'keycdn': '\f3ba',
  'keynote': '\f66c',
  'khanda': '\f66d',
  'kickstarter': '\f3bb',
  'kickstarter-k': '\f3bc',
  'kidneys': '\f5fb',
  'kiss': '\f596',
  'kiss-beam': '\f597',
  'kiss-wink-heart': '\f598',
  'kite': '\f6f4',
  'kiwi-bird': '\f535',
  'knife-kitchen': '\f6f5',
  'korvue': '\f42f',
  'lambda': '\f66e',
  'lamp': '\f4ca',
  'landmark': '\f66f',
  'landmark-alt': '\f752',
  'language': '\f1ab',
  'laptop': '\f109',
  'laptop-code': '\f5fc',
  'laptop-medical': '\f812',
  'laravel': '\f3bd',
  'lastfm': '\f202',
  'lastfm-square': '\f203',
  'laugh': '\f599',
  'laugh-beam': '\f59a',
  'laugh-squint': '\f59b',
  'laugh-wink': '\f59c',
  'layer-group': '\f5fd',
  'layer-minus': '\f5fe',
  'layer-plus': '\f5ff',
  'leaf': '\f06c',
  'leaf-heart': '\f4cb',
  'leaf-maple': '\f6f6',
  'leaf-oak': '\f6f7',
  'leanpub': '\f212',
  'lemon': '\f094',
  'less': '\f41d',
  'less-than': '\f536',
  'less-than-equal': '\f537',
  'level-down': '\f149',
  'level-down-alt': '\f3be',
  'level-up': '\f148',
  'level-up-alt': '\f3bf',
  'life-ring': '\f1cd',
  'lightbulb': '\f0eb',
  'lightbulb-dollar': '\f670',
  'lightbulb-exclamation': '\f671',
  'lightbulb-on': '\f672',
  'lightbulb-slash': '\f673',
  'lights-holiday': '\f7b2',
  'line': '\f3c0',
  'link': '\f0c1',
  'linkedin': '\f08c',
  'linkedin-in': '\f0e1',
  'linode': '\f2b8',
  'linux': '\f17c',
  'lips': '\f600',
  'lira-sign': '\f195',
  'list': '\f03a',
  'list-alt': '\f022',
  'list-ol': '\f0cb',
  'list-ul': '\f0ca',
  'location': '\f601',
  'location-arrow': '\f124',
  'location-circle': '\f602',
  'location-slash': '\f603',
  'lock': '\f023',
  'lock-alt': '\f30d',
  'lock-open': '\f3c1',
  'lock-open-alt': '\f3c2',
  'long-arrow-alt-down': '\f309',
  'long-arrow-alt-left': '\f30a',
  'long-arrow-alt-right': '\f30b',
  'long-arrow-alt-up': '\f30c',
  'long-arrow-down': '\f175',
  'long-arrow-left': '\f177',
  'long-arrow-right': '\f178',
  'long-arrow-up': '\f176',
  'loveseat': '\f4cc',
  'low-vision': '\f2a8',
  'luchador': '\f455',
  'luggage-cart': '\f59d',
  'lungs': '\f604',
  'lyft': '\f3c3',
  'mace': '\f6f8',
  'magento': '\f3c4',
  'magic': '\f0d0',
  'magnet': '\f076',
  'mail-bulk': '\f674',
  'mailbox': '\f813',
  'mailchimp': '\f59e',
  'male': '\f183',
  'mandalorian': '\f50f',
  'mandolin': '\f6f9',
  'map': '\f279',
  'map-marked': '\f59f',
  'map-marked-alt': '\f5a0',
  'map-marker': '\f041',
  'map-marker-alt': '\f3c5',
  'map-marker-alt-slash': '\f605',
  'map-marker-check': '\f606',
  'map-marker-edit': '\f607',
  'map-marker-exclamation': '\f608',
  'map-marker-minus': '\f609',
  'map-marker-plus': '\f60a',
  'map-marker-question': '\f60b',
  'map-marker-slash': '\f60c',
  'map-marker-smile': '\f60d',
  'map-marker-times': '\f60e',
  'map-pin': '\f276',
  'map-signs': '\f277',
  'markdown': '\f60f',
  'marker': '\f5a1',
  'mars': '\f222',
  'mars-double': '\f227',
  'mars-stroke': '\f229',
  'mars-stroke-h': '\f22b',
  'mars-stroke-v': '\f22a',
  'mask': '\f6fa',
  'mastodon': '\f4f6',
  'maxcdn': '\f136',
  'meat': '\f814',
  'medal': '\f5a2',
  'medapps': '\f3c6',
  'medium': '\f23a',
  'medium-m': '\f3c7',
  'medkit': '\f0fa',
  'medrt': '\f3c8',
  'meetup': '\f2e0',
  'megaphone': '\f675',
  'megaport': '\f5a3',
  'meh': '\f11a',
  'meh-blank': '\f5a4',
  'meh-rolling-eyes': '\f5a5',
  'memory': '\f538',
  'mendeley': '\f7b3',
  'menorah': '\f676',
  'mercury': '\f223',
  'meteor': '\f753',
  'microchip': '\f2db',
  'microphone': '\f130',
  'microphone-alt': '\f3c9',
  'microphone-alt-slash': '\f539',
  'microphone-slash': '\f131',
  'microscope': '\f610',
  'microsoft': '\f3ca',
  'mind-share': '\f677',
  'minus': '\f068',
  'minus-circle': '\f056',
  'minus-hexagon': '\f307',
  'minus-octagon': '\f308',
  'minus-square': '\f146',
  'mistletoe': '\f7b4',
  'mitten': '\f7b5',
  'mix': '\f3cb',
  'mixcloud': '\f289',
  'mizuni': '\f3cc',
  'mobile': '\f10b',
  'mobile-alt': '\f3cd',
  'mobile-android': '\f3ce',
  'mobile-android-alt': '\f3cf',
  'modx': '\f285',
  'monero': '\f3d0',
  'money-bill': '\f0d6',
  'money-bill-alt': '\f3d1',
  'money-bill-wave': '\f53a',
  'money-bill-wave-alt': '\f53b',
  'money-check': '\f53c',
  'money-check-alt': '\f53d',
  'monitor-heart-rate': '\f611',
  'monkey': '\f6fb',
  'monument': '\f5a6',
  'moon': '\f186',
  'moon-cloud': '\f754',
  'moon-stars': '\f755',
  'mortar-pestle': '\f5a7',
  'mosque': '\f678',
  'motorcycle': '\f21c',
  'mountain': '\f6fc',
  'mountains': '\f6fd',
  'mouse-pointer': '\f245',
  'mug-hot': '\f7b6',
  'mug-marshmallows': '\f7b7',
  'music': '\f001',
  'napster': '\f3d2',
  'narwhal': '\f6fe',
  'neos': '\f612',
  'network-wired': '\f6ff',
  'neuter': '\f22c',
  'newspaper': '\f1ea',
  'nimblr': '\f5a8',
  'nintendo-switch': '\f418',
  'node': '\f419',
  'node-js': '\f3d3',
  'not-equal': '\f53e',
  'notes-medical': '\f481',
  'npm': '\f3d4',
  'ns8': '\f3d5',
  'nutritionix': '\f3d6',
  'object-group': '\f247',
  'object-ungroup': '\f248',
  'octagon': '\f306',
  'odnoklassniki': '\f263',
  'odnoklassniki-square': '\f264',
  'oil-can': '\f613',
  'oil-temp': '\f614',
  'old-republic': '\f510',
  'om': '\f679',
  'omega': '\f67a',
  'opencart': '\f23d',
  'openid': '\f19b',
  'opera': '\f26a',
  'optin-monster': '\f23c',
  'ornament': '\f7b8',
  'osi': '\f41a',
  'otter': '\f700',
  'outdent': '\f03b',
  'page4': '\f3d7',
  'pagelines': '\f18c',
  'pager': '\f815',
  'paint-brush': '\f1fc',
  'paint-brush-alt': '\f5a9',
  'paint-roller': '\f5aa',
  'palette': '\f53f',
  'palfed': '\f3d8',
  'pallet': '\f482',
  'pallet-alt': '\f483',
  'paper-plane': '\f1d8',
  'paperclip': '\f0c6',
  'parachute-box': '\f4cd',
  'paragraph': '\f1dd',
  'parking': '\f540',
  'parking-circle': '\f615',
  'parking-circle-slash': '\f616',
  'parking-slash': '\f617',
  'passport': '\f5ab',
  'pastafarianism': '\f67b',
  'paste': '\f0ea',
  'patreon': '\f3d9',
  'pause': '\f04c',
  'pause-circle': '\f28b',
  'paw': '\f1b0',
  'paw-alt': '\f701',
  'paw-claws': '\f702',
  'paypal': '\f1ed',
  'peace': '\f67c',
  'pegasus': '\f703',
  'pen': '\f304',
  'pen-alt': '\f305',
  'pen-fancy': '\f5ac',
  'pen-nib': '\f5ad',
  'pen-square': '\f14b',
  'pencil': '\f040',
  'pencil-alt': '\f303',
  'pencil-paintbrush': '\f618',
  'pencil-ruler': '\f5ae',
  'pennant': '\f456',
  'penny-arcade': '\f704',
  'people-carry': '\f4ce',
  'pepper-hot': '\f816',
  'percent': '\f295',
  'percentage': '\f541',
  'periscope': '\f3da',
  'person-booth': '\f756',
  'person-carry': '\f4cf',
  'person-dolly': '\f4d0',
  'person-dolly-empty': '\f4d1',
  'person-sign': '\f757',
  'phabricator': '\f3db',
  'phoenix-framework': '\f3dc',
  'phoenix-squadron': '\f511',
  'phone': '\f095',
  'phone-office': '\f67d',
  'phone-plus': '\f4d2',
  'phone-slash': '\f3dd',
  'phone-square': '\f098',
  'phone-volume': '\f2a0',
  'php': '\f457',
  'pi': '\f67e',
  'pie': '\f705',
  'pied-piper': '\f2ae',
  'pied-piper-alt': '\f1a8',
  'pied-piper-hat': '\f4e5',
  'pied-piper-pp': '\f1a7',
  'pig': '\f706',
  'piggy-bank': '\f4d3',
  'pills': '\f484',
  'pinterest': '\f0d2',
  'pinterest-p': '\f231',
  'pinterest-square': '\f0d3',
  'pizza': '\f817',
  'pizza-slice': '\f818',
  'place-of-worship': '\f67f',
  'plane': '\f072',
  'plane-alt': '\f3de',
  'plane-arrival': '\f5af',
  'plane-departure': '\f5b0',
  'play': '\f04b',
  'play-circle': '\f144',
  'playstation': '\f3df',
  'plug': '\f1e6',
  'plus': '\f067',
  'plus-circle': '\f055',
  'plus-hexagon': '\f300',
  'plus-octagon': '\f301',
  'plus-square': '\f0fe',
  'podcast': '\f2ce',
  'podium': '\f680',
  'podium-star': '\f758',
  'poll': '\f681',
  'poll-h': '\f682',
  'poll-people': '\f759',
  'poo': '\f2fe',
  'poo-storm': '\f75a',
  'poop': '\f619',
  'popcorn': '\f819',
  'portrait': '\f3e0',
  'pound-sign': '\f154',
  'power-off': '\f011',
  'pray': '\f683',
  'praying-hands': '\f684',
  'prescription': '\f5b1',
  'prescription-bottle': '\f485',
  'prescription-bottle-alt': '\f486',
  'presentation': '\f685',
  'print': '\f02f',
  'print-search': '\f81a',
  'print-slash': '\f686',
  'procedures': '\f487',
  'product-hunt': '\f288',
  'project-diagram': '\f542',
  'pumpkin': '\f707',
  'pushed': '\f3e1',
  'puzzle-piece': '\f12e',
  'python': '\f3e2',
  'qq': '\f1d6',
  'qrcode': '\f029',
  'question': '\f128',
  'question-circle': '\f059',
  'question-square': '\f2fd',
  'quidditch': '\f458',
  'quinscape': '\f459',
  'quora': '\f2c4',
  'quote-left': '\f10d',
  'quote-right': '\f10e',
  'quran': '\f687',
  'r-project': '\f4f7',
  'rabbit': '\f708',
  'rabbit-fast': '\f709',
  'racquet': '\f45a',
  'radiation': '\f7b9',
  'radiation-alt': '\f7ba',
  'rainbow': '\f75b',
  'raindrops': '\f75c',
  'ram': '\f70a',
  'ramp-loading': '\f4d4',
  'random': '\f074',
  'raspberry-pi': '\f7bb',
  'ravelry': '\f2d9',
  'react': '\f41b',
  'reacteurope': '\f75d',
  'readme': '\f4d5',
  'rebel': '\f1d0',
  'receipt': '\f543',
  'rectangle-landscape': '\f2fa',
  'rectangle-portrait': '\f2fb',
  'rectangle-wide': '\f2fc',
  'recycle': '\f1b8',
  'red-river': '\f3e3',
  'reddit': '\f1a1',
  'reddit-alien': '\f281',
  'reddit-square': '\f1a2',
  'redhat': '\f7bc',
  'redo': '\f01e',
  'redo-alt': '\f2f9',
  'registered': '\f25d',
  'renren': '\f18b',
  'repeat': '\f363',
  'repeat-1': '\f365',
  'repeat-1-alt': '\f366',
  'repeat-alt': '\f364',
  'reply': '\f3e5',
  'reply-all': '\f122',
  'replyd': '\f3e6',
  'republican': '\f75e',
  'researchgate': '\f4f8',
  'resolving': '\f3e7',
  'restroom': '\f7bd',
  'retweet': '\f079',
  'retweet-alt': '\f361',
  'rev': '\f5b2',
  'ribbon': '\f4d6',
  'ring': '\f70b',
  'rings-wedding': '\f81b',
  'road': '\f018',
  'robot': '\f544',
  'rocket': '\f135',
  'rocketchat': '\f3e8',
  'rockrms': '\f3e9',
  'route': '\f4d7',
  'route-highway': '\f61a',
  'route-interstate': '\f61b',
  'rss': '\f09e',
  'rss-square': '\f143',
  'ruble-sign': '\f158',
  'ruler': '\f545',
  'ruler-combined': '\f546',
  'ruler-horizontal': '\f547',
  'ruler-triangle': '\f61c',
  'ruler-vertical': '\f548',
  'running': '\f70c',
  'rupee-sign': '\f156',
  'rv': '\f7be',
  'sack': '\f81c',
  'sack-dollar': '\f81d',
  'sad-cry': '\f5b3',
  'sad-tear': '\f5b4',
  'safari': '\f267',
  'salad': '\f81e',
  'sandwich': '\f81f',
  'sass': '\f41e',
  'satellite': '\f7bf',
  'satellite-dish': '\f7c0',
  'sausage': '\f820',
  'save': '\f0c7',
  'scalpel': '\f61d',
  'scalpel-path': '\f61e',
  'scanner': '\f488',
  'scanner-keyboard': '\f489',
  'scanner-touchscreen': '\f48a',
  'scarecrow': '\f70d',
  'scarf': '\f7c1',
  'schlix': '\f3ea',
  'school': '\f549',
  'screwdriver': '\f54a',
  'scribd': '\f28a',
  'scroll': '\f70e',
  'scroll-old': '\f70f',
  'scrubber': '\f2f8',
  'scythe': '\f710',
  'sd-card': '\f7c2',
  'search': '\f002',
  'search-dollar': '\f688',
  'search-location': '\f689',
  'search-minus': '\f010',
  'search-plus': '\f00e',
  'searchengin': '\f3eb',
  'seedling': '\f4d8',
  'sellcast': '\f2da',
  'sellsy': '\f213',
  'server': '\f233',
  'servicestack': '\f3ec',
  'shapes': '\f61f',
  'share': '\f064',
  'share-all': '\f367',
  'share-alt': '\f1e0',
  'share-alt-square': '\f1e1',
  'share-square': '\f14d',
  'sheep': '\f711',
  'shekel-sign': '\f20b',
  'shield': '\f132',
  'shield-alt': '\f3ed',
  'shield-check': '\f2f7',
  'shield-cross': '\f712',
  'ship': '\f21a',
  'shipping-fast': '\f48b',
  'shipping-timed': '\f48c',
  'shirtsinbulk': '\f214',
  'shish-kebab': '\f821',
  'shoe-prints': '\f54b',
  'shopping-bag': '\f290',
  'shopping-basket': '\f291',
  'shopping-cart': '\f07a',
  'shopware': '\f5b5',
  'shovel': '\f713',
  'shovel-snow': '\f7c3',
  'shower': '\f2cc',
  'shredder': '\f68a',
  'shuttle-van': '\f5b6',
  'shuttlecock': '\f45b',
  'sickle': '\f822',
  'sigma': '\f68b',
  'sign': '\f4d9',
  'sign-in': '\f090',
  'sign-in-alt': '\f2f6',
  'sign-language': '\f2a7',
  'sign-out': '\f08b',
  'sign-out-alt': '\f2f5',
  'signal': '\f012',
  'signal-1': '\f68c',
  'signal-2': '\f68d',
  'signal-3': '\f68e',
  'signal-4': '\f68f',
  'signal-alt': '\f690',
  'signal-alt-1': '\f691',
  'signal-alt-2': '\f692',
  'signal-alt-3': '\f693',
  'signal-alt-slash': '\f694',
  'signal-slash': '\f695',
  'signature': '\f5b7',
  'sim-card': '\f7c4',
  'simplybuilt': '\f215',
  'sistrix': '\f3ee',
  'sitemap': '\f0e8',
  'sith': '\f512',
  'skating': '\f7c5',
  'skeleton': '\f620',
  'sketch': '\f7c6',
  'ski-jump': '\f7c7',
  'ski-lift': '\f7c8',
  'skiing': '\f7c9',
  'skiing-nordic': '\f7ca',
  'skull': '\f54c',
  'skull-crossbones': '\f714',
  'skyatlas': '\f216',
  'skype': '\f17e',
  'slack': '\f198',
  'slack-hash': '\f3ef',
  'slash': '\f715',
  'sledding': '\f7cb',
  'sleigh': '\f7cc',
  'sliders-h': '\f1de',
  'sliders-h-square': '\f3f0',
  'sliders-v': '\f3f1',
  'sliders-v-square': '\f3f2',
  'slideshare': '\f1e7',
  'smile': '\f118',
  'smile-beam': '\f5b8',
  'smile-plus': '\f5b9',
  'smile-wink': '\f4da',
  'smog': '\f75f',
  'smoke': '\f760',
  'smoking': '\f48d',
  'smoking-ban': '\f54d',
  'sms': '\f7cd',
  'snake': '\f716',
  'snapchat': '\f2ab',
  'snapchat-ghost': '\f2ac',
  'snapchat-square': '\f2ad',
  'snow-blowing': '\f761',
  'snowboarding': '\f7ce',
  'snowflake': '\f2dc',
  'snowflakes': '\f7cf',
  'snowman': '\f7d0',
  'snowmobile': '\f7d1',
  'snowplow': '\f7d2',
  'socks': '\f696',
  'solar-panel': '\f5ba',
  'sort': '\f0dc',
  'sort-alpha-down': '\f15d',
  'sort-alpha-up': '\f15e',
  'sort-amount-down': '\f160',
  'sort-amount-up': '\f161',
  'sort-down': '\f0dd',
  'sort-numeric-down': '\f162',
  'sort-numeric-up': '\f163',
  'sort-up': '\f0de',
  'soundcloud': '\f1be',
  'soup': '\f823',
  'sourcetree': '\f7d3',
  'spa': '\f5bb',
  'space-shuttle': '\f197',
  'spade': '\f2f4',
  'speakap': '\f3f3',
  'spider': '\f717',
  'spider-black-widow': '\f718',
  'spider-web': '\f719',
  'spinner': '\f110',
  'spinner-third': '\f3f4',
  'splotch': '\f5bc',
  'spotify': '\f1bc',
  'spray-can': '\f5bd',
  'square': '\f0c8',
  'square-full': '\f45c',
  'square-root': '\f697',
  'square-root-alt': '\f698',
  'squarespace': '\f5be',
  'squirrel': '\f71a',
  'stack-exchange': '\f18d',
  'stack-overflow': '\f16c',
  'staff': '\f71b',
  'stamp': '\f5bf',
  'star': '\f005',
  'star-and-crescent': '\f699',
  'star-christmas': '\f7d4',
  'star-exclamation': '\f2f3',
  'star-half': '\f089',
  'star-half-alt': '\f5c0',
  'star-of-david': '\f69a',
  'star-of-life': '\f621',
  'stars': '\f762',
  'staylinked': '\f3f5',
  'steak': '\f824',
  'steam': '\f1b6',
  'steam-square': '\f1b7',
  'steam-symbol': '\f3f6',
  'steering-wheel': '\f622',
  'step-backward': '\f048',
  'step-forward': '\f051',
  'stethoscope': '\f0f1',
  'sticker-mule': '\f3f7',
  'sticky-note': '\f249',
  'stocking': '\f7d5',
  'stomach': '\f623',
  'stop': '\f04d',
  'stop-circle': '\f28d',
  'stopwatch': '\f2f2',
  'store': '\f54e',
  'store-alt': '\f54f',
  'strava': '\f428',
  'stream': '\f550',
  'street-view': '\f21d',
  'stretcher': '\f825',
  'strikethrough': '\f0cc',
  'stripe': '\f429',
  'stripe-s': '\f42a',
  'stroopwafel': '\f551',
  'studiovinari': '\f3f8',
  'stumbleupon': '\f1a4',
  'stumbleupon-circle': '\f1a3',
  'subscript': '\f12c',
  'subway': '\f239',
  'suitcase': '\f0f2',
  'suitcase-rolling': '\f5c1',
  'sun': '\f185',
  'sun-cloud': '\f763',
  'sun-dust': '\f764',
  'sun-haze': '\f765',
  'sunrise': '\f766',
  'sunset': '\f767',
  'superpowers': '\f2dd',
  'superscript': '\f12b',
  'supple': '\f3f9',
  'surprise': '\f5c2',
  'suse': '\f7d6',
  'swatchbook': '\f5c3',
  'swimmer': '\f5c4',
  'swimming-pool': '\f5c5',
  'sword': '\f71c',
  'swords': '\f71d',
  'synagogue': '\f69b',
  'sync': '\f021',
  'sync-alt': '\f2f1',
  'syringe': '\f48e',
  'table': '\f0ce',
  'table-tennis': '\f45d',
  'tablet': '\f10a',
  'tablet-alt': '\f3fa',
  'tablet-android': '\f3fb',
  'tablet-android-alt': '\f3fc',
  'tablet-rugged': '\f48f',
  'tablets': '\f490',
  'tachometer': '\f0e4',
  'tachometer-alt': '\f3fd',
  'tachometer-alt-average': '\f624',
  'tachometer-alt-fast': '\f625',
  'tachometer-alt-fastest': '\f626',
  'tachometer-alt-slow': '\f627',
  'tachometer-alt-slowest': '\f628',
  'tachometer-average': '\f629',
  'tachometer-fast': '\f62a',
  'tachometer-fastest': '\f62b',
  'tachometer-slow': '\f62c',
  'tachometer-slowest': '\f62d',
  'taco': '\f826',
  'tag': '\f02b',
  'tags': '\f02c',
  'tally': '\f69c',
  'tanakh': '\f827',
  'tape': '\f4db',
  'tasks': '\f0ae',
  'tasks-alt': '\f828',
  'taxi': '\f1ba',
  'teamspeak': '\f4f9',
  'teeth': '\f62e',
  'teeth-open': '\f62f',
  'telegram': '\f2c6',
  'telegram-plane': '\f3fe',
  'temperature-frigid': '\f768',
  'temperature-high': '\f769',
  'temperature-hot': '\f76a',
  'temperature-low': '\f76b',
  'tencent-weibo': '\f1d5',
  'tenge': '\f7d7',
  'tennis-ball': '\f45e',
  'terminal': '\f120',
  'text-height': '\f034',
  'text-width': '\f035',
  'th': '\f00a',
  'th-large': '\f009',
  'th-list': '\f00b',
  'the-red-yeti': '\f69d',
  'theater-masks': '\f630',
  'themeco': '\f5c6',
  'themeisle': '\f2b2',
  'thermometer': '\f491',
  'thermometer-empty': '\f2cb',
  'thermometer-full': '\f2c7',
  'thermometer-half': '\f2c9',
  'thermometer-quarter': '\f2ca',
  'thermometer-three-quarters': '\f2c8',
  'theta': '\f69e',
  'think-peaks': '\f731',
  'thumbs-down': '\f165',
  'thumbs-up': '\f164',
  'thumbtack': '\f08d',
  'thunderstorm': '\f76c',
  'thunderstorm-moon': '\f76d',
  'thunderstorm-sun': '\f76e',
  'ticket': '\f145',
  'ticket-alt': '\f3ff',
  'tilde': '\f69f',
  'times': '\f00d',
  'times-circle': '\f057',
  'times-hexagon': '\f2ee',
  'times-octagon': '\f2f0',
  'times-square': '\f2d3',
  'tint': '\f043',
  'tint-slash': '\f5c7',
  'tire': '\f631',
  'tire-flat': '\f632',
  'tire-pressure-warning': '\f633',
  'tire-rugged': '\f634',
  'tired': '\f5c8',
  'toggle-off': '\f204',
  'toggle-on': '\f205',
  'toilet': '\f7d8',
  'toilet-paper': '\f71e',
  'toilet-paper-alt': '\f71f',
  'tombstone': '\f720',
  'tombstone-alt': '\f721',
  'toolbox': '\f552',
  'tools': '\f7d9',
  'tooth': '\f5c9',
  'toothbrush': '\f635',
  'torah': '\f6a0',
  'torii-gate': '\f6a1',
  'tornado': '\f76f',
  'tractor': '\f722',
  'trade-federation': '\f513',
  'trademark': '\f25c',
  'traffic-cone': '\f636',
  'traffic-light': '\f637',
  'traffic-light-go': '\f638',
  'traffic-light-slow': '\f639',
  'traffic-light-stop': '\f63a',
  'train': '\f238',
  'tram': '\f7da',
  'transgender': '\f224',
  'transgender-alt': '\f225',
  'trash': '\f1f8',
  'trash-alt': '\f2ed',
  'trash-restore': '\f829',
  'trash-restore-alt': '\f82a',
  'treasure-chest': '\f723',
  'tree': '\f1bb',
  'tree-alt': '\f400',
  'tree-christmas': '\f7db',
  'tree-decorated': '\f7dc',
  'tree-large': '\f7dd',
  'tree-palm': '\f82b',
  'trees': '\f724',
  'trello': '\f181',
  'triangle': '\f2ec',
  'tripadvisor': '\f262',
  'trophy': '\f091',
  'trophy-alt': '\f2eb',
  'truck': '\f0d1',
  'truck-container': '\f4dc',
  'truck-couch': '\f4dd',
  'truck-loading': '\f4de',
  'truck-monster': '\f63b',
  'truck-moving': '\f4df',
  'truck-pickup': '\f63c',
  'truck-plow': '\f7de',
  'truck-ramp': '\f4e0',
  'tshirt': '\f553',
  'tty': '\f1e4',
  'tumblr': '\f173',
  'tumblr-square': '\f174',
  'turkey': '\f725',
  'turtle': '\f726',
  'tv': '\f26c',
  'tv-retro': '\f401',
  'twitch': '\f1e8',
  'twitter': '\f099',
  'twitter-square': '\f081',
  'typo3': '\f42b',
  'uber': '\f402',
  'ubuntu': '\f7df',
  'uikit': '\f403',
  'umbrella': '\f0e9',
  'umbrella-beach': '\f5ca',
  'underline': '\f0cd',
  'undo': '\f0e2',
  'undo-alt': '\f2ea',
  'unicorn': '\f727',
  'union': '\f6a2',
  'uniregistry': '\f404',
  'universal-access': '\f29a',
  'university': '\f19c',
  'unlink': '\f127',
  'unlock': '\f09c',
  'unlock-alt': '\f13e',
  'untappd': '\f405',
  'upload': '\f093',
  'ups': '\f7e0',
  'usb': '\f287',
  'usd-circle': '\f2e8',
  'usd-square': '\f2e9',
  'user': '\f007',
  'user-alt': '\f406',
  'user-alt-slash': '\f4fa',
  'user-astronaut': '\f4fb',
  'user-chart': '\f6a3',
  'user-check': '\f4fc',
  'user-circle': '\f2bd',
  'user-clock': '\f4fd',
  'user-cog': '\f4fe',
  'user-crown': '\f6a4',
  'user-edit': '\f4ff',
  'user-friends': '\f500',
  'user-graduate': '\f501',
  'user-hard-hat': '\f82c',
  'user-headset': '\f82d',
  'user-injured': '\f728',
  'user-lock': '\f502',
  'user-md': '\f0f0',
  'user-md-chat': '\f82e',
  'user-minus': '\f503',
  'user-ninja': '\f504',
  'user-nurse': '\f82f',
  'user-plus': '\f234',
  'user-secret': '\f21b',
  'user-shield': '\f505',
  'user-slash': '\f506',
  'user-tag': '\f507',
  'user-tie': '\f508',
  'user-times': '\f235',
  'users': '\f0c0',
  'users-class': '\f63d',
  'users-cog': '\f509',
  'users-crown': '\f6a5',
  'users-medical': '\f830',
  'usps': '\f7e1',
  'ussunnah': '\f407',
  'utensil-fork': '\f2e3',
  'utensil-knife': '\f2e4',
  'utensil-spoon': '\f2e5',
  'utensils': '\f2e7',
  'utensils-alt': '\f2e6',
  'vaadin': '\f408',
  'value-absolute': '\f6a6',
  'vector-square': '\f5cb',
  'venus': '\f221',
  'venus-double': '\f226',
  'venus-mars': '\f228',
  'viacoin': '\f237',
  'viadeo': '\f2a9',
  'viadeo-square': '\f2aa',
  'vial': '\f492',
  'vials': '\f493',
  'viber': '\f409',
  'video': '\f03d',
  'video-plus': '\f4e1',
  'video-slash': '\f4e2',
  'vihara': '\f6a7',
  'vimeo': '\f40a',
  'vimeo-square': '\f194',
  'vimeo-v': '\f27d',
  'vine': '\f1ca',
  'vk': '\f189',
  'vnv': '\f40b',
  'volcano': '\f770',
  'volleyball-ball': '\f45f',
  'volume': '\f6a8',
  'volume-down': '\f027',
  'volume-mute': '\f6a9',
  'volume-off': '\f026',
  'volume-slash': '\f2e2',
  'volume-up': '\f028',
  'vote-nay': '\f771',
  'vote-yea': '\f772',
  'vr-cardboard': '\f729',
  'vuejs': '\f41f',
  'walker': '\f831',
  'walking': '\f554',
  'wallet': '\f555',
  'wand': '\f72a',
  'wand-magic': '\f72b',
  'warehouse': '\f494',
  'warehouse-alt': '\f495',
  'watch': '\f2e1',
  'watch-fitness': '\f63e',
  'water': '\f773',
  'water-lower': '\f774',
  'water-rise': '\f775',
  'webcam': '\f832',
  'webcam-slash': '\f833',
  'weebly': '\f5cc',
  'weibo': '\f18a',
  'weight': '\f496',
  'weight-hanging': '\f5cd',
  'weixin': '\f1d7',
  'whale': '\f72c',
  'whatsapp': '\f232',
  'whatsapp-square': '\f40c',
  'wheat': '\f72d',
  'wheelchair': '\f193',
  'whistle': '\f460',
  'whmcs': '\f40d',
  'wifi': '\f1eb',
  'wifi-1': '\f6aa',
  'wifi-2': '\f6ab',
  'wifi-slash': '\f6ac',
  'wikipedia-w': '\f266',
  'wind': '\f72e',
  'wind-warning': '\f776',
  'window': '\f40e',
  'window-alt': '\f40f',
  'window-close': '\f410',
  'window-maximize': '\f2d0',
  'window-minimize': '\f2d1',
  'window-restore': '\f2d2',
  'windows': '\f17a',
  'windsock': '\f777',
  'wine-bottle': '\f72f',
  'wine-glass': '\f4e3',
  'wine-glass-alt': '\f5ce',
  'wix': '\f5cf',
  'wizards-of-the-coast': '\f730',
  'wolf-pack-battalion': '\f514',
  'won-sign': '\f159',
  'wordpress': '\f19a',
  'wordpress-simple': '\f411',
  'wpbeginner': '\f297',
  'wpexplorer': '\f2de',
  'wpforms': '\f298',
  'wpressr': '\f3e4',
  'wreath': '\f7e2',
  'wrench': '\f0ad',
  'x-ray': '\f497',
  'xbox': '\f412',
  'xing': '\f168',
  'xing-square': '\f169',
  'y-combinator': '\f23b',
  'yahoo': '\f19e',
  'yandex': '\f413',
  'yandex-international': '\f414',
  'yarn': '\f7e3',
  'yelp': '\f1e9',
  'yen-sign': '\f157',
  'yin-yang': '\f6ad',
  'yoast': '\f2b1',
  'youtube': '\f167',
  'youtube-square': '\f431',
  'zhihu': '\f63f',
  'done-list': '\e92e',
  'rocket-s': '\e92f',
  'abc-desc': '\e930',
  'book-shelf': '\e931',
);

$listOfColors: (
  'black': $cBlack,
  'white': $cWhite,
  'blue': $cBlue,
  'blue-dark': $cBlueDark,
  'aqua': $cAqua,
  'red': $cRed,
  'white-red': $cWhiteRed,
  'red-mute': $cRedMute,
  'green': $cGreen,
  'grey': $cGrey,
  'light-grey-blue': $cLightGreyBlue,
  'orange': $cOrange,
  'orange-dark': $cOrangeDark,
  'yellow': $cYellowIcons,
  'bb-yellow':$cBbYellow,
  'qujat-aqua':$cQujatAqua,
  'coral': $cCoral,
  'light-purple': $cLightPurple,
);

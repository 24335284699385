.sn-dialog__backdrop {
  z-index: -1;
  pointer-events: all;
  background: $dimmed-background;
}

.sn-ios-padding {
  .sn-dialog__inner {
    padding-top: $ios-statusbar-height !important;
    padding-top: env(safe-area-inset-top) !important;
    padding-bottom: env(safe-area-inset-bottom) !important;

    > div {
      max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
    }
  }
}

.sn-body {
  &--dialog {
    overflow: hidden;
  }
}

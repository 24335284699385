.s-form__checkbox {
  display: none;
  //@include pos(absolute, -1);
  //width: unset;
  //opacity: 0;

  &:checked {
    + label {
      &::before {
        font-size: 2.1rem;
        margin-top: -0.1rem;
        margin-left: -0.1rem;
        content: '\E64D';
      }
    }
  }

  + label {
    @include pos();
    display: inline-block;
    line-height: 1.2em;
    color: $cBlack;
    padding-left: 3rem;
    padding-bottom: 0;
    cursor: pointer;

    &::before {
      @include pos(absolute, $l: 0);
      font-family: $fIcons;
      color: $cBlack;
      content: '\E6E8';
    }
  }

  &--thm-light {
    + label {
      color: $cWhite;

      &::before {
        color: $cWhite;
      }
    }
  }
}

.row-sort-start-btn {
  @extend .row-sort-start, .row-sort-wrap;

  > * {
    margin: 1rem;

    &:last-child {
      margin-left: 1rem;
    }
  }
}


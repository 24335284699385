%s-region-list__item {
  display: block;
  width: 100%;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.s-region-list__item {
  @extend %s-region-list__item;

  &.is-active {
    > span {
      &::after {
        margin-top: -0.6rem;
        transform: rotateX(180deg);
        transition: transform $gDuration;
      }
    }
  }

  .s-region-list {
    display: none;
  }

  > span {
    @include pos();
    font-weight: 600;
    cursor: pointer;
    border-bottom: 1px dotted transparent;
    transition: border-color $gDuration ease-in;

    &::after {
      @include pos(absolute, $t: 50%, $r: -2rem);
      margin-top: -0.4rem;
      font-family: $fIcons;
      font-size: 1.2rem;
      content: map-get($listOfIcons, 'angle-down');
      transform: rotateX(0);
      transition: transform $gDuration;
    }

    &:hover {
      border-color: $cBlack;
      transition: border-color $gDuration ease-in;
    }
  }
}

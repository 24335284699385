.sn-time__event {
  position: absolute;
  bottom: 2px;
  left: 50%;
  height: 5px;
  width: 10px;
  border-radius: 5px;
  background-color: $secondary;
  transform: translate3d(-50%, 0, 0);
}

@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-main-footer__links {
    @include sflx($dir: column);

    a,
    span {
      &:not( :last-child ) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and ( max-width: 767px ) {
  .s-main-footer {
    flex-direction: column;
    background-image: none;
  }

  .s-main-footer__left-panel {
    width: 100%;
    flex-direction: column;
  }

  .s-main-footer__right-panel {
    width: 100%;
    text-align: left;
  }

  .s-main-footer__links {
    padding: 3rem 0;
  }

  .s-main-footer__link,
  .s-main-footer__item {
    &:not( :last-child ) {
      margin-right: 0;
    }
  }
}

.s-time-sheet {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  width: 100%;
}

.s-time-sheet-row {
  min-height: 3em;

  &:nth-child(odd) {
    background-color: #F5F6FA;
  }

  &:last-child {
    .s-time-sheet-cell {
      border-bottom: 1px solid #DBDDEA;
    }
  }
}

.s-time-sheet-cell {
  padding: 0.5em 1em;
  border-right: 1px solid #DBDDEA;
  vertical-align: middle;
}

.s-time-sheet-cell-day {
  user-select: none;
  text-align: center;
}

.s-time-sheet-cell-day.s-time-sheet-cell-holiday {
  color: #97A5E6;
  background: #F2F4FD;
  text-align: center;
  cursor: inherit;
}

.s-time-sheet-cell-holiday {
  color: #97A5E6;
  background-color: #F2F4FD;
  text-align: center;
  font-weight: bold;
}

.s-time-sheet-cell-holiday.s-time-sheet-cell-out {
  font-weight: normal;
}

.s-time-sheet-cell-out {
  color: #B00000;
  text-align: center;
  font-weight: bold;
}

.s-time-sheet-cell-moved {
  color: #8A8981;
}

.s-time-sheet-cell-dropped {
  color: #6A645C;
}

.s-time-sheet-cell-absent {
  &:hover {
    .s-time-sheet-cell-absent--value {
      display: none;
    }

    background: url('../images/attendance.svg') center center no-repeat;

    &.without-hover-icon {
      background: unset;
    }
  }

  cursor: pointer;
  color: #B00000;
  text-align: center;
  font-weight: bold;

  &.absent-symbol-load-file {
    color: #8BBD20;
  }
}

.s-time-sheet-cell-attend {
  color: #97A5E6;
  text-align: center;
}

.s-time-sheet-cell-holiday.s-time-sheet-cell-total {
  color: #97A5E6;
  font-weight: bold;
}

.s-time-sheet-cell-total {
  font-weight: bold;
  color: #3045A7;
}

.s-time-sheet-cell-header {
  min-height: 4em;
  text-align: center;

  &--current {
    background: #faff0042;
  }
}

.s-time-sheet--head-row {
  min-height: 3em;
}

.s-time-sheet--head-cell {
  padding: 1.2em 0.5em 1.5em;
  border-top: 1px solid #DBDDEA;
  border-bottom: 1px solid #DBDDEA;
  vertical-align: middle;
}

.s-time-sheet--head-cell-month {
  border-right: 1px solid #DBDDEA;
}

.s-time-sheet-fixed-part-container {
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 1em;

  .s-time-sheet-row {
    &:nth-child(2) {
      .s-time-sheet-cell {
        &:first-child {
          border: none;
        }
      }
    }
  }
}

.s-time-sheet-scroll-part-container {
  flex-grow: 3;
  flex-shrink: 2;
  overflow-x: scroll;
  padding-bottom: 1em;

  .s-time-sheet-cell {
    &:last-child {
      border-right: none;
    }
  }
}

.s-time-sheet-fixed-part,
.s-time-sheet-scroll-part {
  display: table;
  width: 100%;
}

.child-card__photo {
  @include sbg($s: cover);
  display: block;
  width: 100%;
  padding-top: 95%;
  border-radius: $gBorderRadius;
  overflow: hidden;
}

.child-card__no_photo {
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;
  @include sbg (
    $c:#fafbfe,
    $i:'../images/avatar_rectangle.svg',
    $s: 60%
  );
  width: 100%;
}

.child-card__photo_empty {
  @include pos();
  cursor: pointer;
  transition: background-color .2s ease-in;

  &::after {
    @include pos(absolute, $t: 50%, $l: 50%);
    transform: translate(-50%, -50%);
    @include sflx($juscont: center, $alitems: center);
    width: 6.4rem;
    height: 6.4rem;
    font-family: $fIcons;
    font-size: 3.8rem;
    color: #aeb3c6;
    border: 1px solid #dfe2ed;
    border-radius: 50%;
    content: '\E61A';
  }

  &:hover {
    background-color: darken(#f7f9fc, 3%) !important;
    transition: background-color .2s ease-in;
  }
}

.cabinet-personal__tab {
  font-weight: 600;
  color: #838796;
  margin-right: 4rem;
  border-bottom: 2px solid transparent;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: #838796;
    border-color: #838796;
  }

  &.is-active {
    color: #3a53b6;
    border-color: #3a53b6;
  }


  @media screen and (max-width: 999px) {
    margin-bottom: 2rem;
  }
}

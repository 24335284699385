@keyframes a-scale {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.04, 1.04, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.courses-array {
  display: table;
  width: 100%;
  font-size: 0;
  padding-bottom: 3.2rem;

  .courses-block {
    width: 49%;
    max-height: 250px;
    max-width: 100.1rem;
    margin: 0 auto 3rem;

    &:last-child {
      margin: 0 auto 0;
    }
  }
}

.flex-wrap {
  .courses-array {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.video-wrapper {
  margin-bottom: 3rem;
}

.courses-wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  max-width: 100.1rem;

  .courses-footer {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }
}

.courses-block {
  @extend .block;
  display: inline-flex;
  flex-direction: column;
  color: $c_black;
}

.courses-block__wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 347px;
}

.courses-block__image {
  //flex: 1 0 auto;
  width: 25.3rem;
  max-height: 250px;
  @include resp_height($height: 253 / 347 * 100%);
  @include sbg($size: cover);
  border-radius: 6px;
}

.courses-block__description {
  width: 1002 / 1280 * 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 3rem;
  color: black;
}

.courses-block__header {
  width: 100%;
  padding-right: 3.1rem;
}

.courses-block__title {
  display: inline-flex;
  flex-direction: column;
  font: 2.4rem/1.2em $font_sans_sb;
  color: $c_black;
  text-transform: uppercase;
  padding-top: 2.4rem;
  padding-bottom: 1.3rem;
  @include cl_w(558, 687)
}

.courses-block__time {
  display: inline-flex;
  flex-direction: column;
  font: 1.6rem/1.2em $font_sans_sb;
  color: $c_black;
  @include cl_w(129, 687);
  justify-content: flex-end;
  white-space: nowrap;

  span {
    &:before {
      font-family: $font_themify;
      margin-right: 1rem;
      content: "\E6C6";
      color: $c_green;
    }
  }
}

.courses-block__body {
  font: 1.6rem/1.2em $font_default;
  padding-right: 5.5rem;
  flex-grow: 1;
}

.courses-block__control {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3.1rem;
  padding-bottom: 2.9rem;

}

.home-courses-outer {
  background-image: url(../images/course_bg.jpg);
  background-color: #e9f2fb;
  background-position: center center;
  background-size: auto 100%;


  .home-courses-inner {
    padding-top: 2.5rem;
    padding-bottom: 3.5rem;

    .section-title {
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-courses-outer {
    background-position: calc(100% + 32rem) -28rem, calc(-3rem - 60%) center;
  }
}

@media screen and (max-width: 800px) {
  .home-courses-outer {
    background: #e9f2fb url(../images/flag_kz.png) center/ 110rem no-repeat;
  }
}

.course-outer {
  margin-bottom: 3rem;

  .course-inner {
    background-color: $c_white;
    box-shadow: 0 0 10px rgba(#687c8f, .28);
    border-radius: 6px;
    padding: 3rem;
  }
}

.course-content-wrapper {
  width: 100%;
  margin: 0 auto;
}

.lesson-collapse {
  background-color: $c_white;
  box-shadow: 0 0 5px rgba(#687c8f, .28);
  border-radius: 6px;
  margin-bottom: 0.6rem;

  &:last-child {
    margin-bottom: 3rem;
  }

  .lesson-collapse-title {
    font: 1.8rem/1.4em $font_sans_sb;
    cursor: pointer;
    padding: 2.1rem 1.8rem;
    box-shadow: 0 0 5px rgba(#687c8f, .28);
    border-radius: 6px;

    &:before {
      font-family: $font_themify;
      margin-right: 1rem;
      content: "\e61a";
      color: #7282c3;
    }
  }

  .lesson-collapse-body {
    display: none;
    padding: 3rem 3rem 3.5rem;
  }

  &.is-active {
    .lesson-collapse-title {
      color: $c_white;
      background-color: #7282c3;
      box-shadow: 0 5px 5px rgba(#687c8f, .28);
      border-radius: 6px 6px 0 0;

      &:before {
        color: $c_white;
        content: "\e622";
      }
    }

    .lesson-collapse-body {
      display: block;
    }
  }
}

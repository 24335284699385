.s-test-process__subjects-list {
  display: block;
  width: 100%;

  @media screen and ( max-width: 1008px ) {
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;
  }
}

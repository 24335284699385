.s-test-process__subject {
  display: block;
  width: 100%;
  line-height: 1.4em;
  text-transform: uppercase;
  padding: 1rem 2rem;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  transition: background-color $gDuration ease-in;

  &:not( :last-child ) {
    border-bottom: 1px solid $stpGrey;
  }

  &:hover {
    background-color: $stpGrey;
    transition: background-color $gDuration ease-in;
  }

  &.is-selected {
    background-color: $stpGrey;
    transition: background-color $gDuration ease-in;
  }
}

//html, body {
//  height: 100%;
//  font-size: $font_size_default !important;
//}
//body {
  //min-width: 32rem;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: center;
  //text-rendering: optimizeLegibility !important;
  //-webkit-font-smoothing: antialiased !important;
  //-moz-osx-font-smoothing:grayscale;
  //-webkit-tap-highlight-color: transparent;
  //-webkit-tap-highlight-color: rgba(0,0,0,0);
  //font-family: $font_default;
  //color: $c_black;
  //background-color: $c_white;
  //@include selection($c_blue, $c_white);
  //* {
  //  box-sizing: border-box;
  //  &:focus {
  //    outline: none;
  //  }
  //}
//}
//a {
//  text-decoration: none;
//}
//ul, ol {
//  list-style: none;
//  margin: 0;
//  padding: 0;
//}
[class*=-outer] {
  width: 100%;
  [class*=-inner] {
    width: 100%;
    max-width: $gWidthDef;
    margin: 0 auto;
  }
  [class*=-inner-new] {
    width: 100%;
    max-width: 1810px;
    margin: 0 auto;
    font-size: 10px;
  }
}
[class*=-array] {
  display: table;
  //font-size: 0;
  width: 100%;
}
.flex-wrap {
  [class*=-array] {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}
//.main-content {
//  width: 100%;
//  flex: 1 0 auto;
//  display: flex;
//  flex-direction: column;
//  justify-content: flex-start;
//  align-items: center;
//}
//table {
//  border-spacing: 0;
//  border-collapse: collapse;
//  table-layout: fixed;
//}
//[data-submenu] {
//  display: none;
//  opacity: 0;
//  transition: opacity .2s ease-in;
//  &.is-active {
//    opacity: 1;
//    transition: opacity .2s ease-in;
//  }
//}
//.section-bottom__control {
//  display: flex;
//  width: 100%;
//  justify-content: flex-end;
//  align-items: center;
//}
//
//.empty-text {
//  font-size: 1.6rem;
//}


//@media screen and (max-width: $gWidthDef + 40px) {
//  [class*=-outer] {
//    [class*=-inner] {
//      padding-right: 2rem;
//      padding-left: 2rem;
//    }
//  }
//}
//@media screen and (max-width: 360px) {
//  .section-bottom__control {
//    .button {
//      width: 100%;
//    }
//  }
//}
//
//[v-cloak] {display: none}

@media screen and (max-width: 1440px) {
  [class*=-outer] {
    [class*=-inner] {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
}

.s-field__value {
  display: block;
  width: 100%;
  background-color: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #e3e7ea;
  padding-bottom: 1rem;
  transition-duration: .3s;
  &:focus {
    border-color: #3045a7;
  }
}
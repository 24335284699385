@media screen and ( max-width: $gWidthMid - 1px ) {
  .static-block{
    top: 3rem;
    left: 2rem;
  }
}

@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-bilim-marketing{
    flex-direction: column;
  }
  .s-bilim-marketing__textWrapper{
    padding: 0 2rem 2rem;
    width: 90%;
    border: none;
    text-align: right;
  }
  .s-bilim-marketing__imagesWrapper {
    align-self: unset;
  }

  .static-block{
    position: relative;
    top: unset;
    left: unset;
    margin: auto;
  }

}

@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-bilim-marketing__imagesWrapper {
    width: 100%;
  }
  .s-bilim-marketing{
    padding: 2rem;
  }

  .s-bilim-marketing__textWrapper{
    width: 100%;
    padding: 0 0 2rem;
  }
  .s-bilim-marketing__schoolImg{
    display: none;
    &--mobile{
      display: block;
      margin-top: -3rem;
    }
  }
  .s-bilim-marketing__text{
    a{
      font-size: 16px;
      min-width: 15rem;
    }
  }

  .s-bilim-marketing__textHead{
    font-size: 35px;
  }
  .s-bilim-marketing__textParagraph{
    font-size: 16px;
  }

}

.sn-field__native {
  @include sflx();
  width: 100%;
  min-width: 0; // FF
  line-height: 1.3em;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  background: none;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  border: none;
  outline: 0 !important; // FF

  &:-webkit-autofill {
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 3rem $cWhite inset !important;
    }
  }

  &[ type='file' ] {
    line-height: 1em; // Chrome
  }
}

.kindergarten-block__address {
  @extend %magic-title;
  max-height: 7.2rem;
  margin-top: auto;
  margin-bottom: 2.5rem;

  &:hover {
    span {
      background-color: $cWhite;
    }
  }
}
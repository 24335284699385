.home-colleges-outer {
  background-color: #e2e8ef;
  .home-colleges-inner {
    padding-top: 4.5rem;
    padding-bottom: 3.5rem;
    .section-title {
      margin-bottom: 2rem;
    }
    .button {
      min-width: 24rem;
      margin: 0 auto;
      display: table;
    }
  }
}
.s-test-process__result-time-value {
  i {
    font-style: normal;
    font-weight: 600;
    font-size: 4.8rem;
  }

  span {
    vertical-align: baseline;
    font-size: 2rem;
    margin: 0 2rem 0 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and ( max-width: 479px ) {
    i {
      font-size: 3.6rem;
    }
  }
}

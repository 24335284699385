.form-radio {
  @extend .form-checkbox;

  &:checked {
    + label {
      &::after {
        display: block;
      }
    }
  }

  + label {
    &::before {
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid #a9b9c2;
      content: '' !important;
    }

    &::after {
      @include pos(absolute, $t: 50%, $l: .6rem);
      transform: translateY(-50%);
      display: none;
      width: .8rem;
      height: .8rem;
      background-color: #364ca7;
      border-radius: 50%;
      content: '';
    }
  }
}
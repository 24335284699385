.adaptive-table {
  table.sn-table {
    @media screen and (max-width: $gMinWidthTablet) {
      tr {
        height: 2.8rem;
      }
      th, td {
        padding: 0.5rem 1rem;
        white-space: normal;
      }
      th {
        font-size: 1rem;
      }
      td {
        font-size: 1.2rem;
      }
    }
  }
}

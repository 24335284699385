.s-file__name {
  @include sflx($alitems: center);
  width: 100%;
  max-width: 100%;
  font-size: 1.4rem;
  padding: 1.5rem 0;
  margin-bottom: -0.5rem;
  transition: color $gDuration ease-in;

  .s-str-ico {
    flex: 1 1 auto;
    line-height: 1.3em;
    padding-left: 1.6em;
    margin-right: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .s-file__btn {
    margin-left: 1rem;
  }
}

.sn-date__calendar-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  width: 14.285% !important;
  height: 12.5% !important;
  position: relative;

  &--out {
    opacity: 0.18;
  }

  > div,
  button {
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
  }

  .sn-btn--dense {
    padding: 0.7rem;
  }

  > div {
    line-height: 32px;
    text-align: center;
  }

  &--fill {
    visibility: hidden;
  }
}

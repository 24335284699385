.s-test-process__status-value {
  color: $cGreen;
  padding: 1rem 0;

  &.is-wait {
    color: $cOrange;
  }

  &.is-end {
    color: $cRed;
  }

  &.is-preview {
    color: $cGrey;
  }
}

.sn-virtual-scroll__padding {
  background: linear-gradient(transparent,
    transparent 20%,
    rgba(128, 128, 128, .03) 20%,
    rgba(128, 128, 128, .08) 50%,
    rgba(128, 128, 128, .03) 80%,
    transparent 80%,
    transparent);
  background-size: 100% 50px;
}

.sn-table.sn-virtual-scroll__padding {
  tr {
    height: 0 !important;
  }

  td {
    padding: 0 !important;
  }
}

.tbl-cont {
  table-layout: unset;

  th, td {
    height: 5rem;
    text-align: left;
    padding: .5rem 1.2rem;

    &:not(:last-child) {
      border-right: 1px solid #dfe2ed;
    }
  }

  th {
    font-weight: 600;
    font-size: 1.4rem;
    color: $cWhite;
    background-color: #aeb3c6;

    &:first-child {
      border-radius: $gBorderRadius 0 0 0;
    }

    &:last-child {
      border-radius: 0 $gBorderRadius 0 0;
    }
  }

  td {
    border-bottom: 1px solid #dfe2ed;

    &[colspan] {
      padding: 0;
    }

    strong {
      font-weight: 600;
    }

    .tbl-cont {
      th {
        border-radius: 0!important;
      }
    }
  }
}

.tbl-cont_align-center {
  text-align: center !important;
}

@for $name from 1 through 24 {
  .tbl-col-#{$name} {
    width: (100 / 24 * $name) * 1%;
  }
}


@media screen and (max-width: 999px) {
  .tbl-cont {
    th, td {
      height: auto;
    }
  }

  .tbl-cont_align-center {
    text-align: left !important;
  }
}
@media screen and ( max-width: 479px ) {
  .s-group {
    @include sflx($dir: column, $alitems: center);
    width: 100%;
    margin-right: 0;
    margin-left: 0;

    > * {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

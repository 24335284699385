.tof-body__input {
  display: block;
  width: 100%;
  height: 4rem;
  font-size: 1.6rem;
  color: $cBlack;
  text-align: center;
  background-color: $cWhite;
  padding-right: 0;
  padding-left: 0;
  border-radius: $gBorderRadius;
  border: 1px solid #f5ba2e;

  @include placeholder(#aeb3c6);

  &[disabled] {
    background-color: transparent;
    border: unset;
  }
}

.s-inform-block {
  @include sbg($c: #ffecb8, $i: '../images/move_to_new.png', $p: center top);
  width: 100%;
  max-width: 102.4rem;
  font-size: 1.7rem;
  line-height: $lh;
  font-weight: 300;
  padding: 1.5rem 5rem;
  margin-top: -3rem;
  border-radius: 10px;
  box-shadow: 0 0.1rem 1.3rem rgba(48, 69, 167, 0.45);
}

.s-to-top {
  //@include pos(fixed, 16, $b: 42%, $r: 2rem);
  @include pos(fixed, 16, $b: 0, $r: 0);
  @include sflx($alitems: center, $juscont: center);
  width: 6rem;
  height: 6rem;
  font-size: 3.6rem;
  font-family: $fIcons;
  color: $cWhite;
  background-color: $cGreen;
  border-radius: $gBorderRadius;
  cursor: pointer;
  box-shadow: 0 0 0;
  transform: scale(0);
  opacity: 0;
  transition: all $gDuration ease-out;

  &::after {
    content: '\E627';
  }

  &:hover {
    background-color: lighten($cBlue, 5%);
    box-shadow: 0 0 1.5rem rgba($cBlack, 0.3);
    transform: scale(1.05);
    transition: all $gDuration ease-in;
  }

  &--visible {
    opacity: 1;
    transform: scale(1);
    transition: all $gDuration ease-out;
  }
}

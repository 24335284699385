.s-statits-fig__block-inner {
  @include sflx($juscont: center, $alitems: center);
  width: 20.6rem;
  height: 20.6rem;
  font-size: 3.2rem;
  font-weight: 600;
  color: $cBlue;
  text-transform: uppercase;
  padding: 2rem;
  transform: rotate(45deg);

  img {
    max-width: 100%;
  }
}

.sn-table__bottom {
  min-height: 4.8rem;
  padding: 0.4rem 1.4rem 0.4rem 1.6rem;
  font-size: 1.2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  .sn-table__control {
    min-height: 2.4rem;
  }
}

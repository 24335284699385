.s-congratulations__image {
  @include sbg($s: cover);
  width: 25rem;
  height: 25rem;
  margin: 3rem auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

$sContainerContour: #687c8f;

.container-contour {
  display: block;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba($sContainerContour, .28);

  @media screen and (max-width: 479px) {
    border-radius: 0;
    box-shadow: none;
  }
}

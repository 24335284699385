.tab-head__item {
  flex: 1;
  display: block;
  color: $cTabColor;
  text-transform: uppercase;
  padding: .9rem 3rem;
  //border-top: 1px solid $cTabHeadBorder;
  //border-right: 1px solid $cTabHeadBorder;
  //border-bottom: 1px solid $cTabHeadBorder;
  cursor: pointer;
  background-color: $cTabBG;
  transition: background-color .2s ease-in;

  &:last-of-type {
    border-radius: 0 $gBorderRadius $gBorderRadius 0;
  }

  &:first-of-type {
    //border-left: 1px solid $cTabHeadBorder;
    border-radius: $gBorderRadius 0 0 $gBorderRadius;
  }

  &:hover {
    background-color: darken($cWhite, 5%);
    transition: background-color .2s ease-in;
  }

  &.is-active {
    color: $cWhite;
    background-color: $cTabHeadActive;
    //border-color: $cTabHeadActive;
    cursor: default;
  }

  &:last-of-type.is-active {
    color: $cWhite;
    background-color: $cTabHeadActive;
    //border-color: $cTabHeadActive;
  }
}

html {
  font-size: $gFontSize;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  @include sflx($dir: column, $alitems: center);
  font-size: 1.6rem;
  font-family: $fDef;
  color: $cBlack;
  //background-color: $cBodyBackground;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-tap-highlight-color: transparent;

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  strong {
    font-weight: 600;
  }
}

.noscript {
  display: block;
  text-align: center;
  padding: 3rem;
}

button {
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

.is-locked {
  overflow: hidden;
}

.main {
  @include pos($z: 3);
  @include sflx($dir: column, $alitems: center);
  flex: 1 0 auto;
  width: 100%;
}

[data-toggle-class] {
  cursor: pointer;
}


//@media screen and (min-width: 2500px) {
//  html {
//    font-size: $gFontSizeFixed;
//  }
//}

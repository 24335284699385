@import '../mixins/_font_face.mix.scss';
@import '../mixins/_lib.mix.scss';
@import '../mixins/_sprite.mix.scss';
@import '../mixins/_clearfix.mix.scss';
@import '../mixins/_sprite.mix.scss';
@import '../mixins/_sprite.mix.scss';

//@include font-face("Open Sans", "../fonts/OpenSans_Regular");
//@include font-face("Open Sans", "../fonts/OpenSans_Italic", null, italic);
//@include font-face("Open Sans", "../fonts/OpenSans_Bold", bold);
//@include font-face("Open Sans", "../fonts/OpenSans_BoldItalic", bold, italic);
//@include font-face("Open Sans Semibold", "../fonts/OpenSans_Semibold");
//@include font-face("Open Sans Light", "../fonts/OpenSans_Light");
//@include font-face("themify", "../fonts/themify");
//@include font-face("fontawesome", "../fonts/fontawesome-webfont");

$font_default: "Open Sans", sans-serif;
$font_sans_sb: "Open Sans Semibold", sans-serif;
$font_sans_l: "Open Sans Light", sans-serif;
$font_themify: snation;
$font_awesome: fontawesome;
$font_size_default: 10px;
$width_default: 1280px;
$width_tablet: 999px;
$width_mobile: 767px;

[v-cloak]{display:none}

// Global
$gFontSize: 10px;
$gFontSizeFixed: 10 / 2560 * 100vw;
$gBorderRadius: 5px;
$gWidthDef: 1280px;
$gWidthMax: 2560px;
$animationMenuDuration: .3s;

// Fonts
$fDef: 'Open Sans', sans-serif;

.s-statits-fig__block {
  @include pos();
  background-color: $cWhite;
  margin: 1rem;

  &:not( :first-child ) {
    border-radius: 16px;
    box-shadow: 0 0 1.3rem rgba(60, 80, 172, 0.58);
  }

  &--first {
    .s-statits-fig__block-desc {
      right: 50%;
      bottom: 164%;
      width: 44.8rem;
    }
  }

  &--second {
    .s-statits-fig__block-desc {
      top: 134%;
      left: 60%;
      text-align: right;

      span {
        &::after {
          left: 0;
        }
      }
    }
  }

  &--third {
    .s-statits-fig__block-inner {
      img {
        max-width: 80%;
      }
    }

    .s-statits-fig__block-desc {
      right: 53%;
      bottom: 158%;
    }
  }
}

.s-region-container {
  display: flex;
  flex-direction: column;
  width: 41rem;
  max-width: 100%;
  transition: all 0.5s;

  &--right {
    @extend .s-region-container;
    border-left: 1px solid $cBorder;
    overflow: hidden;
    padding-left: 1rem;

    &.opened {
      min-height: max-content;
    }
  }
}

.opened-enter-active {
  transition: all 0.5s;
  width: 41rem;
  opacity: 1;
  overflow: hidden;
  max-height: 90rem;
}

.opened-enter {
  transition: all 0.5s;
  opacity: 0;
  width: 0;
  max-height: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
  height: 4rem;
}

.slide-fade-leave-active {
  transition: all 0.3s;
  height: 4rem;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  height: 0;
}
.s-notification__link {
  display: inline-block;
  color: $cBlue;
  border-bottom: 1px solid transparent;
  transition: border-bottom $gDuration ease-in;

  &:not( :last-child ) {
    margin-bottom: 0.5rem;
  }

  &:hover {
    border-bottom-color: darken($cBlue, 1%);
    transition: border-bottom $gDuration ease-in;
  }
}

.s-insta-carousel {
  .owl-stage {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .owl-item {
    @include sflx($dir: column);
  }

  .owl-nav {
    @include pos(absolute, 2, 50%);
    @include sflx($juscont: space-between);
    margin-top: -1.9rem;
    width: 100%;

    .owl-prev,
    .owl-next {
      outline: none;

      &::after {
        font: 3.8rem/1em $fIcons;
        color: #8DBE2D;
      }

      &.disabled {
        &::after {
          color: $cGrey;
        }
      }
    }

    .owl-prev {
      margin-left: -2.5rem;

      &::after {
        content: '\E64A';
      }
    }

    .owl-next {
      margin-right: -2.5rem;

      &::after {
        content: '\E649';
      }
    }
  }
}

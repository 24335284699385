.button {
  display: inline-block;
  //min-width: 16rem;
  //font: 1.8rem/1em $font_default;
  text-align: center;
  border-radius: $gBorderRadius;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all .2s ease-in;
  &:hover {
    transition: all .2s ease-in;
  }
  &.__disabled {
    background-color: #b4c1c9 !important;
    color: $c_white !important;
    cursor: default !important;
  }
  &.__small {
    font-size: 1.5rem;
    min-width: 12rem;
    padding: 1.5rem 1.5rem;
  }
  &.__middle {
    font-size: 1.8rem;
    min-width: 22rem;
    padding: 1.8rem;
  }
  &.__big {
    font-size: 2rem;
    min-width: 26rem;
    padding: 2rem;
  }
}
.button_style_table {
  color: $c_black;
  background-color: $c_white !important;
  border: 1px solid #d1d8dc;
  border-radius: 6px;
  transition: all .2s ease-in;
  &::after {
    color: $c_black !important;
    transition: color .2s ease-in;
  }
  &:hover {
    color: $c_white !important;
    background-color: $c_blue !important;
    transition: all .2s ease-in;
    &:after {
      color: $c_white !important;
      transition: color .2s ease-in;
    }
  }
}
[class*=button_type_] {
  position: relative;
  padding-right: 6rem;
  font-family: $font_sans_sb;
  text-align: center;
  &:after, i {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 1.4rem;
    margin-top: -1.1rem;
    font: 2.2rem/1em $font_themify;
    color: $c_white;
  }
  i {
    z-index: 3;
  }
}
.button_type_move {
  min-width: 22.5rem;
  &:after {
    content: '\E628';
  }
}

.button_type_remove {
  &:after {
    content: '\E605';
  }
}
.button_type_signin {
  padding-right: 5rem;
  &:after {
    content: '\E603';
  }
}
.button_type_logout {
  padding-right: 5rem;
  &:after {
    content: '\E62B';
  }
}
.button_type_add {
  &:after {
    content: '\E61A';
  }
}
.button_add {
  &:before {
    content: '\E61A';
  }
}
.button_desktop{
  &:before {
    content: '\e640';
  }
}
.button_type_remove {
  &:after {
    content: '\E605';
  }
}
.button_type_retry {
  &:after {
    content: '\E6BD';
  }
}
.button_type_profile {
  &:after {
    content: '\E602';
  }
}
.button_type_send {
  &:after {
    content: '\E75A';
  }
}
.button_type_warning {
  &:after {
    content: '\E718';
  }
}
.button_type_file {
  &:after {
    content: '\E647';
  }
}
.button_type_attachment {
  &:after {
    content: '\E647';
  }
}

.button_type_save {
  &:after {
    right: 20rem;
    content: '\E64C';
  }
}
.button_type_back {
  &:after {
    content: '\e64a';
  }
}
.button_type_back {
  &:after {
    content: '\e64a';
  }
}
.button_type_archive {
  &:after {
    content: '\e664';
  }
}
.button_type_download {
  &::after {
    content: "\E6A7";
  }
}
.button_type_review {
  &::after {
    content: "\E645";
  }
}

.button_type_clip {
    &::after {
        content: "\E647";
    }
}

.button_type_announcement {
    &::after {
        content: "\E64F";
    }
}

.button_theme_file {
    cursor: default;
    color: $c_grey;
    border: 1px solid #ced5d9;
}

.button_theme_green {
    background-color: $c_green;
    color: $c_white;
    &:hover {
        background-color: darken($c_green, 5%);
    }
}

.button_theme_grey {
    background-color: $c_grey;
    color: $c_white;
    &:hover {
        background-color: darken($c_grey, 5%);
    }
}

.button_theme_blue {
    background-color: $c_blue;
    color: $c_white;
    &:hover {
        background-color: darken($c_blue, 5%);
    }
}

.button_theme_blue_dark {
    background-color: $c_blue_dark;
    color: $c_white;
    &:hover {
        background-color: darken($c_blue_dark, 5%);
    }
}

.button-group {
    font-size: 0;
    .button {
        border-radius: 0;
        display: inline-block;

        &.button_theme_grey {
            border-right: 1px solid rgb(228, 228, 228);
        }
        &.button_theme_aqua {
            border-right: 1px solid rgb(228, 228, 228);
        }
        &:first-child {
            border-radius: 6px 0 0 6px;
        }
        &:last-child {
            border-radius: 0 6px 6px 0;
            border-right: none;
        }
    }
}

[class*=button_icon_] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.8rem;
  width: 4.8rem;
  height: 4.8rem;
  font: 1.8rem/1em $font_themify;
  padding: 0;
  border: 1px solid #d1d8dc !important;
  border-radius: 6px;
  transition: all .2s ease-in;
  &:hover {
    transition: all .2s ease-in;
  }
  &::after {
    font-family: $font_themify;
  }
}
.button_icon_edit {
  &::after {
    content: '\E61C';
  }
}
.button_icon_add {
  &::after {
    content: '\E61A';
  }
}
.button_icon_reject {
  &::after {
    content: "\E620";
  }
}
.button_icon_remove {
  &::after {
    content: '\E605';
  }
}
.button_theme_file {
  cursor: default;
  color: $c_grey;
  border: 1px solid #ced5d9;
}
.button_theme_green {
  background-color: $c_green;
  color: $c_white;
  &:hover {
    background-color: darken($c_green, 5%);
  }
}
.button_theme_salat {
  background-color: $c_green_white;
  color: $c_white;
  &:hover {
    background-color: darken($c_green_white, 5%);
  }
}
.button_theme_arhive{
  background-color: $c_white;
  color:  #aeb3c6;
  border: 1px solid  #aeb3c6;
  width: 50%;
  padding: 1rem;
}
.button_theme_grey {
  position: relative;
  background-color: #aeb3c6;
  color: $c_white !important;
  &:hover {
    background-color: darken(#aeb3c6, 5%);
  }
}
.button_theme_red {
  background-color: #ef5151;
  color: $c_white !important;
  &:hover {
    background-color: darken(#ef5151, 5%);
  }
  &--photo {
    position: absolute;
    padding: 1em;
    padding-left: 2em;
    width: 10em;
    top: 13.5em;
    left: 1.7em;
    background-color: #b3c5d4;
    &:hover {
      background-color: darken(#b3c5d4, 5%);
    }
    &::before {
      position: absolute;
      left:5%;
      font: 1em $font_themify;
      content: "\e61a";
    }
  }
  &--pencil{
    font: 1.6rem/1em "Open Sans",sans-serif;
    &:before{
      position: absolute;
      left: 5%;
      font: 1em snation;
      content: "\e61d";
    }
  }
}
.button_theme_white {
  background-color: $c_white;
  color: $c_black;
  &:hover {
    background-color: darken($c_white, 5%);
  }
  &--back, &--up {
    border: 1px solid #bdc7cd;
    padding-left: 2em;
    position: relative;
    &::before {
      position: absolute;
      left:5%;
      font: 1em $font_themify;
    }
  }
  &--back {
    &::before {
      content: "\e64a"
    }
  }
  &--plus {
    background-color: #33cc66;
    color: $c_white !important;
    text-align: center;
    position: relative;
    padding-right: 0;
    margin-right: 0 !important;
    border: none !important;
    &:hover {
      background-color: darken(#33cc66, 5%);
    }
    &::before {
      position: absolute;
      left:5%;
      font: 1em $font_themify;
      content: "\e61a"
    }
  }
  &--up {
    margin-right: 2em;
    &::before {
      content: "\e648"
    }
  }
  &--new {
    text-align: center;
    position: relative;
    padding-right: 30px;
    padding-left: 50px;
    background-color: #aeb3c6;
    color: $c_white !important;
    min-width: 26rem !important;
    border-radius: 6px 6px 0 0;
    top: .45em;
    margin-bottom: .6em !important;
    &:hover {
      background-color: darken(#aeb3c6, 5%);
      padding-top: .9em !important;
    }
    &::before {
      position: absolute;
      left:5%;
      font: 1em $font_themify;
    }
  }
  &--list-icon {
    &::before {
      content: "\e6c3";
    }
  }
  &--layout-icon {
    &::before {
      content: "\E67C";
    }
  }
  &--plus-icon {
    padding-left: 40px;
    &::before {
      content: "\e61a";
    }
  }
  &--ti-list-icon {
    &::before {
      content: "\e694";
    }
  }
  &--document-icon {
    &::before {
      content: "\e639";
    }
  }
  &--person-icon {
    &::before {
      content: "\e602";
    }
  }
  &--back-icon {
    top: 0.55em;
    margin-right: 0 !important;
    min-width: 6em !important;
    &::before {
      content: "\e64a";
    }
  }
  &--home-icon {
    &::before {
      content: "\e69b";
    }
  }
  &--roller-icon {
    &::before {
      content: "\e61e";
    }
  }
  &--clipboard-icon {
    &::before {
      content: "\e6b4";
    }
  }
  &--diary-icon {
    &::before {
      content: "\e6c7";
    }
  }
  &--bar-chart-icon {
    &::before {
      content: "\e6ba";
    }
  }
  &--pulse-icon {
    &::before {
      content: "\e680";
    }
  }
  &--star-icon {
    &::before {
      content: "\e60a";
    }
  }
}

.button_theme_blue {
    background-color: #3045a7;
    color: $c_white;
    &:hover {
        background-color: darken(#3045a7, 5%);
    }
}
.button_theme_white-blue {
  color: $c_white;
  background-color: #00a8ff;
  &:hover {
    background-color: darken($c_blue, 5%);
  }
}
.button_thm_light {
  color: $c_dark;
  background-color: #fff;
  border: 1px solid #8a9da8;
}
.button_theme_red {
  background-color: $c_red;
  color: $c_white !important;
  &:hover {
    background-color: darken(red, 5%);
  }
}
.button_theme_aqua {
  position: relative;
  background-color: $c_aqua;
  color: $c_white;
  &:hover {
    background-color: darken($c_aqua, 5%);
  }
  &--new {
    color: #fff;
    background-color: #10206d;
    text-align: center;
    position: relative;
    padding-right: 30px;
    padding-left: 50px;
    min-width: 26rem !important;
    border-radius: 6px 6px 0 0;
    height: 3em;
    top: .25em;
    margin-bottom: .6em !important;
    padding-top: 1.8rem !important;
    &:hover {
      background-color: darken(#10206d, 8%);
      color: $c_white;
    }
    &::before {
      position: absolute;
      left:5%;
      font: 1em $font_themify;
    }
  }
  &--list-icon {
    &::before {
      content: "\e6c3";
    }
  }
  &--layout-icon {
    &::before {
      content: "\e67c";
    }
  }
  &--plus-icon {
    padding-left: 35px;
    &::before {
      content: "\e61a";
    }
  }
  &--ti-list-icon {
    &::before {
      content: "\e694";
    }
  }
  &--document-icon {
    &::before {
      content: "\e639";
    }
  }
  &--person-icon {
    &::before {
      content: "\e602";
    }
  }
  &--search{
    font: 1.6rem/1em "Open Sans",sans-serif;
    &:before{
      position: absolute;
      left: 5%;
      font: 1em snation;
      content: "\e610";
    }
  }
  &--home-icon {
    &::before {
      content: "\e69b";
    }
  }
  &--roller-icon {
    &::before {
      content: "\e61e";
    }
  }
  &--clipboard-icon {
    &::before {
      content: "\e6b4";
    }
  }
  &--diary-icon {
    &::before {
      content: "\e6c7";
    }
  }
  &--bar-chart-icon {
    &::before {
      content: "\e6ba";
    }
  }
  &--pulse-icon {
    &::before {
      content: "\e680";
    }
  }
  &--star-icon {
    &::before {
      content: "\e60a";
    }
  }
}

.button_theme__dark-blue{
  color: #fff;
  background-color: #10206d;
  text-align: center;
  position: relative;
  &:after{
    position: absolute;
    left: -80%;
    top: 55%;
    font: 1em snation;
  }
}

.button_theme__change-photo {
  height: 4rem;
  width: 18rem;
  font-size: 1.4rem;
  background-color: #bdc7cd;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -1.9rem;
  color: white;
  border-radius: 0.5rem;
  &:before {
    content: '\E61A';
    position: absolute;
    font-family: snation;
    left: 1.3rem;
    bottom: 1.2rem;
  }
  &:hover {
    background-color: #3c6;
  }
  &--camera {
    font-size: inherit;
    &:before {
      content: '\E64E';
    }
  }
}
@media screen and (max-width: 1200px) {
  .button_theme__change-photo{
    bottom: -0.5rem;
  }
}
.noActiv {
  background-color: #aeb3c6;
  text-decoration: none;
  &:hover {
    background-color: darken(#aeb3c6, 5%);
  }
}
@media screen and (max-width: 999px) {
  .button_theme_white--new {
    &:hover {
      padding-top: 1.5rem !important;
    }
  }
}

.sn-checkbox__bg {
  left: 11px;
  top: 11px;
  right: auto;
  bottom: 0;
  width: 45%;
  height: 45%;
  border: 2px solid currentColor;
  border-radius: 2px;
  transition: background 0.22s cubic-bezier(0, 0, 0.2, 1) 0ms;
}

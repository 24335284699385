.sn-ripple__inner {
  @include pos(absolute, $t: 0, $l: 0);
  opacity: 0;
  color: inherit;
  border-radius: 50%;
  background: currentColor;
  pointer-events: none;
  will-change: transform, opacity;

  &--enter {
    transition: transform .225s cubic-bezier(.4, 0, .2, 1), opacity .1s cubic-bezier(.4, 0, .2, 1);
  }

  &--leave {
    transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
  }
}

.sn-linear-progress__stripe {
  transition: width 0.3s;
  background-image: linear-gradient(45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent) !important;
  background-size: 40px 40px !important;
}

.s-mobile-menu-name-group{
  font-size: 11px;
  line-height: 2rem;
  color: #8ea1f5;
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}
.s-mobile-menu-name-group-border{
  border: 1px solid #5169d4 ;
  margin-left: 2.2rem;
  margin-right: 2.2rem;
}

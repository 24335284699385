.tbl-simple-info {
  tbody {
    tr {
      &:first-child {
        td {
          border-top: 1px solid #e2e7ea;
        }
      }

      &:nth-child(odd) {
        td {
          background-color: #f7f9fc;
        }
      }

      td {
        vertical-align: top;
        padding: 1.3rem 1.5rem;
        border-bottom: 1px solid #e2e7ea;

        &:first-child {
          font-weight: 600;
          text-align: right;
          border-right: 1px solid #bdc7cd;
        }
      }
    }
  }


  @media screen and (max-width: 480px) {
    tbody {
      display: block !important;
      width: 100%;
      tr {
        display: block !important;
        width: 100%;
        td {
          display: block !important;
          width: 100% !important;

          &:first-child {
            text-align: left;
            border-right: 0;
          }
        }
      }
    }
  }
}
.s-photo-album__picture {
  @include pos();
  @include sbg($c: $cGrey, $s: cover);
  width: 100%;
  padding-top: (1920 / 1.78) / 1920 * 100%;
  border-radius: $gBorderRadius;
  overflow: hidden;

  .sn-spinner {
    @include pos(absolute, $t: 0, $r: 0, $b: 0, $l: 0);
    margin: auto;
  }
}

.sn-notify__wrapper {
  @include pos(fixed, 99999);
  @include sflx($dir: column);
  width: 100%;
  height: 0.1rem;

  &--pos-top-right {
    top: 0;
    right: 0;
    align-items: flex-end;
  }
}

.kindergarten-block__description {
  @include sflx($dir: column, $juscont: space-between);
  flex: 1 1 auto;
  padding-right: .5rem;
  padding-left: 1.5rem;


  @media screen and (max-width: 479px) {
    padding: 1.5rem 0;
  }
}
.pagination {
  @include sflx($alitems: center, $juscont: center);
  width: 100%;

  li {
    @extend .pagination__item;

    &.is-active,
    &.active {
      background-color: #8DBE2D;
      color: $cWhite;

      &:hover {
        background-color: $cGreen;
      }

      a {
        color: $cWhite;
      }

    }

  }

}

.pagination_mrgn_t {
  margin-top: 3rem;
}

.pagination__wrapper {
  margin-top: 3rem;
}

.header__link {
  font: 1.6rem/1.2em $fDef;
  color: $cWhite;
  text-transform: uppercase;

  a {
    color: $cWhite;

    &:hover {
      border-bottom: 1px solid darken($cWhite, 1%);
    }

  }

}
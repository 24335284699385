.sn-tab {
  padding: 0 1.6rem;
  min-height: 4rem;
  transition: color 0.3s, background-color 0.3s;
  text-transform: uppercase;
  white-space: nowrap;
  // for route tabs
  color: inherit;
  text-decoration: none;

  &--full {
    min-height: 7.2rem;
  }

  &--no-caps {
    text-transform: none;
  }

  &--active {
    .sn-tab__indicator {
      opacity: 1;
      transform-origin: left;
    }
  }

  &--inactive {
    opacity: 0.85;
  }

  .sn-badge {
    top: 0.3rem;
    right: -1.2rem;
  }
}

.modal-select-candidate {
  .modal-dialog {
    max-width: 59.6rem;
  }
}
.form__candidate {
  .form__string {
    text-align: center;
    .selected-variant {
      font: 1.8rem/1.2em $font_sans_sb;
      text-align: center;
      color: $c_black;
    }
    .statement__upload-wrapper {
      justify-content: center;
      .button_type_attachment {
        padding-left: 6rem;
      }
      .button_type_file {
        width: 245 / 515 * 100%;
        &:last-child {
          margin-left: 25 / 515 * 100%;
        }
      }
    }
  }
  .modal-vacancy__line-between {
    margin-bottom: 0;
  }
}

.candidate-list {
  width: 100%;
  display: block;
  &.__subjects {
    .candidate-item {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #d1d8dc;
      .candidate-item__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
      }
      input {
        width: 5rem;
        padding-right: .5rem;
        padding-left: .5rem;
        border-radius: 0;
        font-size: 2rem;
        font-family: $font_default;
      }
      .button {
        order: 3;
        width: 5rem;
        min-width: 5rem;
        padding-right: .5rem;
        padding-left: .5rem;
        color: $c_black;
        border-radius: 0 6px 6px 0;
        border: 1px solid #d1d8dc;
        border-left: 0;
        background-color: $c_white;
        &::after {
          color: $c_black;
        }
        &:hover {
          background-color: darken($c_white, 5%);
        }
      }
    }
  }
}
.candidate-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 2rem;
  input {
    order: 2;
    width: 10rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
    font: 2.4rem $font_sans_sb;
    color: $c_black;
    text-align: center;
    @include placeholder(#99a0a7);
    &:focus {
      border-color: #d1d1d1;
    }
  }
  input[type="radio"], input[type="checkbox"] {
    display: none;

    &:disabled {
      &:checked {
        +label {
          background-color: #e8eaea !important;
        }
      }
      + label {
        border-color: #d1d8dc !important;
        background-color: inherit !important;
        cursor: not-allowed;
        ~ .candidate-item__name {
          background-color: inherit !important;
          border-color: #d1d8dc !important;
          color: #8a9da8 !important;
          a {
            color: #3045a7b8 !important;
          }
        }
      }
        + label:after {
          border-color: #d1d8dc !important;
          background-color: inherit !important;
          color: #8a9da8 !important;
          transform: scale(1.2);
      }
    }

    &.__success {
      &:checked {
        + label {
          border-color: $c_green;
          background-color: $c_green;
          ~ .candidate-item__name {
            background-color: #d6f7e1;
            border-color: $c_green;
            color: $c_green;
          }
        }
      }
      + label {
        order: 2;
        border-top: 1px solid #d1d8dc;
        border-right: 1px solid #d1d8dc;
        border-bottom: 1px solid #d1d8dc;
        &:after {
          content: '\E64C';
        }
      }
    }
    &.__reject {
      &:checked {
        + label {
          border-color: #f46390;
          background-color: #f46390;
          ~ .candidate-item__name {
            background-color: #ffdde8;
            border-color: #f46390;
            color: #f46390;
          }
        }
      }
      + label {
        order: 3;
        border-radius: 0 6px 6px 0;
        border-top: 1px solid #d1d8dc;
        border-right: 1px solid #d1d8dc;
        border-bottom: 1px solid #d1d8dc;
        &:after {
          content: '\E646';
        }
      }
    }
    &:checked {
      + label {
        transition: all .2s ease-in;
        &:after {
          color: $c_white;
          transform: scale(1.3);
          transition: all .2s ease-in;
        }
        ~ .candidate-item__name {
          font-family: $font_sans_sb;
          transition: all .2s ease-in;
        }
      }
    }
    + label {
      width: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding-bottom: 0;
      transition: all .2s ease-in;
      flex-shrink: 0;
      &:after {
        font: 2.2rem/1em $font_themify;
        color: #99a0a7;
        transform: scale(1);
        transition: all .2s ease-in;
      }
    }
  }
}
.candidate-item__name {
  order: 1;
  flex: 1 1 auto;
  text-align: left;
  font: 1.8rem/1.2em $font_default;
  color: $c_black;
  padding: 1.35rem 2rem;
  border: 1px solid #d1d8dc;
  border-radius: 6px 0 0 6px;
  transition: all .2s ease-in;
	a{
		color: $c_blue;
		&:hover {
			border-bottom: 1px solid darken($c_blue, 1%);
		}
	}
}
.candidate-item__name-count{
  border-radius: 0;
  max-width: 140px;
  min-width: 140px;
  border-left: 0;
}


@media screen and (max-width: 480px) {
  .modal-select-candidate {
    .modal-dialog {
      padding-top: 6rem;
    }
  }
  .protocol-download-wrapper {
    .button {
      width: 100%;
    }
  }
  .form__candidate {
    .form__string {
      .selected-variant {
        font: 1.8rem/1.2em $font_sans_sb;
        text-align: center;
        color: $c_black;
      }
      .statement__upload-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        .button {
          width: 100% !important;
        }
        //.button_type_attachment {
        //  padding-left: 6rem;
        //}
        //.button_type_file {
        //  margin-top: 2rem;
        //  margin-left: 0 !important;
        //}
      }
    }
  }
  .candidate-item__name-org_name{
    order: 0 !important;
  }
  .label-candidate-item__name-org_name, .candidate-item__name-count{
    display: none !important;
  }
  .hidden-candidate-item__name-count, .candidate-item-with_occupied_count{
    display: block !important;
  }
  .candidate-item__name-hidden, .hidden-label-for-org-count{
    border-top:0 !important;
  }

  .hidden-label-for-org-count{
    border-radius: 0 0 6px 0 !important;
  }

  .candidate-item__name-hidden{
    border-radius: 0 0 0 6px !important;
  }
  .candidate-item__name-org_name{
    border-radius: 6px 6px 0 0 !important;
  }

  .candidate-item__name {
    font-size: 1.6rem;
  }
}

.course-spoiler__progress-scale-back {
  @include pos();
  flex-shrink: 0;
  display: block;
  width: 10.5rem;
  height: 1rem;
  background-color: #dfe2ed;
  border-radius: $gBorderRadius;
  overflow: hidden;
  margin-right: 1.2rem;
  margin-left: 1.2rem;
}

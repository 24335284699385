.s-test-process__button {
  @include pos(absolute, $z: 1, $l: 0);
  background-color: $stpGrey;
  padding: 1.1rem 2rem 1.1rem 6rem;
  border-radius: 3px 0 0 3px;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  transform: translateX(-3.9rem);
  transition: transform $gDuration ease-in;
  will-change: contents;

  &::before {
    @include pos(absolute, $l: 0, $t: 0);
    @include sflx($juscont: center, $alitems: center);
    width: 4rem;
    height: 100%;
    font-size: 2.2rem;
    font-family: $fIcons;
    color: $cWhite;
    background-color: $stpGreyDark;
  }

  &:hover {
    transform: translateX(-100%);
    transition: transform $gDuration ease-in;
  }

  &--logout {
    top: 2rem;

    &::before {
      content: map-get($listOfIcons, 'close');
    }
  }

  &--map {
    top: 7rem;

    &::before {
      content: map-get($listOfIcons, 'align-justify');
    }
  }
}

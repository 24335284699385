$cBlack: #000;
$cDark: #1b2b3b;
$cWhite: #fff;
$cBlue: #3045a7;
$cWhiteBlue: #00a8ff;
$cLighterBlue: #E4E9FF;
$cAqua: #3ca8fb;
$cSalat: #1ab3b1;
$cGreen: #33cc66;
$cGreenDark: #1ab36d;
$cGreenWhite: #57bd6f;
$cOcean: #63b1c0;
$cGrey: #d8dde2;
$cRed: #ff0000;
$cOrange: #f5942e;
$cLightBlue: #007cd2;
$cBorder: #d1d8dc;
$cBorderLight: #eceef5;
$cDisabled: $cGrey;
$cPlaceholder: #8a9da8;
$cGreyText: #8a9da8;
$cError: $cRed;
$cRedBtn: #ff7784;
$cBbYellow: #FFB800;
$cHover: #ecf2f5;
$cHoverDark: #dee2ed;

$listOfColors: (
    ('black', $cBlack),
    ('dark', $cDark),
    ('white', $cWhite),
    ('blue', $cBlue),
    ('white-blue', $cWhiteBlue),
    ('aqua', $cAqua),
    ('salat', $cSalat),
    ('green', $cGreen),
    ('green-dark', $cGreenDark),
    ('green-white', $cGreenWhite),
    ('ocean', $cOcean),
    ('gray', $cGrey),
    ('red', $cRed),
    ('orange', $cOrange),
    ('bb-yellow', $cBbYellow),
    ('light-blue', $cLightBlue),
    ('lighter-blue', $cLighterBlue),
);

.sn-table__grid-item-card {
  vertical-align: top;
  padding: 1.2rem;
  height: 100%;
  overflow: hidden;

  .sn-separator {
    margin: 1.2rem 0;
  }
}

.college-library-upload-btn {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-top: 1rem;
}

.college-library-upload-file {
  width: 25rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .s-str-ico {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

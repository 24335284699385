.profile-create-block {
  .button {
    width: 100%;
    font-family: $font_sans_sb;
    padding-left: 3rem;
    margin-bottom: 2rem;
  }
}
.profile-create-block__image {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: .17;
}
.profile-create-block__text {
  position: relative;
  width: 24.6rem;
  height: 27.8rem;
  border: 1px dashed $c_blue;
  border-radius: 6px;
  margin-bottom: 3rem;
  font-size: 1.6rem;
  line-height: 1.2em;
  text-align: center;
  color: $c_blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  .__title {
    font-family: $font_sans_sb;
    margin-bottom: 3rem;
  }
  .__desc {
    font-family: $font_default;
  }
}
.s-list--ol {
  counter-reset: item;
  padding-left: 0;
}

.s-list--ol-li {
  display: block;
  &:before {
    content: counter(item) '. ';
    counter-increment: item;
    font-weight: 600;
  }
}
.tof-body__editing {
    td {
        height: auto;
        background-color: #fef3d8 !important;
        padding: .5rem .5rem 5.5rem .5rem;
    }

    .tof-body__input {
        padding-right: .6rem;
        padding-left: .6rem;
    }

    .tof-button-wrapper {
        display: block;
    }

    .tof-control-wrapper {
        display: none;
    }

    &.on-create {
        .tof-control-wrapper {
            display: none;
        }

        .tof-button-wrapper {
            display: block;
            bottom: 1rem;
        }

        .tof-body__spec-text {
            height: auto;
        }
    }
}

.sn-contacts-title {
  font-size: 2.8rem;
  color: #293CA6;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 8rem;


  &--double {
    span {
      @include pos();

      &::before,
      &::after {
        @include pos(absolute, $t: calc(50% - 0.1rem));
        width: 12rem;
        height: 0.2rem;
        background-color: currentColor;
        content: '';
      }

      &::before {
        right: calc(100% + 8rem);
      }

      &::after {
        left: calc(100% + 8rem);
      }
    }
  }
}
.sn-contacts-upper{
  text-transform: uppercase;
}


.sn-toggle__inner {
  width: 6rem;
  min-width: 6rem;
  height: 4rem;
  padding: 1.3rem 1.2rem;

  &--active {
    color: $primary;

    .sn-toggle__track {
      opacity: 0.54;
    }

    .sn-toggle__thumb-container {
      transform: translate3d(1.8rem, 0, 0);
    }

    .sn-toggle__thumb {
      background-color: currentColor;
      border-color: currentColor;

      .s-ico {
        color: $cWhite;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .s-table-with-filters {
    &__sort-row {
      &--with-col-filter {
        flex-direction: column;
        place-items: unset;

        > div:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .s-table-with-filters {
    &__sort-row {
      > span, &--label {
        font-size: 13px;
        margin-right: 0.5rem;
      }

      .sn-field {
        &__wrapper {
          width: 15rem;
        }

        font-size: 13px;
      }
    }

    &__search-row {
      > .sn-btn {
        min-width: 10rem;
        font-size: 13px;
        padding: 1rem 0.5rem;

        i::before {
          font-size: 13px;
        }
      }

      .sn-field {
        font-size: 13px;
      }
    }

    &__between-btns-row {
      > .sn-btn {
        font-size: 13px;
        padding: 1rem 0.5rem;

        i::before {
          font-size: 13px;
        }

        &:first-child {
          min-width: 10rem;
        }

        &:last-child {
          min-width: unset;
        }
      }
    }
  }
}

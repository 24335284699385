.s-dropdown {
  @include pos(absolute, 1, calc(100% + 1.4rem), $l: 50%);
  transform: translateX(-50%);
  display: none;
  min-width: 100%;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 1rem rgba(104, 124, 143, 0.43);

  &::before {
    @include pos(absolute, -1, $b: calc(100% - 0.75rem), $l: 50%);
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $cWhite;
    box-shadow: -0.3rem -0.3rem 1.2rem -0.3rem rgba(104, 124, 143, 0.43);
    transform: translateX(-50%) rotate(45deg);
    content: '';
  }

  &::after {
    @include pos(absolute, 1, $b: 100%, $l: 0);
    width: 100%;
    height: 1.5rem;
    content: '';
  }

  &--from-right,
  &--from-left {
    transform: translateX(0);

    &::before {
      transform: rotate(45deg);
    }
  }

  &--from-right {
    right: 0;
    left: auto;

    &::before {
      right: 3.9rem;
      left: auto;
    }
  }

  &--from-left {
    left: 0;

    &::before {
      left: 3.9rem;
    }
  }

  &--child {
    position: static;
    transform: none;

    &::before {
      content: none;
    }
  }

  &--nowrap {
    > * {
      white-space: nowrap;
    }
  }
}

.s-mobile-menu__body {
  position: relative;
  width: 100%;

  &-content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 10rem;
  }
}

.row-sort-end-btn {
  @extend .row-sort-end, .row-sort-wrap;

  > * {
    margin: 1rem;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}


.rating-block__star {
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:first-child {
    margin-right: 0;
  }

  &::after {
    font-size: 1em;
    font-family: $fThemify;
    content: '\E60A';
  }
}

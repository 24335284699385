.s-two-field-wrapper {
  @extend .row;

  > * {
    @extend .col-lg-6;
    @extend .col-md-6;
    @extend .col-sm-12;
  }

  > .sn-field__wrapper {
    padding: 1rem 1.5rem;
  }

}

$cTestBlockLeft: #8a9da8;

.test-block__cell {
  display: block;
  min-height: 10rem;
  font-size: 1.4rem;
  line-height: 1.4em;
  padding: 2rem;
  border-right: 1px solid $bTestBlock;

  &:last-child {
    border-right: 0;
  }
}

.test-block__cell_left {
  flex-shrink: 0;
  width: 12rem;
  color: $cTestBlockLeft;
  text-align: right;
}

.test-block__cell_right {
  flex: 1 1 auto;
  & > p {
    margin: 0;
  }
}

@media screen and (max-width: 639px) {
  .test-block__cell_left {
    width: 3rem;
    padding: 0;

    span {
      display: table;
      transform-origin: left center;
      transform: rotate(-90deg) translate(-100%, 1rem);
    }
  }
}

@media screen and (max-width: 479px) {
  .test-block__cell {
    padding: 1rem 0 1rem 1rem;
  }

  .test-block__cell_left {
    span {
      transform: rotate(-90deg) translateX(-100%);
    }
  }
}

.s-form__radio {
  @extend .s-form__checkbox;

  &:checked {
    + label {
      &::before {
        font-size: unset;
        margin-top: unset;
        margin-left: unset;
        content: '';
      }

      &::after {
        display: block;
      }
    }
  }

  + label {
    &::before {
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 1px solid #A9B9C2;
      content: '';
    }

    &::after {
      @include pos(absolute, $t: 50%, $l: 0.6rem);
      transform: translateY(-50%);
      display: none;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #364CA7;
      border-radius: 50%;
      content: '';
    }
  }
}

.answer-block {
  @include sflx($dir: column);
  width: 100%;
  min-height: 40rem;
  font-size: 1.8rem;
  line-height: 1.33em;

  @media screen and (max-width: 639px) {
    font-size: 1.4rem;
  }
}

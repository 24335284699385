.interest-block__mini-block {
  @include sflx($dir: column, $alitems: center, $juscont: flex-start);
  width: 18.85rem;
  height: 18.85rem;
  box-shadow: 0 0 1.6rem rgba(54, 76, 167, 0.59);
  border: 1px solid rgba(54, 76, 167, 0.25);
  padding: 2.5rem;
  margin-right: 3rem;
  border-radius: 5px;
  position: relative;
  transition: all $animateSpeed;
  opacity: 0.9;

  &:last-child {
    margin-right: 0;
  }

  .interest-block__mini-block-image {
    width: 9.8rem;
    height: 5.2rem;
  }

  &:first-child {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-1.png');
    }
  }

  &:nth-child( 2 ) {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-2.png');
    }
  }

  &:nth-child( 3 ) {
    .interest-block__mini-block-image {
      background: 50% 0 no-repeat url('../images/school/school-statement_s-3.png');
    }
  }

  &:nth-child( 4 ) {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-4.png');
    }
  }

  &:nth-child( 5 ) {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-5.png');
    }
  }

  &:nth-child( 6 ) {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-6.png');
    }
  }

  &:nth-child( 7 ) {
    .interest-block__mini-block-image {
      background: 50% 50% no-repeat url('../images/school/school-statement_s-7.png');
    }
  }

  &:hover {
    box-shadow: 0 0 1.6rem rgba(54, 76, 167, 0.75);
    transition: all $animateSpeed;
    opacity: 1;
  }
}

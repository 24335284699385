.sn--text-primary {
  color: $primary !important;
}

.sn--text-white {
  color: $cWhite !important;
}

@each $name, $color in $listOfColors {
  .sn--text-#{$name} {
    color: $color !important;
  }
}


.sn--bg-primary {
  background: $primary !important;
}

$darkBlue: #25367E;


.s-support-team-button {
  .sn-list {
    width: 23.5rem !important;
  }

  .support-team-margin {
    width: 23.5rem !important;
    margin-left: 2rem;
    height: 45px;
  }

  .sn-btn__content .s-ico,
  .sn-btn__content .s-ico-fab,
  .sn-btn__content .s-ico-fa,
  .sn-btn__content .s-str-ico-fa::before,
  .sn-btn__content .s-str-ico-fa-rev::before,
  .sn-btn__content .s-notification::before {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sn-btn {
    text-transform: none;
  }

  .support-team-button-active {
    box-shadow: 0 14px 27px rgba(33, 59, 138, 0.24);
    background-color: white !important;
    border-bottom-color: white;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;

    transition: border-bottom-color 100ms linear,
    border-bottom-left-radius 100ms linear,
    border-bottom-right-radius 100ms linear;
    &.support-team-button-active {
      color: $darkBlue;
      border: unset;
    }
  }
}

.sn-menu.support-team-list {
  width: 23.5rem !important;
  position: absolute !important;
  box-shadow: 0 14px 27px rgba(33, 59, 138, 0.24) !important;
  border: 1px solid #F5942E;
  border-top: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  &.support-team-list--white {
    border: unset;
  }
  &.support-team-list--qujat {
    border-color: $cQujatAqua;
  }

  .sn-item {
    padding: 8px 21px;
  }
}

.s-main-page-contacts-phone-number {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;

  a {
    color: $darkBlue !important;
  }
}

.s-main-page-contacts-text {
  font-size: 16px;
  padding-left: 2em;
  color: $darkBlue !important;
}

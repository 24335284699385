.s-circular-progress-bar {
  &__inner {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}

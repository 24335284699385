.sn-rating {
  color: #FFEB3B;
  vertical-align: middle;

  &--editable .s-ico {
    cursor: pointer;
  }

  &--non-editable span,
  .s-ico {
    outline: 0;
  }
}

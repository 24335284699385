.statement-service--step {
  display: flex;

  &:last-child {
    .statement-service--step__num {
      span {
        &::after {
          display: none;
          width: 0;
        }
      }
    }
  }
}

.statement-service--step__num {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-right: 1rem;
  flex-shrink: 0;
  i {
    font-family: snation;
    position: relative;
    color: $cBlue;
    left: 0.6rem;
    top: calc(50% - 1.6rem);

    &.s-str-ico {
      &::before {
        background-color: $cWhite;
      }
    }
  }
  span {
    font-size: 1.8rem;
    color: $cBlue;
    border: 1px solid $cBlue;
    border-radius: 25px;
    padding: 0.65rem 1.15rem;
    position: relative;
    background: $cWhite;

    &::after {
      display: block;
      top: 0;
      margin-top: 100%;
      position: absolute;
      left: calc(50% - 0.1rem);
      height: 150rem;
      width: 0.1rem;
      background-color: $cBlue;
      content: '';
    }
  }
}

.s-steps-numeration__item {
  @include pos();
  @include sflx($alitems: center);
  font-weight: 600;
  font-size: 1.4rem;
  color: $cLightGreyBlue;
  padding: 1rem 0 1rem 4rem;

  &::before {
    @include pos(absolute, $l: 0);
    @include sflx($d: inline-flex, $alitems: center, $juscont: center);
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid currentColor;
    border-radius: 50%;
    content: attr(data-numeration);
  }

  &.is-active {
    color: $cBlue;
  }

  &.is-success {
    color: $cGreen;
  }
}

.s-ad-banner__title-wrapper {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > .s-ad-banner__image {
    margin-bottom: unset;
    border-radius: unset;
    margin-right: 1.5rem;
  }
}


@keyframes a-shake-margin {
  40% {
    margin-left: 2px;
  }

  60%,
  80% {
    margin-left: -2px;
  }

  70%,
  90% {
    margin-left: 2px;
  }
}

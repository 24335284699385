.test-cont__title {
  font-size: 2.8rem;
  line-height: 1.2em;
  font-family: $fSansSB;
  text-align: center;
}

.test-cont__title_mrgn_b {
  margin-bottom: 3rem;
}

@media screen and (max-width: 479px) {
  .test-cont__title {
    font-size: 2.4rem;
  }

  .test-cont__title_mrgn_b {
    margin-bottom: 2rem;
  }
}

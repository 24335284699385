.s-year-month-picker {
  display: grid;
  grid-template-columns: 3.8rem 1fr 3.8rem;
  align-items: center;
  grid-column-gap: 0.5rem;
  width: fit-content;

  &--borderless {
    grid-template-columns: 2.8rem 1fr 2.8rem;

    .sn-btn--squire {
      min-height: 2.8rem;
      min-width: 2.8rem;
    }
  }

  &--value {
    width: 100%;
    text-align: center;
  }
}

.s-dropdown__link {
  @extend .s-dropdown__item;
  background-color: transparent;
  transition: background-color $gDuration ease-in;

  &:hover,
  &.is-active {
    background-color: $cHover;
    transition: background-color $gDuration ease-in;

    span,
    strong {
      border-color: $cHover;
    }

    + .s-dropdown__link {
      span,
      strong {
        border-color: $cHover;
      }
    }
  }

  &--multi {
    position: relative;

    span {
      font-size: inherit;
      padding-right: 2rem;
      padding-left: 2rem;
    }

    &:hover > .s-dropdown-hidden {
      display: block;
    }
  }

  &--logout {
    .s-str-ico {
      padding-left: 1.5em;

      &::before {
        font-size: 1em;
      }
    }
  }
}

.s-organization-profile__panel {
  padding: 2rem;
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;

  .s-plain-text,
  .s-table-simple,
  .s-table {
    color: $cBlueDark;
  }
}

.sn-chip {
  vertical-align: middle;
  border-radius: 16px;
  outline: 0;
  position: relative;
  height: 32px;
  margin: 4px;
  background: #e0e0e0;
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  padding: 7px 12px;
  transition: 0.3s;

  &--square {
    border-radius: $gBorderRadius;
    .sn-avatar {
      border-radius: $gBorderRadius -1px 0 0 $gBorderRadius -1px;
    }
  }

  &--selected {
    .sn-avatar {
      visibility: hidden !important;
      width: 1rem;
    }
  }

  .sn-avatar {
    font-size: 32px;
    margin-left: -12px;
    margin-right: 6px;
  }

  &--colored {
    .sn-chip__icon {
      color: inherit;
    }
  }

  &--outline {
    background: transparent;
    border: 1px solid currentColor;
  }

  &--dense {
    border-radius: $gBorderRadius;
    height: 2.4rem;
    font-size: 1.2rem;
    padding: 0 0.6rem;
    &.sn-chip--selected {
      .sn-avatar {
        width: 5px;
      }
    }
    .sn-avatar {
      font-size: 24px;
      margin-left: -6px;
      margin-right: 4px;
    }
    .sn-chip__icon {
      font-size: 14px;
      margin: 0;
    }
    .sn-chip__icon--left {
      margin-right: 5px;
    }
    .sn-chip__icon--right {
      margin-left: 5px;
    }
    .sn-chip__icon--remove {
      margin-left: 5px;
      margin-right: -2px;
    }
  }
}

.desktop {
  .sn-chip {
    &--clickable {
      &:focus {
        box-shadow: $shadow-1;
      }
    }
  }
}

@media screen and ( max-width: 1400px ) {
  .s-btn-table--empty {
    display: none;
  }
}

@media screen and ( min-width: 1400px ) {
  .s-table-cabinet .s-group--nowrap.s-group--equal {
    > * {
      margin: 0.3rem;
    }
  }
}


@media screen and ( max-width: 479px ) {
  .s-title-head {
    font-size: 2.4rem;
  }
  @for $i from 1 through 6 {
    h#{$i},
    h#{$i}.s-title-head {
      font-size: (2.4 - (($i - 1) / 10 * 4)) + rem;
    }
  }
  h1.s-title-head {
    font-size: 2.4rem;
  }
}

[data-content-wrapper] {
  @include pos();

  &.is-active {
    .content-hide-text {
      display: none;
    }

    .content-show-text {
      display: block;
    }

    [data-content-hide] {
      &::after {
        opacity: 0;
        transition: opacity .2s ease-in;
      }
    }

    [data-content-hide-text] {
      bottom: -4.5rem;
      transition: bottom .2s ease-in;

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .content-hide-text {
    display: block;
  }

  .content-show-text {
    display: none;
  }
}

.s-direction-block__buttons {
  @include sflx();
  flex-shrink: 0;
  display: block;
  margin-right: 0;

  > * {
    &:not( :last-child ) {
      margin-bottom: 1rem;
    }
  }
}

.s-application-block__warning-message {
  @include sflx($alitems: center);
  flex: 1 1 auto;
  font-size: 1.4rem;
  line-height: 1.36em;
  color: $cBlueDark;
  padding-right: 2rem;

  &::before {
    font-size: 3rem;
    line-height: 1.3em;
    font-family: $fFAIcons;
    color: #AE0000;
    margin-right: 2.5rem;
    content: map_get($listOfIconsFA, 'comment-alt-exclamation');
  }
}

.course-block__picture {
  @include sbg($s: contain);
  display: block;
  width: 100%;
  border-radius: $gBorderRadius $gBorderRadius 0 0;

  &::before {
    display: table;
    margin-top: 100%;
    content: '';
  }
}

$cBlack: #000;
$cWhite: #fff;
$cBlue: #364ca7;
$cGreen: #33cc66;

$hBGItem: #f1f2f4;

$bgHeader: #142c8c;
$bgFooter: #243d9b;

$cGreenArrow: #9acf0e;

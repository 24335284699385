.test-block__steps {
  font-size: 3.6rem;
  line-height: 1.2em;
  color: $cBlue;

  i {
    font-style: normal;
    margin: 0 .5rem;
  }
}

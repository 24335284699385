.sn-field {
  @include sflx($wrap: nowrap, $juscont: flex-start);
  transition: all $gDuration ease-in;

  &--item-aligned {
    .sn-field__before {
      min-width: 5.6rem;
    }
  }

  &--two-field {
    .sn-field__label {
      margin-bottom: unset;
    }

    .sn-field__bottom {
      padding-top: unset;
    }
  }

  &--readonly,
  &--disabled {
    .sn-field__control {
      pointer-events: none;
    }

    .sn-placeholder {
      color: $cPlaceholder;
      opacity: 1 !important;
      -webkit-text-fill-color: $cPlaceholder !important;
    }
  }

  &--disabled {
    .sn-field__control > div {
      opacity: 0.6! important;

      &,
      * {
        outline: 0! important;
      }
    }
  }

  &--readonly {
    .sn-field__control {
      border-style: dashed;
    }
  }

  &--borderless {
    .sn-field__control {
      padding: 0;
      border-style: hidden;
    }
  }

  &--borderless-parent {
    & > .sn-field > .sn-field__inner > .sn-field__control {
      padding: 0;
      border-style: hidden;
    }
  }

  &--focused {
    .sn-field__control {
      border-color: $cBlue;
    }
  }

  &--error {
    .sn-field__label {
      animation: a-shake-margin 0.36s;
    }

    .sn-field__messages {
      color: $cRed;
      transition: color $gDuration ease;
    }

    .sn-field__control {
      border-color: $cRed;
      transition: border-color $gDuration ease;
    }
  }

  &--auto-height {
    .sn-field__control {
      height: auto;
    }

    .sn-field__control, .sn-field__native {
      min-height: 3.8rem;
    }

    .sn-field__native {
      align-items: center;
    }

    .sn-field__control-container {
      padding-top: 0;
    }

    .sn-field__native, .sn-field__prefix, .sn-field__suffix {
      line-height: 18px;
    }
  }

  &--preview {
    padding-top: 0;
    padding-bottom: 0;
    transition: all $gDuration ease-in;

    .sn-field__control {
      padding-right: 0;
      padding-left: 0;
      border: 0;
      cursor: default !important;
      pointer-events: unset !important;
      transition: all $gDuration ease-in;
    }

    .sn-field__native {
      color: currentColor;
      padding: 0;
      cursor: default !important;
      pointer-events: unset !important;
      transition: all $gDuration ease-in;
    }
  }
  &--date {
    .sn-field__before {
      padding-right: unset;
    }
  }
}

.tipo-content-wrapper {
  @extend .profile-content-wrapper;
  padding-bottom: 0.1rem;
  margin-bottom: 3rem;
}

.tipo-title {
  font: 600 1.8rem/1.2em $font_default;
  color: $c_black;
  text-align: left;
  margin-bottom: 2rem;

  span {
    display: block;
    font-weight: 400;
    font-size: 1.6rem;
  }
}

.sn-btn {
  @include pos();
  display: inline-flex;
  align-items: center;
  line-height: 1;
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  background: transparent;
  padding: 1.1rem 2rem 1rem;
  border: 0;
  outline: 0;
  box-shadow: $gShadow;
  cursor: pointer;
  transition: $gDuration cubic-bezier(0.25, 0.8, 0.5, 1);

  &.is-disabled {
    opacity: 0.7 !important;
  }

  &--standart {
    &:not( .is-disabled ) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        transition: $gDuration cubic-bezier(0.25, 0.8, 0.5, 1);
      }

      &:active,
      &.sn-btn--active {
        &::before {
          box-shadow: $gShadowActive;
        }
      }
    }
  }

  &--outline {
    padding: 1rem 1.9rem 0.9rem;
    border: 1px solid currentColor;
    background: transparent !important;
  }

  &--bordered {
    border-color: #DFE2ED;
  }

  &--no-uppercase {
    text-transform: none;
  }

  &--rectangle {
    border-radius: $gBorderRadius;
  }

  &--rounded {
    border-radius: 28px;
  }

  &--push {
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);

    &:active,
    &.sn-btn--active {
      &:not( .is-disabled ) {
        transform: translate3d(0, 0.3rem, 0);
        border-bottom-width: 0;
      }
    }

    .sn-focus-helper,
    .sn-ripple-container {
      height: auto;
      bottom: -0.3rem;
    }
  }

  &--round {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    min-height: 4rem;
    padding: 0 !important;
    border-radius: 50%;
  }

  &--squire {
    align-items: center;
    min-height: 4rem;
    font-size: 1.4rem;
    padding: 0.5rem 1rem !important;
    border-radius: $gBorderRadius;
  }

  &--dense {
    padding: 0.6rem 1.5rem 0.5rem;

    &.sn-btn--outline {
      padding: 0.5rem 1.4rem 0.4rem;
    }

    .sn-btn--round {
      padding: 0;
      width: 3rem;
      height: 3rem;
      min-width: 3rem;
      min-height: 3rem;
    }

    .s-pos-on-left {
      margin-right: 0.6rem;
    }

    .s-pos-on-right {
      margin-left: 0.6rem;
    }
  }

  &--flat,
  &--outline,
  &--unelevated {
    box-shadow: none;
  }

  &--fab,
  &--fab-mini {
    .s-ico {
      font-size: 1.5em;
      width: 100%;
      height: 100%;
    }
  }

  &--fab {
    width: 4rem;
    height: 4rem;
  }

  &--fab-mini {
    width: 3rem;
    height: 3rem;
  }

  &--ellipsis {
    .sn-btn__content {
      @include sflx();
      width: 100%;
      flex-flow: nowrap;
      flex-wrap: nowrap;

      div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .s-ico,
  .sn-spinner {
    font-size: 1.4em;
  }
}

button.sn-btn {
  -webkit-appearance: button;
}

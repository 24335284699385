.sn-slider__focus-ring {
  position: absolute;
  top:0;
  width: 21px;
  height: 21px;
  transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out;
  border-radius: 50%;
  opacity: 0;
  transition-delay: .14s;
}

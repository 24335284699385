.s-table-schedule__add-item {
  @include sflx($juscont: center, $alitems: center);
  width: 5rem;
  height: 5rem;
  border: 1px solid #ACB4C3;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  opacity: 0;
  transition: all $gDuration ease-in;

  &::after {
    font-size: 2.4rem;
    font-family: $fIcons;
    color: #8C93A2;
    content: '\E61A';
    transition: color $gDuration ease-in;
  }

  &:hover {
    background-color: $cWhite;
    border-color: $cWhite;
    transition: all $gDuration ease-in;
  }
}

.test-cont__menu-wrapper {
  @include sflx($dir: column);
  width: 100%;
  padding: 2rem 0;

  @media screen and (max-width: 1008px) {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  @media screen and (max-width: 639px) {
    flex-direction: column;
  }
}

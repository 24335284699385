.s-test-process__center {
  @include sflx($dir: column);
  flex: 1 1 auto;
  padding: 2rem 4rem 2rem 2rem;

  @media screen and ( max-width: 1008px ) {
    width: 100%;
    padding-right: 2rem;
  }
}

.faq-answear {
  display: block;
  width: 100%;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);

  &:not(:last-child) {
    margin-bottom: .6rem;
  }

  &.is-active {
    .faq-answear__head {
      background-color: #3a53b6;
      color: $cWhite;

      &::after {
        color: $cWhite;
        transform: rotateX(180deg);
        transition: transform .2s ease-in;
      }
    }
  }
}
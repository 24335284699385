.sn-checkbox__inner {
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 11px;
  outline: 0;
  border-radius: 50%;
  color: rgba(0,0,0,0.54);

  &--active,
  &--indeterminate {
    color: $primary;

    .sn-checkbox__bg {
      background: currentColor;
    }
  }

  &--active {
    path {
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.18s cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    }
  }

  &--indeterminate {
    .sn-checkbox__check-indet {
      transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, 0) scale3d(1, 1, 1);
      transition: transform 0.22s cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }
}

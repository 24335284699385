.home-interest-block-wrapper {
  @include sflx($juscont: center, $alitems: stretch);
  width: 100%;

  .interest-block {
    width: 40.7rem;

    &:not(:last-child) {
      margin-right: 3rem;
    }
  }


  @media screen and (max-width: 1349px) {
    flex-wrap: wrap;

    .interest-block,
    .interest-block__mini-block {
      margin-right: 1.5rem !important;
      margin-bottom: 3rem;
      margin-left: 1.5rem !important;
    }
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: center;

    .interest-block {
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0!important;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--school {
    @extend .home-interest-block-wrapper;
    justify-content: space-between;

    @media screen and ( max-width: 1349px ) {
      justify-content: space-around;
    }
  }
}
.sn-pagination {
  input {
    text-align: center;
    -moz-appearance: textfield;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .sn-btn {
    padding: 1.2rem 1.543rem;

    &.sn--disabled {
      visibility: hidden;
      background-color: unset !important;
    }
  }

  .sn-pagination__directions {
    padding: 1.1rem;
  }

  .sn-field__control {
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }
}

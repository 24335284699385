.profile__menu-wrapper {
  width: 100%;
  display: table;
  font-size: 0;
  margin-bottom: 1.5rem;
}

.flex-wrap {
  .profile__menu-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    li {
      display: flex;
      a, span {
        display: flex;
        justify-content: center;
        align-items: center
      }
    }
  }
}
.profile-content-flex {
  display: flex;
  margin-bottom: 2rem;
  .profile-buttons_container {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and ( max-width: 767px ) {
    flex-direction: column;
  }
}
.profile__menu-wrapper {
  li {
    //flex: 1;
    display: inline-block;
    vertical-align: top;
    width: 240 / 1280 * 100%;
    margin-right: 20 / 1280 * 100%;
    margin-bottom: 1.5rem;
    position: relative;
    &:nth-child(5n+5), &:last-child {
      margin-right: 0;
    }
    &.is-active {
      a, span {
        background-color: $c_green;
        border-color: $c_green;
        color: $c_white;
        &:hover {
          background-color: $c_green;
        }
      }
    }
    a, span {
      display: block;
      width: 100%;
      color: $c_black;
      padding: .95rem 0;
      font: 1.6rem/1.2em $font_sans_sb;
      text-align: center;
      text-transform: uppercase;
      border: 1px solid #c7d2d9;
      border-radius: 6px;
      background-color: $c_body;
      transition: background-color .2s ease-in;
      &:hover {
        background-color: darken($c_body, 5%);
        transition: background-color .2s ease-in;
      }
      i {
        display: block;
        position: absolute;
        z-index: 2;
        top: -1.5rem;
        right: 1.2rem;
        font: bold 1.6rem/1.2em $font_default;
        color: $c_white;
        background-color: $c_orange;
        box-shadow: 2px 2px 4px rgba($c_black, .2);
        border-radius: 6px;
        padding: .5rem;
      }
    }
  }
}
.menu-container {
  display: flex;
}
$menu-element: profile__menu-element;
.profile__menu-wrapper-new {
  color: black;
  width: 24em;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  margin-right: 2em;
  margin-top: 13.1em;
  margin-bottom: 1.5em;
  .profile__menu-element:nth-child(1) {
    border-radius: 5px 5px 0 0;
    &.#{$menu-element}__is-active {
      border-radius: 1px 5px 0 0;
    }
  }
  .profile__menu-element:last-child {
    border-radius: 0 0 5px 5px;
    &.#{$menu-element}__is-active {
      border-radius: 0 0 1px 5px;
    }
  }
}
.notifications-counter {
  margin-left: 3.5em;
  background: #ff6600;
  font-size: 1em;
  color: white;
  border-radius: 15px;
  padding: 0 .5em;
  font-style: normal;
  font-weight: bold;
}
.profile__menu-element {
  border-bottom: 1px solid #eceef5;
  padding: 0;
  background: white;
  transition: all .2s ease-in;
  &:hover {
    transition: all .2s ease-in;
    background-color: #eceef5
  }
  .profile__menu-link {
    display: block;
    padding: .8em 1.9em;
    .notifications-counter {
      margin-left: 3.5em;
      background: #ff6600;
      font-size: 1em;
      color: white;
      border-radius: 15px;
      padding: 0 .5em;
      font-style: normal;
      font-weight: bold;
    }
  }
  &__is-active {
    background: #eceef5;
    box-shadow: inset 2px 0 0 #3045a7;
    &:hover {
      background-color: darken(#eceef5, 6%);;
    }
    .profile__menu-link {
      font-weight: 600;
    }
  }
}
.need-help-link {
  margin-left: 2.3em;
  margin-top: 2em;
  color: #6dbefc;
  font-size: 1.3em;
  font-weight: bold;
}
.profile__menu-link {
  color: black;
  font-size: 1.6em;
  &:visited {
    color: black;
  }
}

.profile-content-wrapper {
  display: block;
  position: relative;
  width: 100%;
  background-color: $c_white;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 6px;
  padding: 3rem 3rem 6rem;
  .preloader-wrapper {
    .svg {
      display: block;
      margin: auto;
      width: 10%;
      height: 300px;
      transform: rotate(0);
      animation: rotate 4s cubic-bezier(.51, .92, .24, 1) forwards infinite;
    }
  }
  &--table {
    padding: 3rem 3rem 3rem;
  }
  .profile-form-container {
    display: flex;
    margin-top: 3rem;
    .modal-inform {
      width: 15%;
      color: #a3a3a3;
      text-align: left;
      padding-right: 3rem;
    }
    .organization-photo-container{
      .modal-inform {
        width: 100%;
      }
    }
    .button_theme_white--back {
      min-width: 0;
      margin-right: 1em;
    }
    .button_theme_blue {
      background-color: #02aef0;
      position: relative;
      padding-left: 2em;
      &::before {
        position: absolute;
        left:5%;
        font: 1em $font_themify;
      }
    }
    .form__vacancy-create {
      width: 50%;
      margin: 0 6.5em;
      .form__radio-button {
        margin-right: 2em;
      }
      .form__label{
        margin-bottom: .5em;
      }
      .form__checkbox, .form__radio {
        margin-bottom: 2em;
        input[type=checkbox], input[type=radio] {
          display: none;
          &:checked {
            + label {
              &:before {
                content: '\e64c';
                background-color: #14b1f1;
                border: 1px solid #14b1f1;
                border-radius: 3px;
                height: 1em;
                width: 1em;
                color: white;
                top: .2rem;
                left: 0;
                font-size: 1.5rem/1.2em;
              }
            }
          }

          + label {
            cursor: pointer;
            padding-bottom: 0;
            padding-left: 3rem;
            position: relative;

            &:before {
              content: ' ';
              height: 1em;
              width: 1em;
              border: 1px solid silver;
              border-radius: 3px;
              position: absolute;
              z-index: 1;
              top: .2rem;
              left: 0;
              font: 1.5rem/1.2em $font_themify;
            }
          }
        }
      }
      .form__radio--green {
        input[type=checkbox], input[type=radio] {
          &:checked {
            + label {
              &:before {
                background-color: $c_green;
                border: 1px solid $c_green;
              }
            }
          }
        }
      }
    }
  }
}
.button_theme_white {
  border: 1px solid #bdc7cc;
}
.button_theme_white--up {
  margin-right: 2rem;
  position: relative;
  &:before {
    content: "\e648";
    position: absolute;
    left: 5%;
    font: 1em snation;
  }
}
.profile-content-wrapper-new {
  padding: 1em 1em 6em;
  box-shadow: none;
  background: none;
}
.profile-content__line-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  &:last-child {
    margin-bottom: 0;
  }
  .button {
    min-width: 24.6rem;
    font-family: $font_sans_sb;
  }
  &--no-margin {
    margin-bottom: 0;
  }
  &--table {
    height: 2.3em;
  }
  &--table_active {
    height: 6.1em;
  }
  &--edit {
    height: 6.3em;
  }
}
.profile-content__left-wrapper {
  flex: 1 1 auto;
  > label {
    display: block;
  }
  .button {
    min-width: 21rem;
    font-family: $font_sans_sb;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    //&:last-child {
    //  margin-right: 0;
    //}
  }
  .button_theme_white {
    border: 1px solid #d1d8dc;
  }
  .filter-block {
    width: 100%;
    max-width: 26rem;
    margin-bottom: 1.5rem;
  }
  .__left-director-menu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 243 / 1052 * 100%;
      margin-right: 20 / 1052 * 100%;
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }
  }
  &--space-between {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}
.profile-content__right-wrapper {
  flex: 1 0 auto;
  width: 100%;
  max-width: 42.2rem;
  form.form__filter-search {
    margin-bottom: 2rem;
  }
  .filter-block {
    margin-bottom: 2rem;
  }
}
.profile-content__right-search-wrapper {
  @extend .profile-content__right-wrapper;
  max-width: 15.8rem;
  position: relative;
  >.button {
    min-width: unset;
    width: 100%;
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
    margin-bottom: 1.5rem;
  }
  .button_theme_green {
    &:hover {
      background-color: $c_green;
    }
  }
  .form__vac-search {
    position: absolute;
    z-index: 2;
    top: 100%;
    right: 0;
    display: none;
    width: auto;
    background-color: $c_green;
    padding: 1rem 1.5rem;
    border-radius: 6px 0 6px 6px;
    box-shadow: 0 0 5px rgba($c_black, .51);
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      bottom: 100%;
      right: 0;
      width: 15.8rem;
      height: 2rem;
      background-color: $c_green;
    }
    input {
      width: 47.5rem;
      padding-right: 5rem;
    }
    button {
      position: absolute;
      z-index: 2;
      top: 50%;
      margin-top: -1rem;
      right: 2.9rem;
      padding: 0;
      &:after {
        content: '\E610';
        font: 2.2rem/1em $font_themify;
        color: #92a4ae;
      }
    }
  }
}
.profile-content__inform {
  font-size: 1.6rem;
  line-height: 1.2em;
  text-align: center;
  padding-bottom: 3rem;
  border-bottom: 1px solid $c_black;
  .__title {
    font-family: $font_sans_sb;
    color: $c_green;
    margin-bottom: 1.5rem;
  }
  .__desc {
    font-family: $font_default;
    color: $c_black;
  }
}

.profile-content__block {
  margin: 4rem 0 0;
  .table__profile {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text-warning-color {
    color: #f06060;
  }
  .text-success-color {
    color: #33cc66;
  }
}
.profile-table__top-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $c_black;
  &--space {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}
.profile-table__title {
  font: bold 2.2rem/1.2em $font_default;
  padding-right: 1.5rem;
  margin: 0;
  color: #162e66;
}

.profile-table__edit {
  font: 600 1.6rem/1.2em $fDef;
  color: $c_black;
  cursor: pointer;
  &:hover {
    span {
      border-bottom: 1px dotted darken($c_blue, 1%);
    }
  }
}
.profile-table-photo_container {
  display: flex;
  width: 100%;
  .profile-table_container {
    display: block;
    width: 70%;
  }
}
.profile-photo_container {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  .profile-photo_block {
    position: relative;
    margin-top: 6.5em;
    margin-right: 2rem;
    width: 24.5em;
    height: 27.5em;
    border: #8a9da8 dashed 1px;
    border-radius: 10px;
    display: inline-block;
    background: no-repeat #f7f9fc 50% 50% url("/images/balabaqsha/kindergarten_boy.svg");
    background-size: 140px;
    &--girl {
      background: no-repeat #f7f9fc 50% 50% url("/images/balabaqsha/kindergarten_girl.svg");
      background-size: 120px;
    }
  }
}
.profile-photo--fix {
  padding-right: 3rem;
  .profile-photo_block {
    margin-top: 3.5rem;
    margin-right: 0;
    width: 24.5rem;
    height: 27.5rem;
    background-size: cover;
  }
}
.profile-content__bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3rem;
  .button {
    min-width: 24.6rem;
  }
}
.profile-content-tables {
  margin-top: 5em;
}
.profile-content__columns {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 4rem 0;
  .profile-content__block {
    flex: 1 1 auto;
    margin: 0;
  }
}
.profile-content__photo-wrapper {
  padding-top: 3.6rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.profile-content__photo {
  width: 19.6rem;
  height: 23.8rem;
  border-radius: 6px;
  @include sbg($color: #d1d8dc, $size: contain);
}
.profile-create-array {
  width: 100%;
  display: table;
  font-size: 0;
  .profile-create-block {
    display: inline-block;
    width: 24.6rem;
    vertical-align: top;
    margin-top: 3rem;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}
.flex-wrap {
  .profile-create-array {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
  }
}
.profile-content__search-result {
  display: block;
  width: 100%;
  font: 1.8rem/1.2em $font_default;
  color: $c_black;
  margin-bottom: 3rem;
  span {
    font-family: $font_sans_sb;
  }
}
.profile-vacancy-array {
  .vacancy-position {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__new {
    font-size: initial;
    margin-top: 6.2em;
    margin-bottom: 3em;
    background: inherit;
    .vacancy-position {
      background: inherit;
    }
  }
}
.application-notification-block {
  display: flex;
  background-color: $c_blue;
  border-radius: 5px;
  padding-left: 2rem;
  margin-left: 1%;
  width: 98%;
  height: 3.5rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  &__text {
    color: white;
    font-size: 2rem;
    line-height: 3.5rem;
  }
  &__image {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .profile__menu-wrapper-new {
    display: none !important;
  }
}

@media screen and (max-width: 1199px) {
  .profile-content__line-between--table {
    height: 4.8em;
    .profile-content__left-wrapper {
      text-align: start;
    }
  }
  .profile-content__line-between--table_active {
    height: auto;
    .profile-content__left-wrapper {
      text-align: start;
    }
  }
  .profile-form-container {
    display: flex;
    flex-direction: column;
    .modal-inform {
      width: 100% !important;
      text-align: right !important;
    }
  }
  .profile__menu-wrapper {
    display: none !important;
  }
  .profile__menu-wrapper-new {
    width: auto;

  }
  .need-help-link {
    display: none;
  }
  .profile-content-wrapper {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .profile-content__columns {
    margin-top: 0;
  }
  .profile-content-wrapper {
    .profile-content-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      .profile-table__top-wrapper {
        padding-bottom: 0;
      }
      .profile-buttons_container {
        justify-content: center;
      }
      .profile-table__top-wrapper {
        margin-bottom: 0;
      }
      .profile-content-tables {
        width: 100%;
      }
      .profile-photo_container {
        padding-right: 0;
        align-items: center;
        margin-bottom: 3em;
        text-align: center;
        width: 100%;
        .button_theme_white--back-icon {
          margin-bottom: 2em !important;
        }
        .profile-photo_block {
          margin-top: 0;
          margin-right: 0;
        }
      }
    }
  }
  .profile-table__title {
    margin-bottom: 1em;
    &--line {
      display: none;
    }
  }
}
@media screen and (max-width: 999px) {
  .profile-content__columns {
    .profile-content__block {
      .table__profile {
        display: block;
        width: 100%;
        tbody {
          display: block;
          width: 100%;
          tr {
            display: block;
            width: 100%;
            td {
              display: block;
              width: 100% !important;
              border-right: 0 !important;
              font-size: 1.5rem;
              text-align: left !important;
              padding: 1rem 2rem !important;
              &:first-child {
                font-family: $font_default;
              }
              &:last-child {
                font-family: $font_sans_sb;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .profile-photo--fix {
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-content__line-between--table {
    height: auto;
  }
  .profile-content__line-between {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1em;
    >.button {
      margin-bottom: 3rem;
      //&:last-child {
      //  margin-bottom: 0;
      //}
    }
    &--edit {
      padding-top: 1.2em;
      height: auto;
    }
  }

  .profile-content__left-wrapper {
    width: 100%;
    display: table;
    font-size: 0;
    .button {
      width: 48%;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .profile-content__right-wrapper {
    width: 100%;
    max-width: 100%;
  }
  .profile-content-wrapper {
    box-shadow: none;
    padding: 0;
  }
  .profile-content__columns {
    .profile-content__block {
      .profile-table__top-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .profile-table__title {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .profile-table__title {
    font-size: 2rem;
  }
  .profile-content__photo-wrapper {
    padding-top: 6.5rem;
    padding-right: 0;
  }
}

@media screen and (max-width: 620px) {
  .profile-content-wrapper-new {
    .profile-content__line-between {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      .button_theme_aqua--new, .button_theme_white--new {
        width: 100%;
        border-radius: 6px;
        border: none;
        min-width: 23rem!important;
        height: auto;
      }
    }

    .button-container_right {
      padding: 10px 0 10px 0;
      .button_type_archive {
        width: 100%;
        font-size: 18px;
        text-align: center;
        padding-right: 0;
        min-width: 23rem!important;
        &:after {
          right: 80%;
          left: 0;
          content: '';
        }
        &:before {
          content: '\e664';
          font-family: snation;
          position: absolute;
          left: 5%
        }
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .profile-content__columns {
    flex-direction: column;
    .profile-content__block {
      order: 2;
      width: 100%;
    }
    .profile-content__photo-wrapper {
      order: 1;
      padding: 0;
      margin-bottom: 2rem;
    }
  }
  .profile-table__title {
    font-size: 1.8rem;
  }
  .profile-table__edit {
    span {
      border-bottom: 1px dotted darken($c_blue, 1%);
    }
  }
  .profile-table__top-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .profile-table__title {
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 479px) {
  .profile-content__left-wrapper {
    .button {
      display: block;
      width: 100%;
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 400px) {
  .profile-content__line-between {
    >.button {
      width: 100%;
    }
  }
}

.form-date {
  @include pos();
  display: block;
  width: 100%;

  .form-input {
    padding-right: 4rem;
  }

  &::after {
    @include pos(absolute, 1, calc(50% - 1rem), $r: 1rem);
    font-weight: normal;
    font-size: 1.8rem;
    font-family: $fIcons;
    color: $cGreyText;
    content: '\E6B6';
  }
}

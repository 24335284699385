.s-panel {
  width: 100%;
  background-color: $cWhite;
  box-shadow: 0 0 1rem rgba(#687C8F, 0.28);
  border-radius: $gBorderRadius;
  padding: 3rem;

  &--auth {
    width: max-content;
    max-width: 100%;
  }
}

.s-group {
  @include sflx($d: inline-flex, $wrap: wrap, $alitems: stretch);

  &--wider {
    display: flex;
    width: 100%;
  }

  &--to-center {
    justify-content: center;
  }

  &--nowrap {
    flex-wrap: unset;
  }

  &--align-center {
    align-items: center;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--equal {
    > * {
      flex: 1;
    }
  }

  > * {
    margin: 0.5rem;
  }
}

@for $i from 1 through 12 {
  $value: ($i * 5 / 10) + rem;
  .pt-#{$i * 5} {
    padding-top: $value;
  }
  .pr-#{$i * 5} {
    padding-right: $value;
  }
  .pb-#{$i * 5} {
    padding-bottom: $value;
  }
  .pl-#{$i * 5} {
    padding-left: $value;
  }
}

.sn-checkbox__check {
  color: $cWhite;

  path {
    stroke: currentColor;
    stroke-width: 3.12px;
    stroke-dashoffset: 29.78334;
    stroke-dasharray: 29.78334;
  }
}

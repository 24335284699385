.course-spoiler-head {
  @include sflx($alitems: center);
  width: 100%;
  background-color: $cWhite;
  border-radius: $gBorderRadius $gBorderRadius 0 0;
  transition: all .2s ease-in;

  &.is-active {
    color: $cWhite;
    background-color: #3a53b6;
    transition: all .2s ease-in;

    .course-spoiler-head__button {
      color: $cWhite;
      transform: rotate(180deg);
      transition: all .2s ease-in;
    }

    + .course-spoiler-body {
      //display: block;
    }
  }

  .course-spoiler__section-name {
    color: #aeb3c6;
  }
}

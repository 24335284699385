.ol-lbc__option-remove {
  @include sflx($juscont: center, $alitems: center);
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  background-color: $cBlue;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  transition: background-color $gDuration ease-in;

  &:hover {
    background-color: darken($cBlue, 10);
  }

  &::after {
    font-size: 2rem;
    font-family: $fIcons;
    color: $cWhite;
    content: map_get($listOfIcons, 'close');
  }
}

.tablesorter .filtered {
  display: none;
}

.tablesorter .tablesorter-errorRow td {
  text-align: center;
  cursor: pointer;
  background-color: #E6BF99;
}

.tablesorter thead .disabled {
  display: none;
}

.tablesorter_filter {
  //height: 3rem;
}

.tablesorter-filter {
  height: 3rem;
  min-width: 10rem;
  width: 100%;
  border: 1px solid #d1d8dc;
  box-shadow: none;
  border-radius: 5px;
}

.tablesorter_filter-searchIcon {
  position: relative;
  color: #7E8695;
  font-size: 2rem;

  &::after {
    content: '\E610';
    font: 1.8rem $font_themify;
    position: absolute;
    top: 0.7rem;
    right: 4%;
  }
}

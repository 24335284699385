.statistic-graph__desc {
  @include pos(absolute, 2);
  display: block;
  width: 24rem;
  padding-bottom: .5rem;
  border-bottom: 3px solid $cWhite;


  @media screen and (max-width: 999px) {
    position: relative;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    width: 30rem;
    padding-right: 0 !important;
    padding-left: 9rem !important;
    margin-left: -7rem;
  }

  @media screen and (max-width: 479px) {
    width: 100%;
    text-align: center;
    padding-left: 0 !important;
    margin-left: 0;
    border-bottom: 0;
  }
}

.statistic-graph__desc--school {
  @extend .statistic-graph__desc;
  border-bottom: 1px solid $cBorder;
}
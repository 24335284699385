.button-container{
    padding: 10px;
    margin-bottom: 10px;

    &>a{
        font-size: 16px;
        text-align: right;
    }

    &_right{
        @extend .button-container;
        text-align: right;
        display: flex;
        justify-content: space-between;
    }
}
//Сброс позиционарования
@mixin clear() {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

//Сборка background
@mixin sbg($color: transparent, $repeat: no-repeat, $position: center center, $size: auto auto) {
  background-color: $color;
  //background-image: url($image);
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}

//Ширина в процентах
@mixin cl_w($width_block, $width_parent) {
  width: $width_block / $width_parent * 100%;
}

//Высота в процентах
@mixin resp_height($display: inline-block, $height: 100%) {
  display: $display;
  &:before {
    content: '';
    display: table;
    margin-top: $height;
  }
}

//Стилизация placeholder
@mixin placeholder($color: #000) {
  &::-webkit-input-placeholder {color:$color;text-overflow: ellipsis;}
  &::-moz-placeholder          {color:$color;text-overflow: ellipsis;}/* Firefox 19+ */
  &:-moz-placeholder           {color:$color;text-overflow: ellipsis;}/* Firefox 18- */
  &:-ms-input-placeholder      {color:$color;text-overflow: ellipsis;}
}

//Цвет выделения текста
//@mixin selection($bg_color: blue, $color: white) {
//  ::selection {
//    background: $bg_color;
//    color: $color;
//  }
//}

@mixin triangle($below: false, $size: 20rem, $color: #fff, $way: top){
  &:after, &:before {
    position: absolute;
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
  }
  @if $below {
    &:after {
      content: '';
      bottom: 0;
      @include triangleWay($way: $way, $size: $size, $color: $color);
    }
  } @else {
    &:before {
      content: '';
      top: 0;
      @include triangleWay($way: $way, $size: $size, $color: $color);
    }
  }
}

@mixin triangleWay($way, $size, $color) {
  @if $way == top {
    border-bottom: solid $size $color;
    border-left: solid $size transparent;
    border-right: solid $size transparent;
  } @else if $way == bottom {
    border-top: solid $size $color;
    border-left: solid $size transparent;
    border-right: solid $size transparent;
  } @else if $way == right {
    border-left: solid $size $color;
    border-bottom: solid $size transparent;
    border-top: solid $size transparent;
  } @else if $way == left {
    border-right: solid $size $color;
    border-bottom: solid $size transparent;
    border-top: solid $size transparent;
  }
}

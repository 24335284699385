.s-form__reset-password {
  @extend .s-panel;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &-wrapper {
    padding: 0 2rem;
    width: 100%;
    max-width: 60rem;
  }
}

.table-on-fire__head {
  display: table-header-group !important;

  table {
    display: table !important;

    thead {
      display: table-header-group !important;
    }
  }

  th {
    @include pos($z: 3);
    vertical-align: middle;
    font-weight: 600;
    font-size: 1.2rem;
    color: $cWhite;
    background-color: #aeb3c6;
    padding: 0 .5rem;

    &.tof-grid__index,
    &.tof-grid__spec {
      z-index: 5;
      height: 13.2rem;
    }
  }

  > tr > th {
    &:nth-child(1),
    &:nth-child(2) {
      vertical-align: top;
      padding: 2rem 1.2rem;
    }
  }

  td {
    padding: 0;
  }
}

.tof-head__title-wrapper {
  height: 5.2rem;
  background-clip: padding-box;
  border-bottom: 1px solid $tBorderLocal;
}

.tof-head__title-part {
  height: 8rem;
  background-clip: padding-box;
  border-right: 1px solid $tBorderLocal;

  &:last-child {
    border-right: 0;
  }
}

.tof-head__title-course {
  height: 2.6rem;
  background-clip: padding-box;
  border-bottom: 1px solid $tBorderLocal;
}

.tof-head__title-course-part {
  height: 5.3rem;
  background-clip: padding-box;
  border-right: 1px solid $tBorderLocal;

  &:last-child {
    border-right: 0;
  }
}

// Fix height for Firefox
@-moz-document url-prefix() {
  .tof-head__title-course-part {
    height: 5.4rem;
  }
}

.news-array {
  display: table;
  width: 100%;
  font-size: 0;
  .news-block {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    vertical-align: top;
    width: 406.6 / 1280 * 100%;
    margin-right: 30 / 1280 * 100%;
    margin-bottom: 3rem;
    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }
}
.flex-wrap {
  .news-array {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}


@media screen and (max-width: 999px) {
  .news-array {
    .news-block {
      width: 48%;
      margin-right: 4%;
      &:nth-child(3n+3) {
        margin-right: 4%;
      }
      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 639px) {
  .news-array {
    .news-block {
      width: 100%;
      margin-right: 0;
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }
  }
}

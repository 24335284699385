%s-questions-map__pagination {
  @include sflx($alitems: center, $juscont: center);
  width: 4rem;
  height: 4rem;
  color: $stpGreyDark;
  margin: 0 1rem;
  border: 1px solid $stpGrey;
  border-radius: $gBorderRadius;
  cursor: pointer;

  &::before {
    font-size: 2rem;
    font-family: $fIcons;
  }

  &:hover {
    background-color: darken($cWhite, 5%);
    transition: background-color $gDuration ease;
  }
}

.s-questions-map__pagination {
  @include sflx();
  width: 12rem;
}

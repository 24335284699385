.sn-transition--field-message {
  &-enter-active,
  &-leave-active {
    transition: transform 0.6s cubic-bezier(0.86, 0, 0.07, 1), opacity 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  &-leave,
  &-leave-active {
    position: absolute;
  }
}

.pagination-tablet-show {
  display: none;
}

.pagination__button {
  &:hover {
    cursor: pointer;
  }
}

@media ( max-width: 1008px ) {
  .pagination_tablet_show {
    display: block;
  }

  .pagination-desktop-show {
    display: none;
  }
}
$fDef: 'Open Sans', sans-serif;
$cBlue: #3045A7;

.error-outer {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .error-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
.error-block-wrapper {
  width: 75.2rem;
  height: 49.8rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  padding: 6rem;
}
.error__type {
  font: bold 9.5rem/1.2em $fDef;
  margin-bottom: 1rem;
}
.error__message {
  font: 2.4rem/1.2em $fDef;
  a {
    font-weight: bold;
    color: $cBlue;
    &:hover {
      border-bottom: 1px solid darken($cBlue, 1%);
    }
  }
}


@media screen and (max-width: 699px) {
  .error-block-wrapper {
    width: 100%;
    height: auto;
    background: none !important;
    padding: 0;
  }
}

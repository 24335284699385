.sn-notify__close {
  @include pos(absolute, $t: 0, $r: 0);
  @include sflx($alitems: center, $juscont: center);
  align-self: flex-start;
  width: 5rem;
  height: 5rem;
  font-size: 1.5rem;
  font-family: $fIcons;
  color: $cWhite;
  background-color: transparent;
  border-top-right-radius: 7px;
  cursor: pointer;

  &::before {
    content: map_get($listOfIcons, 'close');
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color $gDuration linear;
  }
}

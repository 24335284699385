.tbl-cont__tr {}

$listOfTRStatus: (
  (waiting, #ff9600),
  (checking, #02aef0),
  (success, #33cc66),
  (empty, #02aef0),
  (downloaded, #33cc66),
  (attached, #33cc66),
  (deleted, #1D2525),
  (priority, #223b99)
);

@each $name, $color in $listOfTRStatus {
  .tbl-cont__tr_#{$name} {
    .tbl-cont__btn {
      color: $color;
      border-color: $color;

      @if ($name == attached) {
        background-color: $color !important;
        color: $cWhite;
        cursor: default;
      }

      &:hover {
        color: $cWhite;
        background-color: darken($color, 5%);
      }
    }

    .tbl-cont__status {
      @if ($name != empty) {
        color: $color;
      }
      @else {
        color: #e18c96
      }
    }
  }
}
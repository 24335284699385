.child-profile-wrapper {
  @include sflx($alitems: stretch);
  width: 100%;
  padding-top: 0;
  padding-bottom: 4rem;

  .child-profile-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media screen and (max-width: 1009px) {
    .child-profile__inform-column {
      width: 100%;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    .child-profile__right {
      width: 100%;
    }
  }
}

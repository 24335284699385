.container-body-bottom-wrapper {
  @include sflx($juscont: flex-end);
  padding-bottom: 3rem;

  > * {
    &:not( :last-child ) {
      margin-right: 2rem;
    }
  }

  &.to-center {
    justify-content: center;
  }


  @media screen and ( max-width: 767px ) {
    flex-direction: column;
    align-items: center;

    > * {
      &:not( :last-child ) {
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and ( max-width: 480px ) {
    > * {
      width: 100%;
    }
  }
}
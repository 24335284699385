$fIcons: snation;

.home-news-outer {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .s-title-section {
    margin-bottom: 0;
  }
}
.news-carousel,
.s-partners-carousel,
.s-albums-carousel {
  position: relative;
  margin-top: -3rem;
  .owl-stage {
    display: flex;
    align-items: stretch;
    padding: 3rem 0;
    .owl-item {
      display: flex;
      flex-direction: column;
    }
    .news-block {
      flex: 1 0 auto;
      border: 1px solid #edf0f2;
    }
  }
  .owl-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    height: 0;
    margin-top: -1.9rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .owl-prev, .owl-next {
      &:after {
        font: 3.8rem/1em $fIcons;
        color: #8DBE2D;
      }

      &.disabled {
        &::after {
          color: #ACB1C1;
        }
      }
    }
    .owl-prev {
      margin-left: -5.5rem;
      &:after {
        content: '\E64A';
      }
    }
    .owl-next {
      margin-right: -5.5rem;
      &:after {
        content: '\E649';
      }
    }
  }
}


@media screen and (max-width: 1379px) {
  .home-news-outer {
    overflow: hidden;
  }
}

@media screen and (max-width: 599px) {
  .home-news-outer {
    padding-top: 3rem;
  }
}

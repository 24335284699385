.sn--no-margin {
  margin: 0 !important;
}

.sn--no-padding {
  padding: 0 !important;
}

.sn--no-border {
  border: 0 !important;
}

.sn--no-border-radius {
  border-radius: 0 !important;
}

.sn--no-box-shadow {
  box-shadow: none !important;
}

.sn--no-outline {
  outline: 0 !important;
}

.sn--ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &-2-lines, &-3-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  &-2-lines {
    -webkit-line-clamp: 2;
  }

  &-3-lines {
    -webkit-line-clamp: 3;
  }
}

.sn--readonly {
  cursor: default !important;
}

.sn--disabled, [disabled] {
  &, * {
    outline: 0 !important;
    cursor: not-allowed !important;
  }
}

.sn--disabled, [disabled] {
  opacity: .6 !important;
  //color: #617886 !important;
}

.sn--hidden {
  display: none !important;
}

.sn--invisible {
  visibility: hidden !important;
}

.sn--transparent {
  background: transparent !important;
}

.sn--overflow-auto {
  overflow: auto !important;
}

.sn--overflow-hidden {
  overflow: hidden !important;
}

.sn--overflow-hidden-y {
  overflow-y: hidden !important;
}

.desktop {
  .sn-focus-helper {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: inherit;
    outline: 0;
    opacity: 0;
    transition: background-color .3s cubic-bezier(.25, .8, .5, 1), opacity .4s cubic-bezier(.25, .8, .5, 1);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: inherit;
      transition: background-color .3s cubic-bezier(.25, .8, .5, 1), opacity .6s cubic-bezier(.25, .8, .5, 1);
    }

    &::before {
      background: $cBlack;
    }

    &::after {
      background: $cWhite;
    }

    &--rounded {
      border-radius: $gBorderRadius;
    }

    &--round {
      border-radius: 50%;
    }
  }

  .sn-focusable, .sn-manual-focusable, .sn-hoverable {
    outline: 0;
  }

  .sn-focusable:focus, .sn-manual-focusable--focused, .sn-hoverable:hover {
    > .sn-focus-helper {
      background: currentColor;
      opacity: .15;

      &:before {
        opacity: .1;
      }

      &:after {
        opacity: .4;
      }
    }
  }

  .sn-focusable:focus, .sn-manual-focusable--focused {
    > .sn-focus-helper {
      opacity: .22;
    }
  }
}

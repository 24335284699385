.table-on-fire__body {
  // Подсветка строк
  tr {
    background-color: unset !important;

    &:nth-child(odd) {
      td {
        background-clip: padding-box;
        background-color: $cWhite;
      }
    }

    &:nth-child(even) {
      td {
        background-clip: padding-box;
        background-color: $tTrEvenBg;
      }
    }

    &:hover {
      td {
        background-clip: padding-box;
        background-color: $trHover;
      }
    }
  }

  td {
    @include pos();
    height: 5rem;
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    background-clip: padding-box;
    border-right: 1px solid $tBorderLocal;
    border-bottom: 1px solid $tBorderLocal;

    &:nth-child(1) {
      text-transform: uppercase;
    }

    &:nth-child(1),
    &:nth-child(2) {
      text-align: left;

      .tof-body__input {
        text-align: left;
      }
    }

    &:last-child {
      border-right: 0;
    }

    // Подсветка ячейки и столбца
    &:hover {
      &::before {
        @include pos(absolute, $z: 1, $l: 0, $r: 0);
        margin-top: -500rem;
        display: block;
        height: 1000rem;
        background-color: rgba(darken($tTrEvenBg, 10%), .3);
        content: '';
      }

      &::after {
        @include pos(absolute, 2, 0, 0, 0, 0);
        display: block;
        background-color: $tdHoverBg;
        border: 1px solid $tdHoverBorder !important;
        content: '';
      }
    }

    > * {
      @include pos($z: 3);
    }
  }

  .tof-grid__index,
  .tof-grid__spec {
    transform: translateY(.1rem);

    &:hover {
      &::before {
        display: none;
      }
    }
  }
}

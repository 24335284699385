.portfolio-main__head {
  display: flex;
  margin: 2rem 0;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.portfolio-main__head--text {
  width: 75%;
}
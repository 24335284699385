.sn-item__label {
  line-height: 1.2em !important;
  max-width: 100%;

  &--overline {
    color: rgba(0,0,0,0.7);
  }

  &--caption {
    color: rgba(0,0,0,0.54);
  }

  &--header {
    color: #757575;
    padding: 16px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01786em;
  }

  + .sn-item__label {
    margin-top: 4px;
  }
}

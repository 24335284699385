.s-region-list__link {
  @extend %s-region-list__item;
  padding-left: 3rem;

  .active-arrow {
    &::before {
      top: 0;
      transform: rotate(180deg);
    }
  }

  &.is-active {
    > a {
      @include pos();

      &::before {
        @include pos(absolute, $l: -2.4rem);
        font-family: $fIcons;
        content: map-get($listOfIcons, 'check');
      }
    }

    .active-arrow {
      &::before {
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }
}

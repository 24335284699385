.portfolio__doc-block {
  display: flex;
  flex-wrap: wrap;
}

.portfolio__doc {
  padding: 1rem 1rem 0;
  width: 21.32rem;
  height: 30rem;
  max-width: 100%;
  border: 1px solid $cBorder;
  border-radius: 5px;
  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
  margin-right: 1.6rem;
  color: $cBlack;

  &:hover {
    color: $cBlack;
  }

  &:nth-child( 5n + 5 ) {
    margin-right: 0;
  }
}

.portfolio__doc--modal {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.portfolio__doc-photo {
  width: 19.32rem;
  height: 23rem;
  max-width: 100%;
  border: 1px solid $cBorder;
  border-radius: 5px;
  background: 50% 50% no-repeat;
  background-size: contain;
}

.portfolio__doc-text {
  align-items: center;
  display: flex;
  height: 6rem;
}

.form__filter-search {
  position: relative;
  width: 100%;
  input {
    height: 4rem;
    padding-right: 4rem;
    font-size: 1.6rem;
  }
  button {
    position: absolute;
    top: 50%;
    margin-top: -1.1rem;
    right: 1rem;
    display: block;
    font: 2.2rem $font_themify;
    color: $c_black;
    padding: 0;
    &::after {
      content: '\E610';
    }
  }
}

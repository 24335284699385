.s-mobile-menu__item {
  min-height: 4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  color: $cWhite;
  cursor: pointer;
  user-select: none;
  width: 100%;
  outline: none !important;

  &--green {
    color: $cGreen;

    .s-str-ico::before {
      color: $cGreen;
    }
  }

  &--black {
    color: $cBlack;

    .s-str-ico::before {
      color: #a9b9c2;
    }
  }

  &--to-right {
    justify-content: flex-end;
  }

  .s-str-ico {
    padding-left: 1.5em;
  }

  &--settings-right{
    @extend
    .s-str-ico-rev,
    .s-str-ico--thm-arrow-right;
    font-size: 15px;

    &::before {
      right: 2.2rem;
    }
  }

  &--submenu-down{
    text-align: unset;
    justify-content: space-between;
    @extend .s-str-ico-rev;
    &::after {
      @extend %s-ico;
      font-size: 1.2em;
      content: "\E64B";
      transition: transform 300ms linear;
    }
  }
  &--submenu-up {
    &::after {
      transform: rotateX(180deg);
    }
  }
  &--submenu-child{
    padding-left: 4.2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    line-height: 20px;
  }

  &--selected {
    @extend .s-bold,
      .s-str-ico-rev,
      .s-str-ico--thm-check;

    &::before {
      right: 2.2rem;
    }
  }
  &--selected-region {
    @extend .s-bold,
    .s-str-ico-rev,
    .s-str-ico--thm-check;

    &::before {
      right: 2.2rem;
      font-family: $fIcons;
      content: map-get($listOfIcons, 'close');
      font-size: 16px;
      @extend .s-bold;
      }
    }
  }


.cabinet-body__personal-top-wrapper {
  @include sflx($alitems: center);
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;


  @media screen and (max-width: 999px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

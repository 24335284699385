.sn-slider__track-markers {
  color: black;
  background-image: repeating-linear-gradient(to right, currentColor, currentColor 2px, transparent 0, transparent);

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 2px;
    width: 2px;
    background: currentColor;
  }
}

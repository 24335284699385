.content__head-wrapper {
  @include sflx($alitems: center, $juscont: space-between);
  width: 100%;
  padding: 0 0 3rem;
}


@media screen and (max-width: 767px) {
  .content__head-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .content__head-left {
      margin-bottom: 2rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .s-time-sheet-scroll-part {
    .s-time-sheet--head-cell {
      text-align: left;
    }
  }
  .s-time-sheet--head-cell,
  .s-time-sheet--head-row {
    font-size: 0.9em;
  }
  .s-time-sheet-container {
    max-width: 100%;
  }
  //.s-time-sheet-fixed-part {
  //  tr {
  //    td:first-child, th:first-child {
  //      display: none;
  //    }
  //  }
  //}
}

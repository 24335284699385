.sn-notify {
  @include pos();
  width: 100%;
  max-width: 40rem;
  font-size: 1.4rem;
  padding: 2rem;
  margin: 0.5rem 2rem;
  border-radius: 7px;
  background-color: rgba(#1a1b1f, 0.98) !important;
  box-shadow: 0 0.5rem 1.5rem rgba(#1a1b1f, 0.5);

  a {
    color: #93B4F3 !important;
  }

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 2rem;
  }

  &--info {
    color: #93B4F3;
  }

  &--success {
    color: #1FDD6A;
  }

  &--warning {
    color: #FFAA5B;
  }

  &--error {
    color: #FF5353;
  }
}

.sn-tabs__content {
  overflow: hidden;
  flex: 1 1 auto;

  &--align {
    &-center {
      justify-content: center;
    }

    &-right {
      justify-content: flex-end;
    }

    &-justify .q-tab {
      flex: 1 1 auto;
    }
  }
}

.s-table-schedule__body {
  tr {
    td {
      background-color: #DFE2ED;
    }

    &:hover {
      td {
        background-color: #D6D9E6;
      }
    }
  }

  td {
    width: 28rem;
    height: 16rem;

    &.s-table-schedule__col-time {
      padding: 1.5rem;
      vertical-align: top;
    }

    // Подсветка ячейки и столбца
    &:hover {
      @include pos();

      &::before {
        @include pos(absolute, 1, $l: 0, $r: 0);
        margin-top: -500rem;
        display: block;
        height: 1000rem;
        background-color: rgba(#D6D9E6, 0.5);
        content: '';
      }
    }
  }
}

.s-steps__chain-line {
  @include pos(absolute, -1, 1.7rem, $r: 0);
  width: 50rem;
  height: 0.6rem;
  background-color: #D9E3ED;

  &::before {
    @include pos(absolute, $t: 0, $l: 0);
    width: 0;
    height: 100%;
    background-color: $cBlue;
    content: '';
    transition: width 0.3s linear;
  }
}

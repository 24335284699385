.college-library-desc--name {
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;

  &.open {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: unset;
  }
}

.college-library-desc--author {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: $cMetal;
  font-weight: 600;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;

  &.open {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: unset;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.college-library-desc--type {
  font-size: 1.4rem;
  color: $cMetal;
  font-weight: 600;
}

.college-library-desc--watches {
  font-size: 1.3rem;
  color: $cMetal;
  margin-top: 0.3rem;

  &::before {
    font-family: $font_themify;
    content: map-get($listOfIcons, 'download');
    font-size: 1.4rem;
  }
}

.college-library-desc--description {
  max-width: 76rem;
  font-size: 1.4rem;
  white-space: pre-line;
}

.college-library-desc--open {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.college-library-desc--link {
  max-height: 6.5rem;
  max-width: 76rem;
  font-size: 1.4rem;
  white-space: pre-line;
}

.lib-hr{
  width: 100%;
  height: 0.1rem;
  background-color: #ACB1C1;
  border: 0;
}

.sn-table__grid-item {
  padding: 0.4rem;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &--selected {
    transform: scale(0.95);
    .sn-table__grid-item-card {
      background: rgb(246 249 255);;
    }
  }
}

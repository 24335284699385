.s-vis-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.s-vis-no-margin {
  margin: 0 !important;
}

.s-vis-no-padding {
  padding: 0 !important;
}

.s-vis-no-border {
  border: 0 !important;
}

.s-vis-no-border-radius {
  border-radius: 0 !important;
}

.s-vis-no-shadow {
  box-shadow: none !important;
}

.s-vis-no-outline {
  outline: 0 !important;
}

.s-vis-hide {
  display: none !important;
}

.s-vis-invisible {
  visibility: hidden !important;
}

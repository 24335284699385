.s-chat-popup {
  &__head {
    background: rgba(48, 69, 167, 0.6);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 0 0.7rem 1.3rem;

    .sn-modal__close {
      color: white;
      position: relative;

      &:hover {
        background-color: unset;
      }
    }
  }
}

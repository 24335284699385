.sn-textarea {
  &.sn-field--readonly .sn-field__native {
    pointer-events: auto;
  }

  .sn-field__control {
    min-height: 4rem;
    height: auto;
  }

  .sn-field__native {
    resize: vertical;
    min-height: 4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  &--autogrow {
    .sn-field__native {
      resize: none;
    }
  }
}

.mobile .sn-textarea,
.sn-textarea.disabled {
  .sn-field__native {
    resize: none;
  }
}

.sn-linear-progress {
  @include pos();
  width: 100%;
  overflow: hidden;
  height: 0.4rem;
  color: $primary;

  &--reverse {
    transform: scale3d(-1, 1, 1);
  }
}

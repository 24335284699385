.sn-checkbox__check-indet {
  width: 100%;
  height: 0;
  left: 0;
  top: 50%;
  border-color: $cWhite;
  border-width: 1px;
  border-style: solid;
  transform: translate3d(0, -50%, 0) rotate3d(0, 0, 1, -280deg) scale3d(0, 0, 0);
}

.form__profile-edit {
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  .form__radio {
    display: inline-block;
    width: 20rem;
    vertical-align: middle;
  }
  .button {
    min-width: 24.6rem;
    font-family: $font_sans_sb;
  }
  .alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    li {
      font-size: 15px;
    }
  }
  .alert-dismissable,
  .alert-dismissible {
    padding-right: 35px;
  }
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
    float: right;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  .btn{
    font-family: "Open Sans Semibold",sans-serif;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    padding: 10px 20px;
    min-width: 16rem;
      &:after{
        position: absolute;
        z-index: 2;
        color: inherit;
        top: 60%;
        right: 13rem;
        margin-top: -1.1rem;
        font: 15px/1em snation;
      }
  }
  .btn ~ .button_type_save{
    min-width: 22rem;
      &:after{
        right: 19rem;
      }
  }
}
.form__string {
  .cke_reset {
    width: 100%;
  }
}
.form__profile-edit--new {
  max-width: 100em;
  margin: 0;
}
.form__select-rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.form__select-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1.2rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #eaeef0;
  &:nth-last-child(2) {
    border-bottom: 0;
  }
  .label {
    width: 25rem;
    min-width: 25rem;
    padding-right: 1.8rem;
  }
  .select2 {
    //width: auto !important;
    flex: 1 1 auto;
  }
}
.label {
  display: inline;
}
.form__select-row-add {
  width: 100%;
  text-align: right;
  margin-bottom: 2rem;
  .label {
    cursor: pointer;
    border-bottom: 1px dotted darken($c_blue, 1%);
  }
}

.form__item {
  @extend .form__string;
  &:last-of-type {
    margin-bottom: 3rem;
    text-align: left;
  }
}


@media screen and (max-width: 599px) {
  .form__select-row {
    flex-direction: column;
    align-items: flex-start;
    label, .label {
      width: 100%;
      margin-bottom: 1rem;
      padding-right: 0;
    }
    .select2 {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 479px) {
  .form__profile-edit {
    .form__radio {
      display: block;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.s-logotype {
  flex-shrink: 0;
  display: block;
  height: 4rem;

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

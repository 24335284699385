@keyframes ld {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1.1);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

.loading-progress {
  position: relative;
  opacity: 0.8;
  color: transparent !important;
  text-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    cursor: default;
    color: transparent;
    outline: none !important;
    box-shadow: none;
  }

  &::before {
    @include pos(absolute, $t: 50%, $l: 50%);
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background: transparent;
    border: 1px solid #FFFFFF;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    margin-top: -1.2rem;
    margin-left: -1.2rem;
    animation: ld 1s ease-in-out infinite;
    content: '';
  }
}

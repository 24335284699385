$bgItem: #f3f6f8;
$bItem: #c7d2d9;

.test-cont__menu-item {
  display: block;
  width: 100%;
  font: 1.6rem/1.2em $fSansSB;
  text-align: center;
  background-color: $bgItem;
  padding: 1.45rem 1.5rem;
  border: 1px solid $bItem;
  border-radius: 6px;
  margin: .75rem 0;
  cursor: pointer;
  transition: background-color .2s ease-in;

  &.not-choice {
    cursor: default;
  }

  &.is-active {
    color: $cWhite;
    background-color: $cGreen;

    &:hover {
      color: $cWhite;
      background-color: $cGreen;
    }
  }

  &:hover {
    background-color: darken($bgItem, 5%);
    transition: background-color .2s ease-in;
  }

  @media screen and (max-width: 1008px) {
    @include sflx($alitems: center, $juscont: center);
    margin-right: 1rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: 639px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.s-main-footer__item {
  display: inline-block;
  color: $cWhite;
  cursor: pointer;

  &:not( :last-child ) {
    margin-right: 3rem;
    margin-bottom: 2rem;
  }

  &:hover {
    span {
      border-bottom: 1px dotted darken($cWhite, 1%);
    }
  }

  span {
    border-bottom: 1px dotted transparent;
  }

  &--region-mobile {
    display: none;
    @media (max-width: 1008px) {
      display: block;
    }
  }

  &--region-desktop {
    @media (max-width: 1008px) {
      display: none;
    }
  }
}
.s-main-footer__item--region {
  align-self: baseline;
}

.ol-lbc__option-choice {
  @include sflx($juscont: center, $alitems: center);
  flex-shrink: 0;
  width: 15rem;
  height: 5rem;
  font: 1.4rem $fDef;
  color: #3D4651;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #ccc;
  border-left: 0;
  transition: background-color $gDuration ease-in;

  &.is-active {
    color: $cWhite;
    background-color: $cGreen;
    border-color: $cGreen;
  }
}

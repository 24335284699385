[data-content-hide] {
  @include pos();
  margin-bottom: 5rem;
  overflow: hidden;
  transition: height .2s ease-in;

  &.inactive {
    &::after {
      display: none;
    }

    + .content-hide-button {
      display: none;
    }
  }

  &::after {
    @include pos(absolute, 2, $b: 0rem, $l: 0);
    display: block;
    width: 100%;
    height: 4rem;
    background: linear-gradient(to top, $cWhite 0%, $cWhite 55%, transparent 90%);
    opacity: 1;
    transition: opacity .2s ease-in;
    content: '';
  }
}

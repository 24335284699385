.s-main-header_contacts {
  display: flex;
  align-items: baseline;
  color: #FFFFFF;
  padding-right: 1rem;
}

.s-main-header_contacts__link {
  color: #FFFFFF;
  display: flex;
  align-items: baseline;
}
.s-main-header_contacts__divider {
  margin-left: 2rem;
  padding-right: 2rem;
  min-height: 28px;
  align-self: center;
  border-left: 1px solid;
}

.s-main-header_contacts__icon {
  padding-right: 1rem;
  font-size: 20px;
}

.s-main-header_contacts_contact {
  display: flex;
  flex-direction: column;
  line-height: 27px;
}

.s-main-header_contacts__number {
  font-size: 20px;
}
.s-main-header_contacts__label {
  margin-top: -0.5rem;
  font-size: 13px;
  font-weight: 300;
}

.s-main-header_contacts__new-label {
  margin-left: 2rem;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  font-weight: 600;
  padding: 0.5rem 1rem 0.5rem 0;;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.s-main-header_contacts__new-label__triangle {
  color: transparent;
  content: "0";
  width: 10px;
  height: 19px;
  background-color: #F06C0D;
  clip-path: polygon(5% 40%, 50% 0, 100% 0, 100% 100%, 50% 100%, 5% 60%, 0 50%);
}

.s-main-header_contacts__new-label__text {
  background-color: #F06C0D;
  font-weight: 600;
  height: 19px;
  width: 50px;
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
.s-main-header_contacts__new-label {
  display: none;
}








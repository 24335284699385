.s-region-list {
  @include sflx($dir: column);
  width: 100%;
  overflow-y: auto;

  .s-region-list {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.s-vue-modal__header {
  @include pos();
  @include sflx($juscont: space-between, $alitems: stretch);
  width: 100%;
  min-height: 4rem;
  color: $cWhite;
  background-color: $cBlue;
  padding: 0.8rem 5.5rem 0.8rem 2rem;
  border-top-left-radius: $gBorderRadius;
  border-top-right-radius: $gBorderRadius;
  user-select: none;
}

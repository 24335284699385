.tof-control-wrapper {
  position: absolute !important;
  top: 50%;
  margin-top: -1.5rem;
  right: 2rem;
  display: flex;
  width: 7.5rem;

  > * {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}


.sn-time__clock-pos-0 {
  top: 0%;
  left: 50% /* rtl:ignore */;
}

.sn-time__clock-pos-1 {
  top: 6.7%;
  left: 75% /* rtl:ignore */;
}

.sn-time__clock-pos-2 {
  top: 25%;
  left: 93.3% /* rtl:ignore */;
}

.sn-time__clock-pos-3 {
  top: 50%;
  left: 100% /* rtl:ignore */;
}

.sn-time__clock-pos-4 {
  top: 75%;
  left: 93.3% /* rtl:ignore */;
}

.sn-time__clock-pos-5 {
  top: 93.3%;
  left: 75% /* rtl:ignore */;
}

.sn-time__clock-pos-6 {
  top: 100%;
  left: 50% /* rtl:ignore */;
}

.sn-time__clock-pos-7 {
  top: 93.3%;
  left: 25% /* rtl:ignore */;
}

.sn-time__clock-pos-8 {
  top: 75%;
  left: 6.7% /* rtl:ignore */;
}

.sn-time__clock-pos-9 {
  top: 50%;
  left: 0% /* rtl:ignore */;
}

.sn-time__clock-pos-10 {
  top: 25%;
  left: 6.7% /* rtl:ignore */;
}

.sn-time__clock-pos-11 {
  top: 6.7%;
  left: 25% /* rtl:ignore */;
}

.sn-time__clock-pos-1.fmt24 {
  top: 6.7%;
  left: 75% /* rtl:ignore */;
}

.sn-time__clock-pos-2.fmt24 {
  top: 25%;
  left: 93.3% /* rtl:ignore */;
}

.sn-time__clock-pos-3.fmt24 {
  top: 50%;
  left: 100% /* rtl:ignore */;
}

.sn-time__clock-pos-4.fmt24 {
  top: 75%;
  left: 93.3% /* rtl:ignore */;
}

.sn-time__clock-pos-5.fmt24 {
  top: 93.3%;
  left: 75% /* rtl:ignore */;
}

.sn-time__clock-pos-6.fmt24 {
  top: 100%;
  left: 50% /* rtl:ignore */;
}

.sn-time__clock-pos-7.fmt24 {
  top: 93.3%;
  left: 25% /* rtl:ignore */;
}

.sn-time__clock-pos-8.fmt24 {
  top: 75%;
  left: 6.7% /* rtl:ignore */;
}

.sn-time__clock-pos-9.fmt24 {
  top: 50%;
  left: 0% /* rtl:ignore */;
}

.sn-time__clock-pos-10.fmt24 {
  top: 25%;
  left: 6.7% /* rtl:ignore */;
}

.sn-time__clock-pos-11.fmt24 {
  top: 6.7%;
  left: 25% /* rtl:ignore */;
}

.sn-time__clock-pos-12.fmt24 {
  top: 15%;
  left: 50% /* rtl:ignore */;
}

.sn-time__clock-pos-13.fmt24 {
  top: 19.69%;
  left: 67.5% /* rtl:ignore */;
}

.sn-time__clock-pos-14.fmt24 {
  top: 32.5%;
  left: 80.31% /* rtl:ignore */;
}

.sn-time__clock-pos-15.fmt24 {
  top: 50%;
  left: 85% /* rtl:ignore */;
}

.sn-time__clock-pos-16.fmt24 {
  top: 67.5%;
  left: 80.31% /* rtl:ignore */;
}

.sn-time__clock-pos-17.fmt24 {
  top: 80.31%;
  left: 67.5% /* rtl:ignore */;
}

.sn-time__clock-pos-18.fmt24 {
  top: 85%;
  left: 50% /* rtl:ignore */;
}

.sn-time__clock-pos-19.fmt24 {
  top: 80.31%;
  left: 32.5% /* rtl:ignore */;
}

.sn-time__clock-pos-20.fmt24 {
  top: 67.5%;
  left: 19.69% /* rtl:ignore */;
}

.sn-time__clock-pos-21.fmt24 {
  top: 50%;
  left: 15% /* rtl:ignore */;
}

.sn-time__clock-pos-22.fmt24 {
  top: 32.5%;
  left: 19.69% /* rtl:ignore */;
}

.sn-time__clock-pos-23.fmt24 {
  top: 19.69%;
  left: 32.5% /* rtl:ignore */;
}

.sn-time__clock-pos-0.fmt24 {
  top: 0%;
  left: 50% /* rtl:ignore */;
}

.statistic-graph-wrapper {
  @include pos();
  display: block;
  width: 52.5rem;
  height: 46.6rem;
  margin: 0 auto;

  &::before {
    @include pos(absolute, $b: 2.2rem, $l: 10.3rem);
    display: block;
    width: 30.4rem;
    height: 30.4rem;
    border: 5px solid #ffffff;
    border-radius: 50%;
    content: '';
  }


  @media screen and (max-width: 999px) {
    @include sflx($dir: column, $alitems: center);
    width: 100%;
    height: unset;

    &::before {
      display: none;
    }

    .statistic-graph {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }
}

.statistic-graph-wrapper--school {
  @extend .statistic-graph-wrapper;

  &::before {
    border: 1px solid $cBlue;
  }
}
.interest-block__mini-block-overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: rgba(54, 76, 167, 0.6);
  padding-top: 2rem;
  opacity: 0;
  transition: opacity 0.2s ease-in 0.1s;
}
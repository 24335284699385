@media screen and ( max-width: 599px ) {
  .s-uploader {
    width: 100%;
    min-width: 28rem;
    max-width: 32rem;
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }

  .s-uploader__content {
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .s-uploader__download {
    margin-top: 1.5rem;
    margin-left: unset;
  }
}

.vseobuch__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $c_black;
  padding-bottom: 1rem;
}

.vseobuch__title {
  font: 3.2rem/1.2em $font_sans_l;
  text-align: left;
  text-transform: uppercase;
  padding-right: 2rem;
  margin-bottom: 2rem;
}

.vseobuch__vac-count-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: $font_sans_sb;
  margin-bottom: 2rem;
}

.vac-count__label {
  width: 18rem;
  font-size: 1.5rem;
  line-height: 1.2em;
  text-align: right;
}

.vac-count__num {
  font-size: 2.4rem;
  line-height: 1.2em;
  padding-right: 2rem;
  padding-left: 2rem;
}


@media screen and (max-width: 767px) {
  .vseobuch__header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .vac-count__label {
    text-align: left;
    width: auto;
  }
}

@media screen and (max-width: 479px) {
  .vseobuch__title {
    font-size: 2.4rem;
  }
}
.s-form__textarea {
  @extend %input;
  padding-top: 1rem;
  min-height: 10rem;
  max-height: 25rem;
  max-width: 100%;
  resize: vertical;

  &:focus {
    outline: transparent;
    border-color: $cBlue;
  }

  &--autogrow {
    overflow: hidden;
    min-height: 4rem;
    max-height: unset;
    padding-bottom: 1rem;
    resize: none;
  }
}

.sn {
  &--row,
  &--column,
  &--flex {
    display: flex;
    flex-wrap: wrap;
  }
  &--flex,
  &--row {
    > .sn--col {
      width: auto;
      min-width: 0;
      max-width: 100%;
    }
  }
  &--flex,
  &--column {
    > .sn--col {
      height: auto;
      min-height: 0;
      max-height: 100%;
    }
  }
  &--row.sn--inline,
  &--column.sn--inline,
  &--flex.sn--inline {
    display: inline-flex;
  }
  &--row.sn--reverse {
    flex-direction: row-reverse;
  }
  &--column {
    flex-direction: column;
  }
  &--column.sn--reverse {
    flex-direction: column-reverse;
  }
  &--wrap {
    flex-wrap: wrap;
  }
  &--no-wrap {
    flex-wrap: nowrap;
  }
  &--reverse-wrap {
    flex-wrap: wrap-reverse;
  }
  &--order-first {
    order: -10000;
  }
  &--order-last {
    order: 10000;
  }
  &--order-none {
    order: 0;
  }
  &--justify-start {
    justify-content: flex-start;
  }
  &--justify-end {
    justify-content: flex-end;
  }
  &--justify-center,
  &--flex-center {
    justify-content: center;
  }
  &--justify-between {
    justify-content: space-between;
  }
  &--justify-around {
    justify-content: space-around;
  }
  &--items-start {
    align-items: flex-start;
  }
  &--items-end {
    align-items: flex-end;
  }
  &--items-center,
  &--flex-center {
    align-items: center;
  }
  &--items-baseline {
    align-items: baseline;
  }
  &--items-stretch {
    align-items: stretch;
  }
  &--content-start {
    align-content: flex-start;
  }
  &--content-end {
    align-content: flex-end;
  }
  &--content-center {
    align-content: center;
  }
  &--content-stretch {
    align-content: stretch;
  }
  &--content-between {
    align-content: space-between;
  }
  &--content-around {
    align-content: space-around;
  }
  &--self-start {
    align-self: flex-start;
  }
  &--self-end {
    align-self: flex-end;
  }
  &--self-center {
    align-self: center;
  }
  &--self-baseline {
    align-self: baseline;
  }
  &--self-stretch {
    align-self: stretch;
  }
  &--cursor-pointer {
    cursor: pointer !important;
  }
  &--col {
    flex: 10000 1 0;
  }
  &--col-auto {
    flex: 0 0 auto;
  }
  &--text-center {
    text-align: center;
  }
}

.iframe-youtube {
  @include pos();
  display: inline-block;
  width: 100% !important;
  max-width: 64rem;

  &::before {
    display: table;
    margin-top: 360 / 640 * 100%;
    content: '';
  }

  iframe {
    @include pos(absolute, $t: 0, $l: 0);
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

%tbl-cont__btn {
  background-color: transparent;
  border: 1px solid #dfe2ed;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:hover {
    transition: background-color .2s ease-in;
  }
}
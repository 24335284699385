.child-profile__spacer {
  display: block;
  width: 100%;
  height: 5rem;
}
@media screen and (max-width: 768px) {
  .child-profile__spacer {
    height: 2.5rem;
  }
}

.vacancy-position {
  width: 100%;
  display: block;
  box-shadow: 0 0 10px rgba(#687C8F, 0.28);
  background-color: $c_white;
  border-radius: 6px;
  border: 1px solid #D1D8DC;
  margin-bottom: 3rem;
}

.vacancy-position__head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #D1D8DC;
  padding: 2rem 2.5rem;
  flex-direction: column;
}

.vacancy-position__name,
.vacancy-position__date {
  font: 1.8rem/1.2em $font_default;
  color: $c_black;
}

.vacancy-position__body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 5rem 2rem;
}

.vacancy-cell {
  flex: 1;
  display: block;
  //display: flex;
  //flex-direction: column;
  //justify-content: space-between;
  //align-items: center;
  //margin-bottom: 8rem;
  position: relative;

  &:last-child {
    .vacancy-cell-table {
      border-right: 0;
    }

    .vacancy-cell__indicator {
      &::before {
        left: auto;
        right: 50%;
      }

      &::after {
        display: none;
      }
    }
  }

  &:first-child {
    .vacancy-cell__indicator {
      &::before {
        left: 50%;
      }
    }
  }

  &.__step {
    .vacancy-cell__indicator {
      &::after {
        content: url(../images/triangle.png);
        width: auto;
      }
    }
  }

  &.__wait {
    .vacancy-cell__indicator {
      &::before {
        background-color: #B1C0C9;
      }

      span {
        background-color: #B1C0C9;

        &::after {
          content: '\E66E';
        }
      }
    }

    .vacancy-cell-table {
      color: #8A9DA8;
    }
  }

  &.__process {
    &.__step {
      .vacancy-cell__indicator {
        &::after {
          content: url(../images/triangle_orange.png);
        }
      }
    }

    .vacancy-cell__indicator {
      &::before {
        background-color: $c_orange;
      }

      span {
        background-color: $c_orange;

        &::after {
          content: '\E619';
        }
      }

      .__inform {
        color: $c_orange;
      }
    }
  }

  &.__done {
    .vacancy-cell__indicator {
      &::before {
        background-color: $c_green;
      }

      span {
        background-color: $c_green;

        &::after {
          content: '\E64C';
        }
      }
    }
  }

  &.__reject {
    .vacancy-cell__indicator {
      &::before {
        background-color: #FF7F7F;
      }

      span {
        background-color: #FF7F7F;

        &::after {
          content: '\E646';
        }
      }

      .__inform {
        color: #FF7F7F;
      }
    }
  }
}

.vacancy-cell__indicator {
  width: 100%;
  text-align: center;
  padding: 3.5rem 1rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 6rem;
    left: 0;
    width: 100%;
    height: 2rem;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    top: 6rem;
    right: 0;
    height: 2rem;
    width: 0.3rem;
    background-color: $c_white;
  }

  .__inform {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    font: 1.8rem/1.2em $font_sans_sb;
    color: $c_green;
    text-align: center;
  }

  span {
    position: relative;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 3px solid $c_white;
    font: 3rem/1em $font_themify;
    color: $c_white;
  }
}

.vacancy-cell__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.vacancy-cell-table {
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #D1D8DC;
  color: $c_black;

  .s-btn {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.vacancy-cell__title {
  width: 100%;
  min-height: 14rem;
  font: 1.8rem/1.2em $font_default;
  text-align: center;
  padding: 0 2rem 1rem;
  border-bottom: 1px solid #D1D8DC;

  .__step-name {
    font-weight: bold;
  }
}

.vacancy-cell__text {
  width: 100%;
  padding: 2rem;
  font: 1.6rem/1.2em $font_default;
  text-align: center;
}

.vacancy-cell__message {
  @extend .vacancy-cell__text;
  background-color: #FF7F7F;
  color: $c_white;
  width: 100%;
  max-width: 24rem;
  margin: 0 auto;
  border-radius: 6px;
}

.vacancy-cell__bottom {
  //position: absolute;
  //z-index: 2;
  //bottom: -5.5rem;
  //top: 100%;
  //left: 50%;
  //transform: translateX(-50%);
  width: 100%;
  text-align: center;
  margin-top: auto;

  .button {
    font-family: $font_sans_sb;
    margin-top: 1.5rem;
    width: 100%;
    max-width: 24rem;
  }
}


@media screen and ( max-width: 999px ) {
  .vacancy-position__body {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }

  .vacancy-cell {
    width: 100%;
    flex: none;

    &:first-child {
      .vacancy-cell__indicator {
        &::before {
          left: 2rem;
          top: 50%;
        }
      }
    }

    &:last-child {
      .vacancy-cell__indicator {
        &::before {
          left: 2rem;
          right: auto;
          top: auto;
          bottom: 50%;
        }
      }
    }
  }

  .vacancy-cell__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 10rem;
    position: relative;
  }

  .vacancy-cell-table {
    border-right: 0;
    align-items: flex-start;
  }

  .vacancy-cell__title {
    min-height: unset;
    text-align: left;
    padding-bottom: 1rem;

    .__step {
      display: inline-block;
    }

    .__step-name {
      display: inline-block;
    }
  }

  .vacancy-cell__text {
    text-align: left;
    padding: 1rem 0;
  }

  .vacancy-cell__message {
    display: inline-block;
    max-width: none;
    width: auto;
    padding: 1rem;
    margin: 0;
  }

  .vacancy-cell__indicator {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 6rem;
    height: 100%;
    padding: 0;

    &::before {
      top: 0;
      left: 2rem;
      height: 100%;
      width: 2rem;
    }

    &::after {
      top: auto;
      left: 2rem;
      bottom: 0;
      width: 2rem;
      height: 0.3rem;
    }

    .__inform {
      display: none;
    }

    span {
      position: absolute;
      top: 50%;
      margin-top: -3rem;
      left: 0;
      width: 6rem;
      height: 6rem;
    }
  }

  .vacancy-cell__bottom {
    text-align: left;

    .button {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
}
@media screen and ( max-width: 599px ) {
  .vacancy-position {
    border: 0;
    box-shadow: none;
  }

  .vacancy-position__head,
  .vacancy-position__body {
    padding-right: 0;
    padding-left: 0;
  }

  .vacancy-position__head {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 1rem;
  }

  .vacancy-position__date,
  .vacancy-position__name {
    font-size: 1.6rem;
  }

  .vacancy-position__name {
    margin-bottom: 0.5rem;
  }
}
@media screen and ( max-width: 479px ) {
  .vacancy-cell {
    &:first-child {
      .vacancy-cell__indicator {
        &::before {
          left: 1.5rem;
        }
      }
    }

    &:last-child {
      .vacancy-cell__indicator {
        &::before {
          left: 1.5rem;
        }
      }
    }
  }

  .vacancy-position__head {
    padding-top: 0;
  }

  .vacancy-position__body {
    padding: 1rem 0 0;
  }

  .vacancy-cell__wrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5rem;
  }

  .vacancy-cell__indicator {
    width: 4rem;

    &::before {
      left: 1.5rem;
      width: 1rem;
    }

    &::after {
      left: 1.5rem;
      width: 1rem;
    }

    span {
      margin-top: -2rem;
      width: 4rem;
      height: 4rem;
      font-size: 2rem;
    }
  }

  .vacancy-cell__title {
    font-size: 1.4rem;
  }

  .vacancy-cell__text {
    font-size: 1.4rem;
  }

  .vacancy-cell__bottom {
    .button {
      font-size: 1.5rem;
      padding: 1.5rem;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

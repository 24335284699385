.kindergarten-catalog-wrapper {
  @include sflx($wrap: wrap);
  width: 100%;
  padding: 3rem 0;

  .kindergarten-block {
    width: 48.5%;
    margin-right: 3%;
    margin-bottom: 4rem;
    padding: 1.5rem;

    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }


  @media screen and (max-width: 959px) {
    flex-direction: column;
    align-items: center;

    .kindergarten-block {
      width: 100%;
      max-width: 60rem;
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  @media screen and (max-width: 479px) {
    .kindergarten-block {
      max-width: 32rem;
    }
  }
}
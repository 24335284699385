.s-phone {
  flex-shrink: 0;
  font-weight: 300;

  strong {
    font-weight: 700;
  }

  .s-str-ico {
    padding-left: 1.2em;

    &::before {
      font-size: 1em;
      transform: translateY(-45%);
    }
  }
}

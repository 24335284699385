.s-chat-popup {
  position: fixed;
  right: 2rem;
  bottom: 7rem;
  z-index: 1;

  &__buttons-wrapper {
    display: flex;

    > .sn-btn {
      font-weight: 700;
      background-color: #7b90c6;
      box-shadow: 0 0 10px 1px #000000bd;
    }

    .sn-btn--round {
      min-width: 5.5rem;
      min-height: 5.5rem;
      width: unset;
      height: unset;
      position: relative;

      .s-ico-fa {
        font-size: 2em;
      }
    }
  }
}

.s-circular-progress-bar {
  &__gradient-circle {
    z-index: 2;
    stroke-dasharray: 1256.64, 1256.64;
    stroke-dashoffset: 1256.64;
    stroke-linecap: butt;
    transform-origin: center;
    transform: rotate(-90deg);
  }
}

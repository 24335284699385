.tof-body__bottom {
  td {
    font-weight: 700;
    font-size: 1.6rem;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid $cBlack;
    background-color: #fef3d8 !important;

    &:nth-child(1),
    &:nth-child(2) {
      text-align: left;
    }
  }
}

.header {
  @include pos($z: 3);
  @include sflx($juscont: space-between, $alitems: center);
  flex-shrink: 0;
  width: 100%;
  //background-color: $cWhite;
  @include sbg($bgHeader, "../images/inner_header_bg.jpg", $s: auto 100%);
  box-shadow: 0 2px 8px rgba($cBlack, .4);

  @media screen and (max-width: 1199px) {
    display: none;
  }

}

.header_page_home {
  @include pos(absolute, 9, 0, 0);
  background: none;
  box-shadow: none;
}

.header_mrgn_r {
  margin-right: 3rem;
}

//TODO Переписать при переносе
.header_button {
  min-width: 10.2rem;
  padding: 1.05rem 2rem;
}

//TODO Переписать при переносе
.header_button_border {
  @extend .header_button;
  border: 1px solid rgba($cWhite, 0);
  border-radius: 6px;
  transition: border-color .2s ease-in;

  &:hover {
    border-color: rgba($cWhite, 1);
    transition: border-color .2s ease-in;
  }

}

@include select-drop-down("header");

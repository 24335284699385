.interest-block {
  @include sflx($alitems: stretch);
  border-radius: $gBorderRadius;
  box-shadow: 0 0 16px rgba(54, 76, 167, 0.59);
  overflow: hidden;
  transition: box-shadow .2s ease-in;
  will-change: contents;

  &:hover {
    box-shadow: 0 0 46px rgba(54, 76, 167, 0.59);
    transition: box-shadow .2s ease-in;

    .interest-block__description {
      background-size: 120% 120%;
      transition: all $animateSpeed ease-in;
    }

    .interest-block__desc-title {
      transform: translateY(-4rem);
      transition: transform $animateSpeed ease-in;
    }

    .interest-block__desc-link {
      transform: translateY(-1.5rem);
      opacity: 1;
      transition: all $animateSpeed ease-in;
    }
  }
}

.interest-block_t_first {
  &:hover {
    .interest-block__image-float {
      transform: translate(0.7rem, -0.7rem);
      transition: transform $animateSpeed ease-in;
    }

    .interest-block__image-float-mini {
      transform: translate(1.5rem, -1.5rem);
      transition: all $animateSpeed ease-in;
    }
  }

  .interest-block__image-float {
    bottom: -5rem;
    left: -2.5rem;
    width: 27rem;
    height: 27rem;
  }

  .interest-block__image-float-mini {
    top: -11rem;
    left: -2rem;
    width: 27rem;
    height: 27rem;
  }
}

.interest-block_t_second {
  &:hover {
    .interest-block__image {
      background-size: 105% 105%;
      transition: all $animateSpeed ease-in;
    }

    .interest-block__image-float-mini {
      transform: translate(1.5rem, 1.5rem);
      transition: all $animateSpeed ease-in;
    }
  }

  .interest-block__image {
    background-position: left bottom;
    background-size: 100% 100%;
    transition: all $animateSpeed ease-in;
  }

  .interest-block__image-float-mini {
    top: -5rem;
    left: 0;
    width: 27rem;
    height: 27rem;
  }
}

.interest-block_t_third {
  &:hover {
    .interest-block__image {
      background-size: 105% 105%;
      transition: all $animateSpeed ease-in;
    }

    .interest-block__image-float-mini {
      transform: translate(1.5rem, -1.5rem);
      transition: all $animateSpeed ease-in;
    }
  }

  .interest-block__image {
    background-position: center bottom;
    background-size: 100% 100%;
    transition: all $animateSpeed ease-in;
  }

  .interest-block__image-float-mini {
    bottom: -1rem;
    left: 0;
    width: 27rem;
    height: 27rem;
  }
}
.commission-result-block {
  @extend .block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
}
.commission-result-block__name,
.commission-result-block__place strong,
.commission-result-block__date strong {
  font-family: $font_sans_sb;
}
.commission-result-block__name,
.commission-result-block__place {
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}
.commission-result-block__date {
  display: block;
  width: 100%;
}
.commission-result-block__no-result {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font_sans_sb;
  color: $c_grey;
}
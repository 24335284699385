.sn-timeline__dot-img {
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  height: 31px;
  width: 31px;
  background: currentColor;
  border-radius: 50%;
}

.tbl_thm_kindergarten-groups {
  td {
    font-weight: 600;
  }
}

.kindergarten-groups__spoiler {
  @include sflx($d: inline-flex, $alitems: center, $juscont: center);
  width: 5rem;
  height: 5rem;
  font-size: 1.8rem;
  color: #3045a7;
  font-family: $fIcons;
  cursor: pointer;

  &::after {
    content: '\E64B';
  }
}
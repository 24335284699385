.step-block__status-icon {
  @include sflx($alitems: center, $juscont: center);
  flex-shrink: 0;
  width: 7rem;
  height: 7rem;
  font-family: $fIcons;
  font-size: 3rem;
  color: $cWhite;
  background-color: #dfe2ed;
  border-radius: 50%;
  margin: 3.5rem auto;

  &::after {
    content: '\E66E';
  }
}
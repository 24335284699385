.s-notify-count-block {
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  color: $cWhite;
  background-color: $cOrangeDark;
  padding: 0.2rem 0.5rem;
  border-radius: $gBorderRadius;
  margin-left: 1rem;
}

.s-test-process__navigation-forward {
  @extend %s-test-process__navigation-buttons;
  margin-right: 3rem;

  &::before {
    right: -3rem;
    content: map_get($listOfIcons, 'angle-right');
  }

  @media screen and ( max-width: 1264px ) {
    margin-right: unset;
  }
}

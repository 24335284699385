.sn-list {
  &--padding {
    padding: 8px 0;

    .sn-item__label {
      &--header {
        padding-top: 8px;
      }
    }
  }

  &--bordered {
    border: 1px solid rgba(0, 0, 0, .12);
  }

  &--separator {
    >.sn-item-type {
      + {
        .sn-item-type {
          border-top: 1px solid rgba(0, 0, 0, .12);
        }
      }
    }
  }

  &--dark {
    color: #fff;
    border-color: rgba(255, 255, 255, .48);

    .sn-item__label {
      &--header {
        color: rgba(255,255,255,0.64);
      }

      &--overline {
        color: rgba(255,255,255,0.8);
      }

      &--caption {
        color: rgba(255,255,255,0.8);
      }
    }

    .sn-item__section {
      &--side {
        &:not( .sn-item__section--avatar ) {
          color: rgba(255,255,255,0.7);
        }
      }
    }

    &.sn-list {
      &--separator {
        > .sn-item-type {
          + .sn-item-type {
            border-top-color: rgba(255, 255, 255, .48);
          }
        }
      }
    }
  }
}

$bgAnswerItemSymbol: #f3f6f8;
$bAnswerItem: #d4dbde;
$sAnswerItemActive: #18242b;

.answer-block__item {
  @include pos();
  @include sflx($alitems: center);
  width: 100%;
  min-height: 5rem;
  padding: 1rem 2rem 1rem 7rem;
  border-top: 1px solid $bAnswerItem;
  border-left: 1px solid $bAnswerItem;
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid $bAnswerItem;
  }

  &::before {
    @include pos(absolute, 2, 0, 0);
    @include sflx($alitems: center, $juscont: center);
    width: 5rem;
    height: 100%;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $bgAnswerItemSymbol;
    border-right: 1px solid $bAnswerItem;
    content: attr(data-answer-var);
    transition: background-color .2s ease-in;
  }

  &::after {
    @include pos(absolute, 1, 0, 0);
    display: block;
    width: 0;
    height: 100%;
    background-color: $bgAnswerItemSymbol;
    content: "";
    transition: width .2s ease-in;
  }

  &.is-active {
    background-color: $cWhite;
    box-shadow: 0 0 21px 7px rgba($sAnswerItemActive, .07);

    &::before {
      color: $cWhite;
      background-color: $cGreen;
    }

    &:hover {
      &::before {
        background-color: $cGreen;
      }

      &::after {
        width: 0;
      }
    }
  }

  &:hover {
    &::before {
      background-color: darken($bgAnswerItemSymbol, 5%);
      transition: background-color .2s ease-in;
    }

    &::after {
      width: 100%;
      transition: width .2s ease-in;
    }
  }

  span {
    @include pos($z: 2);
    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 639px) {
  .answer-block__item {
    padding-left: 5.5rem;

    &::before {
      width: 4rem;
    }
  }
}

.statistic-graph {
  @include pos(absolute, 3);


  @media screen and (max-width: 999px) {
    position: static;
    @include sflx($alitems: center);
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
  }
}

.statistic-graph_first {
  top: 0;
  left: 14.8rem;

  .statistic-graph__icon {
    background-image: url(../images/balabaqsha/home_statistic_1.png);
  }

  .statistic-graph__desc {
    bottom: 5.1rem;
    left: -17.7rem;
    padding-right: 5rem;
  }
}

.statistic-graph_second {
  bottom: 0;
  left: 0;

  .statistic-graph__icon {
    background-image: url(../images/balabaqsha/home_statistic_2.png);
  }

  .statistic-graph__desc {
    left: -20.5rem;
    bottom: 4.5rem;
    padding-right: 5rem;
  }
}

.statistic-graph_third {
  bottom: 0;
  right: 0;

  .statistic-graph__icon {
    background-image: url(../images/balabaqsha/home_statistic_3.png);
  }

  .statistic-graph__desc {
    right: -21rem;
    bottom: 4.4rem;
    padding-left: 4rem;
  }
}

.statistic-graph_first--school {
  @extend .statistic-graph_first;

  .statistic-graph__icon {
    background-image: url(../images/school/infographic_01_w.svg);
  }
}

.statistic-graph_second--school {
  @extend .statistic-graph_second;

  .statistic-graph__icon {
    background-image: url(../images/school/infographic_02_w.svg);
  }
}

.statistic-graph_third--school {
  @extend .statistic-graph_third;

  .statistic-graph__icon {
    background-image: url(../images/school/infographic_03_w.svg);
  }
}
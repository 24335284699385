.s-year-month-picker--popup {
  &-wrapper {
    max-width: 25rem !important;
    width: 100%;

    > div {
      padding: 1.5rem;
    }
  }

  &-icon {
    cursor: pointer;
    transition: background-color 0.3s linear, color 0.3s linear;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #364CA7FF;
      background-color: white;
    }
  }

  &-year {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #364CA7FF;
    color: white;

    span {
      font-weight: 600;
      font-size: 2rem;
    }
  }

  &-month {
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s linear;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    border-radius: 14%;

    &--active {
      background-color: #364CA7FF;
      font-weight: 600;
      color: white;
    }

    &:not(&--active) {
      &:hover {
        background-color: rgba(54, 70, 161, 0.1);
      }
    }
  }

  &-months {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

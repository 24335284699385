.header__telephone {
  @include pos();
  display: block;
  font-size: 2.2rem;
  line-height: 1em;
  color: $cWhite;
  margin-bottom: 1rem;
  margin-left: 7rem;

  &::before {
    @include pos(absolute, 2, .4rem, -2.5rem);
    width: 15px;
    height: 15px;
    @include sbg($s: contain);
    //background-image: url(../images/phone.png);
    content: "";
  }

  span {
    font-family: $fSansL;
    margin-right: .5rem;
  }

  strong {
    font-family: $fDef;
  }
}

.device-telephone__wrapper {
  display: none;
}


@media screen and (max-width: 767px) {
  .header__telephone {
    font-size: 1.8rem;
    margin-bottom: 0;
    margin-left: 5rem;
  }
}

@media screen and (max-width: 479px) {
  .header__telephone {
    display: none;

    &::before {
      top: .2rem;
    }
  }
  .device-telephone__wrapper {
    order: 3;
    display: block;
    width: 100%;
    padding: 1.5rem 2rem;
    text-align: center;

    .header__telephone {
      display: inline-block;
      margin-left: 0;
    }
  }
}

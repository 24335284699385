.s-bilim-marketing__text{
  font-style: normal;
  color: #3045A7;
}

.s-bilim-marketing__textHead{
  font-weight: bold;
  font-size: 42px;
  line-height: 51px;
}
.s-bilim-marketing__textParagraph{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

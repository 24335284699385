.s-photo-album__mini-item {
  @include pos();
  @include sbg($c: $cGrey, $s: cover);
  @include sflx($d: inline-flex, $juscont: center, $alitems: center);
  border-radius: $gBorderRadius;
  cursor: pointer;
  transition: box-shadow $gDuration ease-in;

  &::before {
    display: table;
    margin-top: (1920 / 1.78) / 1920 * 100%;
    content: '';
  }

  &::after {
    @include pos(absolute, 9, 0, 0, 0, 0);
    transition: background-color $gDuration ease-in;
    content: '';
  }

  &.is-active {
    &:not( .is-loading ) {
      box-shadow: inset 0.2rem 0.2rem 0.5rem rgba($cBlack, 0.6),
        inset -0.2rem -0.2rem 0.5rem rgba($cBlack, 0.6);
    }

    &::after {
      background-color: rgba($cWhite, 0.4);
      cursor: default;
    }
  }
}

.profile-home-wrapper {
  display: table;
  font-size: 0;
  width: 100%;
  .prof-home-block {
    display: inline-block;
    vertical-align: top;
    width: 625 / 1280 * 100%;
    margin-right: 30 / 1280 * 100%;
    margin-bottom: 3rem;
    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}
.flex-wrap {
  .profile-home-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
  }
}
$listOfBtnThemes: (
    ('white', $cWhite, $cBlack),
    ('black', $cBlack, $cWhite),
    ('blue', $cBlue, $cWhite),
    ('blue-dark', $cBlueDark, $cWhite),
    ('aqua', $cAqua, $cWhite),
    ('green', $cGreen, $cWhite),
    ('grey', $cGrey, $cWhite),
    ('red', $cRed, $cWhite),
    ('red-mute', $cRedMute, $cWhite),
    ('coral', $cCoral, $cWhite),
    ('light-purple', $cLightPurple, $cWhite),
    ('orange', $cOrange, $cWhite),
    ('yellow-as-alert', $cAlertYellow, $cBlack),
    ('bb-yellow', $cBbYellow, $cWhite),
    ('lighter-blue', $cLighterBlue, $cBlue),
);

[ class*='s-btn--thm-' ] {
  transition: all $gDuration ease-in;

  &:hover {
    transition: all $gDuration ease-in;
  }
}

@each $name, $background, $color in $listOfBtnThemes {
  .s-btn--thm-#{$name} {
    color: $color;
    background-color: $background;

    &.s-btn--border {
      color: $background;
      border-color: $background;
    }

    &.s-btn--border-hover {
      color: $background;
      border-color: transparent;
    }

    &:hover,
    &.is-active {
      background-color: darken($background, 5%);

      &.s-btn--border {
        color: $color;
        border-color: darken($background, 5%);
      }

      &.s-btn--border-hover {
        color: $background;
        background-color: transparent;
        border-color: $background;
      }
    }

    @if $name == 'white' {
      &.s-btn--border {
        color: $cBlack;
        background-color: $cWhite;
        border-color: $cBorder;
        transition: all $gDuration ease-in;

        &:hover,
        &.is-active {
          background-color: $cGrey;
          color: $cWhite;
          transition: all $gDuration ease-in;
        }
      }
    }
    @if $name == 'lighter-blue' {
      &.s-btn--border {
        border-color: $color;
        color: $color;
        background-color: $background;
      }
      &:hover,
      &.is-active {
        border-color: $color;
        color: $color;
        background-color: $background;
      }
    }
  }
}

.s-btn {
  @include pos();
  display: inline-block;
  line-height: 1em;
  text-align: center;
  padding: 1.2rem 2rem;
  border-radius: $gBorderRadius;
  cursor: pointer;
  user-select: none;
  //box-shadow: 0 1px 5px rgba($cBlack, .2), 0 2px 2px rgba($cBlack, .14), 0 3px 1px -2px rgba($cBlack, .12);
  //transition: .3s cubic-bezier(.25, .8, .5, 1);

  &--border,
  &--border-hover {
    background-color: transparent;
    padding: 1.1rem 1.9rem;
    border: 1px solid;
  }

  &--border-hover {
    border-color: transparent;
  }

  &--disabled,
  &[ disabled ] {
    color: $cWhite !important;
    background-color: $cGrey !important;
    cursor: not-allowed;
  }

  &--empty {
    min-width: 4rem;
    min-height: 4rem;
  }

  &--ico {
    font-size: 2rem;
    padding: 0.9rem;
  }

  &--inline {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    .s-str-ico {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 150%;
    }
  }

  &--w-45 {
    width: 4.5rem;
  }

  &--w-100 {
    min-width: 10rem;
  }

  &--w-150 {
    min-width: 15rem;
  }

  &--w-200 {
    min-width: 20rem;
  }

  &--w-250 {
    min-width: 25rem;
  }

  &--w-280 {
    min-width: 28rem;
  }

  &--w-300 {
    min-width: 30rem;
  }

  &--wider {
    width: 100%;
  }

  &--big {
    font-size: 1.5em;
    padding: 1.8rem 3rem;
  }

  &--mw-200 {
    @include media-breakpoint-up(sm) {
      max-width: 20rem;
    }
  }

  &--mw-150 {
    @include media-breakpoint-up(sm) {
      max-width: 15rem;
    }
  }
}

@media screen and (max-width: 639px) {
  .btn-adaptive-helper {
    width: 100%
  }
}

.sn-expansion-item {
  &--standard {
    &.sn-expansion-item--expanded {
      > div > .sn-expansion-item__border {
        opacity: 1;
      }
    }
  }

  &--popup {
    transition: padding 0.5s;

    > .sn-expansion-item__container {
      border: 1px solid $separator-color;

      > .sn-separator {
        display: none;
      }
    }

    &.sn-expansion-item {
      &--collapsed {
        padding: 0 15px;
      }

      &--expanded {
        padding: 15px 0;

        & + & {
          padding-top: 0;
        }
      }
    }

    &.sn-expansion-item--collapsed:not( :first-child ) {
      > .sn-expansion-item__container {
        border-top-width: 0;
      }
    }

    &.sn-expansion-item--expanded + &.sn-expansion-item--collapsed {
      > .sn-expansion-item__container {
        border-top-width: 1px;
      }
    }
  }

  &:first-child > div > .sn-expansion-item__border--top {
    opacity: 0;
  }

  &:last-child > div > .sn-expansion-item__border--bottom {
    opacity: 0;
  }

  &--expanded + &--expanded {
    > div > .sn-expansion-item__border--top {
      opacity: 0;
    }
  }
}

.kindergarten-head-inform-wrapper {
  @include sflx();
  width: 100%;


  @media screen and (max-width: 1009px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

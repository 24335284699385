.s-table-schedule__card-overlay {
  @include pos(absolute, 1, 0, 0, 0, 0);
  @include sflx($juscont: center, $alitems: center);
  width: 100%;
  height: 100%;
  background-color: rgba($cBlack, 0.5);
  opacity: 0;
  transition: opacity $gDuration ease-in;

  > * {
    display: none;
  }
}

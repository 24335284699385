.interest-block__description {
  @include pos();
  @include sflx($dir: column);
  @include sbg($i: '../images/block_bg.png');
  background-color: $c_blue;
  width: 50%;
  height: 24rem;
  padding: 1rem;
  overflow: hidden;
  border-radius: 0 $gBorderRadius $gBorderRadius 0;
  background-size: 100% 100%;
  transition: all $animateSpeed ease-in;
  will-change: contents;
}

.sn-tabs__arrow {
  cursor: pointer;
  min-width: 3.6rem;

  &--right {
    text-align: right;
  }

  &--faded {
    opacity: 0.5;
  }
}

.sn-time__clock-pointer {
  width: 1px;
  height: 50%;
  transform-origin: top center /* rtl:ignore */;
  min-height: 0;
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  color: $primary;
  background: currentColor;
  transform: translate3d(-50%, 0, 0);

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 50%;
    background: currentColor;
    transform: translate3d(-44%, 0, 0);
  }

  &::before {
    bottom: -4px;
    width: 8px;
    height: 8px;
  }

  &::after {
    top: -3px;
    height: 6px;
    width: 6px;
  }
}

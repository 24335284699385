.s-test-process__center-value {
  flex: 1 1 auto;
  font-size: 1.8rem;
  min-height: 4rem;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
  }

  @media screen and ( max-width: 1264px ) {
    padding-left: 3rem;
  }
}

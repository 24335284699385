.prof-home-block {
  background-color: $c_white;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 6px;
  margin-bottom: 3rem;
}
.prof-home-block__head {
  padding: 1.5rem 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d8dc;
}
.prof-home-block__title {
  font: 1.8rem/1.2em $font_sans_sb;
  color: $c_black;
}
.prof-home-block__date {
  font: 1.4rem/1.2em $font_default;
  color: #7d898e;
  padding-left: 2.4rem;
  position: relative;
  &:before {
    content: '\E6B6';
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -.9rem;
    left: 0;
    display: block;
    font-family: $font_themify;
  }
}
.prof-home-block__body {
  padding: 2rem;
}
.prof-home-block__bottom {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 3rem;
  .button {
    font-family: $font_sans_sb;
  }
}
.prof-home-block__info-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  .__left {
    flex: 1 1 auto;
    ul {
      li {
        font: 1.6rem/1.2em $font_default;
        color: $c_black;
        margin-bottom: 1rem;
      }
    }
  }
  .__right {
    flex: 1 0 auto;
    width: 20.8rem;
    border-left: 1px solid #d1d8dc;
    text-align: center;
    margin-left: 2rem;
    canvas {
      display: inline-block;
      width: 16rem;
      height: 16rem;
      margin-bottom: 1.5rem;
    }
  }
}
.diagram__title {
  font: 1.6rem/1.2em $font_sans_sb;
  color: $c_black;
}
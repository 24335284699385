.s-list-of-answers__item {
  @include pos();
  display: block;
  width: 100%;
  min-height: 5rem;
  padding: 1.45rem 2rem 1.45rem 7rem;
  background-color: $cWhite;
  border-top: 1px solid $stpGrey;
  cursor: pointer;
  transition: background-color $gDuration ease-in;

  &:hover {
    background-color: lighten($stpGrey, 5%);
    transition: background-color $gDuration ease-in;
  }

  &:last-child {
    border-bottom: 1px solid $stpGrey;

    &::before {
      border-bottom: 0;
    }
  }

  &::before {
    @include pos(absolute, $t: 0, $l: 0);
    @include sflx($alitems: center, $juscont: center);
    width: 5rem;
    height: 100%;
    font-weight: 700;
    font-size: 1.8rem;
    background-color: $stpGrey;
    border-bottom: 1px solid $cWhite;
    transition: color $gDuration ease-in, background-color $gDuration ease-in;
    content: attr(data-var-answer);
  }

  &.is-selected {
    background-color: $stpGrey;
    transition: background-color $gDuration ease-in;

    &::before {
      color: $cWhite;
      background-color: $stpGreyDark;
      transition: color $gDuration ease-in, background-color $gDuration ease-in;
    }
  }

  &.is-loading {
    &::before {
      @include sbg($i: '../images/spinner.svg', $s: 120% 120%);
      color: $stpGrey;
      transition: none;
    }
  }
}

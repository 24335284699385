.sn-chip__icon {
  color: rgba(0,0,0,0.54);
  font-size: 20px;
  margin: -4px;

  &--left {
    margin-right: 6px;
  }

  &--right {
    margin-left: 6px;
  }

  &--remove {
    margin-left: 6px;
    margin-right: -6px;
    opacity: 0.6;
    transition: 0.3s;
    outline: none;

    &:hover {
      opacity: 1;
    }

    &:focus {
      opacity: 1;
    }
  }
}

.s-application-block__controls {
  @include sflx($juscont: flex-end);
  flex-shrink: 0;
  width: 21rem;
  margin-bottom: 1rem;

  > * {
    &:not( :last-child ) {
      margin-right: 1rem;
    }
  }
  &--update-btn {
    max-width: 21rem;
    min-width: 21rem;
    width: 100%;
  }
}

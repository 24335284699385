.sn-toggle {
  vertical-align: middle;

  &--dark {
    .sn-toggle__inner {
      color: $cWhite;
    }

    .sn-toggle__inner--active {
      color: $primary;
    }

    .sn-toggle__thumb:before {
      opacity: 0.32 !important;
    }
  }

  &--dense {
    .sn-toggle__inner {
      height: 20px;
      padding: 3px 12px;
    }

    .sn-toggle__thumb-container {
      top: 0;
    }
  }

  &.sn--disabled {
    opacity: 0.75 !important;
  }
}

.desktop {
  .sn-toggle__thumb {
    &::before {
      @include pos(absolute, -1, 0, 0, 0, 0);
      border-radius: 50%;
      background: currentColor;
      opacity: 0.12;
      transform: scale3d(0, 0, 0);
      transition: transform $option-focus-transition;
      content: '';
    }
  }

  .sn-toggle {
    &:focus {
      &:not( .sn--disabled ) {
        .sn-toggle__thumb {
          &::before {
            transform: scale3d(1.8, 1.8, 1.8);
          }
        }
      }
    }
  }
}

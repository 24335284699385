.sn-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  thead,
  tr,
  th,
  td {
    border-color: rgba(0, 0, 0, 0.12);
  }

  thead th {
    background-color: $cGrey;
    color: $cWhite;
  }

  thead tr,
  tbody td {
    height: 4.8rem;
  }

  tbody tr {
    &.sn--selected {
      background: rgba(0, 0, 0, 0.06);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }

    &:nth-child(even) {
      background-color: $c_body;
    }
  }

  th {
    font-weight: 600;
    font-size: 1.4rem;
    user-select: none;
    //opacity: 0.54;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    &.sn--sortable:hover,
    &.sn--sorted {
      opacity: 0.86;
    }

    &.sn--sortable {
      cursor: pointer;
    }

    &:hover .sn-table__sort-icon {
      opacity: 0.64;
    }

    &.sn--sorted .sn-table__sort-icon {
      opacity: 0.86 !important;
    }

    &.sn--sort-desc .sn-table__sort-icon {
      transform: rotate(180deg);
    }

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }

  th,
  td {
    padding: 0.7rem 1.6rem;
    background-color: inherit;
  }


  thead,
  td,
  th {
    border-style: solid;
    border-width: 0;
  }


  tbody td {
    font-size: 1.6rem;
  }

  &--col-auto-width {
    opacity: 1 !important;
    width: 0.1rem;
    padding-right: 0 !important;
  }

  &--flat {
    box-shadow: none;
  }

  &--bordered {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  &--square {
    border-radius: 0;
  }

  &--no-wrap {
    th,
    td {
      white-space: nowrap;
    }
  }


  &--grid {
    box-shadow: none;

    .sn-table__top {
      padding-bottom: 0.4rem;
    }

    .sn-table__middle {
      min-height: 0.2rem;
      margin-bottom: 0.4rem;
    }

    thead {
      &,
      th {
        border: 0 !important;
      }
    }

    .sn-table__linear-progress {
      bottom: 0;
    }

    .sn-table__bottom {
      border-top: 0;
    }
  }

  &--horizontal-separator {
    thead,
    tbody tr:not( :last-child ) td {
      border-width: 0 0 1px 0;
    }
  }

  &--vertical-separator {
    td,
    th {
      border-width: 0 0 0 1px;
    }

    thead tr:last-child th {
      border-bottom-width: 1px;
    }

    td:first-child,
    th:first-child {
      border-left: 0;
    }
  }

  &--cell-separator {
    td,
    th {
      border-width: 1px;
    }

    td:first-child,
    th:first-child {
      border-left: 0;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }

    thead tr {
      th {
        border-color: #DFE2ED;
      }
      &:first-child th {
        border-top: 0;
        border-bottom: 0;
      }
    }

    tbody {
      tr:last-child td {
        border-bottom: 0;
      }
    }
  }


  .sn-table--col-auto-width {
    border-left: 0;
    border-right: 0;

    & + td,
    & + th {
      border-left: 0;
    }
  }

  &--dense {
    .sn-table__bottom {
      min-height: 4.2rem;
    }

    .sn-table__sort-icon {
      font-size: 110%;
    }

    .sn-table {
      th,
      td {
        padding: 0.4rem 0.8rem;
      }

      thead tr,
      tbody tr,
      tbody td {
        height: 2.8rem;
      }

      th:first-child,
      td:first-child {
        padding-left: 1.6rem;
      }

      th:last-child,
      td:last-child {
        padding-right: 1.6rem;
      }
    }

    .sn-table__bottom-item {
      margin-right: 0.8rem;
    }
  }

  &--dark {
    .sn-table__bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.48);
    }

    thead, tr, th, td {
      border-color: rgba(255, 255, 255, 0.48);
    }

    tbody tr {
      &.sn--selected {
        background: rgba(255, 255, 255, 0.1);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.07);
      }
    }

    &.sn-table--vertical-separator,
    &.sn-table--cell-separator {
      .sn-table__top {
        border-bottom: 1px solid rgba(255, 255, 255, 0.48);
      }
    }
  }
}

.home-statistic-outer {
  .home-statistic-inner {
    padding-top: 2.5rem;
    padding-bottom: 5.5rem;
    .s-title-section {
      color: $c_white;
    }
    .section-title-desc {
      color: $c_white;
      margin-bottom: 4rem;
    }
  }
}
.statistics-diagram-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 62rem;
}
.stat__middle-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34.6rem;
  height: 34.6rem;
  border-radius: 50%;
  border: 2px solid $c_green;
  position: relative;
}
.stat__logotype {
  // max-width: 12.4rem;
  width: 14rem;
  img {
    // max-width: 100%;
    width: 100%;
  }
}
.stat-block {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  &.__left {
    .stat-block__image {
      order: 2;
    }
    .stat-block__desc {
      order: 1;
      right: 100%;
    }
  }
  &.__right {
    .stat-block__image {
      order: 1;
    }
    .stat-block__desc {
      order: 2;
      left: 100%;
    }
  }
  &:nth-child(1) {
    left: 65 / 346 * 100%;
    bottom: 266 / 346 * 100%;
    .stat-block__desc {
      padding-right: 2rem;
      bottom: 108 / 214 * 100%;
    }
  }
  &:nth-child(2) {
    top: 65 / 346 * 100%;
    left: 266 / 346 * 100%;
    .stat-block__desc {
      padding-left: 3rem;
      left: 206 / 214 * 100%;
      bottom: 143 / 214 * 100%;
    }
  }
  &:nth-child(3) {
    left: 65 / 346 * 100%;
    top: 266 / 346 * 100%;
    .stat-block__desc {
      padding-left: 5rem;
      left: 194 / 214 * 100%;
      bottom: 47 / 214 * 100%;
    }
  }
  &:nth-child(4) {
    top: 65 / 346 * 100%;
    right: 266 / 346 * 100%;
    .stat-block__desc {
      right: 194 / 214 * 100%;
      padding-right: 5rem;
      bottom: 47 / 214 * 100%;
    }
  }
}
.stat-block__image {
  width: 21.4rem;
  height: 21.4rem;
  @include sbg($color: #3a4fac, $size: contain);
  position: relative;
  border-radius: 50%;
  box-shadow: 0 0.3rem 1.4rem -0.5rem rgba(0, 0, 0, 0.3);
}
.stat-block__desc {
  position: absolute;
  z-index: 2;
  width: auto;
  display: inline-block;
  padding-bottom: 1rem;
  border-bottom: 2px solid $c_white;
}
.stat-block__num {
  font: bold 4.8rem/1em $font_default;
  color: $c_green;
  white-space: nowrap;
}
.stat-block__title {
  font: 2.2rem/1em $font_sans_sb;
  color: $c_white;
}


@media screen and (max-width: 1099px) {
  .statistics-diagram-wrapper {
    height: auto;
  }
  .stat__middle-circle {
    width: 100%;
    height: auto;
    border-radius: 0;
    border: 0;
  }
  .stat__logotype {
    display: none;
  }
  .stat-wrapper {
    width: 100%;
    display: table;
    font-size: 0;
    .stat-block {
      display: inline-block;
      vertical-align: top;
      width: 48%;
      margin-right: 4%;
      margin-top: 2rem;
      margin-bottom: 2rem;
      position: relative;
      top: 0 !important;
      left: 0 !important;
      &:nth-child(2n+2) {
        margin-right: 0;
      }
      .stat-block__desc {
        right: auto;
        padding-right: 0;
        padding-left: 5rem;
        left: 194 / 214 * 100%;
        bottom: 47 / 214 * 100%;
      }
    }
  }
}
@media screen and (max-width: 899px) {
  .stat-wrapper {
    .stat-block {
      width: 100%;
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .stat-wrapper {
    .stat-block {
      display: block;
      text-align: center;
      margin: 0 auto 12rem;
      .stat-block__image {
        display: inline-block;
      }
      .stat-block__desc {
        width: 100%;
        top: 100%;
        margin-top: 2rem;
        left: 0;
        border: 0;
        padding-left: 0;
        text-align: center;
      }
    }
  }
}

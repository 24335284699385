.s-form__photo {
  @include pos();
  @include sbg(
    $c: #f7f9fc,
    $i: '../images/avatar_rectangle.svg',
    $p: center bottom,
    $s: cover
  );
  flex-shrink: 0;
  align-self: flex-start;
  width: 22rem;
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;

  &::before {
    display: table;
    margin-top: (220 * 1.33) / 220 * 100%;
    content: '';
  }

  input[ type='file' ] {
    display: none;
  }
}

.s-form__photo-button {
  @include pos(absolute, $b: 0, $l: 50%);
  width: 100%;
  max-width: 90%;
  transform: translate(-50%, 50%);
}

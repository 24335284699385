.container-content-head__tab-item {
  flex: 1;
  display: block;
  max-width: 22rem;
  font-weight: 600;
  color: $cWhite;
  text-align: center;
  background-color: #AEB3C6;
  padding: 1.1rem 1.5rem;
  border-radius: $gBorderRadius $gBorderRadius 0 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:not( :last-child ) {
    margin-right: 1.2rem;
  }

  &:hover {
    background-color: darken(#AEB3C6, 5%);
    transition: background-color 0.2s ease-in;
  }

  &.is-active {
    background-color: #3045A7;
    padding-top: 1.45rem;
    padding-bottom: 1.25rem;
    cursor: default;
    margin-top: -0.5rem;
    @media screen and (max-width: 1009px) {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }
  }

  .s-str-ico {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--only-icon {
    min-width: 4.5rem;
    padding: 1.45rem 0.65rem 1.25rem;
    margin-top: -0.5rem;
    background-color: $cLightPurple;
    flex: 0;

    &:hover {
      background-color: darken($cLightPurple, 8%);
    }

    .s-str-ico {
      left: 0.7rem;

      &::before {
        transform: translateY(-80%);
      }

      i {
        display: none;
      }
    }
  }

  @media screen and ( max-width: 639px ) {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: $gBorderRadius;

    &.is-active {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }

    &:not( :last-child ) {
      margin-right: 0;
      margin-bottom: 2rem;
      margin-left: 0;
    }

    &--only-icon {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;

      .s-str-ico {
        i {
          display: block;
          font-style: normal;
        }

        &::before {
          transform: translateY(-50%);
        }
      }
    }
  }
}

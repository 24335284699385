.home-course-section-wrapper {
  @include sflx($wrap: wrap, $juscont: center, $alitems: stretch);

  .course-section {
    flex-basis: 47%;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    margin-left: 1.5rem;
  }

  @media screen and (max-width: 1009px) {
    flex-direction: column;
    align-items: center;

    .course-section {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 3rem;
    }
  }
}

form.form__search-subjects {
  max-width: 71.4rem;
  width: 100%;
  display: inline-block;
  position: relative;
  input {
    padding-right: 23rem;
    border: 0;
  }
  .button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    min-width: 21rem;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    border-radius: 0 6px 6px 0;
  }
}


@media screen and (max-width: 480px) {
  form.form__search-subjects {
    input {
      padding-right: 6rem;
    }
    .button {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      display: block;
      font: 2.2rem $font_themify;
      padding: 0;
      width: 5rem;
      height: 5rem;
      min-width: 5rem;
      overflow: hidden;
      background: $c_green;
      color: $c_green;
      &::after {
        content: '\E610';
        color: $c_white;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.sn-radio {
  vertical-align: middle;

  &.sn--disabled {
    .sn-radio__inner {
      color: $cPlaceholder;
    }
    pointer-events: none;
    opacity: 1 !important;
  }

  &--dark {
    .sn-radio__inner {
      color: rgba(255,255,255,0.7);

      &::before {
        opacity: 0.32 !important;
      }

      &--active {
        color: $primary;
      }
    }
  }

  &--dense {
    .sn-radio__bg {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .sn-radio__inner {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }
}

.desktop {
  .sn-radio {
    &:focus {
      &:not( .sn--disabled ) {
        .sn-radio__inner {
          &::before {
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }

    &--dense {
      &:focus {
        &:not( .sn--disabled ) {
          .sn-radio__inner {
            &::before {
              transform: scale3d(2, 2, 2);
            }
          }
        }
      }
    }
  }

  .sn-radio__inner {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background: currentColor;
      opacity: 0.12;
      transform: scale3d(0, 0, 0);
      transition: transform 0.22s cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }
}

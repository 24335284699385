.home-successful-teacher {
  background-image: url(../images/students.png), url(/images/flag_kz.png);
  background-color: #e9f2fb;
  background-repeat: no-repeat;
  background-position: calc(100% + 20rem) -28rem,calc(-3rem - 13%) center;
  background-size: 96rem 80rem, 80rem 64rem;

  .home-successful-teacher-inner {
    padding: 80px 20px;
    .section-title {
    }
  }
  .successful-teacher-wrapper {
    text-align: center;
  }
  .successful-teacher-text {
    margin-bottom: 38px;
    font: 1.6rem/1.4em $font_default;
  }
}
@media screen and (max-width: 1024px) {
  .home-successful-teacher {
    background-position: calc(100% + 32rem) -28rem,calc(-3rem - 60%) center;
   }
}
@media screen and (max-width: 800px) {
  .home-successful-teacher {
    background: #e9f2fb url(../images/flag_kz.png) center/ 110rem no-repeat;
  }
}
@media screen and (max-width: 600px) {
  .home-successful-teacher .successful-teacher-text br {
    display: none;
  }
}
.form-group {
  @include sflx($dir: column);
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  &:last-child {
    margin-top: 3rem;

    button {
      margin-left: auto;
    }
  }

  .btn {
    min-width: 20rem;
  }
}
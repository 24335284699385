@media screen and ( max-width: 1279px ) {
  .s-application-block__body {
    //margin-right: -27rem;
  }
}


@media screen and ( max-width: 699px ) {
  .s-application-block {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .s-application-block__columns {
    flex-direction: column;
  }

  .s-application-block__column-left {
    width: 100%;
    padding-right: 0;
  }

  .s-application-block__head {
    @include pos();
    padding-top: 3rem;
  }

  .s-application-block__mark {
    @include pos(absolute, 1, 0, $r: 0);
    font-size: 1.22rem;
  }

  .s-application-block__column-right{
    @include sflx($alitems: flex-start);
    > * {
      &:not( :last-child ) {
        margin-right: 1rem;
      }
    }
    width: 100%;
  }

  .s-application-block__controls {
    justify-content: flex-start;
    margin-bottom: 1rem;
    &--update-btn {
      margin-bottom: 2rem;
      margin-right: 1rem;
    }
  }

  .s-application-block__body {
    margin-right: 0;
  }

  .s-application-block__steps {
    flex-direction: column;
  }

  .s-application-block__step {
    width: 100%;
  }

  .s-application-block__step-head {
    text-align: left;

    &--inactive {
      + .s-application-block__step-body {
        display: none;
      }
    }
  }

  .s-application-block__warning {
    flex-direction: column;
  }

  .s-application-block__warning-message {
    align-items: flex-start;
    padding-right: 0;
    margin-bottom: 1.5rem;

    &::before {
      margin-right: 2rem;
    }
  }
}

@media screen and ( max-width: 510px ) {
  .s-application-block__column-right{
    @include sflx($dir: column, $alitems: center);
    width: 100%;
  }
}

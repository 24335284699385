.s-dropdown__item {
  display: block;
  color: $cBlack;
  font-size: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &:last-child {
    border-radius: 0 0 $gBorderRadius $gBorderRadius;
  }

  &:first-child {
    border-radius: $gBorderRadius $gBorderRadius 0 0;

    span,
    strong {
      border-top: 0;
    }
  }

  span,
  strong {
    display: block;
    width: 100%;
    padding: 1.05rem 1rem;
    border-top: 1px solid $cBorder;
  }

  &--account {
    max-width: 20rem;
    cursor: default;

    span,
    strong {
      display: block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@keyframes a-shake {
  0% {
    transform: translateX(0.5rem);
  }

  20% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(-0.5rem);
  }

  60% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(0.5rem);
  }

  100% {
    transform: translateX(0);
  }
}

.tbl-cont__search {
  @include pos();
  padding-right: 3rem;

  &::after {
    @include pos(absolute, $t: 50%, $r: 1rem);
    margin-top: -.8rem;
    font-size: 1.6rem;
    line-height: 1em;
    font-family: $fIcons;
    color: $cWhite;
    content: '\E610';
  }

  input {
    @include placeholder($cWhite);
    width: 100%;
    height: 3rem;
    font-weight: 600;
    font-size: 1.4rem;
    color: $cWhite;
    background-color: transparent;
    padding-right: 0;
    padding-left: 0;
    border: 0;
  }
}

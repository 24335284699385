.s-test-process__left {
  @include sflx($dir: column);
  flex-shrink: 0;
  width: 20rem;
  border-right: 1px solid $stpGrey;

  @media screen and ( max-width: 1008px ) {
    width: 100%;
    border-right: unset;
  }
}

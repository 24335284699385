.content__head-right {
  flex-shrink: 0;
}

.filter-block {
  .form__datetime {
    .input__date {
      margin-right: 0;
    }
  }
}
.faq-answear__head {
  @include pos();
  padding: 1.6rem 5rem 1.6rem 2rem;
  border-radius: $gBorderRadius $gBorderRadius 0 0;
  cursor: pointer;

  &::after {
    @include pos(absolute, $t: 0, $r: 2rem);
    @include sflx($juscont: center, $alitems: center);
    width: 2rem;
    height: 100%;
    font-family: $fIcons;
    font-size: 1.8rem;
    color: #3045a7;
    content: '\E64B';
    transform: rotateX(0);
    transition: transform .2s ease-in;
  }
}
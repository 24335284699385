.s-notification {
  @include pos();
  min-width: auto;
  font-size: 1.4rem;
  padding: 1.7rem 5rem;
  margin-top: 0.5rem;
  border-radius: $gBorderRadius;
  @include media-breakpoint-up (md) {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  &::before {
    @extend .s-ico;
    @include pos(absolute, $t: 0, $l: 0);
    @include sflx($alitems: center, $juscont: center);
    width: 5rem;
    height: 5rem;
    font-size: 2.2rem;
    font-family: $fIcons;
  }

  &.info {
    @include s-notification--thm(rgba(187, 238, 255, 0.9), #005599, info-alt);
  }

  &.success {
    @include s-notification--thm(#DFF2BF, #227700, face-smile);
  }

  &.warn {
    @include s-notification--thm(#FEEFB3, #9F6000, announcement);
  }

  &.error {
    @include s-notification--thm(#FFBABA, #D8000C, bolt);
  }
}

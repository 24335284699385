.sn-select__input {
  border: 0;
  outline: 0 !important; // needed for FF
  background: transparent;
  min-width: 50px !important;
  padding: 0;
  height: 0;
  min-height: 24px;
  line-height: 24px;
  width: 100%;

  &--padding {
    padding-left: 4px;
  }

  &--noclear {
    input[type=text]::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    input[type=text]::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    input[type=search]::-webkit-search-decoration,
    input[type=search]::-webkit-search-cancel-button,
    input[type=search]::-webkit-search-results-button,
    input[type=search]::-webkit-search-results-decoration {
      display: none;
    }
  }
}

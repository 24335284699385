.kindergarten-filter {
  display: block;
  width: 100%;
}

.kindergarten-filter_t_region {
  align-self: flex-end;
  max-width: 24rem;
  margin-right: 2rem;

  .btn, // legacy
  .s-btn {
    display: block;
    width: 100%;
    padding-top: 1.45rem;
    padding-bottom: 1.45rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.kindergarten-filter_t_type {
  align-self: flex-end;
  max-width: 42rem;
  margin-right: 2rem;

  .select2-container--select2-custom-multi {
    &.select2-container--open {
      .select2-selection--multiple {
        border-bottom: 0;
        border-radius: $gBorderRadius $gBorderRadius 0 0;
      }
    }
    .select2-selection--multiple {
      border: 1px solid #a9b9c2;
      border-radius: $gBorderRadius;
      .select2-selection__rendered {
        display: block;
        padding: 0 .45rem;

        li{
          margin: .45rem .6rem .45rem 0;

          &:not(.select2-search) {
            @include pos();
            max-width: 13rem;
            float: left;
            font-weight: 600;
            font-size: 1.3rem;
            color: $cWhite;
            background-color: #33cc66;
            padding: .6rem 3rem .6rem 1rem;
            border-radius: $gBorderRadius;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .select2-selection__choice__remove {
              @include pos(absolute, $t: 0, $r: 0);
              @include sflx($alitems: center, $juscont: center);
              width: 3rem;
              height: 100%;
              font-size: 2.4rem;
            }
          }
        }
      }
    }

    input {
      height: 2.9rem;
    }
  }
}

.select2-container--select2-custom-multi {
  margin-top: -.2rem;
  .select2-results__options {
    li {
      @include pos();
      background-color: $cWhite;
      padding-top: 1.15rem !important;
      padding-bottom: 1.15rem !important;
      padding-left: 4.6rem !important;

      border-top: 1px solid #e2e7ea;

      &::before {
        @include pos(absolute, $t: 50%, $l: 1.3rem);
        @include sflx($alitems: center, $juscont: center);
        transform: translateY(-50%);
        display: block;
        width: 2rem;
        height: 2rem;
        font-size: 1.4rem;
        font-family: $fIcons;
        color: $cWhite;
        border: 1px solid #a9b9c2;
        border-radius: 2px;
        content: '';
      }

      &[aria-selected=true] {
        font-weight: 600;
        background-color: #f4f5f9;

        &::before {
          background-color: #33cc66;
          border-color: #33cc66;
          content: '\E64C';
        }
      }
    }
  }
}

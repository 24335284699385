.s-direction-block__controls {
  @include sflx();
  flex-shrink: 0;

  > * {
    &:not( :last-child ) {
      margin-right: 1rem;
    }
  }
}

@media screen and (max-width: 1474px) {
  .s-main-header_contacts__new-label {
    margin-left: 0 !important;
  }
  .s-main-header_contacts__icon {
    font-size: 15px !important;
  }
  .s-main-header_contacts__number {
    font-size: 15px !important;
  }
  .s-main-header_contacts__divider {
    margin-left: 1.5rem !important;
  }
  .s-main-header_contacts__label {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1327px) {
  .s-main-header_contacts__new-label {
    display: none !important;
  }
}

@media screen and (max-width: 1355px) {
  .s-main-header__left-panel {
    padding-left: 1.5rem !important;
    padding-right: 0 !important;
  }
  .s-main-header__right-panel {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }
  .s-logotype s-main-header__logotype {
  }
}

@media screen and (max-width: 1239px) {
  .s-main-header__logotype {
    margin-right: 1rem !important;
  }
  .s-main-header__section {
    margin-right: 1rem !important;
  }
}

@media screen and (max-width: 1170px) {
  .s-main-header {
    &:not(.s-main-header--without-adaptive) {
      padding: 1rem 2rem !important;
    }
  }

  .s-main-header__left-panel {
    padding: 0 !important;
  }

  .s-main-header__right-panel {
    &:not(.s-main-header__right-panel--without-adaptive) {
      display: none !important;
    }
  }

  .s-main-header__burger {
    display: flex !important;
  }

  .s-main-header_contacts {
    display: none !important;
  }
}

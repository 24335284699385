.s-application-block__step-head {
  font-size: 1.4rem;
  text-align: center;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
  height: 40px;

  &--done {
    color: $cWhite;
    background-color: $cGreen;

    &::after {
      border-left-color: $cGreen;
    }
  }

  &--waiting {
    color: $cWhite;
    background-color: $cOrange;

    &::after {
      border-left-color: $cOrange;
    }
  }

  &--failed {
    color: $cWhite;
    background-color: $cRed;

    &::after {
      border-left-color: $cRed;
    }
  }

  &--done,
  &--failed,
  &--waiting {
    @include pos();

    &::after {
      @include pos(absolute, $z: 2, $t: 0, $l: 100%);
      width: 0;
      height: 0;
      border-top: 1.3rem solid transparent;
      border-left-width: 1rem;
      border-left-style: solid;
      border-bottom: 1.3rem solid transparent;
      content: '';
    }
  }

  &--inactive {
    color: #B2B7D4;
    background-color: #EDEEF4;
  }

  // Фикс от срезания иконок
  .s-str-ico {
    &::before {
      margin-top: 0.02rem;
    }
  }
}

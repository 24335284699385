//$fonts: (
//  ("Open Sans", OpenSans_Regular, "", ""),
//  ("Open Sans", OpenSans_Italic, italic, ""),
//  ("Open Sans", OpenSans_Bold, "", 700),
//  ("Open Sans", OpenSans_BoldItalic, italic, 700),
//  ("Open Sans Semibold", OpenSans_Semibold, "", ""),
//  ("Open Sans Light", OpenSans_Light, "", ""),
//  ("themify", themify, "", "")
//);
$fDefSize: 10px;

//@each $name, $file, $style, $weight in $fonts {
//  @font-face {
//    font-family: $name;
//    @if($style != "") {
//      font-style: $style;
//    }
//    @if($weight != "") {
//      font-weight: $weight;
//    }
//    src: url('./fonts/#{$file}.woff2') format("woff2"),
//    url('./fonts/#{$file}.woff') format("woff"),
//    url('./fonts/#{$file}.ttf') format("truetype"),
//    url('./fonts/#{$file}.svg') format("svg");
//  }
//}

$fDef: "Open Sans", sans-serif;
$fSansSB: "Open Sans Semibold", sans-serif;
$fSansL: "Open Sans Light", sans-serif;
$fThemify: snation;

.s-insta-block {
  @include sflx($dir: column);
  flex: 1 0 auto;
  border: 1px solid #edf0f2;
  box-shadow: 0 0 1rem rgba(104, 124, 143, 0.28);
  border-radius: $gBorderRadius;
  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: 0 0 2rem rgba(#687c8f, .48);
  }
}
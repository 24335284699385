.home-diagram-outer {
  .home-diagram-inner {
    padding-top: 4rem;
    padding-bottom: 5rem;
    .canvas-wrapper {
      width: 100%;
      max-width: 64rem;
      canvas {
        width: 100%;
      }
    }
  }
}
.diagram-title {
  font: 3.2rem/1.2em $font_sans_l;
  color: $c_black;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.diagram-date {
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
  text-align: center;
  margin-bottom: 3rem;
}
.diagram-columns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.diagram-labels {
  flex: 1 0 auto;
  padding-right: 5rem;
  padding-left: 5rem;
}
.diagram-labels__block {
  margin-bottom: 2.5rem;
  li {
    padding-top: .1rem;
    padding-bottom: .2rem;
    padding-left: 5.8rem;
    position: relative;
    font: 1.8rem/1.2em $font_sans_sb;
    color: $c_black;
    i {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: block;
      width: 3.8rem;
      height: 100%;
      min-height: 1.7rem;
    }
  }
}


@media screen and (max-width: 999px) {
  .diagram-columns {
    flex-direction: column;
    .diagram-labels {
      order: 2;
    }
    .canvas-wrapper {
      order: 1;
      margin-bottom: 2rem;
    }
  }
}
@media screen and (max-width: 599px) {
  .home-diagram-outer {
    .home-diagram-inner {
      padding-top: 3rem;
    }
  }
  .diagram-columns {
    .diagram-labels {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .diagram-title {
    font-size: 2.2rem;
  }
}
.sn-select__dialog {
  width: 90vw !important;
  max-width: 90vw !important;
  max-height: calc(100vh - 70px) !important;
  background: white;
  display: flex;
  flex-direction: column;

  > .sn--scroll {
    position: relative;
    background: inherit;
  }

  .sn-field__wrapper {
    background-color: rgba(0,0,0,0.05);
    .sn-field {
      overflow-y: auto;
    }
  }

  .sn-field__label {
    display: none;
  }

  .sn-field__control {
    border: none !important;
  }
}

.platform-ios {
  .sn-select__dialog {
    max-height: 50vh !important;
  }
}

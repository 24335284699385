.modal-region-select-wrapper {
  @include sflx($alitems: flex-end);

  .form-group {
    width: 30%;
    margin-top: 0;
    margin-bottom: 0;

    &:not( :last-child ) {
      @include pos();
      margin-right: 5%;

      &::after {
        @include pos(absolute, $b: 0.7rem, $r: -12%);
        font-size: 2.4rem;
        font-family: $fIcons;
        color: #8996A5;
        content: '\E649';
      }
    }
  }
}


@media screen and ( max-width: 767px ) {
  .modal-region-select-wrapper {
    flex-direction: column;
    align-items: flex-start;

    .form-group {
      width: 100%;
      margin-right: 0! important;

      &:not( :last-child ) {
        margin-bottom: 2rem;
      }

      &::after {
        display: none! important;
      }
    }
  }
}

.s-table-with-filters {
  &__between-btns-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    > .sn-btn {
      &:first-child {
        margin-right: 1rem;
        min-width: 15rem;
      }
      &:last-child {
        min-width: 30rem;
      }
    }
  }
}

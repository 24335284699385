.sn-field__control {
  @include pos();
  @include sflx($wrap: nowrap, $alitems: stretch);
  max-width: 100%;
  width: 100%;
  height: 4.2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;
  outline: none;
  transition: border-color $gDuration ease;

  .sn-field__append {
    button {
      margin-right: -1.2rem;
    }
  }
}

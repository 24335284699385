.s-flag-mini {
  @include pos();
  @include sflx($alitems: center, $juscont: center);
  @include sbg($i: '../indv/mektep/images/flag.svg', $s: cover);
  width: 4rem;
  height: 5.8rem;
  color: $cWhite;
  padding-bottom: 2rem;

  &::before {
    font-weight: 300;
  }
}

.s-flag-mini--medium {
  @include sbg($i: '../indv/mektep/images/flag-medium.svg');
  height: 8rem;
  width: 4.5rem;
}

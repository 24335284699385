.navigation {
  @include sflx($juscont: center, $alitems: center);
}

[class*=navigation_t_] {
  font-family: $fIcons;
  color: #7e8695;
}

$listOfNavigation: (
  (first, '\E662'),
  (backward, '\E64A'),
  (forward, '\E649'),
  (last, '\E661'),
);


@each $name, $icon in $listOfNavigation {
  .navigation_t_#{$name} {
    &::after {
      content: $icon;
    }
  }
}
.a-slide-vertical {
  &-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
  }

  &-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  &-enter-to,
  &-leave {
    max-height: 25rem !important;
    overflow: hidden;
  }

  &-enter,
  &-leave-to {
    overflow: hidden;
    min-height: 0 !important;
    max-height: 0 !important;
    padding: 0 !important;
  }
}

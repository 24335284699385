.s-info-card--with-buttons {
  > div:nth-child(2) {
    padding-left: 1.5rem;

    > div:first-child {
      margin-left: unset;
      margin-right: unset;
    }
  }
}

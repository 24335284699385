@for $i from 1 through 12 {
  .mt-#{$i * 5} {
    margin-top: ($i * 5 / 10) + rem;
  }

  .mr-#{$i * 5} {
    margin-right: ($i * 5 / 10) + rem;
  }

  .mb-#{$i * 5} {
    margin-bottom: ($i * 5 / 10) + rem;
  }

  .ml-#{$i * 5} {
    margin-left: ($i * 5 / 10) + rem;
  }
}

.course-spoiler-body__item {
  @include sflx($alitems: center);
  width: 100%;
  min-height: 6.8rem;
  color: $cBlack;
  padding-left: 3rem;
  transition: background-color .2s ease-in;

  &:hover {
    background-color: #edeff7;
    transition: background-color .2s ease-in;
  }

  &.is-usage {
    .course-spoiler-body__item-progress-wrapper {
      color: $cBlack;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dfe2ed;
  }
}

.sn-modal {
  width: 100%;
  max-width: 70rem;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 3.8rem rgba(104, 124, 143, 0.68);

  &--small {
    max-width: 30rem;
  }

  &--480 {
    max-width: 48rem;
  }

  &--700 {
    max-width: 70rem;
  }

  &--800 {
    max-width: 80rem;
  }

  &--medium {
    max-width: 60rem;
  }

  &--big {
    max-width: 98rem;
  }

  &--large {
    max-width: 110rem;
  }
}

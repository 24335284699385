.s-breadcrumbs__link {
  @include pos();
  display: inline;
  vertical-align: middle;
  font-size: 1.4rem;
  color: $cBreadcrumbsItem;
  word-wrap: break-word;
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: darken($cBreadcrumbsItem, 1%);
  }

  &::after {
    display: inline;
    font-size: 1.2rem;
    line-height: 1em;
    font-family: $fIcons;
    margin-right: 1rem;
    margin-left: 1rem;
    content: '\E649';
  }
}

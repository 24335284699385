.s-test-process__questions-map {
  @include pos(relative, $t: 0, $r: 0);
  height: 100%;
  transform: translateX(100%);
  transition: transform $gDuration ease-in;

  &.is-show {
    transform: translateX(0);
    transition: transform $gDuration ease-in;

    .s-test-process__button {
      &:hover {
        transform: translateX(-3.9rem);
      }

      &--map {
        &::before {
          content: map_get($listOfIcons, 'arrow-right');
        }
      }
    }
  }

  .s-test-process__button--map {
    z-index: 1;
    right: auto;
    left: 0;
    transform: translateX(-3.9rem);

    &:hover {
      transform: translateX(-100%);
    }
  }

  @media screen and ( max-width: 1008px ) {
    @include pos(absolute, $t: 0, $r: 0);
    width: 20rem;
    border-left: 1px solid $stpGrey;
  }
}

.s-table-schedule__add-item-wrapper {
  @include pos($z: 3);
  @include sflx($alitems: center, $juscont: center);
  width: 100%;
  height: 100%;

  &:hover {
    .s-table-schedule__add-item {
      opacity: 1;
      transition: all $gDuration ease-in;
    }
  }
}

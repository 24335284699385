.s-main-header {
  @include pos(absolute, 99, 0, 0);
  @include sflx($alitems: stretch);
  width: 100%;

  &--background {
    @include pos(relative);
    @include sbg($cBlue, '../images/header_bg.jpg', $s: auto 100%);
    box-shadow: 0 0.2rem 0.8rem rgba($cBlack, 0.3);
  }
}

.course-spoiler {
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);

  &:not(:last-child) {
    margin-bottom: .6rem;
  }

  &--college {
    width: 100%;

    .course-spoiler-body {
      display: block;
    }
  }

  &.is-done {
    .course-spoiler__progress-value {
      color: #33cc66;
    }
  }
}

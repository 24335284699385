.form__inform{
    background-color: #243d9b;
    color:white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 1.6rem;
    font-family: "Open Sans";

    img{
        vertical-align: middle;
        color: white;
        width: 30px;
        display: inline-block;
        margin-right: 10px;
    }
    span{
        vertical-align: middle;
    }


}

.modal-help {
  @extend %modal;

  .modal-dialog {
    max-width: 78.2rem;
  }

  .modal-help-form-columns {
    @include sflx();
    width: 100%;

    .form-checkbox {
      flex-shrink: 0;

      + label {
        width: 100%;
        max-width: 26rem;
        padding-right: 2rem;
      }
    }

    .form-upload {
      @include sflx();
      flex: 1 1 auto;
    }
  }


  @media screen and (max-width: 767px) {
    .modal-help-form-columns {
      flex-direction: column;

      .form-checkbox {
        + label {
          max-width: 100%;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
.sn-fs-loader {

  color: #000;
  flex-direction: column;
  position: fixed !important;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: currentColor;
    opacity: .5;
    z-index: -1;
  }

  > div {
    margin: 40px 20px 0;
    max-width: 450px;
    text-align: center;

  }
}

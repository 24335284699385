table.table__tipo {
  + .tipo-title {
    margin-top: 3rem;
  }
  thead {
    tr {
      td {
        font: 1.3rem/1.2em $font_sans_sb;
        border-top: 1px solid #d1d8dc;
        border-bottom: 1px solid #d1d8dc;
        padding: 1.7rem 1.5rem;
      }
    }
  }
  tbody {
    tr {
      td {
        font: 1.6rem/1.2em $font_default;
        padding: 1.4rem 1.5rem;
      }
    }
  }
  tr {
    &.is-active {
      td {
        &:nth-child(n+2) {
          font-weight: bold;
        }
      }
    }
    td {
      color: $c_black;
      text-align: center;
      border-right: 1px solid #d1d8dc;
      &:nth-child(1) {
        width: 180 / 1220 * 100%;
      }
      &:nth-child(2) {
        width: 600 / 1220 * 100%;
      }
      &:nth-child(3) {
        width: 180 / 1220 * 100%;
      }
      &:last-child {
        width: 260 / 1220 * 100%;
        border-right: 0;
      }
    }
  }
}
.last-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-style: normal;
    margin-right: 2rem;
  }
  span {
    display: block;
    font: 1.6rem/1.2em $font_sans_sb;
    color: #0fa116;
    background-color: #def5d3;
    padding: .5rem 1rem;
    border-radius: 5px;
  }
}

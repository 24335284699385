.sn-slider__thumb {
  top: 0;
  left: 0;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: transform .18s ease-out, fill .18s ease-out, stroke .18s ease-out;
  stroke-width: 3.5;
  stroke: currentColor;

  circle {
    stroke: currentColor;
    fill: currentColor;
  }
}

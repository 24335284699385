.kindergarten-album-wrapper {
  width: 100%;

  > * {
    margin-right: 1rem;
    margin-left: 1rem;
  }


  @media screen and ( max-width: 479px ) {
    > * {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

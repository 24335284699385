.s-stat__image {
  width: 7rem;
  height: 7rem;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: #3045A6;
  margin: 3rem auto;
  @include media-breakpoint-down (sm) {
    display: none;
  }
}
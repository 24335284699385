.s-blog__profile {
  &-image {
    @include sbg($s:cover);
    flex-shrink: 0;
    width: 16rem;
    height: 17rem;
    border-radius: $gBorderRadius;
    position: relative;

    .s-btn--ico {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      border-color: $cWhite;
      color: $cWhite;
      background-color: transparent;
      transition: all $gDuration ease-in;

      &:hover {
        background-color: $cWhite;
        color: currentColor;
      }
    }
  }

  &-name {
    display: block;
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.3em;
  }

  &-position {
    color: $cGrey;
  }
}

%block__inner {
  width: 100%;
  max-width: $wDef;
  margin: 0 auto;

  @media screen and (max-width: 1320px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

}
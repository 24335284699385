.statistic-block {
  @include sflx($dir: column, $alitems: center);
  flex: 1;
  text-align: center;
  padding-right: .5rem;
  padding-left: .5rem;

  &:not(:last-child) {
    border-right: 1px solid #dfe2ed;
  }

  &.is-empty {
    .statistics-block__value {
      color: #e18c96;
    }
  }

  &.is-not-empty {
    .statistics-block__value {
      color: #33cc66;
    }
  }
}
.sn-time__clock-position {
  position: absolute;
  min-height: 32px;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  transform: translate3d(-50%, -50%, 0) /* rtl:ignore */;
  border-radius: 50%;

  &--disable {
    opacity: 0.4;
  }

  &--active {
    background-color: $primary;
    color: #fff;
  }
}

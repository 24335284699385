.s-steps-body-wrapper {
  width: 100%;
}

.s-steps-body-wrapper--info {
  margin-top: 1.5rem;
  padding: 2.5rem;
  border-radius: 7px;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.35);
}

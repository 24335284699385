//.modal-information-of-voting {
//  .modal-dialog {
//    max-width: 98rem;
//    padding: 3rem;
//  }
//  .table__simple, .table__simple-head {
//    margin-bottom: 3rem;
//    tr {
//      td, th {
//        font-size: 1.6rem;
//        &:first-child {
//          width: 330 / 920 * 100%;
//          font-family: $font_default;
//          text-align: right;
//        }
//        &:last-child {
//          width: 590 / 920 * 100%;
//          //font-family: $font_default;
//        }
//      }
//    }
//  }
//  .table__comm {
//    thead {
//      tr {
//        td {
//          font: 1.6rem/1.2em $font_default;
//          color: $c_black;
//        }
//      }
//    }
//    tbody {
//      tr {
//        td {
//          font: 1.4rem/1.2em $font_default;
//          color: $c_black;
//          padding-top: 1rem;
//          &.__success {
//            color: $c_green;
//            font-family: $font_sans_sb;
//          }
//          &.__reject {
//            color: $c_red;
//          }
//        }
//      }
//    }
//    tr {
//      td {
//        border-right: 1px solid #d1d8dc;
//        padding-right: 1rem;
//        padding-bottom: 1rem;
//        padding-left: 1rem;
//        text-align: center;
//        &:last-child {
//          border-right: 0;
//        }
//        &:nth-child(1) {
//          width: 12rem;
//          text-align: right;
//        }
//      }
//    }
//  }
//}
//.iov-title {
//  font: 1.8rem/1.2em $font_sans_sb;
//  color: $c_black;
//  text-align: center;
//  margin-bottom: 2rem;
//}
//.table__winner {
//  max-width: 64rem;
//  margin: 0 auto;
//  margin-bottom: 3rem;
//  tbody {
//    tr {
//      &:nth-child(odd), &:hover {
//        background-color: $c_white;
//      }
//      td {
//        width: 50%;
//        color: $c_black;
//        padding: 1rem;
//        &:first-child {
//          font: 1.6rem/1.2em $font_default;
//          text-align: right;
//        }
//        &:last-child {
//          font: bold 1.8rem/1.2em $font_default;
//        }
//      }
//    }
//  }
//  + .button {
//    display: table;
//    margin: 0 auto;
//  }
//}
.download-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  .__text {
    max-width: 22rem;
    font: 1.6rem/1.2em $font_default;
    color: $c_black;
    text-align: right;
    padding-right: 2rem;
  }
}
//.candidate-voting-list {
//  width: 100%;
//  display: block;
//  margin: 3rem 0;
//  li {
//    font: 1.6rem/1.2em $font_default;
//    color: $c_black;
//    margin-bottom: 1rem;
//  }
//}

//@media screen and (max-width: 767px) {
//  .table__winner {
//    display: block;
//    width: 100%;
//    tbody {
//      display: block;
//      width: 100%;
//      tr {
//        display: block;
//        width: 100%;
//        td {
//          display: block;
//          width: 100%;
//          text-align: left !important;
//          padding: 1rem 2rem !important;
//        }
//      }
//    }
//  }
//}
@media screen and (max-width: 480px) {
  //.modal-information-of-voting {
  //  .modal-dialog {
  //    padding-top: 6rem;
  //  }
  //}
  .download-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .__text {
      width: 100%;
      max-width: none;
      text-align: left;
      padding-right: 0;
      margin-bottom: 1rem;
    }
    //.button {
    //  width: 100%;
    //}
  }
}

.home-reviews-outer {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .s-title-section {
    margin-bottom: 0;
  }
}
.reviews-carousel {
  position: relative;
  margin-top: -3rem;
  .owl-stage {
    display: flex;
    align-items: stretch;
    padding: 3rem 0.5rem;
    .owl-item {
      display: flex;
      flex-direction: column;
    }
    .reviews-block {
      flex: 1 0 auto;
      border: 1px solid #edf0f2;
    }
  }
  .owl-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -1.9rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .owl-prev, .owl-next {
      &:after {
        font: 3.8rem/1em $font_themify;
        color: #8DBE2D;
      }

      &.disabled {
        &::after {
          color: #ACB1C1;
        }
      }
    }
    .owl-prev {
      margin-left: -5.5rem;
      &:after {
        content: '\E64A';
      }
    }
    .owl-next {
      margin-right: -5.5rem;
      &:after {
        content: '\E649';
      }
    }
  }
}

.content-outer {
  .reviews-carousel {
    display: inline-flex;
    flex-flow: column wrap;
    align-items: center;
    width: 100%;
  }
  .reviews-block {
    width: 100%;
  }
  .reviews-block__desc {
    text-align: justify;
  }
}

@media screen and (max-width: 1379px) {
  .home-reviews-outer {
    overflow: hidden;
  }
}
@media screen and (max-width: 899px) {
  .home-reviews-outer {
    .home-reviews-inner {
      .reviews-carousel {
        .owl-stage {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
  .content-outer {
    .reviews-block {
      width: 100%;
    }
  }
}
@media screen and (max-width: 599px) {
  .home-reviews-outer {
    padding-top: 3rem;
  }
}

.s-test-process__center-row {
  @include sflx($alitems: stretch);
  width: 100%;
  margin: 4rem 0;

  &:last-child {
    margin-bottom: 2rem;
  }

  @media screen and ( max-width: 1264px ) {
    @include pos();
  }

  @media screen and ( max-width: 1008px ) {
    &:first-child {
      margin-top: 0;
    }
  }
}

.sn--text- {
  &uppercase {
    text-transform: uppercase;
  }

  &lowercase {
    text-transform: lowercase;
  }

  &capitalize {
    text-transform: capitalize;
  }

  &center {
    text-align: center;
  }

  &left {
    text-align: left;
  }

  &right {
    text-align: right;
  }

  &justify {
    text-align: justify;
    hyphens: auto;
  }

  &italic {
    font-style: italic;
  }

  &bold {
    font-weight: bold;
  }

  &no-wrap {
    white-space: nowrap;
  }

  &strike {
    text-decoration: line-through;
  }
}

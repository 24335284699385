.sn-btn-group {
  border-radius: $gBorderRadius;
  box-shadow: $shadow-2;
  vertical-align: middle;
}
.sn-btn-group > .sn-btn-item {
  box-shadow: none;
}
.sn-btn-group > .sn-btn-group > .sn-btn:first-child {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.sn-btn-group > .sn-btn-group > .sn-btn:last-child {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.sn-btn-group > .sn-btn-group:not(:first-child) > .sn-btn:first-child {
  border-left: 0;
}
.sn-btn-group > .sn-btn-group:not(:last-child) > .sn-btn:last-child {
  border-right: 0;
}
.sn-btn-group > .sn-btn-item:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.sn-btn-group > .sn-btn-item + .sn-btn-item {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.sn-btn-group--push {
  border-radius: $gBorderRadius;
}
.sn-btn-group--push > .sn-btn--push .sn-btn__content {
  transition: .3s cubic-bezier(.25, .8, .5, 1);
}
.sn-btn-group--push > .sn-btn--push:active:not(.is-disabled),
.sn-btn-group--push > .sn-btn--push.sn-btn--active:not(.is-disabled) {
  border-bottom-width: 3px;
  transform: none;
}
.sn-btn-group--push > .sn-btn--push:active:not(.is-disabled) .sn-btn__content,
.sn-btn-group--push > .sn-btn--push.sn-btn--active:not(.is-disabled) .sn-btn__content {
  transform: translate3d(0, 3px, 0);
}
.sn-btn-group--rounded {
  border-radius: $gBorderRadius;
}
.sn-btn-group--flat,
.sn-btn-group--outline,
.sn-btn-group--unelevated {
  box-shadow: none;
}
.sn-btn-group--flat > .sn-btn-item,
.sn-btn-group--outline > .sn-btn-item,
.sn-btn-group--unelevated > .sn-btn-item {
  box-shadow: none;
}
.sn-btn-group--outline > .sn-btn-item + .sn-btn-item {
  border-left: 0;
}
.sn-btn-group--outline > .sn-btn-item:not(:last-child) {
  border-right: 0;
}
.sn-btn-group--stretch {
  align-self: stretch;
  border-radius: 0;
}
.sn-btn-group--glossy > .sn-btn-item {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.3), rgba(255,255,255,0) 50%, rgba(0,0,0,0.12) 51%, rgba(0,0,0,0.04)) !important;
}
.sn-btn-group--spread > .sn-btn-group {
  display: flex !important;
}
.sn-btn-group--spread > .sn-btn-item,
.sn-btn-group--spread > .sn-btn-group > .sn-btn-item:not(.sn-btn-dropdown__arrow-container) {
  width: auto;
  min-width: 0;
  max-width: 100%;
  flex: 10000 1 0%;
}

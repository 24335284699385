.container-page-wrapper {
  @include pos();
  @include sflx($dir: column);
  flex: 1 0 auto;
  width: 100%;
  max-width: $gWidthMax;

  .container-head-wrapper {
    min-height: 15rem;
    padding-bottom: 2rem;
  }

  .container-content-container {
    max-width: $gWidthDef;
    &--small {
      max-width: $gWidthSmall;
    }
  }
}

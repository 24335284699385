$fDef: 'Open Sans', sans-serif;
$fIcons: snation;
$cWhite: #FFFFFF;
$cGreen: #8DBE2D;
$cOrange: #F5942E;

//.device-header-outer {
//  display: none;
//}

.device-menu-wrapper {
  display: none;
}

@media screen and ( max-width: 1008px ) {
  //.device-header-outer {
  //  display: block;
  //  position: absolute;
  //  z-index: 99;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //
  //  &.device-header__inner {
  //    position: static;
  //    @include sbg($bgHeader);
  //    background-image: url('../images/inner_header_bg.jpg');
  //  }
  //
  //  .device-header-inner {
  //    display: flex;
  //    justify-content: space-between;
  //    align-items: center;
  //    padding-right: 0;
  //    padding-left: 0;
  //  }
  //}

  .device-header__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .device-menu__logotype {
    flex-shrink: 0;
    display: block;
    height: 7rem;
    padding: 1.5rem 2rem;

    img {
      max-width: 100%;
      height: 4rem;
      max-height: 100%;
    }
  }

  .device-menu__icon {
    padding: 1.5rem 2rem;

    &::after {
      content: '\E68E';
      font: 4rem/1em $fIcons;
      color: $cWhite;
    }
  }

  .device-menu-wrapper {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    transform: translateX(100%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 32rem;
    height: 100vh;
    background-color: rgba(#07154C, 0.98);
    transition: transform 0.2s ease-in;

    &.is-active {
      transform: translateX(0);
      transition: transform 0.2s ease-in;
    }
  }

  .device-menu-header {
    width: 100%;
    min-height: 7rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: auto;
    background-color: #000B36;
    border-bottom: 1px solid #1F366B;
  }

  .device-menu__username {
    flex: 1 0 auto;
    padding-left: 2rem;
    width: calc(100% - 7rem);

    span {
      display: block;
      width: 100%;
      font: 600 2.4rem/1.2em $fDef;
      color: $cGreen;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .device-menu-body {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .device-menu-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    cursor: pointer;

    &::after {
      content: '\E628';
      font: 4rem/1em $fIcons;
      color: $cWhite;
    }
  }

  .device-menu__accordion {
    order: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    height: calc(100% - 59px - 58px - 49px);
  }

  .device-menu__language-wrapper {
    order: 1;
    margin-top: auto;
    display: flex;
    width: 100%;
    min-height: 7rem;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #000B36;

    a {
      display: block;
      padding: 2rem;
      width: 50%;
      font: 2.4rem/1.2em $fDef;
      color: $cWhite;
      text-align: center;

      &:hover {
        background-color: rgba($cWhite, 0.3);
      }

      &.is-active {
        background-color: #1C2D69;
      }
    }
  }

  ul.device-menu__list,
  ul.device-region__list {
    li {
      border-bottom: 1px solid #1F366B;

      &.is-active {
        background-color: #182761;
      }

      &.item-notify {
        a {
          padding-right: 6rem;
        }

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 2;
          top: 50%;
          margin-top: -1.5rem;
          right: 2rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: $cOrange;
          font: bold 1.6rem/1.875em $fDef;
          color: $cWhite;
        }
      }

      a {
        display: block;
        width: 100%;
        font: 2rem/1.2em $fDef;
        color: $cWhite !important;
        padding: 1.1rem 5rem 1.1rem 2rem;
        position: relative;
      }
    }
  }

  ul.device-region__list {
    li {
      a {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .accordion__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 7rem;
    transition: all 0.2s ease-in;

    &.is-active {
      flex: 1 1 auto;

      .__title {
        &::after {
          transform: rotate(90deg);
          transition: transform 0.2s ease-in;
        }

        span {
          font-family: $fDef;
          font-weight: 600;
        }
      }

      .__body {
        max-height: 100rem;
        transition: max-height 0.2s ease-in;
      }
    }

    .__title {
      width: 100%;
      min-height: 7rem;
      padding: 1.5rem 7rem 1.5rem 2rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      background-color: #000B36;
      border-bottom: 1px solid #1F366B;
      position: relative;

      span {
        display: inline-block;
        vertical-align: middle;
        font: 600 2.2rem/1.8em $fDef;
        color: $cWhite;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        content: '\E649';
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        display: block;
        width: 7rem;
        height: 7rem;
        vertical-align: middle;
        font: 3rem/2.33em $fIcons;
        color: $cWhite;
        text-align: center;
        transform: rotate(0);
        transition: transform 0.2s ease-in;
      }
    }

    .__body {
      width: 100%;
      max-height: 0;
      display: block;
      flex: 1 1 auto;
      overflow: auto;
      overflow-x: hidden;
      transition: max-height 0.2s ease-in;
    }
  }

  .accordion__device-menu {
    transition: all 0.2s ease-in;
  }

  .accordion__select-region {
    transition: all 0.2s ease-in;

    &.is-active {
      height: calc(100vh - 48px - 59px - 58px - 51px);
    }
  }
}


@media screen and ( max-width: 767px ) {
  .device-menu__logotype {
    height: 5rem;
    padding: 1rem 2rem;
  }

  .device-menu__icon {
    padding: 1rem 2rem;

    &::after {
      font-size: 3rem;
    }
  }

  .device-menu-header {
    min-height: 5.8rem;
  }

  .device-menu__username {
    width: calc(100% - 5.8rem);

    span {
      font: 600 1.4rem/1.2em $fDef;
    }
  }

  .device-menu-close {
    width: 5.8rem;
    height: 5.8rem;

    &::after {
      font: 2.2rem/1em $fIcons;
    }
  }

  ul.device-menu__list,
  ul.device-region__list {
    li {
      &.item-notify {
        a {
          padding-right: 6rem;
        }

        i {
          margin-top: -1.5rem;
          right: 2rem;
          width: 3rem;
          height: 3rem;
          font: bold 1.6rem/1.875em $fDef;
        }
      }

      a {
        font: 1.6rem/1.2em $fDef;
        padding: 1.5rem 2rem;
      }
    }
  }

  .device-menu__language-wrapper {
    min-height: 5.8rem;

    a {
      padding: 1.8rem 2rem;
      font: 600 1.8rem/1.2em $fDef;
    }
  }

  .accordion__item {
    min-height: 5rem;

    .__title {
      min-height: 5rem;
      padding: 1.5rem 6rem 1.5rem 2rem;

      span {
        font: 1.6rem/1.2em $fDef;
      }

      &::after {
        width: 5rem;
        height: 5rem;
        font: 2.2rem/2.27em $fIcons;
        margin-left: 1rem;
      }
    }
  }
}

//@media screen and ( max-width: 479px ) {
//  .device-header-outer {
//    .main-header__phone {
//      display: none;
//    }
//  }
//}

.child-profile__left,
.child-profile__inform-column {
  flex-shrink: 0;
  display: block;
  width: 22rem;
  margin-right: 3rem;

  .child-card__photo {
    border: 1px solid #aeb3c6;
    background-color: #f7f9fc;
  }



}
@media screen and (max-width: 768px) {
  .child-profile__left,
  .child-profile__inform-column {margin: auto;}
}

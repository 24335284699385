.s-modal__container {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 70rem;
  text-align: left;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 3.8rem rgba(104, 124, 143, 0.68);
  transform: translateY(-2rem);
  transition: transform $gDuration ease-out;
}

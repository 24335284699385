.sn-field__prepend,
.sn-field__before {
  padding-right: 1.2rem;
}

.sn-field__append,
.sn-field__after {
  padding-left: 1.2rem;

  &:empty {
    display: none;
  }
}

.sn-field__append {
  + & {
    padding-left: 0.2rem;
  }
}

.sn-field__prefix,
.sn-field__suffix {
  @include sflx($alitems: center);
  letter-spacing: 0.00937em;
  line-height: 1.55em;
  text-decoration: inherit;
  text-transform: inherit;
  background: none;
  border: none;
  white-space: nowrap;
  transition: opacity 0.36s cubic-bezier(0.4, 0, 0.2, 1);
}

.sn-field__prefix {
  padding-right: 0.4rem;
}

.sn-field__suffix {
  padding-left: 0.4rem;
}

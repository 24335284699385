.s-bilim-marketing{
  box-shadow: 0 0 1rem rgb(104 124 143 / 28%);
  padding: 4rem 5rem;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  background: #fff;
}

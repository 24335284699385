.sn-avatar {
  @include pos();
  vertical-align: middle;
  display: inline-block;
  border-radius: 50%;
  font-size: 4.8rem;
  height: 1em;
  width: 1em;

  img:not( .s-ico ) {
    border-radius: inherit;
    height: inherit;
    width: inherit;
  }
}

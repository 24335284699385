@media screen and ( max-width: 639px ) {
  .s-blog__profile-wrapper {
    flex-direction: column;
  }

  .s-blog__profile-image {
    margin-bottom: 2rem;
  }

  .s-blog__profile-details {
    padding-left: 0;
  }

  .s-blog__feedback-avatar {
    margin-bottom: 1rem;
  }
}

.sn-table__on-fire {
  tr:last-child th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    max-width: 40rem;
    background-color: $cGrey;
    color: #ffffff;
  }
}

.content-top-outer {
  &.__registration {
    margin-bottom: 3rem;

    .content-top-inner {
      flex-wrap: wrap;
      align-items: center;

      .content-top__left-wrapper {
        width: 25%;

        .breadcrumbs {
          width: 100%;
        }

        .section-title {
          margin-bottom: 0;
        }
      }

      .registration-steps-wrapper {
        justify-content: left;
        width: 75%;
        margin-bottom: 0;
      }
    }
  }

  .content-top-inner {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .breadcrumbs {
      width: 50%;
    }

    > .breadcrumbs {
      width: 100%;
    }

    .section-title {
      margin-bottom: 0;
    }

    .registration-steps-wrapper {
      width: 70%;
    }

    .content-top__full {
      width: 100%;

      .breadcrumbs {
        width: 100%;
      }
    }

    .content-top__left-wrapper {
      width: 49%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 3rem;

      .breadcrumbs {
        width: 100%;
        margin-bottom: 2rem;
      }

      .section-title {
        font-size: 2.8rem;
      }
    }

    .content-top__right-wrapper {
      width: 51%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .filter-block {
        width: 100%;
        max-width: 28rem;
        margin-right: 3rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .form__filter-search {
        max-width: 42.5rem;

        button {
          top: auto;
          margin-top: 0;
          bottom: 0.9rem;
        }
      }

      .modal-inform {
        margin-bottom: 0;
      }
    }

    .content-top__left-vac-wrapper {
      @extend .content-top__left-wrapper;
      width: 32%;
    }

    .content-top__right-vac-wrapper {
      @extend .content-top__right-wrapper;
      width: 68%;

      .filter-block {
        width: 25rem;
        max-width: 100%;
      }

      .form__filter-search {
        input {
          height: 4rem;
        }

        button {
          top: 0;
          right: 0;
          bottom: auto;
          width: 4rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.content-top-outer-new {
  background: url(../images/grey-background.png) repeat-x top;
}

.menu-container {
  display: flex;
}

.breadcrumbs-new {
  margin-left: 11px;
}

.content-outer {
  position: relative;

  .preloader-wrapper {
    .svg {
      display: block;
      margin: auto;
      width: 10%;
      height: 300px;
      transform: rotate(0);
      animation: rotate 4s cubic-bezier(0.51, 0.92, 0.24, 1) forwards infinite;
    }
  }

  .content-inner {

    .section-title {
      text-align: left;
    }
  }
}

.content-outer {
  .content-inner {
    padding-top: 2rem;
    padding-bottom: 2rem;

    .section-title {
      text-align: left;
    }
  }
}

.content__about-wrapper {
  margin-bottom: 5rem;
  margin-top: 2rem;

  .button {
    min-width: 24.6rem;
  }
}

.content__bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .button {
    min-width: 24.6rem;
  }
}

.map-wrapper {
  display: block;
  width: 100%;
  height: 37.2rem;
  margin-bottom: 3rem;
}

.section-title-new {
  text-align: start;
  margin-bottom: 0;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
}

.notification_menu_counter {
  margin-left: 6rem;
  font-style: normal;
  background: #FF8400;
  border-radius: 25px;
  padding: 0 0.7rem;
  color: white;
  font-weight: bold;

  &__upper {
    margin-left: 9.5rem;
  }
}

.content-top__right-free-places-wrapper {
  display: flex;
  align-items: flex-end;
}

@media screen and ( max-width: 1320px ) {
  .content-top-inner-new {
    padding-left: 0! important;
  }
}

@media screen and ( max-width: 999px ) {
  .content-top-outer {
    //&.__registration {
    .content-top-inner {
      flex-direction: column;
      justify-content: flex-start;

      .content-top__right-wrapper {
        justify-content: flex-start;
      }
    }
    //}
  }

  .content-top__left-wrapper {
    width: 100%! important;
    padding-right: 0! important;
    margin-bottom: 3rem;
  }

  .content-top__right-wrapper {
    width: 100%! important;
  }

  .content-top__right-vac-wrapper {
    width: 100%! important;
  }

  .content-top-inner-new {
    flex-direction: row! important;
  }
}
@media screen and ( max-width: 620px ) {
  .content-top-outer-new {
    background: none;
  }
}
@media screen and ( max-width: 699px ) {
  .content-top-outer {
    .content-top-inner {
      .content-top__right-vac-wrapper {
        flex-direction: column;

        .filter-block {
          width: 100%;
          margin-right: 0;
          margin-bottom: 2.5rem;
        }

        .s-form__search {
          width: 100%;
        }
      }
    }
  }

  .content__bottom-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .button {
      width: 100%;
      margin-bottom: 2rem;

      &.button_name_back {
        order: 3;
      }
    }
  }

  .filter_form_queue {
    width: 100%! important;
    display: block! important;
  }

  .filter-block_queue {
    max-width: 100%! important;
    margin-bottom: 15px;
  }
}

.menu-container {
  display: flex;
}
@media screen and ( max-width: 1020px ) {
  .menu-container {
    .profile__menu-wrapper-new {
      display: none;
    }
  }
}
@media screen and ( max-width: 599px ) {
  .content__head-right {
    width: 100%;
  }

  .content-top__right-free-places-wrapper {
    flex-direction: column;
    align-items: flex-start;

    .filter-block {
      min-width: 28rem! important;
      padding-left: 0! important;

      &:not( :last-child ) {
        margin-bottom: 2rem;
      }
    }
  }
}

@media screen and ( max-width: 480px ) {
  .content-top-outer {
    .content-top-inner {
      .content-top__left-wrapper {
        .section-title {
          font-size: 2.2rem;
        }
      }
    }
  }

  .content__about-wrapper {
    margin-bottom: 3rem;

    .button {
      width: 100%;
    }
  }
}
@media screen and ( max-width: 1020px ) {
  .menu-container {
    .profile__menu-wrapper-new {
      display: none;
    }
  }
}
@media screen and ( max-width: 400px ) {
  .content__about-wrapper {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.s-application-block__step-body {
  @include sflx($dir: column, $juscont: center, $alitems: center);
  flex: 1 1 auto;
  min-height: 10rem;
  padding: 1.5rem 1.5rem 2rem;
  text-shadow: inset 0 0.3rem 1rem rgba($cWhite, 0.4),
    inset 0 -0.3rem 1rem rgba($cWhite, 0.4);

  .sn-btn,
  a {
    margin-top: 1rem;
  }

  .sn-btn {
    padding: 0.9rem 1.5rem 0.8rem;
  }
}

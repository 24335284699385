.statistiks-table-fixed{
    position: absolute; 
    left:0; 
    min-width: 0px;

    .statistiks-table-fixed-col{
        height: 20.9rem;
    }
}

@for $name from 1 through 30 {
    .statistiks-table-fixed-#{$name} {
        width: $name * 1rem;
    }
}
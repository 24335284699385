.sn-date__view {
  height: 100%;
  width: 100%;
  min-width: 290px;
  padding: 16px;
}
.sn-date__view-no-nav {
  height: 100%;
  width: 100%;
  min-width: 290px;
  padding: 25px 16px 5px;
}

.s-test-process__wrapper {
  @include pos();
  max-width: 120rem;
  min-height: 64rem;
  border: 1px solid $stpBorderWrapper;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 2.1rem rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: 19px;
  margin-right: 19px;

  @media screen and ( max-width: 1222px ) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    margin-left: 10px;
    margin-right: 0px;
  }
  @media screen and ( max-width: 503px ) {
    margin-left: 0px;
  }
}

.s-loading {
  @include pos();

  .s-loading__wrapper {
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity $gDuration ease-in;
  }
}

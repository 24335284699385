.cabinet-body__child-card-wrapper {
  @include sflx($wrap: wrap, $alitems: stretch);
  padding-top: 4rem;
  padding-bottom: 4rem;

  .child-card {
    width: 22rem;
    margin-bottom: 1.2rem;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }

    .progress-block {
      width: 55.26%;
    }
  }


  @media screen and (max-width: 767px) {
    justify-content: center;

    .child-card {
      width: 28rem;
    }
  }

  @media screen and (max-width: 639px) {
    flex-direction: column;
    align-items: center;

    .child-card {
      margin-right: 0 !important;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.s-test-process__loading {
  @include pos(absolute, 9, 0, 0, 0, 0);
  @include sflx($dir: column, $juscont: center, $alitems: center);
  background-color: rgba($cWhite, 0.98);
  border-radius: $gBorderRadius;
}

.s-test-process__loading-enter-active,
.s-test-process__loading-leave-active {
  transition: opacity 0.5s;
}

.s-test-process__loading-enter,
.s-test-process__loading-leave-to {
  opacity: 0;
}

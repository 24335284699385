.course-block__stat {
  @include sflx($juscont: center, $alitems: center);
  width: 100%;
  font-weight: 600;
  margin-top: auto;

  span {
    font-size: 1.4rem;
    line-height: 1.6em;
    margin-right: 1.8rem;
  }

  strong {
    font-size: 2.2rem;
    line-height: 1em;
  }
}

.s-h-max {
  height: max-content;
  max-height: 100%;
}

.s-h-min {
  height: min-content;
  max-height: 100%;
}

.s-h-100 {
  height: 100%;
}

.inform-block {
  @include sflx($dir: column, $juscont: center, $alitems: center);
  width: 100%;
  min-height: 12rem;
  text-align: center;
  background-color: $cWhite;
  padding: 2rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);
}

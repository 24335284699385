.cabinet-menu__item {
  @include pos();
  display: block;
  width: 100%;
  color: $cBlack;
  padding: 1.05rem 2.5rem;
  overflow: hidden;
  background-color: transparent;
  transition: background-color 0.2s ease-in;

  &:not( :last-child ) {
    border-bottom: 1px solid #ECEEF5;
  }

  &:last-child {
    border-radius: 0 0 $gBorderRadius $gBorderRadius;
  }

  &:first-child {
    border-radius: $gBorderRadius $gBorderRadius 0 0;
  }

  &:hover {
    background-color: #ECEEF5;
    transition: background-color 0.2s ease-in;
  }

  &.is-active {
    font-weight: 600;
    background-color: #ECEEF5;

    &::before {
      @include pos(absolute, $t: 0, $l: 0);
      display: block;
      width: 0.2rem;
      height: 100%;
      background-color: #3045A7;
      content: '';
    }
  }

  &[ data-notify-count ] {
    padding-right: 5rem;

    &::after {
      top: 50%;
      right: 1.5rem;
    }
  }
}

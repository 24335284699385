$cBorder: #d1d8dc;

.select2-container {
  font: 1.6rem/1.2em $font_default;

  .select2-selection--single {
    height: 4rem;
    background: $c_white;
    padding-top: 10px;
    text-align: left;
    color: $c_black;
    border: 1px solid #d1d8dc;
    border-radius: $gBorderRadius;
    box-shadow: none;
    padding-right: 5rem;
    padding-left: 0;
    &:focus{
      outline: none !important;
    }
    .select2-selection__rendered {
      color: $c_black;
      line-height: 20px;
      padding-left: 1.5rem;
      .select2-selection__placeholder {
        color: #99a5b3;
      }
    }
    .select2-selection__placeholder {
      color: $c_black;
    }
    .select2-selection__arrow {
      width: 5rem;
      height: 100%;
      transform: rotate(0);
      transition: transform .2s ease-in;
      b {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        border: 0;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        &:after {
          content: '\E64B';
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          margin-top: -1.1rem;
          margin-left: -1.1rem;
          font: 2.2rem $font_themify;
          color: #d1d8dc;
        }
      }
    }
  }
  .select2-dropdown {
    background: $c_white;
    overflow: hidden;
    border: 1px solid #d1d8dc;
    border-top: 0;
    box-shadow: none;
  }
  .select2-search--dropdown {
    input {
      height: 4rem;
      padding-right: 1rem;
      padding-left: 1rem;
      font: 1.6rem $font_default;
      color: $c_black;
    }
  }
  .select2-results {}
  .select2-results__options {
    margin: 0;
    padding: 0;
    font: 1.6rem/1.2em $font_default;
    color: $c_black;
    text-align: left;
    li {
      border-top: 1px solid #d1d8dc;
      padding: 1rem 2rem;
      &[aria-selected=true] {
        background: #d1d8dc;
        color: $c_black;
      }
      &.select2-results__option--highlighted {
        background-color: lighten(#d1d8dc, 5%);
        color: $c_black;
      }
    }
  }
}
.select2-container--default {
  &.select2-container--focus {
    .select2-selection--multiple {
      border: 1px solid #d1d8dc;
    }
  }
  .select2-selection--multiple {
    border: 1px solid #d1d8dc;
    .select2-selection__rendered {
      padding-top: .3rem !important;
      padding-bottom: .3rem !important;
      .select2-selection__choice {
        padding-top: .4rem !important;
        padding-bottom: .4rem !important;
      }
    }
  }
  .select2-search--inline {
    &:first-child {
      width: 100%;
    }
    .select2-search__field {
      width: 100% !important;
      height: 2.6rem !important;
      margin-top: 0;
    }
  }
  .select2-selection--multiple {
    .select2-selection__rendered {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: .6rem;
      li {
        margin: .3rem;
        input {
          height: 3rem;
          width: auto;
        }
      }
    }
    .select2-selection__choice {
      border: 1px solid $c_blue;
      border-radius: 6px;
      //padding: .35rem 3rem .35rem 1rem;
      padding: .6rem 3rem .6rem 1rem;
      margin-top: 0;
      margin-right: 1rem;
      font: 1.4rem/1.2em $font_default;
      color: $c_black;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      background-color: $c_white;
    }
    .select2-selection__choice__remove {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 1rem;
      display: block;
      margin-top: -1.1rem;
      font: 2.2rem/1em $font_default;
      color: $c_black;
      cursor: pointer;
      margin-right: 0;
    }
  }
}
.select2-container--open {
  .select2-selection--single {
    background: $c_white;
    color: $c_black;
    box-shadow: none;
    .select2-selection__rendered {
      color: $c_black;
      .select2-selection__placeholder {
        color: #99a5b3;
      }
    }
    .select2-selection__arrow {
      transform: rotate(180deg);
      transition: transform .2s ease-in;
      b {
        &:after {
          //color: $c_black;
        }
      }
    }
  }
}

.select2-container--open {
  z-index: 99999;
}

select {
  width: 100%;
  height: 5rem;
  background: $c_white;
  border-radius: 6px;
  font: 1.8rem/1.2em $font_default;
  color: $c_black;
  text-align: left;
  box-shadow: none;
  padding-right: 2rem;
  padding-left: 2rem;
  border: 1px solid #d1d8dc;
}

.kindergarten-block__title {
  @extend %magic-title;
  max-height: 7.7rem;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 2rem;

  &:hover {
    span {
      background-color: $cWhite;
    }
  }

  span {
    background-color: $cWhite;
  }
}
.s-social-icons__facebook {
  @extend %s-social-icons__;

  &::after {
    content: map_get($listOfIcons, 'facebook');
  }
}
.s-social-icons__facebook-fa {
  @extend %s-social-icons-fa__;

  &::after {
    content: map_get($listOfIconsFA, 'facebook');
  }
}

@keyframes sn-linear-progress--indeterminate {
  0% {
    transform: translate3d(-35%, 0, 0) scale3d(0.35, 1, 1);
  }

  60% {
    transform: translate3d(100%, 0, 0) scale3d(0.9, 1, 1);
  }

  100% {
    transform: translate3d(100%, 0, 0) scale3d(0.9, 1, 1);
  }
}

@keyframes sn-linear-progress--indeterminate-short {
  0% {
    transform: translate3d(-101%, 0, 0) scale3d(1, 1, 1);
  }

  60% {
    transform: translate3d(107%, 0, 0) scale3d(0.01, 1, 1);
  }

  100% {
    transform: translate3d(107%, 0, 0) scale3d(0.01, 1, 1);
  }
}

.sn-linear-progress__model {
  transform-origin: 0 0;
  transition: transform 0.3s;

  &--determinate,
  &--indeterminate,
  &--query {
    background: $cWhite;
    transition: none;

    &::before,
    &::after {
      @include pos(absolute, $t: 0, $r: 0, $b: 0, $l: 0);
      background: currentColor;
      transform-origin: 0 0;
      content: '';
    }

    &::before {
      animation: sn-linear-progress--indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &::after {
      transform: translate3d(-101%, 0, 0) scale3d(1, 1, 1);
      animation: sn-linear-progress--indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
    }
  }
}

.s-table-with-filters {
  &__statistic_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      > .sn-table__grid-item-title {
        margin-right: 0.5rem;
      }
    }
  }
}

.popup-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    background-color: #3045A7;
    color: #FFFFFF;
    border-radius: 5px;
    text-align: center;
    padding: 1.2rem 2rem;
    transition-duration: 0.2s;
    width: 100%;

    &:hover {
      background-color: darken(#3045A7, 5%);
      transition-duration: 0.2s;
    }
  }

  &__title {
    font: 2.6rem/1.2em 'Open Sans Light', sans-serif;
    color: #000000;
  }

  &__button-grey {
    background-color: #C3CCD1;
    cursor: default;

    &:hover {
      .popup-buttons__text {
        display: none;
      }

      .popup-buttons__message {
        display: block;
      }
    }

    &:hover {
      background-color: darken(#C3CCD1, 5%);
    }
  }

  &__message {
    display: none;
  }
}

.gov-services-outer {
  padding-top: 4rem;
}

.gov-services-blocks-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2.5rem;
}

.gov-service-block {
  width: 30%;
  margin-right: 5%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(104, 124, 143, 0.28);
  transition: box-shadow 0.2s ease-in, -webkit-box-shadow 0.2s ease-in;
  border-radius: 5px;
  transition-duration: 0.2s;
  position: relative;

  &:hover {
    transition: box-shadow 0.2s ease-in, -webkit-box-shadow 0.2s ease-in;
    box-shadow: 0 0 10px grey;

    .gov-service-block__overlay {
      opacity: 1;
    }
  }

  &:nth-child( 3n + 3 ) {
    margin-right: 0;
  }

  &__up {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3045A7;
    padding: 0 6rem 0 11rem;
    min-height: 7.5rem;
    text-align: center;
    position: relative;


    @media screen and (max-width: 1264px) {
      padding: 0 2rem 0 9rem;
    }
  }

  &__up-image {
    width: 7rem;
    position: absolute;
    left: 2rem;


    @media screen and (max-width: 1264px) {
      width: 6rem;
      left: 1.5rem;
    }
  }

  &__up-header {
    font: 1.8rem/1.2em 'Open Sans', sans-serif;
    color: white;

    @media screen and (max-width: 1264px) {
      font-size: 1.6rem;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    min-height: 16rem;
    text-align: center;
    font: 1.6rem/1.2em 'Open Sans', sans-serif;
    color: black;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(54, 76, 167, 0.6);
    padding-top: 8rem;
    opacity: 0;
    transition: opacity 0.2s ease-in 0.1s;
  }

  &_button__success,
  &_button__asap {
    display: inline-block;
    font-size: 1.8rem;
    line-height: 1.2rem;
    text-align: center;
    padding: 1.45rem 2rem;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    z-index: 3;
    min-width: 10rem;
    transition: transform 0.2s ease-in 0.1s, background-color 0.2s ease-in, -webkit-transform 0.2s ease-in 0.1s;
  }

  &_button__asap {
    background: #D1D8DC;

    &:hover {
      background: darken(#D1D8DC, 5%);
    }
  }

  &_button__denied {
    display: inline-block;
    font-size: 1.8rem;
    line-height: 1.2rem;
    text-align: center;
    padding: 1.45rem 1rem;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    color: white;
    z-index: 4;
    transition: transform 0.2s ease-in 0.1s, background-color 0.2s ease-in, -webkit-transform 0.2s ease-in 0.1s;
    background: #D1D8DC;
  }

  &_button__success {
    background: #9ACF0E;

    &:hover {
      background: darken(#9ACF0E, 5%);
    }
  }
}

.load {position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* change these sizes to fit into your project */
  width: 100px;
  height: 100px;
}

.modal-body-load {
  margin: auto;
  width: 10rem;
  height: 10rem;
  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }

    25% {
      transform: translate(160%);
    }

    50% {
      transform: translate(160%, 160%);
    }

    75% {
      transform: translate(0, 160%);
    }
  }

  &:first-child {
    background: #19A68C;
    animation-delay: -1.5s;
  }

  &:nth-child( 2 ) {
    background: #F63D3A;
    animation-delay: -1s;
  }

  &:nth-child( 3 ) {
    background: #FDA543;
    animation-delay: -0.5s;
  }

  &:last-child {
    background: #193B48;
  }

  hr {
    border: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;
  }
}


@media screen and ( max-width: 999px ) {
  .gov-service-block {
    width: 47.8125%;
    margin-right: 4.375%;

    &:nth-child( 3n + 3 ) {
      margin-right: 4.375%;
    }

    &:nth-child( 2n + 2 ) {
      margin-right: 0;
    }
  }
}
@media screen and ( max-width: 750px ) {
  .gov-service-block {
    width: 100%;
    margin-right: 0;

    &:nth-child( 3n + 3 ) {
      margin-right: 0;
    }
  }
}
@media screen and ( max-width: 600px ) {
  .gov-service-block {
    &__description {
      padding: 1rem;
    }
  }

  .popup-buttons {
    &__button {
      width: 100%;
    }
  }
}
@media screen and ( max-width: 430px ) {
  .popup-buttons {
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media screen and ( max-width: 350px ) {
  .gov-service-block {
    &__up-image {
      display: none;
    }

    &__up {
      padding: 0 1rem;
    }

    &__up-header {
      margin-left: 0;
    }
  }
}

.sn-ripple {
  @include pos(absolute, 0, $t: 0, $l: 0);
  width: 100%;
  height: 100%;
  color: inherit;
  border-radius: inherit;
  pointer-events: none;
  overflow: hidden;
  contain: strict;
}

.tbl-cont__tr-spoiler {
  display: none !important;
  width: 100%;
  // background-color: #d6d9e3;
  transition: all .2s ease-in;

  .tbl-cont {
    tr {
      background-color: unset;

      &:nth-child(odd) {
        td {
          background-color: #d6d9e3;
        }
      }

      &:nth-child(even) {
        td {
          background-color: #dfe2ed;
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid $cWhite;
        }
      }

      td {
        &:not(:last-child) {
          border-right: 1px solid $cWhite;
        }
      }
    }
  }
}

.tbl-cont__tr {
  &.is-active {
    .kindergarten-groups__spoiler {
      transform: rotate(180deg);
    }

    + .tbl-cont__tr-spoiler {
      display: table-row !important;
      //box-shadow: inset 0 4px 14px -7px rgba(0,0,0,.5),
      //            inset 0 -4px 14px -7px rgba(0,0,0,.5);


      @media screen and (max-width: 999px) {
        display: block !important;

        td[colspan] {
          display: block;
          width: 100%;

          .tbl-cont {
            tr {
              &:nth-child(odd) {
                td {
                  background-color: #dfe2ed;
                }
              }
            }
          }
        }
      }
    }
  }
}
.study-block {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    &.__disabled {
      .study-block__image {
        background-color: #677984!important;
        transition: background-color .2s ease-in;
        img {
          filter: grayscale(100%) contrast(150%);
          transition: all .2s ease-in;
        }
      }
      .study-block__selected {
        opacity: 0;
        transition: opacity .2s ease-in;
      }
    }
  }

  .study-block__image {
    width: 100%;
    @include resp_height($height: 100%);
    border-radius: 10px;
    position: relative;
    transition: background-color .2s ease-in;
    .study-block__image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      padding: 30px;
      overflow: hidden;
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transition: all .2s ease-in;
    }
    span {
      display: inline-block;
      margin-top: 20px;
      font: 1.4rem/1em $font_default;
      color: $c_white;
      text-align: center;
      text-transform: uppercase;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  
  .study-block__title {
    font: 1.7rem $font_default;
    text-transform: uppercase;
    color: #5d7277;
    margin-top: 15px;
  }
.sn-tabs {
  transition: color 0.3s, background-color 0.3s;

  &--not-scrollable {
    .sn-tabs__arrow {
      display: none;
    }

    .sn-tabs__content {
      border-radius: inherit;
    }
  }

  &--scrollable {
    .sn-tabs__content {
      overflow-x: auto;
    }
  }

  &--vertical {
    display: block !important;
    height: 100%;

    .sn-tabs__content {
      display: block !important;
      height: calc(100% - 7.2rem);
    }

    .sn-tabs__arrow {
      width: 100%;
      height: 3.6rem;
      text-align: center;
    }

    .sn-tab {
      padding: 0 0.8rem;
      margin-right: 0 !important;

      &:not( :first-child ) {
        margin-top: 1rem;
      }
    }

    .sn-tab__indicator {
      height: unset;
      width: 0.2rem;
    }
  }

  &--vertical.sn-tabs--not-scrollable {
    .sn-tabs__content {
      height: 100%;
    }
  }

  &--vertical.sn-tabs--dense {
    .sn-tab__content {
      min-width: 2.4rem;
    }
  }

  &--dense {
    .sn-tab {
      min-height: 3.6rem;
    }

    &--full {
      min-height: 5.2rem;
    }
  }

  &--thm-button {
    .sn-tab {
      border: 1px solid $cBlue;
      border-radius: $gBorderRadius;

      &:not( :last-child ) {
        margin-right: 2rem;
      }

      &:not( .sn-tab--active ) {
        color: #77848C;
        border-color: $cLightGreyBlue;
      }
    }

    .sn-tab__indicator {
      display: none;
    }
  }
}

body.mobile {
  .sn-tabs {
    &__content {
      overflow: auto;
    }
  }
}

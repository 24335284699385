.sn--animated {
  animation-duration: .3s;
  animation-fill-mode: both;
}


.sn--animated.sn--infinite {
  animation-iteration-count: infinite;
}
.sn--animated.sn--hinge {
  animation-duration: 2s;
}

.sn--animated.sn--flipOutX,
.sn--animated.sn--flipOutY,
.sn--animated.sn--bounceIn,
.sn--animated.sn--bounceOut {
  animation-duration: .3s;
}

.sn--animate-scale {
  animation: a-scale .15s;
  animation-timing-function: cubic-bezier(.25, .8, .25, 1);
}

.sn--animate-fade {
  animation: a-fade .2s; /* rtl:ignore */
}

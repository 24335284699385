.s-steps__area {
  @include sflx($juscont: flex-start, $alitems: center, $dir: column);
  flex: 1;

  &:first-child {
    align-items: flex-end;
  }

  &:last-child {
    align-items: flex-start;

    .s-steps__item-icon {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &.is-active {
    .s-steps__item-icon {
      color: $cWhite;
      background-color: $cBlue;
      transform: scale(1.3);
      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s,
        color 0.1s linear 0.3s,
        background-color 0.1s linear 0.3s;
    }

    .s-steps__chain-line {
      &::before {
        width: 100%;
        transition: width 0.3s linear;
      }
    }
  }
}

.test-cont-wrapper {
  @include pos($z: 1);
  @include sflx($alitems: stretch, $juscont: center);
  width: 100%;
  padding: 3rem;
  overflow: hidden;

  @media screen and (max-width: 1008px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 639px) {
    padding: 2rem 1.5rem;
  }
}

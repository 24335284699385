.sn-date {
  display: inline-flex;
  box-shadow: $shadow-2;
  border-radius: $gBorderRadius;
  background: $cWhite;
  max-width: 100%;

  &--readonly,
  &.is--disabled {
    .sn-date__header,
    .sn-date__content {
      pointer-events: none;
    }
  }

  &--bordered {
    border: 1px solid $separator-color;
  }

  &--readonly {
    .sn-date__navigation {
      display: none;
    }
  }

  &--portrait {
    flex-direction: column;
  }

  &--portrait-standard {
    height: 410px;
    width: 290px;

    .sn-date__content {
      height: calc(100% - 86px);
    }

    .sn-date__header {
      border-top-right-radius: inherit;
      height: 86px;
    }

    .sn-date__header-title {
      align-items: center;
      height: 30px;
    }
  }

  &--portrait-minimal {
    height: 324px;
    width: 290px;

    .sn-date__content {
      height: 100%;
    }
  }

  &--landscape {
    flex-direction: row;
    align-items: stretch;

    > div {
      display: flex;
      flex-direction: column;
    }

    .sn-date__content {
      height: 100%;
    }
  }

  &--landscape-standard {
    height: 321px;
    width: 420px;

    .sn-date__header {
      border-bottom-left-radius: inherit;
      min-width: 110px;
      width: 110px;
    }

    .sn-date__header-title {
      flex-direction: column;
    }

    .sn-date__header-today {
      margin-top: 12px;
    }
  }

  &--landscape-minimal {
    height: 321px;
    width: 310px;
  }

  &--dark {
    color: #fff;
    background: $grey-9;
  }

  .sn-btn {
    font-weight: normal;
  }
}

$gBorderRadius: 5px;
$cBlack: #000;
$cBlackText: #333;
$cDark: #1b2b3b;
$cWhite: #fff;
$cBlue: #364ca7;
$cWhiteBlue: #00a8ff;
$cAqua: #3ca8fb;
$cSalat: #1ab3b1;
$cGreen: #33cc66;
$cGreenDark: #1ab36d;
$cGreenWhite: #57bd6f;
$cOcean: #63b1c0;
//$cGrey: #d8dde2;
$cRed: #ff0000;
$cOrange: #f5942e;
$cLightBlue: #007cd2;
$cBorder: #d1d8dc;
$cDisabled: #d8dde2;
$cDisabledInput: #f8f8f8;
$cPlaceholder: #8a9da8;
$cGreyText: #aeb3c6;
$cError: $cRed;
$cRedBtn: #ff7784;
$cAlertRed: #ffebeb;
$cAlertBlue: #bdedff;
$cAlertGreen: #c9f6d8;


@import 'table-on-fire/index';
@import 'rating-block';
@import 'rating-block__star';
@import 'tooltip';
@import 's-time-sheet';
@import "s-ico-fab";

.s-table-with-filters {
  &__highlighted-rows {
    border-left: 3px solid transparent;
    &--red_flag {
      border-left-color: #ff0000a1 !important;
    }
  }
  &__highlighted-grid-item {
    &--red_flag {
      border: 1px solid #ff0000a1 !important;
    }
  }
}

.picture-panel__preview {
  @include sbg($s: cover);
  width: 100%;
  background-color: #f7f9fc;
  border-radius: $gBorderRadius;

  &::before {
    display: table;
    margin-top: 100%;
    content: '';
  }
}
.form__modal-resume {
  .form__string {
    > label {
      display: block;
      width: 100%;
    }
  }
  .form__radio {
    display: inline-block;
    width: 20rem;
    vertical-align: middle;
  }
  .button {
    font-family: $font_sans_sb;
    min-width: 24.6rem;
  }
  .form__file-upload {
    .button_type_add {
      min-width: 32rem;
    }
  }
  .form__bottom {
    align-items: flex-start;
    .form__string {
      width: 377 / 784 * 100%;
      margin-right: 30 / 784 * 100%;
      margin-bottom: 0;
      text-align: left;
      &:nth-child(2n+2) {
        margin-right: 0;
      }
      .input__date {
        margin-bottom: 0;
      }
    }
  }
}
.form__resume-wrapper {
  padding-top: 3rem;
  border-top: 1px solid $c_blue;
  padding-bottom: 3rem;
  &:first-of-type {
    padding-top: 0;
    border-top: 0;
  }
}
.uploaded__photo {
  display: inline-block;
  vertical-align: bottom;
  height: 17.6rem;
  width: 19.6rem;
  text-align: center;
  background-color: #d1d8dc;
  border-radius: 6px;
  @include sbg($size: contain);
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.uploaded__photo-description {
  width: 100%;
  max-width: 28rem;
  display: inline-block;
  vertical-align: bottom;
  padding-left: 2.6rem;
  .__text {
    width: 100%;
    font: 1.6rem/1.2em $font_default;
    color: $c_black;
    margin-bottom: 3rem;
  }
  .button {
    width: 100%;
  }
}


@media screen and (max-width: 599px) {
  .form__resume-wrapper {
    .form__bottom {
      .button {
          margin-bottom: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .uploaded__photo {
    display: block;
    margin-bottom: 2rem;
  }
  .uploaded__photo-description {
    padding-left: 0;
    display: block;
    max-width: 100%;
  }
}
.home-news-block__desc {
  @include pos($z: 2);
  display: block;
  width: 100%;
  max-width: 48rem;
  font-weight: 300;
  margin-top: 1rem;

  @media screen and (max-width: 559px) {
    display: none;
  }
}
.s-vue-modal__mask {
  @include pos(absolute);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(51, 51, 51, 0.68);

  &--disabled {
    background-color: rgba(0, 0, 0, 0);
  }
}

.s-table-schedule__card {
  @include pos($z: 4);
  display: block;
  width: 100%;
  height: 100%;
  text-align: left;
  background-color: $cWhite;
  padding: 0.5rem 1rem 1rem;
  border: 1px solid #C6CAD6;

  &:hover {
    .s-table-schedule__card-overlay {
      opacity: 1;
      transition: opacity $gDuration ease-in;

      > * {
        display: inline-flex;
      }
    }
  }
}

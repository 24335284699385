.s-auth-form {
  @extend .s-panel;
  max-width: 60rem;
}

.s-auth-form__lost-pass {
  @extend .s-medium;
  color: $cBlue;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom-color: $cBlue;
  }
}

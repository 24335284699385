.s-table__col {
  &--to-center {
    text-align: center;
  }

  &--to-right {
    text-align: right;
  }
}

@for $name from 1 through 24 {
  .s-table__col-#{$name} {
    width: (100 / 24 * $name) * 1%;
  }
}

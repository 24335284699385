.s-test-process__center-label {
  flex-shrink: 0;
  display: block;
  width: 10rem;
  //font-size: 1.4rem;
  text-align: left;

  @media screen and ( max-width: 1264px ) {
    @include pos(absolute);
    width: auto;
    transform: rotate(-90deg) translate(-100%);
    transform-origin: left top;
  }
}

.sn-table__progress {
  height: 0 !important;

  th {
    height: 0 !important;
    padding: 0 !important;
    border-bottom: 1px solid transparent !important;
  }

  .sn-linear-progress {
    position: absolute;
    bottom: -0.1rem;
  }
}

$cBlack: #000000;
$cWhite: #FFFFFF;
$fDef: 'Open Sans', sans-serif;
$fIcons: snation;

.global__date,
.global__views {
  display: inline-block;
  vertical-align: middle;
  font: 1.4rem/1.2em $fDef;
  color: #7d898e;
  white-space: nowrap;
  span {
    &:before {
      font-family: $fIcons;
      margin-right: 1rem;
    }
  }
}
.global__date {
  span {
    &:before {
      content: '\E6B6';
    }
  }
}
.global__views {
  span {
    &:before {
      content: '\E63D';
    }
  }
}

.news-block {
  display: inline-flex;
  flex-direction: column;
  color: $cBlack;
  background-color: $cWhite;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 5px;
  transition: box-shadow .2s ease-in;

  &:hover {
    box-shadow: 0 0 20px rgba(#687c8f, .48);
    transition: box-shadow .2s ease-in;
  }
}
.block__foot {
  display: block;
  padding: 0 2rem 2rem;
  text-align: right;
  .global__date {
    margin-right: 2.5rem;
  }
}
.news-block__wrapper {
  flex: 1 0 auto;
  padding-bottom: 2rem;
}
.news-block__image {
  width: 100%;
  height: 16.8rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 6px 6px 0 0;
}
.news-block__title,
.news-block__desc {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.news-block__title {
  font: 600 1.8rem/1.2em $fDef;
  padding-top: 1.5rem;
}
.news-block__desc {
  font: 300 1.6rem/1.4em $fDef;
  padding-top: 2rem;
}


@media screen and (max-width: 767px) {
  .news-block__title,
  .news-block__desc {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .news-block__title {
    font-size: 1.6rem;
  }
  .news-block__desc {
    font-size: 1.5rem;
  }
}

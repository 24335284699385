// Common
.attendance-calendar-icon {
  padding: 1.6rem 0;
}

.attendance-panel {
  display: flex;
  flex-direction: row;

  .row div:not(:last-child):not(.col-1) {
    margin-right: 1.5rem;
  }

  .load-btn-wrapper {

    .sn-field__wrapper {
      text-align: right;
    }

    margin-left: auto;
  }
}

@media screen and (max-width: 1050px) {
  .attendance-panel {
    .attendance-calendar-field {
      min-width: 40%;
    }

    .load-btn-wrapper {
      min-width: 40%;
    }
  }
}

@media screen and (max-width: 768px) {
  .attendance-panel {
    .row div:not(:last-child):not(.col-1) {
      margin-right: unset;
    }

    .attendance-calendar-field {
      min-width: 100%;

      .sn-field__wrapper {
        min-width: 100%;
      }
    }

    .attendance-calendar-icon {
      display: none;
    }

    .load-btn-wrapper {
      min-width: 10%;
    }
  }
}

.load-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// Attendance
.container-body-wrapper.container-body-wrapper-min {
  min-height: auto;
}

// Journal
.attendance-warning-info {
  margin-top: 3em;
}

.attendance-journal {
  .row {
    border-top: 1px solid #BEC7CD;

    &:last-child {
      border-bottom: 1px solid #BEC7CD;
    }

    &:nth-child(even) {
      background-color: #F7F9FC;
    }

    .col {
      padding: 1rem 1.2rem;
      min-height: 4.5rem;
      font-size: 1.6rem;
      border-right: 1px solid #BEC7CD;
      user-select: none;

      &:first-child {
        border-left: 1px solid #BEC7CD;
      }
    }

  }

  .row-head {
    .col {
      font-weight: bold;
    }
  }

  .row-footer {
    .col {
      padding-top: 1.3rem;
    }

    .col-head {
      font-weight: bold;
    }
  }

  .attendance-card-row {
    padding-top: 0.5em;
  }

  .attendance-row {
    .row-load {
      display: inline-block;
      margin: 0 1em;
    }
  }
}

@media (max-width: 768px) {
  .attendance-journal {
    .row {
      .col {
        padding: 1rem 0.5rem;
        font-size: 0.9em;

        &:first-child {
          padding: 1rem 0.3rem;
          text-align: center;
        }
      }
    }

    .row-footer {
      .col {
        &:last-child {
          padding-top: 1.5rem;
          font-size: 1.2em;
          text-align: center;
        }
      }
    }
  }
}

.journal-table {
  td, th {
    text-align: left;
  }

  tr {
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

.attendance-card-control {
  .sn-spinner {
    margin: 0 1em;
  }
}

// TimeSheet

.time-sheet-page {
  width: 100%;
  padding: 1em 0 3em;
}

.time-sheet-info-bar {
  border: 1px solid #CCD1E9;
  border-radius: 0.5em;
  padding: 1em;
  user-select: none;
}

.time-sheet-info-helper {
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  vertical-align: center;
  user-select: none;
  flex-wrap: wrap;

  .helper-row {
    padding: 0.5em 0;
    width: 50%;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
  }

  .helper-text {
    max-width: 100%;
    display: inline-block;
    padding-left: 1em;
  }

  .helper-chip {
    display: inline-block;
    border: 1px solid #F5F6FB;
    background-color: #F5F6FB;
    padding: 0.7em 0 0.9em;
    border-radius: 2em;
    text-align: center;
    width: 3em;
    font-weight: bold;

    &.vacancy {
      color: #97A5E5;
    }

    &.absent {
      color: #B00000;
    }
  }

  .helper-chip-custom {
    display: inline-block;
    border: 1px solid #F5F6FB;
    background-color: #F5F6FB;
    padding: 0.7em 0 0.9em;
    border-radius: 2em;
    text-align: center;
    width: 3em;
    font-weight: 400;

    &.grey-color {
      color: #737A97;
    }

    &.vacancy {
      color: #97A5E5;
    }

    &.absent {
      color: #B00000;
    }

    &--bold {
      font-weight: 700;
    }

    &.green {
      color: #8BBD20;
    }

    &.moved {
      color: #8A8981;
    }

    &.dropped {
      color: #6A645C;
    }
  }
}

@media screen and (max-width: 1050px) {
  .time-sheet-info-helper {
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 0;

    .helper-row {
      width: 100%;
    }
  }
}

.sn-contacts-form__input {
  @include placeholder(#b0b7dd);
  display: block;
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  font-family: $fDef;
  color: $cBlue;
  background-color: $cWhite;
  padding-right: 3rem;
  padding-left: 3rem;
  border: 1px solid #d6daec;
  border-radius: 8px;
  box-shadow: 0 2px 24px rgba(48, 69, 167, 0.15);
  outline:none;
}

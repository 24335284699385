.s-application-block__step {
  @include sflx($dir: column);
  width: 25%;

  &:not( :last-child ) {
    .s-application-block__step-head--waiting {
      border-right: 1px solid #DEE0EA;
    }

    .s-application-block__step-body {
      @include pos();

      &::after {
        @include pos(absolute, 1, 50%, $r: 0);
        transform: translateY(-50%);
        width: 0.1rem;
        height: 60%;
        background-color: $cBorder;
        content: '';
      }
    }
  }
}

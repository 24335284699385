@mixin s-notification--thm($bg, $darker, $icon) {
  background: $bg;

  %s-notification--darker {
    color: $darker;
  }

  &::before {
    @extend .s-ico--thm-#{$icon};
    color: $darker;
  }
}

@media screen and ( max-width: 1008px ) {
  .site-body__right-column {
    max-width: 100%;
    .container-content-head {
      margin-top: 0;
    }
  }
}



@media screen and ( max-width: 639px ) {
  .site-body__right-column {
    .container-content-head {
      margin-top: 0;
    }
  }
}

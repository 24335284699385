.s-statistic-panel {
  @include sflx($dir: column, $alitems: center);
  width: 100%;
  min-width: 14rem;
  max-width: 17.6rem;
  height: 100%;
  color: $cBlueDark;
  padding: 1.5rem;
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;

  &.is-loading {
    .s-statistic-panel__icon,
    .s-statistic-panel__label,
    .s-statistic-panel__value {
      @include sbg($i: $gLoaderPlaceholder, $s: cover);
      transition: background $gDuration ease-in;
    }

    .s-statistic-panel__icon {
      img {
        display: none;
      }
    }

    .s-statistic-panel__label {
      min-height: 1.3rem;
    }

    .s-statistic-panel__value {
      min-height: 1.3rem;
    }
  }
}

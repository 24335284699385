.s-table-schedule__head {
  @include pos($z: 3);

  tr {
    th {
      @include pos($z: 4);
      height: 4.5rem;
      font-weight: 600;
      color: $cWhite;
      background-color: #AEB3C6;
    }
  }
}

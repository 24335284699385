.s-modal__close {
  @include pos(absolute, $t: 0, $r: 0);
  @include sflx($juscont: center, $alitems: center);
  width: 4.5rem;
  height: 100%;
  font-size: 2rem;
  font-family: $fIcons;
  border-top-right-radius: $gBorderRadius;
  cursor: pointer;
  background-color: transparent;
  transition: background-color $gDuration linear;
  color: currentColor;

  &::after {
    content: map-get($listOfIcons, 'close');
    font-weight: 400;
    transform: rotate(0) scale(1);
    transition: all $gDuration ease-out;
  }

  &:hover {
    background-color: darken($cBlue, 5%);
    transition: background-color $gDuration linear;

    &::after {
      font-weight: 700;
      transform: rotate(90deg) scale(1.1);
      transition: all $gDuration ease-in;
    }
  }
}

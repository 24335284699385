$listOfModify: (
  ('end', flex-end),
  ('center', center),
  ('start', flex-start),
  ('between', space-between),
  ('around', space-around),
  ('evenly', space-evenly),
);

%row-sort {
  @include sflx();
  width: 100%;

  @media screen and ( max-width: 600px ) {
    flex-direction: column;
    align-items: center;

    .s-btn {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.row-sort {
  @each $name, $val in $listOfModify {
    &--#{$name} {
      align-items: $val;
    }
  }
}

@each $name, $val in $listOfModify {
  .row-sort-#{$name} {
    @extend %row-sort;
    justify-content: $val;
  }
}

.row-sort-wrap {
  flex-wrap: wrap;
}

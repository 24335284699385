.sn-virtual-scroll {
  &:focus {
    outline: none;
  }

  &--horizontal {
    @include sflx($wrap: nowrap, $alitems: stretch);

    .sn-virtual-scroll {
      &__content {
        @include sflx($wrap: nowrap);
      }

      &__padding,
      &__content,
      &__content > * {
        flex: 0 0 auto;
      }

      &__padding {
        background: linear-gradient(to left,
          transparent,
          transparent 20%,
          rgba(128, 128, 128, 0.03) 20%,
          rgba(128, 128, 128, 0.08) 50%,
          rgba(128, 128, 128, 0.03) 80%,
          transparent 80%,
          transparent);
        background-size: 50px 100%;
      }
    }
  }
}

.s-info-card {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  .s-table th {
    color: #8F98B0;
    font-weight: 600;
  }
}

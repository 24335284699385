%input {
  display: block;
  width: 100%;
  color: $cBlack;
  background-color: $cWhite;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border: 1px solid $cBorder;
  border-radius: $gBorderRadius;

  &::placeholder {
    color: $cPlaceholder;
  }

  &[ disabled ] {
    color: $cDarkPlaceholder;
    user-select: none;
  }
}

.kindergarten-search__button {
  @include pos(absolute, $t: 0, $r: 0);
  @include sflx($alitems: center, $juscont: center);
  width: 4.5rem;
  height: 100%;
  font-size: 2.2rem;
  font-family: $fIcons;
  color: #7e8695;

  &::after {
    content: '\E610';
  }
}
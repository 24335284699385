.s-form__upload {
  display: flex;

  .s-btn:hover {
    .s-str-ico--thm-close::before {
      transform: translateY(-50%) rotate(90deg);
      transition: all 0.2s ease-in;
    }
  }
}

//button {
//  background: none;
//  border: 0;
//  cursor: pointer;
//}
//label {
//  display: inline-block;
//  font: 1.6rem/1.2em $font_default;
//  color: $c_black;
//  padding-bottom: .7rem;
//  i {
//    color: $c_red;
//  }
//}
//
//.form__string {
//  position: relative;
//  margin-bottom: 3rem;
//  .btn--inside{
//    position: absolute;
//    right: 25px;
//    top: 43px;
//    border: none;
//    font-size: 1.2rem;
//    color: $c_green;
//    border:none;
//    border-radius:100%;
//    outline:none;
//    text-align:center;
//    font-weight:bold;
//    padding:2px;
//  }
//  &:last-of-type {
//    margin-bottom: 0;
//    text-align: right;
//  }
//  &.is-disabled {
//    input {
//      pointer-events: none;
//      background: $c_grey_dark;
//    }
//  }
//  &.is-success {
//    .form__warning {
//      color: $c_green;
//    }
//  }
//  &.is-error {
//    input {
//      border-color: $c_red;
//    }
//    .form__warning {
//      color: $c_red;
//    }
//    .input__date {
//      margin-bottom: 0;
//    }
//  }
//  > label {
//    display: block;
//  }
//  input:disabled{
//    background-color: #eee;
//  }
//}
//.form__string_ckeditors {
//  .cke_reset {
//    width: 100%;
//  }
//  .cke_bottom {
//    display: none;
//  }
//}
//.form__string--label-left {
//  &:last-of-type {
//    text-align: left;
//  }
//}
//.form__date {
//  @extend .form__string;
//  max-width: 32rem;
//  //input {
//  //  display: inline-block;
//  //  vertical-align: middle;
//  //  width: 6rem;
//  //  margin-right: 2rem;
//  //  padding-right: .5rem;
//  //  padding-left: .5rem;
//  //  text-align: center;
//  //  &:last-of-type {
//  //    margin-right: 0;
//  //  }
//  //}
//}
//.form__datetime {
//  @extend .form__string;
//  width: 100%;
//  display: flex;
//  justify-content: flex-start;
//  align-items: stretch;
//  .input__date {
//    flex: 1 0 auto;
//    margin-right: 2rem;
//    margin-bottom: 0;
//  }
//  .input__time {
//    width: 9rem;
//  }
//  .select__time {
//    width: 22.8rem;
//  }
//}
//.form__select-button{
//  width: 100%;
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//  .action-text {
//    width: 22rem;
//    min-width: 22rem;
//    font: 1.8rem/1.2em $font_default;
//    color: $c_blue;
//    text-align: right;
//    padding-left: 2rem;
//    span {
//      border-bottom: 1px dotted lighten($c_blue, 1%);
//      cursor: pointer;
//    }
//  }
//}
//.form__date-address {
//  width: 100%;
//  display: flex;
//  justify-content: flex-start;
//  align-items: stretch;
//  .form__string {
//    &:first-child {
//      width: 23rem;
//      margin-right: 3rem;
//      .input__date {
//        margin-bottom: 0;
//      }
//    }
//    &:last-of-type {
//      flex: 1 0 auto;
//      text-align: left;
//    }
//  }
//}
//.input__date {
//  margin-bottom: 2.5rem;
//  position: relative;
//  &:after {
//    content: '\E6B6';
//    position: absolute;
//    z-index: 2;
//    top: 50%;
//    margin-top: -1.1rem;
//    right: 1.5rem;
//    font: 2.2rem/1em $font_themify;
//    color: #acb9c0;
//  }
//  input {
//    padding-right: 5rem;
//  }
//}
//.form__right {
//  @extend .form__string;
//  text-align: right;
//}
//.form__bottom {
//  width: 100%;
//  display: flex;
//  justify-content: space-between;
//  align-items: center;
//  margin-bottom: 3rem;
//  &:last-of-type {
//    margin-bottom: 0;
//  }
//}
//.form__info {
//  font: 1.6rem/1.2em $font_default;
//  color: $c_grey;
//  a {
//    color: $c_blue;
//    &:hover {
//      border-bottom: 1px solid darken($c_blue, 1%);
//    }
//  }
//}
//.form__lost-password {
//  font-size: 1.6rem;
//  line-height: 1.2em;
//  font-family: $font_sans_sb;
//  a {
//    color: $c_blue;
//    &:hover {
//      border-bottom: 1px solid darken($c_blue, 1%);
//    }
//  }
//}
//
//.form__warning {
//  font-size: 1.6rem;
//  line-height: 1.2em;
//  font-family: $font_default;
//  padding-top: 1rem;
//  a {
//    font-family: $font_sans_sb;
//    color: $c_blue;
//    &:hover {
//      border-bottom: 1px solid $c_blue;
//    }
//  }
//}
.filelist {
  display: block;
  width: 100%;
  li {
    display: block;
    max-width: 28rem;
    border: 1px solid $c_black;
    border-radius: 6px;
    padding: 0.8rem 5rem 0.9rem 2rem;
    margin-top: 1.5rem;
    font: 1.8rem/1.2em $font_default;
    color: $c_black;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    i {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 1.4rem;
      display: block;
      margin-top: -1.1rem;
      font: 2.2rem/1em $font_themify;
      cursor: pointer;
      &:after {
        content: '\E646';
      }
    }
  }
}
//.form__string-optional {
//  @extend .form__string;
//  width: 100%;
//  display: flex;
//  justify-content: flex-start;
//  align-items: flex-start;
//  .__left {
//    flex: 1 1 auto;
//    margin-right: 3rem;
//  }
//  .__right {
//    width: 24.6rem;
//  }
//}
//
//.form__item {
//  @extend .form__string;
//  &:last-of-type {
//    margin-bottom: 3rem;
//    text-align: left;
//  }
//}
//
//
//@media screen and (max-width: 599px) {
//  .form__string {
//    &:last-of-type {
//      .button {
//        width: 100%;
//      }
//    }
//  }
//  .form__bottom {
//    flex-direction: column;
//    justify-content: flex-start;
//    align-items: flex-start;
//    .time-block {
//      margin-bottom: 2.5rem;
//    }
//    .button, .form__string {
//      width: 100% !important;
//      max-width: none !important;
//      margin-right: 0 !important;
//      &:first-child {
//        margin-bottom: 3rem !important;
//      }
//    }
//  }
//  .form__datetime {
//    flex-direction: column;
//    justify-content: flex-start;
//    align-items: flex-start;
//    .input__date {
//      width: 100%;
//      margin-right: 0;
//      margin-bottom: 2rem;
//    }
//    .select__time {
//      width: 100%;
//    }
//  }
//  .form__string-optional {
//    flex-direction: column;
//    .__left {
//      width: 100%;
//      margin-right: 0;
//      margin-bottom: 3rem;
//    }
//    .__right {
//      width: 100%;
//      label {
//        width: 100%;
//      }
//      .button {
//        width: 100%;
//      }
//    }
//  }
//}
//@media screen and (max-width: 479px) {
//  .form__select-button{
//    flex-direction: column;
//    align-items: flex-start;
//    .select2-container {
//      margin-bottom: 2rem;
//    }
//    .action-text {
//      width: 100%;
//      text-align: center;
//      padding-left: 0;
//    }
//  }
//}

//
//.form__checkbox, .form__radio {
//  input[type=checkbox], input[type=radio] {
//    display: none;
//    &:checked {
//      + label {
//        &:before {
//          content: '\E64D';
//          top: 0;
//          left: -.1rem;
//          font-size: 1.7rem;
//        }
//      }
//    }
//    + label {
//      cursor: pointer;
//      padding-bottom: 0;
//      padding-left: 3rem;
//      position: relative;
//      &:before {
//        content: '\E6E8';
//        position: absolute;
//        z-index: 1;
//        top: .2rem;
//        left: 0;
//        font: 1.4rem/1.2em $font_themify;
//      }
//      a {
//        font-weight: bold;
//        color: $c_blue;
//        &:hover {
//          border-bottom: 1px solid darken($c_blue, 1%);
//        }
//      }
//    }
//  }
//}

.form__bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.form__string {
  position: relative;
  margin-bottom: 3rem;

  label {
    display: inline-block;
    margin-bottom: 1rem;
  }
}

.sn-date__header-link {
  opacity: 0.64;
  outline: 0;
  transition: opacity 0.3s ease-out;

  &--active,
  &:hover,
  &:focus {
    opacity: 1;
  }
}

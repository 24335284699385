.vacancy-block {
  @extend .block;
  cursor: pointer;
}
.vacancy-block__head {
  display: block;
  border-bottom: 1px solid #d1d8dc;
  padding: 0 2rem;
}
.vacancy-block__title {
  width: 100%;
  min-height: 5.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: 1.8rem/1.2em $font_sans_sb;
  color: $c_black;
  >span {
    flex: 1 1 auto;
  }
  .vacancy-block__icon {
    flex: 1 0 auto;
  }
}
.vacancy-block__icon {
  display: block;
  min-width: 3.5rem;
  width: 3.5rem;
  max-width: 3.5rem;
  text-align: center;
  margin-right: 2rem;
  img {
    max-width: 100%;
  }
}
.vacancy-block__body {
  display: block;
  padding: 2rem;
  ul {
    display: block;
    width: 100%;
    li {
      display: block;
      width: 100%;
      font: 1.6rem/1.2em $font_default;
      color: $c_black;
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


@media screen and (max-width: 480px) {
  .vacancy-block__head {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 359px) {
  .vacancy-block__title {
    font-size: 1.6rem;
  }
  .vacancy-block__icon {
    min-width: 3rem;
    width: 3rem;
    max-width: 3rem;
  }
}
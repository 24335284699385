.sn--touch {
  user-select: none;
  user-drag: none;
  -khtml-user-drag: none;
  -webkit-user-drag: none;
}

.sn--touch-x {
  touch-action: pan-x;
}

.sn--touch-y {
  touch-action: pan-y;
}


.home-head-slide-outer {
  @extend .container-body-wrapper;
  flex: unset;
}

.home-head-slide-inner {
  @extend .container-content-container;

  .btn_thm_blue {
    background-color: #122775;

    &:hover {
      background-color: darken(#122775, 5%);
    }
  }
}


@media ( max-width: 978px ) {
  .s-insta-carousel {
    .owl-nav {
      .owl-prev {
        margin-left: -2rem;
      }

      .owl-next {
        margin-right: -2rem;
      }
    }
  }
}

.s-questions-map {
  @include pos($z: 2);
  display: block;
  width: 100%;
  background-color: $cWhite;
  padding: 2rem 2rem 8rem;

  @media screen and ( max-width: 1008px ) {
    border-left: 1px solid #E2ECF2;
    border-bottom: 1px solid #E2ECF2;
    box-shadow: -0.1rem 0.1rem 1.2rem rgba(0, 0, 0, 0.1);
  }
}

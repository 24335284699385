.kindergarten-block__statistic-wrapper {
  @include sflx($alitems: stretch);
  width: 100%;

  .statistic-block {
    flex: unset;

    &:nth-child(1),
    &:nth-child(2) {
      width: 32%;
    }

    &:nth-child(3) {
      width: 36%;
    }
  }
}
.s-form-filter__spoiler-head {
  &.is-active {
    .s-str-ico--thm-collapse {
      display: inline-block;
    }

    .s-str-ico--thm-expand {
      display: none;
    }
  }

  .s-str-ico--thm-collapse {
    display: none;
  }

  .s-str-ico--thm-expand {
    display: inline-block;
  }
}

.form-upload {
  @include sflx();

  input[type="file"] {
    display: none;
  }

  &__message {
    flex: 1 1 auto;
    padding-right: 2rem;
  }

  &__button {
    flex-shrink: 0;

    .btn {
      min-width: 13rem;
    }
  }


  @media screen and (max-width: 479px) {
    flex-direction: column;

    &__message {
      margin-bottom: 2rem;
    }
  }
}
@media (max-width: $breakpoint-xs-max) {
  .sn-dialog__inner--top {
    padding-left: 0;
    padding-right: 0;
    >div {
      width: 100% !important;
    }
  }

  .sn-dialog__inner--bottom {
    padding-left: 0;
    padding-right: 0;

    > div {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .sn-dialog__inner--minimized{
    padding: 12px;
  }
}

//@media (min-width: $breakpoint-sm-min) {
//  .sn-dialog__inner--minimized {
//
//    > div {
//      max-width: 560px;
//    }
//  }
//}

.sn-table__card {
  color: $cBlack;
  background-color: $cWhite;
  border-radius: $gBorderRadius;
  box-shadow: $shadow-2;

  &--dark {
    color: $cWhite;
    background: $grey-9;
  }
}

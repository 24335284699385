.s-flag {
  @include pos();
  display: inline-block;
  font-size: 1em;
  padding: 0.3em 1em 0.3em 1.5em;
  margin-right: 0.8em;

  &::before,
  &::after {
    @include pos(absolute, $t: 0);
    width: 0;
    height: 0;
    border-top: 0.86em solid transparent;
    border-bottom: 0.86em solid transparent;
    border-left-width: 0.8em;
    border-left-style: solid;
    content: '';
  }

  &::before {
    left: 0;
    border-left-color: $cWhite;
  }

  &::after {
    left: 100%;
  }

  @each $colorName, $color in $listOfColors {
    &.s-b-#{$colorName} {
      &::after {
        border-left-color: $color;
      }
    }
  }
}

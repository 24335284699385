.cabinet-body__buttons-wrapper {
  @include sflx($juscont: flex-end);
  width: 100%;
  padding: 2rem 0;

  > *:not(:last-child) {
    margin-right: 2rem;
  }


  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;

    > * {
      width: 100%;
      text-align: left;
      margin-top: 2rem;
    }
  }
}

.cabinet-body__buttons-left-wrapper {
  @include sflx();
  width: 100%;
  padding: 2rem 0;

  > *:not(:last-child) {
    margin-right: 2rem;
  }


  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;

    > * {
      width: 100%;
      text-align: left;
      margin-top: 2rem;
    }
  }
}

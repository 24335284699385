.sn-table__sort-icon {
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  opacity: 0;
  font-size: 120%;

  &--left,
  &--center {
    margin-left: 0.4rem;
  }

  &--right {
    margin-right: 0.4rem;
  }
}

.form__title {
  position: relative;
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 3rem;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .1rem;
    background-color: black;
    content: '';
  }

  span {
    position: relative;
    display: inline-block;
    background-color: white;
    padding-right: .6rem;
  }
}
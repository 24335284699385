%s-file {
  background-color: $cWhite;
  padding: 1rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 0.9rem rgba(74, 102, 126, 0.28);
  transition: all $gDuration ease-in;

  &:hover {
    .s-file__overlay-control {
      opacity: 1;
      transition: opacity $gDuration ease-in;
    }
  }
}

.s-file {
  @extend %s-file;
}

.s-file-select {
  @extend %s-file;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1.9rem rgba(74, 102, 126, 0.28);
    transition: all $gDuration ease-in;
  }

  &--selected {
    background-color: $cGreen;
    transition: all $gDuration ease-in;

    .s-file__overlay-control {
      opacity: 1;
      transition: opacity $gDuration ease-in;
    }

    .s-file__btn--thm-select {
      color: $cWhite;
      border-color: $cGreen;
      background-color: $cGreen;

      &::after {
        content: map-get($listOfIcons, 'close');
      }
    }

    .s-file__name {
      color: $cWhite;
    }
  }
}

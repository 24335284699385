.s-modal {
  @include pos(fixed, 1000, 0, 0, 0, 0);
  //@include sflx($d: none, $alitems: center, $juscont: center);
  display: none;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow-y: auto;

  &::before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    height: 100%;
    margin-right: -0.5rem;
  }

  &.fade {
    opacity: 0;
    transition: opacity $gDuration linear;

    &.in,
    &.show {
      opacity: 1;

      .s-modal__container {
        transform: translateY(0);
        transition: transform $gDuration ease-in;
      }
    }
  }

  &.in,
  &.show {
    background-color: rgba(51, 51, 51, 0.68);
  }

  &--small {
    .s-modal__container {
      max-width: 50rem;
    }
  }

  &--big {
    .s-modal__container {
      max-width: 100.9rem;
    }
  }

  &--auto {
    .s-modal__container {
      width: auto;
      max-width: unset;
    }
  }

  &--regions {
    .s-modal__container {
      max-width: 82rem;
    }
  }

  &--photo {
    .s-modal__container,
    .s-modal__head {
      background-color: unset;
    }

    .s-modal__container {
      max-width: 86rem;
      box-shadow: unset;
    }
  }
}

.modal-open {
  overflow: hidden;
  // possible hack for ios backgound scroll
  // has a bug that throws user to start of the page
  // position: fixed;
}

.s-file__wrapper {
  @include sflx($wrap: wrap);
  width: calc(100% + 2rem);
  margin-left: -1rem;

  .s-file,
  .s-file-select {
    flex-shrink: 0;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
}

.site-body__right-column {
  .s-file__wrapper {
    .s-file,
    .s-file-select {
      width: 26.7rem;
    }
  }
}

.s-modal__body {
  .s-file__wrapper {
    .s-file-select,
    .s-file {
      width: 22.7rem;
    }
  }
}

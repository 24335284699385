.sn--fit {
  width: 100% !important;
  height: 100% !important;
}

.sn--full-height {
  height: 100% !important;
}

.sn--full-width {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sn--window-height {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  height: 100vh !important;
}

.sn--window-width {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100vw !important;
}

.sn--block {
  display: block !important;
}

.sn--inline-block {
  display: inline-block !important;
}

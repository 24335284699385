.s-table-with-filters {
  &__clickable-rows {
    .sn-table {
      tbody {
        tr {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

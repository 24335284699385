@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-title {
    font-size: 2.8rem;
  }
}


@media screen and ( max-width: 479px ) {
  .s-title {
    font-size: 2.2rem;
  }
}

#table-curriculum-table {
  input {
    &.has-error {
      border-color: #ff0000;
    }
  }

  tr[data-discipline] {
    &.hidden {
      display: none;
    }
  }
}

table.educational-process-table {
  thead {
    th {
      border: 1px solid #dfe2ed;
      min-width: 5.5rem;
    }
  }
  tbody {
    tr.number-course {

    }
  }
}

.table_edit_cell {
  .table_edit_cell_edit {
    display: none;
  }

  .table_edit_cell_value {
    min-height: 100%;
    min-width: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 3rem;
  }

  &:hover {
    .table_edit_cell_value {
      display: none;
    }

    .table_edit_cell_edit {
      display: inline-flex;
    }
  }
}

.curriculum-table-wrapper {
  padding: 40px 0;
}

table.curriculum-table {
  thead {
    & > tr {
      th:nth-child(2) {
        width: 35%;
      }

      th:nth-child(3) {
        width: 30%;
      }

      th:nth-child(4) {
        width: 15%;
      }
    }
  }
}

table.curriculum-info-table {
  tbody {
    tr {
      td:first-child {
        width: 25%;
      }
    }
  }
}

.modal-curriculum-create {
  .modal-dialog {
    max-width: 800px;
  }
}

.modal-discipline-delete {
  .modal-dialog {
    max-width: 450px;
    text-align: center;
  }
}

.modal-curriculum-delete {
  .modal-dialog {
    max-width: 450px;
    text-align: center;
  }
}

.form-сurriculum-create {
  select[disabled] {
    color: $c_grey;
  }

  &.loading-progress {
    &:before {
      border-color: $c_blue;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}

.curriculum-plan-top {
  padding: 40px 0;
}

.curriculum-plan-wrapper {
  //max-width: 170.8rem;
  width: 100%;
  padding: 40px 0;
  //margin-top: 90px;
}

@media screen and (max-width: 1749px) {
  .container-body-wrapper.curriculum-body-wrapper {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-organization-profile__column-left {
    width: 36rem;
  }

  .s-organization-profile__item-label,
  .s-organization-profile__item-value{
    width:50%;
    margin-right: 0;
  }
}

@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-organization-profile__columns-wrapper {
    flex-direction: column;
  }

  .s-organization-profile__column-left {
    width: 100%;
  }

  .s-organization-profile__column-right {
    padding-left: 0;
  }

  .s-photo-album__modal-container {
    min-width: 10rem;
    min-height: 10rem;
  }

  .s-organization-profile__panel {
    padding: 0;
    border: 0;

    .s-list-between__row {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.sn-contact-warning{
  background: rgba(186, 194, 228, 0.4);
  border-radius: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: rgba(49, 73, 176, 0.6);
}

.sn-contact-warning-message{
  margin-left: 2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  max-width: 90%;
}

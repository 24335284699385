.sn-date__navigation {
  height: 12.5%;

  > div {
    &:first-child {
      width: 8%;
      min-width: 24px;
      justify-content: flex-end;
    }

    &:last-child {
      width: 8%;
      min-width: 24px;
      justify-content: flex-start;
    }
  }
}

.s-region__department-container {
  width: 100%;
  padding: 0 1.5rem;

  &.is-active {
    padding-bottom: 1.5rem;
    border: 1px solid $cBorder;
    border-radius: $border-radius;
  }
}

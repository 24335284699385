.footer__panel {
  @include sflx($alitems: center);
}

.footer__panel_left {
  align-items: flex-start;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.footer__panel_right {
  justify-content: flex-end;
  flex-shrink: 0;
}
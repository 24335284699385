.s-file__preview {
  @include pos();
  @include sbg($s: contain);
  display: block;
  width: 100%;
  background-color: #F7F9FC;
  border: 1px solid #AEB3C6;
  border-radius: $gBorderRadius;
  padding-top: 100%;
  overflow: hidden;
  &--ext-label {
    position: absolute;
    top: 0;
    right: 0;
    font-weight: 600;
    padding: 0.5rem;
    font-size: 2rem;
  }
}

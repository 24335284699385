.s-questions-map__item-point {
  @include sflx($alitems: center, $juscont: center);
  width: 2rem;
  height: 2.2rem;
  border-radius: 3px;
  user-select: none;

  &:not( :last-child ) {
    margin-right: 0.4rem;
  }

  &.is-selected {
    color: $cWhite;
    background-color: $stpGreyDark;
  }

  &.is-good {
    color: $cWhite;
    background-color: $cGreen;
  }

  &.is-bad {
    color: $cWhite;
    background-color: $cRed;
  }
}

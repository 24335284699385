.s-main-header__phone {
  @include pos();
  font-size: 2.2rem;
  color: $cWhite;

  .s-phone__signature {
    @include pos(absolute, $t: 3rem, $l: 3.1rem);
    letter-spacing: 0.028rem;
  }
}

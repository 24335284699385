.container-body-content {
  strong {
    font-weight: 600;
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  ul {
    margin-bottom: 2rem;
  }
}

.sn-table__top-filters {
  display: flex;

  .sn-field__wrapper {
    margin-right: 1.5rem;
  }
}



@media (max-width: $gMinWidthTablet) {
  .sn-table__top-filters {
    flex-direction: column;
    width: 100%;

    .sn-field__wrapper {
      margin-right: unset;
      width: 100%;
    }
  }
}

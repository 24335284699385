.s-insta-block__header-logo {
  @include sflx($juscont: center, $alitems: center);
  width: 3.8rem;
  height: 3.8rem;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;

  img {
    height: 90%;
    width: 90%;
  }
}

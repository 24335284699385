.test-cont__result-wrapper {
  @include sflx($alitems: stretch, $juscont: center);
  width: 100%;

  .button {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.test-cont__result-wrapper_mrgn_b {
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 479px) {
  .test-cont__result-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .button_print {
    display: none;
  }

  .test-cont__result-wrapper_mrgn_b {
    margin-bottom: 2rem;
  }
}

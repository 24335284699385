.s-year-month-picker {
  .sn-btn--squire {
    min-height: 3.8rem;
    min-width: 3.8rem;
    padding: 0.5rem 0.7rem !important;
  }

  .sn-field__wrapper {
    cursor: pointer;
    padding: unset;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #202B56;
    min-width: 17.5rem;

    .sn-field__control {
      border-color: #3045a7;
      padding: 0 1.2rem;

      &-container {
        justify-content: space-between;
      }
    }

    .sn-field__native {
      color: #3045a7;
      font-weight: 700;
      justify-content: center;
    }
  }
}

.form-checkbox {
  @include pos(absolute, -1);
  width: unset;
  opacity: 0;

  &:focus {
    + label::before {
      color: $cBlue;
    }
  }

  &:checked {
    + label {
      color: $cBlack;

      &::before {
        font-size: 1.9rem;
        color: $cBlack;
        content: '\E64D';
      }
    }
  }

  + label {
    @include pos();
    display: inline-block;
    line-height: 1.2em;
    color: $cBlack;
    padding-left: 3rem;
    padding-bottom: 0;
    cursor: pointer;

    &::before {
      @include pos(absolute, $l: 0);
      font-family: $fIcons;
      content: '\E6E8';
    }
  }
}

@media ( max-width: 1460px ) {
  .college-library-book--wrapper {
    justify-content: space-evenly;
  }

  .college-library-book {
    margin-right: 1.1rem;
  }
}
@media ( max-width: 1250px ) {
  .college-library-book {
    &:nth-child( 5 ),
    &:nth-child( 10 ) {
      margin-right: 1.1rem;
    }
  }

  .college-library-filter--wrapper {
    flex-wrap: wrap;
  }

  .college-library-upload-btn {
    order: -1;
    display: flex;
    justify-content: flex-end;
  }
  .college-library-filter--input {
    margin-right: 0;
    width: 33%;
  }
  .college-library-filter--select {
    width: 25%;
    flex-grow: 1;
  }
}

@media screen and ( max-width: 1024px ) {
  .college-library-book {
    width: 22rem;
    padding-right: 4rem;
  }

  .college-library-card--popover {
    left: calc(100% + 0.55rem);
    width: 4rem;
    opacity: 1;
    background-color: unset;
    justify-content: space-between;

    > * {
      flex-direction: column;
      justify-content: space-between !important;
      height: 44%;
    }
  }
}

@media ( max-width: 750px ) {
  .college-library-upload-btn {
    justify-content: center;
  }

  .college-library-filter--btn-toggle {
    margin-bottom: 2rem;
  }

  .college-library-filter {
    flex-direction: column;
  }

  .college-library-filter--input {
    width: 100%;
    margin-left: 0;
  }

  .college-library-filter--select {
    width: 100%;
    margin-left: 0;
  }
}
@media ( max-width: 600px ) {
  .college-library-tab {
    margin-top: -2rem;
  }

  .college-library-desc--open {
    align-items: center;
    align-self: unset;

    .college-library-desc--name.open {
      overflow-x: unset;
      flex-wrap: unset;
      text-overflow: unset;
      text-align: center;
      @include word-wrap;
    }
  }

.college-library-desc--author {
&.open {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  @include word-wrap;
  }
}

.college-library-desc--type {
  margin-top: 2rem;
  text-align: center;
}

.college-library-desc--open {
  margin-left: 0;
  }
}

@media ( max-width: 411px ) {
  .college-library-upload-btn {
    width: 100%;
  }
}
@media ( max-width: 411px ) {
  .college-library-btn {
    width: 100%;
  }
}

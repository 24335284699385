.kindergarten-head-inform__table {
  flex: 1 1 auto;
  order: 2;
  padding-right: 3rem;
  padding-left: 3rem;

  .tbl-simple-info {
    td {
      background-color: $cWhite !important;
      border: 0 !important;
      padding: .8rem 1rem !important;
    }
  }


  @media screen and (max-width: 1265px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media screen and (max-width: 1009px) {
    order: 3;
  }
}
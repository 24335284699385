.s-ico {
  @extend %s-ico;

  &::before {
    font-weight: 400;
  }
}

.s-ico--bold {
  &::before {
    font-weight: 600;
  }
}

.s-ico-fa {
  @extend .s-ico;
  font-family: $fFAIcons;
}

.s-ico-fab {
  @extend .s-ico;
  font-family: $fFABrandsIcons;
}

@each $name, $icon in $listOfIcons {
  .s-ico--thm-#{$name},
  .s-str-ico--thm-#{$name} {
    &::before {
      content: $icon;
    }
  }
}

@each $name, $icon in $listOfIconsFA {
  .s-ico-fa--thm-#{$name},
  .s-str-ico-fa--thm-#{$name} {
    &::before {
      content: $icon;
    }
  }
}

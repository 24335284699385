.s-photo-album__modal-prev {
  @extend %s-photo-album__control;
  position: unset;
  justify-content: flex-start;
  font-size: 4.8rem;
  color: $cWhite;
  width: 50%;
  height: 100%;

  &::after {
    content: map_get($listOfIcons, 'angle-left');
  }
}

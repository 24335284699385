.s-combine-title__line {
  @include pos();
  @include sflx($juscont: flex-end, $alitems: center);
  flex: 1 0 auto;

  &::before {
    @include pos(absolute, $t: calc(50% + 0.2rem));
    display: block;
    width: 100%;
    height: 0.1rem;
    background-color: $cBorder;
    content: '';
  }
}

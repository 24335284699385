.ol-lbc__option-wrapper {
  width: 100%;
  padding: 1rem 0;

  &--editor {
    .ol-lbc__option-body {
      border: 0;
    }

    .ol-lbc__option-text {
      height: auto;
      padding-right: 0;
      padding-left: 0;
      border: 0;
      border-radius: 0;
    }

    .ol-view__quill-editor {
      width: 100%;

      .ql-toolbar {
        border-radius: $gBorderRadius 0 0 0;

        &.ql-snow {
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }
      }
      &.quill-editor {
        .ql-editor {
          min-height: 5rem;
        }
      }
      .ql-container {
        height: auto;
      }
    }
  }
}

.feedback-block {
  @extend .block;
  display: inline-flex;
  flex-direction: column;
  color: $c_black;
}
.feedback-block__wrapper {
  flex: 1 0 auto;
  padding-bottom: 3rem;
}
.feedback-block__image {
  width: 12rem;
  height: 12rem;
  min-width: 12rem;
  min-height: 12rem;
  @include sbg($size: cover);
  border-radius: 50%;
  background-image: url(../images/no_name.png);
}
.feedback-block__name,
.feedback-block__title,
.feedback-block__desc {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.feedback-block__name {
  font: 1.8rem/1.2em $font_sans_sb;
}
.feedback-block__foot {
  width: 100%;
  padding: 0 2rem;
  text-align: right;
}
.feedback-block__title {
  display: inline-flex;
  align-items: center;
  padding-top: 1.5rem;
  &.__zero {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 3.2rem;
  }
}
.feedback-block__desc {
  font: 1.6rem/1.2em $font_default;
  padding-top: 3rem;
}

.content-outer {
  .feedback-block {
    width: 100%;
    margin-bottom: 1rem;
  }
  .feedback-block__wrapper {
    display: inline-flex;
    flex-flow: row nowrap;
  }
  .feedback-block__title {
    flex-flow: column wrap;
    min-width: 30rem;
  }
  .feedback-block__name {
    padding-top: 1rem;
    text-align: center;
  }
  .content__about-wrapper {
    display: inline-flex;
    justify-content: space-between;
    width: 90rem;
  }
}
.content-outer.content--feedback {
  display: inline-flex;
  flex-flow: column wrap;
  align-items: center;
  .content-inner.content--feedback {
    display: inline-flex;
    flex-flow: column wrap;
    align-items: center;
  }
}
@media screen and (max-width: 1379px) {
  .feedback-block__title {
    flex-flow: column wrap;
    width: 100%;
  }
  .feedback-block__name {
    padding-top: 1rem;
    text-align: center;
  }
  .content-outer {
    .feedback-block__title {
      width: auto;
    }
  }
}
@media screen and (max-width: 899px) {
  .content-outer.content--feedback {
    .content__about-wrapper {
      width: 100%;
      padding: 0;
    }
  }
}
@media screen and (max-width: 767px) {
  .feedback-block__title,
  .feedback-block__desc {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .feedback-block__title {
    font-size: 1.6rem;
  }
  .feedback-block__desc {
    font-size: 1.5rem;
  }
  .content-outer {
    .feedback-block__wrapper {
      flex-flow: column wrap;
    }
  }
}
@media screen and (max-width: 699px) {
  .content-inner.content--feedback {
    .content__about-wrapper {
      margin-bottom: 3rem;
      .button {
        min-width: unset;
        width: auto;
        font-size: 1.4rem;
      }
    }
    .feedback-block__title {
      min-width: 20rem;
    }
  }
}
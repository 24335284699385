.test-block__nav-button {
  @include pos();
  display: block;
  max-width: 15rem;
  font-size: 1.6rem;
  line-height: 1.2em;
  color: $cWhite;
  text-align: center;
  background-color: $cBlue;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:hover {
    background-color: darken($cBlue, 5%);
    transition: background-color .2s ease-in;
  }
  .test-block__nav_span,
  a {
    display: block;
    height: 100%;
    padding: 1rem 1.5rem;
  }
}

%test-block__nav-button {
  @include pos(absolute, 3, 50%);
  font: 3.8rem/1em $fThemify;
  color: #d4dbde;
  margin-top: -1.9rem;
}

.test-block__nav-button_prev {
  margin-left: 5rem;

  &::before {
    @extend %test-block__nav-button;
    left: -5rem;
    content: "\E64A";
  }
}

.test-block__nav-button_next {
  margin-right: 5rem;

  &::before {
    @extend %test-block__nav-button;
    right: -5rem;
    content: "\E649";
  }
}

@media screen and (max-width: 639px) {
  .test-block__nav-button_prev {
    margin-left: 0;

    &::before {
      left: 1.1rem;
      color: $cWhite;
    }
  }

  .test-block__nav-button_next {
    margin-right: 0;

    &::before {
      right: 1.1rem;
      color: $cWhite;
    }
  }

  .test-block__nav-button {
    width: 6rem;
    height: 6rem;

    span {
      display: none;
    }
  }
}

@mixin setRatingThemes($list, $hover) {
  @each $name, $color in $list {
    &.rating-block_thm_#{$name} {
      .rating-block__star {
        @if $hover == true {
          &:hover {
            color: $color;
            ~ .rating-block__star {
              color: $color;
            }
          }
        }
        &.is-active {
          color: $color;
          ~ .rating-block__star {
            color: $color;
          }
        }
      }
    }
  }
}
$rating_name: 'yellow';
$rating_color: #ffcc00;
$rating_star_red: #CC0000;
$rating_star_orange: #ffc194;
$rating_star_yellow: #fae695;
$rating_star_green: #a8de6f;

.rating-block {
  @include sflx($dir: row-reverse, $alitems: center, $juscont: flex-end);
  flex: 1 0 auto;
  font-size: 2.2rem;
  color: #a9b9c2;
  &.rating-block--full {
    justify-content: space-between;
    .rating-block__star {
      padding-right: 0;
    }
  }
  &.rating-block--low {
    .rating-block__star {
      padding-right: 0.3rem;
      &:after {
        font-size: 0.7em;
      }
    }
  }
  &:not(.rating-block--static) {
    $listOfRating: (
      ($rating_name, $rating_color),
    );
    @include setRatingThemes($listOfRating, true);
  }
  &.rating-block--static {
    .rating-block__star {
      cursor: default;
      user-select: none;
    }
    $listOfRating: (
        ($rating_name, $rating_color),
    );
    @include setRatingThemes($listOfRating, false);
  }
  &.rating-block--chart {
    .rating-block__star {
      &:after {
        content: '';
        border: 1.5px solid #bdc9d0;
      }
    }
    &.rating-block--chart_1 {
      .rating-block__star {
        &.is-active {
          &:after {
            border-color: $rating_star_red;
          }
          ~ .rating-block__star {
            &:after {
              border-color: $rating_star_red;
            }
          }
        }
      }
    }
    &.rating-block--chart_2 {
      .rating-block__star {
        &.is-active {
          &:after {
            border-color: $rating_star_orange;
          }
          ~ .rating-block__star {
            &:after {
              border-color: $rating_star_orange;
            }
          }
        }
      }
    }
    &.rating-block--chart_3 {
      .rating-block__star {
        &.is-active {
          &:after {
            border-color: $rating_star_yellow;
          }
          ~ .rating-block__star {
            &:after {
              border-color: $rating_star_yellow;
            }
          }
        }
      }
    }
    &.rating-block--chart_4,
    &.rating-block--chart_5 {
      .rating-block__star {
        &.is-active {
          &:after {
            border-color: $rating_star_green;
          }
          ~ .rating-block__star {
            &:after {
              border-color: $rating_star_green;
            }
          }
        }
      }
    }
  }
  &__score {
    float: right;
    margin-left: 0.7rem;
    color: black;
    font-size: 1.7rem;
  }
}
[class*=rating-block_thm_] {
  &:hover {
    ~ .rating-block__star {
      transition: background-color .2s ease-in;
    }
  }
}

.sn-contacts__info-social {
  li {
    @include pos();
    display: block;
    padding-left: 4rem;
    font-weight: 300;
    font-size: 2rem;
    color: $cWhite;
    margin-top: 2rem;

    &::before {
      @include pos(absolute, $t: 50%, $l: 0);
      transform: translateY(-50%);
      font-weight: 400;
      font-size: 2.8rem;
      font-family: $fFABrandsIcons;
      color: $cWhite;
    }

    a {
      color: $cWhite;
    }
  }

  &--instagram {
    &::before {
      content: map_get($listOfIconsFA, 'instagram');
    }
  }

  &--facebook {
    &::before {
      content: map_get($listOfIconsFA, 'facebook-f');
    }
  }

  &--whatsapp {
    &::before {
      content: map_get($listOfIconsFA, 'whatsapp');
    }
  }
}

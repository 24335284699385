.s-no-results__image {
  @include sbg($s: cover);
  width: 45%;

  &::before {
    display: table;
    margin-top: 100%;
    content: '';
  }

  &--queue {
    background-image: url(../images/queue_no_results.svg);
  }

  &--search {
    background-image: url(../images/no_results.svg);
    padding: 2rem 5rem;
    background-origin: content-box;
  }

  &--pc {
    background-image: url(../images/use_pc_no_results.svg);
  }

  &--empty-list {
    background-image: url(../images/empty-list-no-results.svg);
  }
}

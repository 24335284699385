.child-card {
  @include sflx($dir: column);
  width: 100%;
  color: $cBlack;
  text-align: center;
  padding: 1.5rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 10px rgba(104, 124, 143, 0.28);
  transition: box-shadow .2s ease-in;

  &:hover {
    box-shadow: 0 0 28px rgba(104, 124, 143, 0.28);
    transition: box-shadow .2s ease-in;
  }
}

.child-card_add {
  .child-card__photo {
    @include pos();
    border: 1px solid #dfe2ed;

    &::after {
      @include pos(absolute, $t: 50%, $l: 50%);
      transform: translate(-50%, -50%);
      display: block;
      font-family: $fIcons;
      font-size: 3.8rem;
      color: #aeb3c6;
      content: '\E61A';
    }
  }
}
.s-chat-popup {
  &__input {
    &--wrapper {
      display: flex;

      .sn-btn--squire {
        min-height: 3rem;
        padding: 0.5rem 0.5rem !important;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }

    font-weight: 400;
    font-size: 12px;
    width: 100%;
    padding: 0.95rem 1.3rem;
    border: unset;
    background: rgb(128 150 255 / 20%);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #8a9da8;
    }
  }
}

.s-table {
  width: 100%;
  max-width: 100%;
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    height: 4.5rem;
    padding: 1rem 1.2rem;
  }

  th {
    font-weight: 600;
    font-size: 1.4rem;
  }

  //&:not( .s-table--without-head ) {
  tr {
    &:last-child {
      td {
        border-bottom: 1px solid #E3E7EA;
      }
    }

    td {
      border-top: 1px solid #E3E7EA;
    }
  }
  //}

  //&.s-table--without-head {
  //  tr {
  //    &:not( :first-child ) {
  //      border-top: 1px solid #E3E7EA;
  //    }
  //  }
  //}
}

.s-table--striped {
  tbody {
    tr {
      &:nth-child( odd ) {
        background-color: #F7F9FC;
      }
    }
  }
}

.s-table--no-border {
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }

    td {
      border-top: 0;
    }
  }
}

.s-table--bordered {
  th {
    &:not( :last-child ) {
      border-right: 1px solid $cBorder;
    }
  }

  td {
    &:not( :last-child ) {
      border-right: 1px solid #BEC7CD;
    }
  }
}

.s-table--fixed {
  table-layout: fixed;
}

.s-table--hover {
  tbody {
    tr {
      &:hover {
        background-color: #ECEEF5;
      }
    }
  }
}

.s-table--radius {
  > thead {
    > tr {
      > th {
        &:first-child {
          border-top-left-radius: $gBorderRadius;
        }

        &:last-child {
          border-top-right-radius: $gBorderRadius;
        }
      }
    }
  }
}

.s-table--pointer {
  .s-table__body {
    tr {
      cursor: pointer;
    }
  }
}

.s-table--quasar {
  table {
    @extend .s-table,
    .s-table--striped,
    .s-table--bordered,
    .s-table--thm-light,
    .s-table--radius;

    &::before {
      display: none;
    }

    tbody {
      tr {
        &:nth-child( odd ) {
          td {
            background-color: #F7F9FC !important;
          }
        }

        &:nth-child( even ) {
          td {
            background-color: $cWhite !important;
          }
        }

        td {
          border-right: 1px solid #BEC7CD !important;

          &:last-child {
            border-right: 0 !important;
          }
        }
      }
    }

    td {
      white-space: normal !important;

      &:last-child {
        border-right: 0 !important;
      }
    }
  }
}

.s-table--break-word{
  word-wrap:break-word;
}

$listOfTableThemes: (
  (dark, $cBlue, $cWhite),
  (light, $cGrey, $cWhite),
  (head-grey, $cWhite, #A8AFCC),
);

@each $name, $background, $color in $listOfTableThemes {
  .s-table--thm-#{$name} {
    .s-table__head {
      th {
        background-color: $background;
        color: $color;
      }
    }
  }
}

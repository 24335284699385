.s-mobile-menu__header {
  width: 100%;
  display: flex;
  min-height: max-content;

  &-close {
    background-color: $cBlue;
    color: $cWhite;
    font-size: 2.5rem;
    width: 5rem;
    display: flex;
    place-self: center;
    text-align: center;
    &::before {
      font-family: $fIcons;
      content: map-get($listOfIcons, 'close');
    }
  }

  &-items {
    flex-grow: 1;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

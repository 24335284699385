.s-questions-map__item {
  @include pos();
  @include sflx();
  font-weight: 600;
  font-size: 1.5rem;
  color: $cGrey;
  text-transform: uppercase;
  padding: 0.2rem 1rem 0.2rem 3rem;
  background-color: $cWhite;
  transition: all $gDuration ease-in;
  cursor: pointer;

  &:not( :last-child ) {
    border-bottom: 1px solid $stpGrey;
  }

  &:hover {
    background-color: rgba(lighten($cAqua, 5%), 0.2);
    transition: all $gDuration ease-in;
  }

  &.is-answer {
    color: $cBlack;
  }

  &.is-selected {
    background-color: rgba(lighten($cAqua, 5%), 0.2);
    transition: all $gDuration ease-in;
  }

  &.is-helper {
    background-color: rgba(lighten($cAqua, 5%), 0.8);
    color: $cWhite;
    transition: all $gDuration ease-in;
  }

  &::before {
    @include pos(absolute, $t: 50%, $l: 0);
    display: block;
    width: 2.5rem;
    text-align: right;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 1.4rem;
    content: attr(data-var-num) '.';
  }
}

.sn-time {
  max-width: 100%;
  box-shadow: $shadow-2;
  border-radius: $gBorderRadius;
  background: #fff;
  outline: none;

  &--readonly,
  &.sn--disabled {
    .sn-time__header-ampm,
    .sn-time__content {
      pointer-events: none;
    }
  }

  &--bordered {
    border: 1px solid $separator-color;
  }

  &--portrait {
    display: inline-flex;
    flex-direction: column;
    width: 290px;
    min-width: 180px;

    .sn-time__header {
      border-top-right-radius: inherit;
      min-height: 86px;
    }

    .sn-time__header-ampm {
      margin-left: 12px;
    }
  }

  &--landscape {
    display: inline-flex;
    align-items: stretch;
    width: 420px;
    min-width: 310px;
    min-height: 180px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .sn-time__header {
      border-bottom-left-radius: inherit;
      width: 110px;
    }

    .sn-time__header-ampm {
      margin-top: 12px;
    }
  }

  &--dark {
    color: $cWhite;
    background: $grey-9;
  }
}

.tbl-cont__btn-ico {
  @extend %tbl-cont__btn;
  @include sflx($juscont: center, $alitems: center);
  width: 3rem;
  height: 3rem;

  &::after {
    font-family: $fIcons;
  }
}

$listOfBtnIco: (
  (remove, '\E605', #e18c96),
  (view, '\E63D', #33cc66),
  (edit, '\E61C', #f5942e),
);

@each $name, $icon, $color in $listOfBtnIco {
  .tbl-cont__btn-ico_t_#{$name} {
    color: $color;
    border-color: $color;

    &::after {
      content: $icon;
    }

    &:hover {
      color: $cWhite;
      background-color: $color;
    }
  }
}

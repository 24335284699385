%span {
  @include pos();
  display: inline-block;

  &::before,
  &::after {
    @include pos(absolute, $t: calc(50% + 0.2rem));
    display: none;
    width: 150rem;
    height: 0.1rem;
    background-color: $cGrey;
    content: '';
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  padding: 0;
  margin: 0;
}

.s-title {
  display: block;
  font-size: 3.2rem;
  line-height: 1.4em;
  overflow: hidden;
  min-height: 1.4em;
}

.s-title--double-line {
  width: 100%;
  text-align: center;

  span {
    @extend %span;

    &::before {
      display: block;
      right: 100%;
      margin-right: 1.5rem;
    }

    &::after {
      display: block;
      left: 100%;
      margin-left: 1.5rem;
    }
  }
}

.s-title--right-line {
  width: 100%;

  span {
    @extend %span;

    &::after {
      display: block;
      left: 100%;
      margin-left: 1.5rem;
    }
  }
}

.s-title--left-line {
  width: 100%;
  text-align: right;

  span {
    @extend %span;

    &::before {
      display: block;
      right: 100%;
      margin-right: 1.5rem;
    }
  }
}

.s-title--underline {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #c6ccdd;
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i},
  h#{$i}.s-title {
    font-size: (3.2 - (($i - 1) / 10 * 4)) + rem;
  }
}

[data-content-hide-text] {
  @include pos(absolute, 2, $b: -3rem, $l: 50%);
  transform: translateX(-50%);
  @include sflx($dir: column, $alitems: center);
  cursor: pointer;
  transition: bottom .2s ease-in;

  &::before {
    margin-bottom: .5rem;
    content: attr(data-content-hide-text);
  }

  &::after {
    font-family: $fIcons;
    content: '\E64B';
  }
}

.statistiks-table {
  table-layout: unset;
  min-width: 1080px;

  th {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    color: #fff;
    background-color: #aeb3c6;
    border-top: 1px solid #dfe2ed;
  }

  td, th {
    height: 5rem;
    padding: .5rem 1.2rem;
    border-bottom: 1px solid #dfe2ed;
    border-right: 1px solid #dfe2ed;
  }

  th:last-child {
    border-radius: 0 5px 0 0;
  }

  tr {
    th {
      &:first-child {
        border-top-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
      }
    }
  }
}


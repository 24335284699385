.footer__text {
  width: 100%;
  max-width: 40rem;
  font: 1.6rem/1.2em $fDef;
  color: $cWhite;
  text-align: right;

  p {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  a {
    color: $cWhite;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    text-align: left;
  }

}

.footer__text_copyright {
  padding-top: 3rem;
}

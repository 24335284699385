.home-news-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2.4rem;
  margin-bottom: 2.4rem;

  .home-news-block {
    height: 33.6rem;

    &:nth-child(1) {
      grid-column: span 2;
    }

    &:nth-child(2) {
      grid-row: span 2;
      height: 100%;
    }

    &:nth-child(3) {
      .home-news-block__desc {
        display: none;
      }
    }

    &:nth-child(4) {
      .home-news-block__desc {
        display: none;
      }
    }
  }


  @media screen and (max-width: 1199px) {
    grid-template-columns: 1fr 1fr;

    .home-news-block {
      &:nth-child(3),
      &:nth-child(4) {
        .home-news-block__title {
          font-size: 2.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;

    .home-news-block {
      &:nth-child(1) {
        grid-column: unset;
      }

      &:nth-child(2) {
        grid-row: unset;
        height: 33.6rem;
      }
    }
  }
}
.s-statistic-panel__icon {
  @include sflx($alitems: center, $juscont: center);
  width: 4.2rem;
  height: 4.2rem;
  margin-bottom: 0.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

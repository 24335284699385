@media screen and (max-width: 1023px) {
  .sn-contacts-title {
    span {
      &::before,
      &::after {
        width: 100rem;
      }
    }
  }
  .sn-contact-background {
    background: none;

  }
}

@media screen and (max-width: 767px) {
  .sn-contacts-wrapper {
    flex-direction: column;
  }
  .sn-contacts__form {
    width: 100%;
  }
  .sn-contacts__form {
    width: 100%;
  }
  .sn-contacts__info {
    width: 100%;
  }

  .sn-contacts-title {
    font-size: 2.2rem;
    margin-bottom: 2rem;

    span {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}


@media screen and (max-width: 479px) {
  .sn-contacts__form {
    padding: 2rem;
  }
  .sn-contacts__info-text {
    font-size: 14px
  }
  .sn-contacts-form__input {
    font-size: 14px
  }
  .sn-contacts-form__textarea {
    font-size: 14px
  }
  sn-contacts__info-social {
    li {
      font-size: 14px
    }
  }
}

@media screen and (max-width: 446px) {
  .sn-contact__form {
    padding: 1rem;
  }
}

@media screen and (max-width: 446px) {
  .sn-contacts-form__input {
    height: 4rem;
  }
}

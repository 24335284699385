.sn-timeline {
  padding: 0;
  width: 100%;
  list-style: none;

  &--dark {
    color: $cWhite;

    .sn-timeline__subtitle {
      opacity: 0.7;
    }
  }

  &--dense {
    &--right {
      .sn-timeline__entry {
        padding-left: 40px;

        &--icon {
          .sn-timeline__dot {
            left: -8px;
          }
        }
      }

      .sn-timeline__dot {
        left: 0;
      }
    }

    &--left {
      .sn-timeline__heading {
        text-align: right;
      }

      .sn-timeline__entry {
        padding-right: 40px;
      }

      .sn-timeline__entry--icon {
        .sn-timeline__dot {
          right: -8px;
        }
      }

      .sn-timeline__content,
      .sn-timeline__title,
      .sn-timeline__subtitle {
        text-align: right;
      }

      .sn-timeline__dot {
        right: 0;
      }
    }
  }

  &--comfortable {
    display: table;

    &--right {
      .sn-timeline__heading {
        .sn-timeline__heading-title {
          margin-left: -50px;
        }
      }

      .sn-timeline__subtitle {
        text-align: right;
        padding-right: 30px;
      }

      .sn-timeline__content {
        padding-left: 30px;
      }

      .sn-timeline__entry {
        &--icon {
          .sn-timeline__dot {
            left: -8px;
          }
        }
      }
    }

    &--left {
      .sn-timeline__heading {
        text-align: right;
      }

      .sn-timeline__heading {
        &-title {
          margin-right: -50px;
        }
      }

      .sn-timeline__subtitle {
        padding-left: 30px;
      }

      .sn-timeline__content {
        padding-right: 30px;
      }

      .sn-timeline__content,
      .sn-timeline__title {
        text-align: right;
      }

      .sn-timeline__entry {
        &--icon {
          .sn-timeline__dot {
            right: 0;
          }
        }
      }

      .sn-timeline__dot {
        right: -8px;
      }
    }

    .sn-timeline__heading {
      display: table-row;
      font-size: 200%;

      > div {
        display: table-cell;
      }
    }

    .sn-timeline__entry {
      display: table-row;
      padding: 0;

      &--icon {
        .sn-timeline__content {
          padding-top: 8px;
        }
      }
    }

    .sn-timeline__subtitle,
    .sn-timeline__dot,
    .sn-timeline__content {
      display: table-cell;
      vertical-align: top;
    }

    .sn-timeline__subtitle {
      width: 35%;
    }

    .sn-timeline__dot {
      position: relative;
      min-width: 31px;
    }
  }

  &--loose {
    .sn-timeline__heading-title {
      text-align: center;
      margin-left: 0;
    }

    .sn-timeline__entry,
    .sn-timeline__subtitle,
    .sn-timeline__dot,
    .sn-timeline__content {
      display: block;
      margin: 0;
      padding: 0;
    }

    .sn-timeline__dot {
      position: absolute;
      left: 50%;
      margin-left: -7.15px;
    }

    .sn-timeline__entry {
      padding-bottom: 24px;
      overflow: hidden;

      &--left {
        .sn-timeline__content {
          float: left;
          padding-right: 30px;
          text-align: right;
        }

        .sn-timeline__subtitle {
          float: right;
          text-align: left;
          padding-left: 30px;
        }
      }

      &--right {
        .sn-timeline__subtitle {
          float: left;
          padding-right: 30px;
          text-align: right;
        }

        .sn-timeline__content {
          float: right;
          text-align: left;
          padding-left: 30px;
        }
      }
    }

    .sn-timeline__entry {
      &--icon {
        .sn-timeline__dot {
          margin-left: -15px;
        }

        .sn-timeline__subtitle {
          line-height: 38px;
        }

        .sn-timeline__content {
          padding-top: 8px;
        }
      }
    }

    .sn-timeline__subtitle,
    .sn-timeline__content {
      width: 50%;
    }
  }

  h6 {
    line-height: inherit;
  }
}

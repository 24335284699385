.sn-btn-dropdown--split {
  .sn-btn-dropdown__arrow-container {
    padding: 0 4px;
    border-left: 1px solid rgba(255,255,255,0.3);
  }
}

.sn-btn-dropdown--simple {
  .sn-btn-dropdown__arrow {
    margin-left: 8px;
  }
}

.sn-btn-dropdown__arrow {
  transition: transform 0.28s;
}

.sn-spinner-mat {
  animation: a-quasar-spinner 2s linear infinite;
  transform-origin: center center;

  .path {
    stroke-dasharray: 1, 200; /* rtl:ignore */
    stroke-dashoffset: 0; /* rtl:ignore */
    animation: a-mat-dash 1.5s ease-in-out infinite;
  }
}

.vacancy-array {
  .vacancy-block {
    display: inline-block;
    width: 480 / 990 * 100%;
    margin-right: 30 / 990 * 100%;
    margin-bottom: 3rem;
    &:nth-child(2n+2) {
      margin-right: 0;
    }
  }
}


@media screen and (max-width: 1099px) {
  .vacancy-array {
    .vacancy-block {
      width: 100%;
      margin-right: 0;
    }
  }
}
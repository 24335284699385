.cabinet-body-wrapper, //TODO legacy code -> delete in html
.container-body-wrapper {
  @include sflx($dir: column, $alitems: center);
  width: 100%;
  flex: 1 0 auto;
  padding-right: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  min-height: 40rem;
}

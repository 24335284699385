@media screen and (max-width: $gMinWidthTablet - 1) {
  .s-direction-block {
    padding: 0 2rem;
  }

  .s-direction-block__head {
    flex-direction: column;
    align-items: flex-start;
  }

  .s-direction-block__item {
    width: 100%;
    padding: 1rem 0;
  }

  .s-direction-block__name {
    padding-top: 1.5rem;
    margin-right: 0;
    order: 2;
  }

  .s-direction-block__buttons {
    margin-top: 1rem;

    > * {
      &:not( :last-child ) {
        margin-right: 0.4rem;
      }
    }
  }

  .s-direction-block__mark {
    padding-top: 1rem;
    order: 1;
    width: 100%;
  }

  .s-direction-block__container {
    padding:0;
    margin:0;
  }

  .s-direction-block__body {
    flex-direction: column;
  }
}


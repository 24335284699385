.voting-person-block {
  position: relative;
  z-index: 1;
  background-color: $c_white;
  padding: 2rem 1.5rem;
  text-align: center;
  border: 1px dashed $c_white;
  border-radius: 6px;
  transition: all .2s ease-in;
  &:hover, &.is-active {
    z-index: 2;
    cursor: pointer;
    border-color: $c_green;
    box-shadow: 0 0 8px rgba($c_green, .32);
    transition: all .2s ease-in;
  }
}
.voting-person-block__photo {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 13.4rem;
  height: 16.2rem;
  @include sbg($size: cover);
  position: relative;
  border-radius: 6px;
  margin-bottom: 3rem;
  span {
    font: 1.6rem/1.2em $font_default;
    color: #7a8691;
  }
}
.voting__notify {
  position: absolute;
  z-index: 2;
  bottom: -1.5rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 6px;
  background-color: $c_green;
  font: bold 1.6rem/1.2em $font_default;
  color: $c_white;
  box-shadow: 2px 2px 4px rgba($c_black, .2);
}
.voting-person-block__name {
  font: bold 1.8rem/1.2em $font_default;
  color: $c_black;
  margin-bottom: 2rem;
}
.voting-person-block__num {
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
}
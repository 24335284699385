.kindergarten-search {
  @include pos();
  align-self: flex-end;
  display: block;
  width: 100%;
  max-width: 30rem;
  margin-left: auto;

  .form-input {
    padding-right: 5rem;
  }
}
$dimmed-background: rgba(0, 0, 0, 0.4);
$light-dimmed-background : rgba(255, 255, 255, 0.6);
$ios-statusbar-height: 2rem;

$breakpoint-xs: 599px;
$breakpoint-sm: 1023px;
$breakpoint-md: 1439px;
$breakpoint-lg: 1919px;

$sizes-xs: 0;                  // Extra small screen
$sizes-sm: $breakpoint-xs + 1; // Small screen
$sizes-md: $breakpoint-sm + 1; // Medium screen
$sizes-lg: $breakpoint-md + 1; // Large screen
$sizes-xl: $breakpoint-lg + 1; // Extra large screen

$breakpoint-xs-min: 0;
$breakpoint-xs-max: $breakpoint-xs;

$breakpoint-sm-min: $sizes-sm;
$breakpoint-sm-max: $breakpoint-sm;

$breakpoint-md-min: $sizes-md;
$breakpoint-md-max: $breakpoint-md;

$breakpoint-lg-min: $sizes-lg;
$breakpoint-lg-max: $breakpoint-lg;

$breakpoint-xl-min: $sizes-xl;
$breakpoint-xl-max: 9999px;

$primary: #027BE3;
$secondary: #26A69A;
$grey-9: #424242;

$elevation-umbra: rgba($cBlack, 0.2);
$elevation-penumbra: rgba($cBlack, 0.14);
$elevation-ambient: rgba($cBlack, 0.12);
$shadow-1: 0 0.1rem 0.3rem $elevation-umbra,
  0 0.1rem 0.1rem $elevation-penumbra,
  0 0.2rem 0.1rem -0.1rem $elevation-ambient;
$shadow-2: 0 0.1rem 0.5rem $elevation-umbra,
  0 0.2rem 0.2rem $elevation-penumbra,
  0 0.3rem 0.1rem -0.2rem $elevation-ambient;
$shadow-4: 0 0.2rem 0.4rem -0.1rem $elevation-umbra,
  0 0.4rem 0.5rem $elevation-penumbra,
  0 0.1rem 1rem $elevation-ambient;
$shadow-5: 0 0.3rem 0.5rem -0.1rem $elevation-umbra,
  0 0.5rem 0.8rem $elevation-penumbra,
  0 0.1rem 1.4rem $elevation-ambient;

$menu-max-width: 95vw;
$menu-box-shadow: $shadow-2;
$menu-background: $cWhite;

$z-fab: 990;
$z-side: 1000;
$z-marginals: 2000;
$z-fixed-drawer: 3000;
$z-fullscreen: 6000;
$z-menu: 6000;
$z-top: 7000;
$z-tooltip: 9000;
$z-notify: 9500;
$z-max: 9998;

// Tooltip
$tooltip-color: #FAFAFA;
$tooltip-background: #757575;
$tooltip-padding: 0.6rem 1rem;
$tooltip-border-radius: $gBorderRadius;
$tooltip-fontsize: 1.4rem;
$tooltip-mobile-padding: 0.8rem 1.6rem;
$tooltip-mobile-fontsize: 1.4rem;


$option-focus-transition: 0.22s cubic-bezier(0, 0, 0.2, 1);

$separator-color         : rgba(0, 0, 0, 0.12);
$separator-dark-color    : rgba(255, 255, 255, 0.48);

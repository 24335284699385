.region__text {
  font: 1.8rem/1.2em $font_default;
  color: $c_black;
  text-align: center;
  margin-bottom: 2rem;
}

.region-selecting__list {
  padding-right: 2rem;
  padding-left: 2rem;

  li {
    margin-bottom: 1rem;
    white-space: nowrap;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-active {
      &::before {
        content: '\E64C';
        position: absolute;
        z-index: 1;
        top: -0.2rem;
        left: -3rem;
        display: block;
        font: 2rem/1em $font_themify;
        color: $c_black;
      }

      div div div.is-active-second a::before {
        content: '\E64C';
        position: absolute;
        z-index: 1;
        left: -2rem;
        display: block;
        font: 2rem/1em $font_themify;
        color: $c_black;
      }

      a {
        border-bottom: 1px dashed lighten($c_black, 1%);
      }
    }

    .form__radio {
      .form__radio {
        padding: 0.5rem 1.5rem !important;
      }
    }

    a {
      font: 1.6rem/1.2em $font_sans_sb;
      color: $c_black !important;

      &:hover {
        border-bottom: 1px dashed lighten($c_black, 1%);
      }
    }
  }
}

.region__footer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: stretch;

  .button {
    min-width: 14rem;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

// Variables
$gBorderRadius: 5px;
$tHeadColor: #10206d;
$tBorderSection: #aeb3c6;
$tBorderLocal: #dfe2ed;
$tTrEvenBg: #f6f7fb;
$tdHoverBg: #e5e7f3;
$tdHoverBorder: #aeb3c6;
$trHover: #edeff7;

@import 'table-on-fire__outer';
@import 'table-on-fire__inner';
@import 'table-on-fire';
@import 'tof-grid';
@import 'table-on-fire__head';
@import 'table-on-fire__body';
@import 'tof-body__spec-text';
@import 'tof-body__title';
@import 'tof-body__title-text';
@import 'tof-body__input';
@import 'tof-body__editing';
@import 'tof-body__result';
@import 'tof-control-wrapper';
@import 'tof-button-wrapper';
@import 'tof-body__bottom';
.s-direction-block__mark {
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s-direction-block__refuse {
  margin-left: 1rem;
}

.s-bilim-marketing__imagesWrapper{
  align-self: baseline;
}
.static-block{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 5rem;
}
.s-bilim-marketing__schoolImg{
  &--mobile{
    display: none;
    img{
      max-width: 100%;
    }
  }
}
//  &:first-of-type{
//    z-index: 1;
//  }
//  &:last-of-type{
//    z-index: -1;
//  }
//}
//.s-bilim-marketing__icon{
//  &:first-of-type{
//    z-index: 2;
//  }
//  &:nth-of-type(2){
//    z-index: 2;
//  }
//  &:last-of-type{
//    z-index: 2;
//  }
//}


.s-vue-modal {
  @include pos(absolute);
  @include sflx($juscont: center);
  width: 100%;
  height: 100%;
  max-width: 65rem;
  padding-top: 2rem;
  transition: all $gDuration ease;

  &--disabled {
    opacity: 0.2;

    &::after {
      @include pos(absolute, 100, 0, 0, 0, 0);
      background-color: transparent;
      content: '';
    }
  }

  &--is-center {
    margin: auto;
    top: 50%;
  }

  &--is-scroll {
    max-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--is-scroll-fullscreen {
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &--is-fullscreen {
    width: 100%;
    min-height: 100%;
    margin: 0;
  }

  &--is-bottom {
    bottom: 0;
  }

  &--is-top {
    top: 0;
  }

  &--is-right {
    right: 0;
    margin-right: 3rem;
  }

  &--is-left {
    left: 0;
    margin-left: 3rem;
  }

  &--xl {
    max-width: 102.4rem;
  }

  &--lg {
    max-width: 85rem;
  }

  &--md {
    max-width: 65rem;
  }

  &--sm {
    max-width: 55rem;
  }

  &--xs {
    max-width: 35rem;
  }

  &--p50 {
    max-width: 50%;
  }

  &--p70 {
    max-width: 70%;
  }

  &--p90 {
    max-width: 90%;
  }
}

.s-statits-fig__block-desc {
  @include pos(absolute, 1);
  width: 41.2rem;
  transform: rotate(45deg);

  span {
    @include pos();
    display: inline-block;
    width: 100%;
    font-weight: 600;
    font-size: 4.6rem;
    color: $cGreen;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $cGreen;

    &::after {
      @include pos(absolute, 1, $b: -0.4rem, $r: 0);
      width: 0.7rem;
      height: 0.7rem;
      background-color: $cBlue;
      border-radius: 50%;
      content: '';
    }
  }

  p {
    font-weight: 600;
    font-size: 2.2rem;
    color: $cWhite;
    margin: 1rem 0;
  }
}

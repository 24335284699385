.test-cont__result-column {
  @include sflx($juscont: space-between, $dir: column, $alitems: center);
  width: 50%;

  @media screen and (max-width: 479px) {
    width: 100%;

    &:nth-child(2) {
      order: 1;
    }
    &:nth-child(1) {
      order: 2;
    }
  }
}

.test-cont__result-block {
  text-align: center;

  span, div {
    display: block;
    width: 100%;
  }
  span {
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  div {
    font-size: 2.2rem;
    line-height: 1.2em;
    font-family: $fSansSB;
  }
}

.test-cont__result-block_mrgn_b {
  margin-bottom: 3rem;
}

@media screen and (max-width: 479px) {
  .test-cont__result-block {
    padding: 1rem 0;
  }
}

.s-application-block {
  @include sflx($dir: column);
  width: 100%;
  padding: 2rem 3rem 0;
  border: 1px solid $cGrey;
  border-radius: $gBorderRadius;
  overflow: hidden;

  &.is-locked {
    background-color: $cErrorLight;

    .s-flag {
      &::before {
        border-left-color: $cErrorLight;
      }
    }

    .s-application-block__body {
      margin-right: 0;

      .s-desc-item__label {
        color: #A093B0;
      }
    }

    .s-application-block__warning {
      background-color: #FBD8D8;
    }
  }

  &.is-loading {
    * {
      font-size: 0;
    }

    .s-application-block__head,
    .s-application-block__steps,
    .s-desc-item__label,
    .s-desc-item__value {
      @include sbg($i: $gLoaderPlaceholder, $s: cover);
      transition: background $gDuration ease-in;
    }

    .s-desc-item__label {
      min-height: 1.6rem;
    }

    .s-desc-item__value {
      min-height: 1.8rem;
    }

    .s-application-block__head {
      height: 3.2rem;
    }

    .s-application-block__steps {
      height: 2.6rem;
    }

    .s-application-block__controls {
      visibility: hidden;
    }
  }
}

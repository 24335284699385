.s-test-process__center-header {
  @include sflx($juscont: flex-start, $alitems: center);
  width: 100%;
  min-height: 2rem;

  @media screen and ( max-width: 1008px ) {
    @include pos(absolute, $t: 2rem, $l: 2rem);
  }

  @media screen and ( max-width: 480px ) {
    top: 10.5rem;
    left: 0;
    text-align: center;
  }
}

.sn-item__section {
  @include sflx($dir: column, $juscont: center);

  &--main {
    width: auto;
    min-width: 0;
    max-width: 100%;
    flex: 10000 1 0%;

    + & {
      margin-left: 8px;
    }

    ~ .sn-item__section {
      &--side {
        align-items: flex-end;
        padding-right: 0;
        padding-left: 16px;
      }
    }

    &.sn-item__section {
      &--thumbnail {
        margin-left: 0;
        margin-right: -16px;
      }
    }
  }

  &--side {
    color: #757575;
    align-items: flex-start;
    padding-right: 16px;
    width: auto;
    min-width: 0;
    max-width: 100%;

    > .s-ico {
      font-size: 24px;
    }

    > .sn-avatar {
      font-size: 40px;
    }
  }

  &--avatar {
    color: inherit;
    min-width: 56px;
  }

  &--thumbnail {
    img {
      width: 100px;
      height: 56px;
    }
  }

  &--nowrap {
    white-space: nowrap;
  }
}

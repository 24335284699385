@media screen and (max-width: 1008px) {
  .s-info-card--with-table {
    .s-table__body td {
      padding-left: unset;

      &::before {
        position: unset;
        text-align: unset;
        min-width: unset;
        max-width: 50%;
        width: 100%;
        padding-left: unset;
        padding-right: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .s-info-card--with-buttons {
    .s-info-card--card {
      padding-right: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .s-info-card--with-buttons {

    > div:nth-child(2) {
      padding-left: unset;
      margin-bottom: 2rem;
    }

    .s-info-card--card {
      order: 2;
    }
  }
}

@media screen and (max-width: 480px) {
  .s-info-card--with-table {
    .s-table__body td {
      padding-left: 1rem;

      &::before {
        max-width: unset;
        padding-left: unset;
        padding-right: unset;
      }
    }
  }
}

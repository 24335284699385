.sn-dialog__inner {
  @include sflx();
  outline: 0;
  overflow: auto;
  will-change: scroll-position;

  &--square {
    >div {
      border-radius: 0 !important;
    }
  }

  &--minimized {
    padding: 24px;

    //>div {
    //  max-height: calc(100vh - 48px);
    //}
  }

  &--maximized {
    >div {
      height: 100%;
      width: 100%;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0 !important;
    }
  }

  &--top {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    >div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &--bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    >div {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--right {
    padding-right: 0 !important;
    padding-left: 0 !important;

    >div {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--left {
    padding-right: 0 !important;
    padding-left: 0 !important;

    >div {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &--fullwidth {
    >div {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  &--fullheight {
    >div {
      height: 100% !important;
      max-height: 100% !important;
    }
  }

  >div {
    pointer-events: all;
    //overflow: auto;
    -webkit-overflow-scrolling: touch;
    //will-change: scroll-position;
    border-radius: $gBorderRadius;
    box-shadow: $shadow-4;
  }

  >.sn-card {
    >.sn-card__actions {
      .sn-btn--rectangle {
        min-width: 64px;
      }
    }
  }
}

.s-test-process__navigation-backward {
  @extend %s-test-process__navigation-buttons;
  margin-left: 3rem;

  &::before {
    left: -3rem;
    content: map_get($listOfIcons, 'angle-left');
  }

  @media screen and ( max-width: 1264px ) {
    margin-left: unset;
  }
}

// Global
$gFontSize: 1.6rem;
$gFontSizeFixed: 10 / 2560 * 100vw;
$gBorderRadius: 5px;
$gWidthDef: 1400px;
$gWidthSmall: 1250px;
$gWidthMax: 2560px;
$animationMenuDuration: 0.3s;

// Fonts
$fDef: 'Open Sans', sans-serif;
$fIcons: snation;

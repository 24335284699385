.s-menu-cabinet__item {
  @include pos();
  display: block;
  width: 100%;
  color: $cBlack;
  overflow: hidden;

  &--link {
    padding: 1.05rem 2.5rem;

    &.is-active {
      font-weight: 600;
    }
  }

  & > span {
    padding: 1.05rem 2.5rem;
    cursor: pointer;

    i {
      transform: rotateX(0deg);
      transition: transform 0.6s;
    }
  }

  &:not( :last-child ) {
    border-bottom: 1px solid $cBorderLight;
  }

  &:last-child {
    border-radius: 0 0 $gBorderRadius $gBorderRadius;
  }

  &:first-child {
    border-radius: $gBorderRadius $gBorderRadius 0 0;
  }

  &:not( .is-active ):hover {
    background-color: $cBorderLight;
  }

  &.is-active {
    &::before {
      @include pos(absolute, $t: 0, $l: 0);
      display: block;
      width: 0.3rem;
      height: 100%;
      background-color: #3045A7;
      content: '';
      z-index: 1;
    }

    & > span {
      font-weight: 600;
    }
  }

  &.is-open,
  &.is-active {
    & > span {
      i {
        transform: rotateX(-180deg);
        transition: transform 0.6s;
      }
    }
  }

  &:not( .is-active ).is-open {
    background-color: $cBorderLight;
  }

  &[ data-notify-count ] {
    padding-right: 5rem;

    &::after {
      top: 50%;
      right: 1.5rem;
    }
  }
}

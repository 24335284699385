@media screen and ( max-width: 1008px ) {
  .course-spoiler {
    &--college {
      .course-spoiler-body {
        height: 100%;
      }

      .course-spoiler-body__item {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 1.5rem;
      }

      .course-spoiler__section-name {
        padding-left: 0;
      }

      .course-spoiler-body__item-progress-wrapper {
        width: 100%;
        text-align: left;
      }
    }
  }
}

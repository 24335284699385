@mixin sflx(
  $d: flex,
  $dir: '',
  $wrap: '',
  $juscont: '',
  $alitems: flex-start) {
  display: $d;
  @if ($dir != '') {
    flex-direction: $dir;
  }
  @if ($wrap != '') {
    flex-wrap: $wrap;
  }
  @if ($juscont != '') {
    justify-content: $juscont;
  }
  @if ($alitems != '') {
    align-items: $alitems;
  }
}

$c_college_shadow: #687C8F;
$cTabColor: #263E9B;
$cTabBG: #D9E3EC;
$fDef: 'Open Sans', sans-serif;
$cWhite: #FFFFFF;
$cBlack: #000000;
$cBlue: #3045A7;

ul.colleges-tabs__head {
  width: 100%;
  display: table;
  font-size: 0;
  border-bottom: 1px solid $cTabColor;
  margin-bottom: 3rem;

  &.colleges-tabs__head--list_long {
    li {
      max-width: 20rem;
    }
  }

  li {
    display: inline-block;
    vertical-align: bottom;
    max-width: 29rem;
    width: 100%;
    font: 600 1.8rem/1.2em $fDef;
    color: #223B99;
    text-align: center;
    padding: 0.8rem 1rem;
    cursor: pointer;
    border-left: 1px solid $cWhite;
    background-color: #C4D0D8;
    transition: background-color 0.2s ease-in;

    &:first-child {
      border-top-left-radius: 6px;
      border-left: 0;
    }

    &:last-child {
      border-top-right-radius: 6px;
    }

    &.is-active {
      background-color: $cWhite;
      border: 1px solid #223B99;
      border-bottom: 0;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      margin-bottom: -0.1rem;
      border-radius: 6px 6px 0 0;

      + li {
        border-left: 0;
      }

      &:hover {
        background-color: $cWhite;
      }
    }

    &:hover {
      background-color: darken(#C4D0D8, 5%);
      transition: background-color 0.2s ease-in;
    }
  }
}

ul.colleges-tabs__body {
  > li {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

.colleges-tab__head-device {
  display: none;
  width: 100%;
  font: 600 1.8rem/1.2em $fDef;
  color: #223B99;
  text-align: center;
  background-color: #C4D0D8;
  padding: 0.8rem 1rem;
  cursor: pointer;

  &.is-active {
    background-color: #223B99;
    color: $cWhite;
  }
}

.colleges-array {
  width: 100%;

  .college-block {
    width: 612 / 1280 * 100%;
    margin-right: 56 / 1280 * 100%;
    margin-bottom: 5.4rem;

    &.__college {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child( 2n+2 ) {
      margin-right: 0;
    }
  }
}

.flex-wrap {
  .colleges-array {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
$college-block: 'college-block';

a.college-block {
  transition: box-shadow 0.2s ease-in;

  &:hover {
    box-shadow: 0 0 20px rgba(#687C8F, 0.48);
    transition: box-shadow 0.2s ease-in;
  }
}

.college-block {
  @include sflx($juscont: flex-start, $alitems: stretch);
  vertical-align: top;
  background-color: $cWhite;
  box-shadow: 0 0 10px rgba(#687C8F, 0.28);
  border-radius: 5px;

  &.__college {
    flex-flow: row wrap;
    padding: 2.5rem 3rem;

    .college-block__description {
      padding: 0 0 0 3rem;
    }

    .college-block__image {
      width: 100%;
      max-width: 30rem;
      //min-height: 24rem;
      border-radius: 5px;

      &::before {
        margin-top: 240 / 300 * 100%;
        display: table;
        content: '';
      }
    }

    .college-block__info {
      flex: 1 1 auto;
      color: $cBlack;
      padding: 2.5rem 0 0;

      td {
        padding: 0.5rem 0;
      }
    }
  }

  &__visual {
    @include sflx($juscont: space-between, $alitems: flex-end);
    flex-flow: column wrap;
    flex-shrink: 0;
    width: 20rem;
    min-height: 18rem;
    border-radius: 6px 0 0 6px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url(../images/back_image.jpg);

    .#{$college-block}__image {
      background: none;

      &.__nophoto {
        background: none;

        &::after {
          content: url(../images/college_icon_nophoto.png);
        }
      }
    }
  }
}

.school_rating {
  @include sflx($d: inline-flex, $juscont: flex-end, $alitems: flex-end);
  flex-flow: row nowrap;
  padding: 0.5rem;

  &--middle {
    justify-content: center;
  }

  &__score {
    @include sflx($juscont: center, $alitems: center);
    flex-flow: row wrap;
    min-width: 3rem;
    min-height: 3rem;
    margin: 0 0 0 0.2rem;
    color: white;
    background: black;
    font-size: 1.7rem;

    &--yellow {
      color: black;
      background: #FFCC00;
    }
  }
}

.college-block__content {
  @include sflx($juscont: flex-start, $alitems: stretch);
}

.college-block__image {
  flex-shrink: 0;
  width: 20rem;
  max-width: 20rem;
  min-height: 18rem;
  border-radius: 6px 0 0 6px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &.__nophoto {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $cBlue;
    background-image: url(../images/back_image.jpg);

    &::after {
      content: url(../images/college_icon_nophoto.png);
    }
  }
}

.college-block__description {
  flex: 1 1 auto;
  color: $cBlack;
  padding: 2.5rem 3rem;
}

.college-block__title {
  font: bold 1.8rem/1.2em $fDef;
  margin-bottom: 1.5rem;
}

.college-block__text {
  font: 1.6rem/1.2em $fDef;
  word-break: break-word;

  ul {
    li {
      margin-bottom: 0.5rem;
    }
  }
}


@media screen and ( max-width: 999px ) {
  .colleges-array {
    .college-block {
      width: 100%;
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  ul.colleges-tabs__head {
    display: none;
  }

  .colleges-tab__head-device {
    display: block;
  }
}
@media screen and ( max-width: 599px ) {
  .college-block__image {
    width: 18rem;
  }

  .college-block__description {
    padding: 1.5rem 2rem;
  }

  .college-block__title {
    font-size: 1.6rem;
  }

  .college-block__text {
    font-size: 1.4rem;
  }
}
@media screen and ( max-width: 479px ) {
  .college-block {
    flex-direction: column;
  }

  .college-block__image {
    width: 100%;
    max-width: 100%;
    border-radius: 6px 6px 0 0;
  }

  .college-block__title {
    margin-bottom: 2rem;
  }
}

@media screen and ( max-width: 1348px ) {
  .s-balabaqsha-catalog__filter-wrapper {
    width: 28rem;
  }
}


@media screen and ( min-width: $gMinWidthDesktop ) {
  .s-balabaqsha-catalog__filter-controls {
    display: none;
  }
}


@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-balabaqsha-catalog {
    flex-direction: column;
  }

  .s-balabaqsha-catalog__filter-wrapper {
    width: 100%;
    padding-right: 0;
  }

  .s-balabaqsha-catalog__filter {
    margin-bottom: 3rem;
  }

  .s-balabaqsha-catalog__blockquote {
    display: none;
  }
}


@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-balabaqsha-catalog__content {
    flex: unset;
  }
}

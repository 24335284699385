.status-cont {
    display: flex; 
    font-size: 16px;
    font-family: 'Open Sans', sans-serif; 
    margin-top: -2rem; 
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .status-cont{
        display: none;
    }
}
.s-chat-popup {
  &__wrapper {
    max-width: 30rem !important;
    width: 100%;
    max-height: 36rem !important;
    height: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex !important;
    flex-direction: column;
  }
}

.s-table-with-filters {
  .sn-table__grid-item-row + .sn-table__grid-item-row {
    margin-top: 1rem;
  }

  .sn-table {
    &__bottom {
      border-top: unset;
    }

    &__middle {
      border: 1px solid #CCD1E9;
    }

    thead {
      th {
        background-color: unset;
        color: #565D82;
        font-weight: 700;
        font-size: 15px;
      }
    }

    thead, tr, th, td {
      border-color: #CCD1E9;
    }

    tbody {
      tr {
        position: relative;

        &:nth-child(even) {
          background-color: unset;
        }

        &:hover {
          background-color: #FAFBFF;
          box-shadow: 0 0 10px #CACEE1;
        }

        td {
          color: #565D82;
        }
      }
    }

    &__grid-item {
      &-card {
        flex-direction: column;
        display: flex;
      }
      &-row {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(204, 209, 233, 0.6);
          padding-bottom: 1rem;
        }
      }
      &-value {
        font-weight: 600;
        font-size: 15px;
        color: #565D82;
        line-height: 20px;
      }
      &-title {
        color: #8A91B7;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

// Fonts

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/OpenSans_Light.woff') format('woff'),
       url('../fonts/OpenSans_Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/OpenSans_Regular.woff') format('woff'),
       url('../fonts/OpenSans_Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: italic;
  src: url('../fonts/OpenSans_Italic.woff') format('woff'),
       url('../fonts/OpenSans_Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/OpenSans_Semibold.woff') format('woff'),
       url('../fonts/OpenSans_Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/OpenSans_Bold.woff') format('woff'),
       url('../fonts/OpenSans_Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'themify';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/themify.ttf') format('truetype'),
       url('../fonts/themify.woff') format('woff'),
       url('../fonts/themify.woff2') format('woff2');
}

.s-test-process__result-table {
  width: 100%;
  border-spacing: 0;

  tr {
    &:not( :last-child ) {
      td {
        border-bottom: 1px solid $stpGrey;
      }
    }
  }

  td {
    padding: 1rem 2rem;

    &:first-child {
      text-align: right;
      word-break: break-word;
      border-right: 1px solid $stpGrey;
    }

    &:last-child {
      min-width: 11rem;
      width: 11rem;
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
    }
  }

  @media screen and ( max-width: 599px ) {
    td {
      width: 50%;

      &:first-child {
        font-size: 2rem;
      }

      &:last-child {
        font-size: 2.4rem;
        text-align: left;
      }
    }
  }

  @media screen and ( max-width: 479px ) {
    td {
      width: unset;
    }
  }
}

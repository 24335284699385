.s-pie-chart__legend-item {
  @include sflx($alitems: center);
  font-size: 1.2rem;
  padding: 0.5rem 0 0.5rem 3rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &.is-active {
    font-weight: 700;
  }

  span {
    flex-shrink: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    border-radius: 3px;
  }

  i {
    display: inline;
    font-style: normal;
    font-weight: 600;
  }
}

.s-chat-popup {
  &__message {
    max-width: 90%;
    background-color: rgb(128 150 255 / 50%);
    flex-direction: column;
    display: flex;
    position: relative;
    padding: 0.5rem 0.8rem;
    border-radius: 14px;

    &--block {
      &.self {
        justify-content: flex-end;

        .s-chat-popup__message {
          background-color: rgb(128 150 255 / 20%);
          order: 2;
        }
        .s-chat-popup__message--time {
          order: 1;
        }
      }

      width: 100%;
      max-width: 100%;
      justify-content: flex-start;
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
    }

    &--user {
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    &--text {

    }
    &--time {
      align-self: flex-end;
      color: rgb(0 21 117 / 46%);
      font-size: 1rem;
    }
  }
}

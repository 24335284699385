.step-block__buttons-wrapper {
  @include sflx($dir: column, $alitems: center);
  margin-top: auto;

  > * {
    min-width: 20rem;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
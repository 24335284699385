.s-table-with-filters {
  &__sort-active {
    .sn-field {
      &__control {
        background-color: #f5942e;
        color: #fff;
      }
      .s-ico-fa {
        &::before {
          color: #fff !important;
        }
      }
    }
  }
  &__sort-row {
    &--with-col-filter {
      justify-content: space-between;
    }

    > div {
      display: flex;
      place-items: center;
    }
    display: flex;
    place-items: center;

    > span, &--label {
      color: #565D82;
      margin-right: 2rem;
    }

    .sn-field {
      &__wrapper {
        width: 20rem;
      }

      &__native {
        justify-content: center;
      }

      &__control {
        min-height: 3rem;
        max-height: 3rem;
        border-radius: 17px;
      }

      color: #565D82;
      font-weight: 700;
      font-size: 15px;

      .s-ico-fa::before {
        color: #565D82;
        font-weight: 600;
      }
    }
  }
}

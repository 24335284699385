.s-upper {
  text-transform: uppercase;
}

.s-lower {
  text-transform: lowercase;
}

.s-cap {
  text-transform: capitalize;
}

.s-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.s-cap-first {
  &::first-letter {
    text-transform: capitalize;
  }
}

.s-light {
  font-weight: 300;
}

.s-medium {
  font-weight: 600;
}

.s-bold {
  font-weight: 700;
}

.s-f-13 {
  font-size: 1.3rem;
}

.s-f-14 {
  font-size: 1.4rem;
}

.s-to-left {
  text-align: left;
}

.s-to-center {
  text-align: center;
}

.s-to-right {
  text-align: right;
}

.s-word-break {
  word-break: break-word;
  hyphens: auto;
}
.pre-line {
  white-space: pre-line;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

%s-social-icons__ {
  @include sflx($alitems: center, $juscont: center);
  width: 2.5rem;
  height: 2.5rem;
  color: $cWhite;

  &::after {
    font-size: 2.2rem;
    font-family: $fIcons;
  }

  &:not( :last-child ) {
    margin-right: 1rem;
  }
}

.s-social-icons {
  @include sflx($juscont: flex-start, $alitems: center);
}
%s-social-icons-fa__ {
  @include sflx($alitems: center, $juscont: center);
  width: 2.5rem;
  height: 2.5rem;
  color: $cWhite;

  &::after {
    font-size: 2.2rem;
    font-family: $fFABrandsIcons;
  }

  &:not( :last-child ) {
    margin-right: 1rem;
  }
}

.s-social-icons {
  @include sflx($juscont: flex-start, $alitems: center);
}

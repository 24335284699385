.sn-select {
  &--without-input {
    .sn-field__control {
      cursor: pointer;
    }
  }

  &--with-input {
    .sn-field__control {
      cursor: text;
    }
  }

  .sn-field__native {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.step-block {
  @include pos();
  @include sflx($dir: column, $alitems: center);
  color: #aeb3c6;
  text-align: center;
  background-color: $cWhite;
  padding: 4rem 2.5rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 10px rgba(104, 124, 143, 0.3);
}

$listOfStatuses: (
  (success, #33cc66, #f0faf3, '\E64C', rgba(51, 204, 102, 0.43)),
  (waiting, #f5942e, #fff8f1, '\E619', rgba(245, 148, 46, 0.46)),
  (rejected, #ff7f7f, #fff8f1, '\E646', rgba(255, 128, 128, 0.38)),
);

@each $name, $color, $bgColor, $icon, $bColor in $listOfStatuses {
  .step-block_t_#{$name} {
    color: $color;
    background-color: $bgColor;
    border: 1px solid $bColor;

    .step-block__status-icon {
      background-color: $color;

      &::after {
        content: $icon;
      }
    }

    .step-block__name,
    .step-block__description {
      color: $cBlack;
    }

    .btn {
      background-color: $color;

      &:hover {
        background: darken($color, 5%);
      }
    }
  }
}
.s-main-header__section {
  @include pos();
  max-width: 25rem;

  //&:not( :last-child ) {
  //  margin-right: 3rem;
  //}

  &.is-active {
    .s-dropdown {
      display: block;
    }
  }

  &.s-notify-count {
    &::after {
      top: 0;
      right: calc(100% - 2.6rem);
    }
  }

  > .s-btn {
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .s-str-ico {
      display: block;

      span {
        display: block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

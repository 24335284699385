%modal {
  padding-right: 0 !important;

  .modal-dialog {
    padding: 0;
  }

  .modal-close {
    font-size: 1.6rem;
    color: $cWhite;
    padding: 1.2rem;
    border-radius: 0 $gBorderRadius 0 0;
    background-color: transparent;
    transition: background-color;

    &:hover {
      background-color: darken(#364ca7, 5%);
      transition: background-color;
    }
  }

  .modal-head {
    background-color: #364ca7;
    padding: 1.1rem 5rem 1.1rem 2rem;
    border-radius: $gBorderRadius $gBorderRadius 0 0;

    .modal__title {
      color: $cWhite;
    }
  }

  .modal-body {
    padding: 2rem;

    + .modal-footer {
      padding-top: 0;
    }
  }

  .modal-text {
    strong {
      font-weight: 600;
    }

    ul {
      list-style-type: disc;
      margin-left: 2rem;
    }
  }

  .modal-footer {
    @include sflx();
    padding: 2rem;

    &_center {
      justify-content: center;
    }

    &_right {
      justify-content: flex-end;
    }

    > * {
      min-width: 15rem;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }


  @media screen and (max-width: 767px) {
    .modal-footer {
      > * {
        width: 100%;
        margin-right: 0 !important;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .modal-body {
      padding: 2rem !important;
    }
  }
}

.footer {
  @include pos($z: 2);
  display: block;
  flex-shrink: 0;
  width: 100%;
  //background-color: $cBlue;
  @include sbg($bgFooter, "../images/footer_bg.png", $s: auto 100%);
  box-shadow: inset 0 6px 30px -6px rgba(0,0,0,0.75);
}

.footer_mrgn_r {
  margin-right: 3rem;
}

.footer_feedback-link {
  cursor: pointer;
  margin-bottom: 3rem;

  span {
    border: 1px dotted transparent;

    &:hover {
      border-bottom-color: darken($cWhite, 1%);
    }

  }

}

//TODO Временный модификатор
.footer_region-link {
  @extend .footer_feedback-link;
  @include pos();
  padding-right: 2.5rem;

  &::after {
    content: '\E693';
    //@include pos(absolute, 2, $b: .3rem, $r: -2.5rem);
    @include pos(absolute, 2, $b: .3rem, $r: 0);
    font-size: 1.8rem;
    line-height: 1em;
    font-family: $fThemify;
  }

  span {
    display: block;
    max-width: 30rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

}

@media screen and (max-width: 480px) {
  .footer_feedback-link {
    width: 100%;
    max-width: 26rem;
  }
  .footer_mrgn_r {
    margin-right: 0;
  }
}

.registration-steps-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  width: 100%;
  margin-bottom: -3.9rem;
}
.registration-step {
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  font-size: 1.6rem;
  line-height: 1.2em;
  margin-right: 8.6rem;
  color: $c_grey;
  &:last-child {
    margin-right: 0;
    .__desc {
      &:after {
        display: none;
      }
    }
  }
  &.is-active {
    color: $c_black;
    .__desc {
      &:after {
        color: $c_black;
      }
    }
  }
  .__label {
    font-family: $font_sans_sb;
  }
  .__desc {
    flex: 1 0 auto;
    font-family: $font_default;
    position: relative;
    &:after {
      content: '\E628';
      position: absolute;
      top: 50%;
      margin-top: -1.3rem;
      right: -5rem;
      font: 2.2rem/1.2em $font_themify;
      color: $c_grey;
    }
  }
}

.registration-message-wrapper {
  width: 100%;
  max-width: 54.4rem;
  margin: 0 auto;
  color: $c_black;
}
.registration-message__title {
  font: 1.6rem/1.2em $font_sans_sb;
  margin-bottom: 1rem;
}
.registration-message__text {
  font: 1.6rem/1.2em $font_default;
}

.canvas-clock {
  display: table;
  margin: 4rem auto 0;
}


@media screen and (max-width: 999px) {
  .registration-step {
    flex: 1;
    max-width: none;
  }
}
@media screen and (max-width: 699px) {
  .registration-steps-wrapper {
    display: none;
  }
}

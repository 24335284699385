$c_black: #000;
$c_dark: #333;
$c_white: #fff;
$c_green: #33cc66;
$c_blue: #3045a7;
$c_aqua: #33a6cc;
$c_grey: #8a9da8;
$c_orange: #ff8400;
$c_red: #ff0000;
$c_green_white: #57bd6f;
$c_body: #f7f9fc;
$c_white_blue: #00a8ff;

$c_body: #f7f9fc;


$c_blue_dark: #081c6b;

$c_aqua_light: #00c0ff;
$c_aqua_dark: #1194dc;

$c_grey_light: #f1f5f7;
$c_grey_dark: #9ba5a5;
$c_green_light: #0db58b;

$c_hr_dashed: #a2aeb7;


$c_header_buttons_bg: #e8f1f7;
$c_social: #d9dfe1;
$c_footer_bottom_bg: #dbe5ea;
$c_footer_bottom_text: #879094;
//Все кнопки красим в $c_blue


$bgHeader: #18308e;
$c_yellow: #ffdb4d;


//Status
$c_done: #68da78;
$c_undone: #ff6969;
$c_purple: #953993;

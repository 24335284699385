$cBlack: #000000;
$cWhite: #FFFFFF;
$cBlue: #3045A7;
$cBlueDark: #202B56;
$cAqua: #3f7fd8;
//$cAqua: #3CA8FB;  Old aqua
$cRed: #FF0000;
$cWhiteRed: #EB5248;
$cRedMute: #F84E43;
$cGreen: #8DBE2D;
$cGrey: #ACB1C1;
$cLightGreyBlue: #a9b9c2;
$cOrange: #F5942E;
$cOrangeDark: #FF6600;
$cYellowIcons: #D6B837;
$cLightPurple: #8EA1F5;
$cPurple: #9C27B0;
$cBbYellow: #f1ae00;
$cQujatAqua: #2DB48C;

$cBorder: #c2c8e4;
$cStriped: #F3F6F8;
$cHover: #ECF2F5;
$cAlertRed: #FFEBEB;
$cAlertRedBorder: #FF6868;
$cAlertBlue: #BDEDFF;
$cAlertGreen: #C9F6D8;
$cAlertGreenNew: #8DC5265C;
$cAlertIconGreen: #70A500;
$cAlertGrey: #C6C6C65B;
$cAlertIconGrey: #999999;
$cAlertYellow: #FFF9DA;
$cError: $cRed;
$cErrorLight: #EFC7C7;
$cGreyText: #AEB3C6;
$cPlaceholder: #8A9DA8;
$cDarkPlaceholder: #484e54;
$cBreadcrumbsItem: #6A7B85;
$cStatitsWrapperBg: #3D52B8;
$cPaleGray: #FAFBFE;
$cMetal: #77848C;
$cCoral: #FF9E8D;

@media screen and ( max-width: 1439px ) {
  .s-statits-fig__block {
    &--first,
    &--third {
      .s-statits-fig__block-desc {
        text-align: right;

        span {
          &::after {
            left: 0;
          }
        }
      }
    }

    &--first {
      .s-statits-fig__block-desc {
        top: 150%;
        left: 80%;
      }
    }

    &--third {
      .s-statits-fig__block-desc {
        top: 144%;
        left: 83%;
      }
    }
  }
}


@media screen and ( max-width: 1119px ) {
  .s-statits-fig {
    width: 100%;
    height: unset;
    justify-content: space-around;
    background-color: unset;
    transform: unset;
    padding-bottom: 3rem;
  }

  .s-statits-fig__block {
    background-color: unset;

    &:first-child {
      width: 100%;
      background-color: unset;

      .s-statits-fig__block-inner {
        width: 100%;
        height: unset;
        color: $cWhite;
        padding-bottom: 1rem;
      }
    }

    &:not( :first-child ) {
      .s-statits-fig__block-inner {
        background-color: $cWhite;
        border-radius: 16px;
      }
      box-shadow: unset;
    }

    &--first,
    &--second,
    &--third {
      width: 20.6rem;

      .s-statits-fig__block-desc {
        width: 100%;
        text-align: center;
      }
    }
  }

  .s-statits-fig__block-inner {
    margin-bottom: 1.5rem;
    transform: unset;
  }

  .s-statits-fig__block-desc {
    position: static;
    transform: unset;
    width: 100%;

    span {
      padding-bottom: 0;
      border-bottom: unset;

      &::after {
        display: none;
      }
    }
  }
}

@media ( min-width: 1119px ) {
  .s-statits-fig__block-desc {
    p {
      width: 90%;
      float: right;
    }
  }
}

@media ( min-width: 1439px ) {
  .s-statits-fig__block {
    &--first,
    &--third {
      .s-statits-fig__block-desc {
        p {
          float: left;
        }
      }
    }
  }
}
@media screen and ( min-width: 1439px ) {
  .s-statits-fig__block {
    &--first,
    &--third {
      p {
        position: absolute;
      }
    }
  }
}

.s-table-with-filters {
  &__search-row {
    display: flex;
    place-items: center;
    margin-bottom: 1rem;

    > button {
      min-width: 15rem;
      margin-right: 1rem;
    }

    > .sn-input {
      width: 100%;
    }
  }
}

@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-statistic-panel__wrapper {
    flex-direction: column;
    margin-bottom: 2.5rem;

    > * {
      margin-bottom: 0.5rem;
      flex-basis: unset;
      flex-grow: unset;
    }
  }

  .s-statistic-panel {
    height: unset;
    flex-direction: row;
    align-items: center;
    max-width: 100% !important;
  }

  .s-statistic-panel__label {
    text-align: left;
    padding-right: 2rem;
  }

  .s-statistic-panel__icon {
    display: none;
  }

  .s-statistic-panel__value {
    width: auto;
    margin-left: auto;
  }

  .s-statistic-panel__label,
  .s-statistic-panel__value {
    margin-top: 0;
    margin-bottom: 0;
  }
}

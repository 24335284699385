.sn-select__options {
  &--content {
    position: relative;
    background-color: inherit;
  }

  &--padding {
    background: linear-gradient(
        transparent,
        transparent 20%,
        rgba(128, 128, 128, .03) 20%,
        rgba(128, 128, 128, .08) 50%,
        rgba(128, 128, 128, .03) 80%,
        transparent 80%,
        transparent
    );
    background-size: 100% 50px;
  }
}

.s-vue-modal__body {
  width: 100%;
  padding: 2rem;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.6rem rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    outline: 1px solid #708090;
  }
}

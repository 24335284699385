.container-content-head__tabs-wrapper {
  @include sflx($alitems: flex-end);
  width: 100%;
  min-height: 4rem;


  @media screen and (max-width: 639px) {
    flex-direction: column;
    align-items: center;
    min-height: unset;
    align-content: center;
  }
}


.container-content-head__tabs--free {
  .container-content-head__tab-item {
    flex: unset;
    max-width: unset;
    //min-width: 20rem;

    &--only-icon {
      min-width: 4.5rem;
    }
  }
}

.container-content-head__tabs--right {
  justify-content: flex-end;
}

.container-content-head__tabs-wrapper--q-btns-mod {
  &.container-content-head__tabs--free {
    .sn-btn {
      flex: unset;
      max-width: unset;
    }
  }
  .sn-btn {
    flex: 1;
    max-width: 25rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    border-radius: $gBorderRadius $gBorderRadius 0 0;
    box-shadow: unset;

    .sn-btn__content > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1;
    }

    &:not( :last-child ) {
      margin-right: 1.2rem;
    }
  }
}


@media screen and (max-width: 639px) {
  .container-content-head__tabs-wrapper--q-btns-mod {
    > .sn-btn {
      display: block;
      width: 100%;
      max-width: 100%;
      border-radius: $gBorderRadius;

      &:not( :last-child ) {
        margin-right: 0;
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }
  }
}

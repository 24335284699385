@mixin select-drop-down($selector) {
  .#{$selector}__select-drop-down {
    @include pos($z: 1);
    padding: 1rem 2rem;

    &__hover {
      padding: 0 2rem 0 0;
      border: 1px solid transparent;
      border-radius: 6px;
      transition: border-color .2s ease-in;

      &:hover {
        border-color: $cWhite;
        transition: border-color .2s ease-in;
      }

      .#{$selector}__select-drop-down__label {
        padding: 1rem 3rem 1rem 2rem;
      }
    }

    &__link {
      font: 1.6rem/1.2em $fDef;
      color: $cWhite;
      text-transform: uppercase;

      a {
        color: $cWhite;

        &:hover {
          border-bottom: 1px solid darken($cWhite, 1%);
        }
      }
    }

    &__account {
      .#{$selector}__select-drop-down {
        &__label {
          min-width: 14rem;
          text-align: center;
        }
        &__item {
          i {
            display: inline-block;
            min-width: 2.2rem;
            height: 2rem;
            font: bold 1.4rem/1em $font_default;
            color: $c_white;
            text-align: center;
            background-color: $c_orange;
            padding: .3rem;
            border-radius: 3px;
            box-shadow: 0 0 4px rgba($c_black, .35);
            box-sizing: border-box;
            margin-left: 1rem;
          }
        }
      }
    }

    &__label {
      @include pos($z: 2);
      max-width: 30rem;
      padding-right: 3rem;
      cursor: pointer;
      transition: color .2s ease-in;

      &::after {
        @include pos(absolute, 2, 50%, $r: 0);
        font: 1.6rem/1rem $fThemify;
        color: $cGreenArrow;
        margin-top: -.5rem;
        content: '\E64B';
      }

      .#{$selector}select-drop-down__text {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &.is-active {
        color: $cBlack;
        transition: color .2s ease-in;
      }

    }

    &__items {
      @include pos(absolute, 1, -.1rem, -.1rem, $r: -.1rem);
      max-height: 0;
      background-color: $cWhite;
      border-radius: 6px;
      box-shadow: 0 0 8px rgba($cBlack, .2);
      overflow: hidden;
      opacity: 0;
      transition: max-height .2s ease-in 0s, opacity .2s ease-in .3s, padding-top 0s ease-in .3s;

      &.status_visible,
      &.is-active {
        max-height: 100rem;
        padding-top: 4rem;
        opacity: 1;
        transition: max-height .2s ease-in .2s, opacity .2s ease-in 0s;
      }

    }

    &__item {
      display: block;
      width: 100%;
      font: 1.6rem/1.2em $fDef;
      color: $cBlack;
      background-color: transparent;
      padding: 1.05rem 2rem;
      cursor: pointer;
      transition: background-color .2s ease-in;

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      &:hover {
        background-color: $hBGItem;
        transition: background-color .2s ease-in;
      }

      &--active {
        background-color: $hBGItem;
      }
    }
  }
}
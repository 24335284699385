.s-uploader__remove {
  padding: 2rem;
  cursor: pointer;

  &:hover {
    .s-str-ico {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      span {
        border-bottom: 1px dotted currentColor;
      }
    }
  }
}

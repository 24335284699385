.college-library-book--wrapper {
  @include sflx($wrap: wrap);
}

.college-library-book {
  margin-right: 5.75rem;
  width: 18rem;

  &:nth-child( 5 ),
  &:nth-child( 10 ) {
    margin-right: 0;
  }
}

.picture-panel__control-backward {
  @include sflx($alitems: center, $juscont: flex-start);
  width: 2.2rem;
  height: 7.7rem;
  font-size: 2.8rem;
  line-height: 1em;
  font-family: $fIcons;
  color: #aeb3c6;
  cursor: pointer;

  &::after {
    margin-left: -.7rem;
    content: '\E64A';
  }
}
.form__statement {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .form__string {
    &:last-child {
      text-align: center;
    }
    .form__checkbox {
      text-align: center;
    }
  }
}
.statement-columns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 5rem;
}
.statement__left-wrapper {
  width: 316 / 1280 * 100%;
  min-width: 31.6rem;
  padding-right: 2rem;
}
.statement__right-wrapper {
  width: 648 / 1280 * 100%;
  min-width: 64.8rem;
  .form__string {
    &:last-child {
      text-align: left;
    }
    > label {
      font-weight: bold;
      width: 312 / 648 * 100%;
    }
    button {
      min-width: 31rem;
    }
  }
}
.statement__upload-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  input[type="file"] {
    display: none;
  }
  .button {
    width: 312 / 648 * 100%;
    &:first-child {
      margin-right: 24 / 648 * 100%;
    }
  }
  .button_type_remove, .button_type_file {
    border: 1px solid $c_blue;
    color: $c_black;
    font-family: $font_sans_sb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
      content: '\E646';
      color: $c_black;
    }
    i {
      &:after {
        content: '\E646';
        color: $c_black;
      }
    }
  }
}
.warning__text {
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
}
.warning__error {
  @extend .warning__text;
  font-family: $font_sans_sb;
  color: $c_red;
}
.statement__info {
  font: bold 1.6rem/1.2em $font_default;
  color: $c_red;
  text-align: center;
}


@media screen and (max-width: 999px) {
  .form__statement {
    padding-top: 0;
  }
  .statement-columns {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .statement__left-wrapper {
    width: 100%;
    margin-bottom: 3rem;
  }
  .statement__right-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 699px) {
  .statement-columns {
    margin-bottom: 3rem;
  }
  .statement__left-wrapper {
    min-width: auto;
    padding-right: 0;
  }
  .statement__right-wrapper {
    min-width: auto;
    .form__string {
      label {
        width: 100%;
        font-size: 1.4rem;
      }
    }
  }
  .statement__upload-wrapper {
    flex-direction: column;
    .button {
      width: 100% !important;
      margin-left: 0 !important;
      &:first-of-type {
        margin-right: 0 !important;
        margin-bottom: 2rem !important;
      }
    }
  }
}
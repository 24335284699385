.s-test-process {
  @include pos();
  @include sflx($alitems: stretch);
  width: 100%;
  min-height: 64rem;
  color: $stpDark;
  border-radius: $gBorderRadius;
  overflow: hidden;
  transition: all $gDuration ease-in;

  @media screen and ( max-width: 1008px ) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.math-tex {
  opacity: 0;
  transition: opacity $gDuration ease;

  .MathJax_SVG {
    &:focus {
      outline: none;
    }
  }
}

.modal-competition-commission {
  .modal-dialog {
    max-width: 122rem;
  }
}
.modal-header__inform-wrapper {
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: flex-start;
  .__left {
    width: 100%;
    padding-right: 1.5rem;
  }
  .__right {
    width: 100%;
    max-width: 40rem;
  }
}
.modal-header__inform {
  display: flex;
  color: #202B56;
  font-size: 1.8rem;
  line-height: 1.2em;
  margin-bottom: .5rem;

  > div {
    &:first-child {
      min-width: 18rem;
    }

    &:last-child {
      font-weight: 600;
    }
  }

  span {
    margin-right: .5rem;
  }
}
.comp-comm__inform-array {
  display: table;
  font-size: 0;
  width: 100%;
  margin-bottom: -4rem;
  .comp-comm__inform-block {
    display: inline-block;
    vertical-align: top;
    width: 188 / 1140 * 100%;
    min-width: 18.8rem;
    //margin-right: 50 / 1140 * 100%;
    margin-right: 5rem;
    margin-bottom: 4rem;
    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
  }
}
.flex-wrap {
  .comp-comm__inform-array {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
.comp-comm__inform-block {
  color: #99a5b1;
  text-align: center;
  border: 1px solid #99a5b1;
  border-radius: 6px;
  position: relative;
  &:after {
    content: '\E649';
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -1.1rem;
    right: -3.6rem;
    font: 2.2rem/1em $font_themify;
  }
  &.is-active {
    color: $c_black;
    border-color: $c_black;
    .__top {
      border-bottom-color: $c_black;
    }
  }
  .__top, .__bottom {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 9rem;
    padding: 1rem 2rem;
  }
  .__top {
    font: 1.6rem/1.2em $font_sans_sb;
    border-bottom: 1px solid #99a5b1;
  }
  .__bottom {
    flex-direction: column;
    span {
      font: 3rem/1.2em $font_sans_sb;
    }
    i {
      font: 1.6rem/1.2em $font_default;
    }
  }
}


@media screen and (max-width: 678px) {
  .modal-header__inform {
      font-size: 1.2rem;
    > div:first-child {
      min-width: 13rem;
    }
  }
  .modal-header__inform-wrapper {
    flex-direction: column;
    .__right {
      width: 100%;
    }
    .__left {
      width: 100%;
      margin-bottom: 2rem;
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 555px) {
  .modal-competition-commission {
    .modal-dialog {
      padding-top: 6rem;
    }
  }
  .comp-comm__inform-array {
    .comp-comm__inform-block {
      width: 20rem;
      display: block;
      margin-right: 0;
      &:after {
        content: '\E64B';
        right: 50%;
        margin-right: -1.1rem;
        top: 100%;
        margin-top: 1rem;
      }
    }
  }
  .flex-wrap {
    .comp-comm__inform-array {
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }
  }
}

.sn-field__messages {
  line-height: 1;

  > div {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;

    + div {
      margin-top: 0.4rem;
    }
  }
}

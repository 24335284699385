.s-insta-block__body-vid {
  width: 100%;
  position: relative;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;

  video {
    width: 100%;
  }
}

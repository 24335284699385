@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/OpenSans_Light.woff') format('woff'),
    url('../fonts/OpenSans_Light.woff2') format('woff2'),
    url('../fonts/OpenSans_Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/OpenSans_Regular.woff') format('woff'),
    url('../fonts/OpenSans_Regular.woff2') format('woff2'),
    url('../fonts/OpenSans_Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/OpenSans_Italic.woff') format('woff'),
    url('../fonts/OpenSans_Italic.woff2') format('woff2'),
    url('../fonts/OpenSans_Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/OpenSans_Semibold.woff') format('woff'),
    url('../fonts/OpenSans_Semibold.woff2') format('woff2'),
    url('../fonts/OpenSans_Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/OpenSans_Bold.woff') format('woff'),
    url('../fonts/OpenSans_Bold.woff2') format('woff2'),
    url('../fonts/OpenSans_Bold.ttf') format('truetype');
}

@font-face {
  font-family: snation;
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/snation.woff') format('woff'),
    url('../fonts/snation.woff2') format('woff2'),
    url('../fonts/snation.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('../fonts/fa-solid-900.eot');
  src: url('../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fa-solid-900.woff2') format('woff2'),
    url('../fonts/fa-solid-900.woff') format('woff'),
    url('../fonts/fa-solid-900.ttf') format('truetype'),
    url('../fonts/fa-solid-900.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../fonts/fa-light-300.eot');
  src: url('../fonts/fa-light-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fa-light-300.woff2') format('woff2'),
  url('../fonts/fa-light-300.woff') format('woff'),
  url('../fonts/fa-light-300.ttf') format('truetype'),
  url('../fonts/fa-light-300.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../fonts/fa-regular-400.eot');
  src: url('../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fa-regular-400.woff2') format('woff2'),
  url('../fonts/fa-regular-400.woff') format('woff'),
  url('../fonts/fa-regular-400.ttf') format('truetype'),
  url('../fonts/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('../fonts/fa-brands-400.eot');
  src: url('../fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fa-brands-400.woff2') format('woff2'),
  url('../fonts/fa-brands-400.woff') format('woff'),
  url('../fonts/fa-brands-400.ttf') format('truetype'),
  url('../fonts/fa-brands-400.svg#fontawesome') format('svg');
}

[class*=form-combine-] {
  @include sflx($alitems: center);
  width: 100%;


  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;

    > * {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }
}

.form-combine- {
  &input {
    .form-input {
      flex: 1 0 auto;
      width: auto;
      margin-right: 1rem;

      + * {
        flex-shrink: 0;
      }
    }
  }

  &checkbox {
    .form-checkbox:not(:last-child) {
      + label {
        margin-right: 6rem;
      }
    }
  }

  &group {
    align-items: flex-end;

    .form-group {
      width: 13rem;
      margin-top: 0;
      margin-bottom: 2rem;

      &:not(:last-child) {
        margin-right: 4rem;
      }
    }
  }
}

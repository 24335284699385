.s-notification__close {
  @include pos(absolute, $t: 0, $r: 0);
  @include sflx($alitems: center, $juscont: center);
  align-self: flex-start;
  width: 5rem;
  height: 5rem;
  font-size: 1.5rem;
  border-top-right-radius: $gBorderRadius;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color $gDuration linear;
  }
}

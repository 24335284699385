.global__from,
.global__date,
.global__views {
  display: inline-block;
  vertical-align: middle;
  font: 1.4rem/1.2em $font_default;
  color: #7d898e;
  white-space: nowrap;
  span {
    &:before {
      font-family: $font_themify;
      margin-right: 1rem;
    }
  }
}

.global__remove {
  display: inline-block;
  vertical-align: middle;
  font: 2rem/1.2em $font_default;
  white-space: nowrap;
  position: absolute;
  margin-left: -4rem;
  span {
    &:before {
      font-family: $font_themify;
      margin-right: 1rem;
    }
  }
}

.global__from {
  span {
    &:before {
      content: '\E693';
    }
  }
}
.global__date {
  span {
    &:before {
      content: '\E6B6';
    }
  }
}
.global__views {
  span {
    &:before {
      content: '\E63D';
    }
  }
}
.global__remove {
  span {
    &:before {
      content: '\E646';
    }
  }
}
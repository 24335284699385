.s-region-list__item-new {
  @extend %s-region-list__item;
  //color: $cGrey;
  color: lighten(#333333, 5%);
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.7rem;
    border-radius: 25px;
    content: '';
    background-color: $cGrey;
    transform: translateY(0.05rem);
  }

  &.department {
    &::after {
      opacity: 1;
      transition: all 2s;
      @include pos(absolute, $t: 50%, $r: 0);
      margin-top: -0.4rem;
      font-family: $fIcons;
      font-size: 1.2rem;
      content: map-get($listOfIcons, 'angle-down');
    }
  }

  &:hover {
    cursor: pointer;
    color: darken($cGrey, 10%);

    &::before {
      background-color: darken($cGrey, 10%);
    }
  }

  &.is-active {
    //font-weight: 700;

    &::before {
      background-color: #404040;
    }

    &.department {
      &::after {
        transition: all 0.1s;
        opacity: 0;
      }
    }

    span {
      font-weight: 700;
      border-bottom: 1px dashed #333333;
    }
  }

  //&.selected {
  //  color: $cBlue;
  //  font-weight: 700;
  //
  //  &::before {
  //    background-color: $cBlue;
  //  }
  //}
}

.cabinet-body__steps-wrapper {
  @include sflx($wrap: wrap, $alitems: stretch);

  .step-block {
    width: 22%;
    margin-right: 4%;
    margin-bottom: 5rem;

    &::after {
      @include pos(absolute, $t: 50%, $l: 100%);
      font-family: $fIcons;
      font-size: 3rem;
      transform: translateY(-50%);
      margin-left: 4%;
      content: '\E628';
    }

    &:nth-child(4n+4) {
      margin-right: 0;

      &::after {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;

      &::after {
        display: none !important;
      }
    }
  }


  @media screen and (max-width: 1265px) {
    justify-content: center;

    .step-block {
      width: 48%;
      max-width: 38rem;
      margin-right: 5rem;
      margin-bottom: 5rem;

      &::after {
        margin-left: 3%;
      }

      &:nth-child(2n+2) {
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 865px) {
    .step-block {
      width: 47%;
      margin-right: 6%;
    }
  }

  @media screen and (max-width: 767px) {
    .step-block {
      margin-bottom: 3rem;

      &::after {
        display: none;
      }
    }
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    align-items: center;

    .step-block {
      width: 100%;
      max-width: 32rem;
      padding-right: 2rem;
      padding-left: 2rem;
      margin-right: 0;
      margin-bottom: 5rem;

      &::after {
        display: flex;
        top: 100%;
        left: 50%;
        margin-top: 1rem;
        margin-left: 0;
        transform: translate(-50%, 0) rotate(90deg);
      }

      &:nth-child(2n+2),
      &:nth-child(4n+4) {
        &::after {
          display: flex;
        }
      }
    }
  }
}
.sn-slide-item {
  @include pos();

  &__left,
  &__right,
  &__top,
  &__bottom {
    visibility: hidden;
    font-size: 1.4rem;
    color: $cWhite;

    .s-ico {
      font-size: 1.714em;
    }
  }
}

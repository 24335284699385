.s-breadcrumbs__item {
  @include pos();
  display: inline;
  vertical-align: middle;
  font-size: 1.4rem;
  color: $cBreadcrumbsItem;
  word-wrap: break-word;
  font-weight: 600;
  margin-right: 0;
  border-bottom: 1px solid transparent;
}

.s-table-with-filters {
  &__search {
    .sn-field {
      &__native {
        color: #98A1CD;
      }

      &__control {
        background: #F4F6FC;
        border: unset;
      }
    }
  }
}

.sn-loading {
  color: $cBlack;

  // override q-transition--fade's absolute position
  position: fixed !important;

  &::before {
    @include pos(fixed, -1, 0, 0, 0, 0);
    background: currentColor;
    opacity: .5;
    content: '';
  }

  > div {
    margin: 40px 20px 0;
    max-width: 450px;
    text-align: center;
  }
}

.q-body--loading {
  overflow: hidden;
}

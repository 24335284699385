.s-time-sheet-container{
  width: 100%;
  min-height: 1em;
  color: #737A97;
  //max-width: 1420px;
  margin: 0 auto;
  padding: 0 2rem;
}

@media screen and (max-width: 768px) {
  .container-content-container {
    &.extended {
      .pad{
        padding-top: 3.5em;
      }
    }
  }
}
@media screen and (min-width: 639px) {
  .container-content-container {
    &.extended {
      .container-content-head__tab-item {
        min-width: 30rem;
      }
    }
    }
}

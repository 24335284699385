.sn-scrollarea__thumb {
  background: $cBlack;
  opacity: 0.2;
  transition: opacity 0.3s;

  &--v {
    right: 0;
    width: 1rem;
  }

  &--h {
    bottom: 0;
    height: 1rem;
  }

  &:hover {
    opacity: 0.3;
    cursor: grab;
  }

  &:active {
    opacity: 0.5;
  }

  &--invisible {
    opacity: 0 !important;

    &:hover {
      cursor: inherit;
    }
  }
}

.s-blog__post {
  &-title {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700;
    font-size: 2rem;
  }

  &-link {
    color: currentColor;
    font-weight: 600;
    text-decoration: underline;
  }

  &--green {
    color: $cGreen;
  }
}
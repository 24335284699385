$borderPagination: #d1d8dc;
$bgItemHover: #f7f9fc;

.pagination__item {
  @include pos();
  width: 4rem;
  height: 4rem;
  font: 1.6rem/2.5em $fSansSB;
  color: $cBlack;
  text-align: center;
  border-right: 1px solid $borderPagination;
  background-color: $cWhite;
  cursor: pointer;
  transition: background-color .2s ease-in;

  &:last-child {
    border-right: 0;
  }

  &:last-child,
  &:first-child {
    font: 2.5rem/1.5em $fSansSB;
  }

  &:hover {
    background-color: darken($bgItemHover, 5%);
    transition: background-color .2s ease-in;
  }

  a {
    @include pos(absolute, 2, 0, 0, 0, 0);
    display: block;
    color: $cBlack;
    outline: none;
  }
  &.pagination__item_status_current {
    background-color: #8DBE2D;
    color: $cWhite;

    &:hover {
      background-color: #8DBE2D;
    }

    a {
      color: $cWhite;
    }

  }

  &.is-active,
  &.active {
    background-color: #8DBE2D;
    cursor: default;

    &:hover {
      background-color: #8DBE2D;
      cursor: default;
    }
  }

  &.is-disable,
  &.disabled {
    background-color: transparent;
    cursor: default;
  }

}

.pagination__item_type_prev,
.pagination__item_type_next {
  font-size: 1.6rem;
  line-height: 2.5em;
  font-family: $fThemify !important;
}

.pagination__item_type_prev {
  &::after {
    content: "\e64a";
  }
}

.pagination__item_type_next {
  &::after {
    content: "\e649";
  }
}

.pagination__item_status_hidden {
  display: none;
}

.pagination__item_status_current {
  background-color: #8DBE2D;
  color: $cWhite;

  &:hover {
    background-color: #8DBE2D;
  }

  a {
    color: $cWhite;
  }

}

.sn-radio__inner {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  padding: 1rem;
  outline: 0;
  border-radius: 50%;
  color: rgba($cBlack, 0.54);

  &--active {
    color: $primary;

    .sn-radio__inner-circle {
      transform: scale3d(0.5, 0.5, 0.5);
    }
  }
}

%s-photo-album__control {
  @include pos(absolute, $t: 0);
  @include sflx($juscont: center, $alitems: center);
  width: 3.5rem;
  height: 100%;
  font-size: 2.4rem;
  font-family: $fIcons;
  color: $cBlueDark;
  border-radius: $gBorderRadius;
  cursor: pointer;
  transition: all $gDuration ease-in;

  &:focus {
    outline: 0;
  }

  //&:hover {
  //  &:enabled {
  //    background-color: $cBlue;
  //    color: $cWhite;
  //  }
  //}
}

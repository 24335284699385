.sn-time__link {
  opacity: 0.56;
  outline: 0;
  transition: opacity 0.3s ease-out;

  &--active,
  &:hover,
  &:focus {
    opacity: 1;
  }
}

.container-list-course-wrapper {
  @include sflx($wrap: wrap, $alitems: stretch);
  margin-right: -1.1rem;
  margin-left: -1.1rem;

  > * {
    width: 22.8rem;
    margin-right: 1.1rem;
    margin-bottom: 2.2rem;
    margin-left: 1.1rem;
  }


  @media screen and (max-width: 767px) {
    > * {
      width: 20.9rem;
    }
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: center;

    > * {
      width: 28rem;
    }
  }
}

.test-cont__timer {
  font: 3.6rem/1.2em $fSansSB;
  text-align: center;
  color: $cBlue;

  i {
    font-style: normal;
    margin: 0 .5rem;
  }
}

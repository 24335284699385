.wide-site-block__body {
  font: 1.6rem/1.2em $fDef;
  padding: 1.5rem 0;

  ul {
    li {
      margin-bottom: .5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
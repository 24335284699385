.child-profile__title {
  @include pos();
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  overflow: hidden;

  &:not(:first-child) {
    margin-top: 3rem;
  }

  &::before {
    @include pos(absolute, $t: 50%, $l: 0);
    width: 100%;
    height: .1rem;
    background-color: $cBlack;
    content: '';
  }

  span {
    @include pos();
    display: inline-block;
    background-color: $cWhite;
    padding-right: .6rem;
  }
}
@media print {
  .header,
  .footer,
  .content-top-outer {
    display: none;
  }

  .main {
    width: 100%;
    height: 100%;
  }

  // Test end result
  .test-cont-outer {
    @include sflx($alitems: center, $juscont: center);
    width: 100vw;
    height: 100vh;

    .test-cont-inner {
      @include sflx($alitems: center, $juscont: center);
      width: 100vw;
      height: 100vh;

      .button {
        display: none;
      }
    }
  }
  .container-contour {
    box-shadow: none;
  }
}
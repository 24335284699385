.home-container {
  padding: 4rem 0;

  .container-body-bottom-wrapper {
    padding-bottom: 0;
  }
}

.home-container_statistic {
  @include sbg($c: #dfe2ed, $i: '../images/balabaqsha/home-statistic-bg.jpg', $s: auto 100%);

  .home-container {
    height: 66.9rem;
  }


  @media screen and (max-width: 999px) {
    .home-container {
      height: unset;
    }
  }

  @media screen and (max-width: 767px) {
    background-image: unset;
  }

  &--school {
    @extend .home-container_statistic;
    @include sbg($c: #FFFFFF, $s: auto 100%);
    background-image: none;
  }
}

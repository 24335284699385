.block {
  background-color: $c_white;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 6px;
  transition: box-shadow .2s ease-in;
  &:hover {
    box-shadow: 0 0 20px rgba(#687c8f, .48);
    transition: box-shadow .2s ease-in;
  }
}
.block__foot {
  display: block;
  padding: 0 2rem 2rem;
  text-align: right;
  .global__date {
    margin-right: 2.5rem;
  }
}
.owl-prev,
.owl-next {
  &::after {
    font-family: $fIcons !important;
  }
}

.select2-selection__arrow {
  b {
    &::after {
      font-family: $fIcons !important;
    }
  }
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  font-family: $fIcons !important;
}

.s-embed-video {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  > iframe, > video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

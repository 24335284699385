.s-test-process__status {
  @include sflx($dir: column, $alitems: center);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 2rem;

  @media screen and ( max-width: 480px ) {
    padding-bottom: 6rem;
  }
}

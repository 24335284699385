.college-about-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 4rem;
  .table__simple {
    width: 63%;
    margin-right: 25 / 1220 * 100%;
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: $c_white;
        }
        &:nth-child(even) {
          background-color: #f3f7fc;
        }
        td {
          font-size: 1.6rem;
        }
      }
    }
  }
}
.college-about__right-wrapper {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 425 / 1220 * 100%;
  min-width: 36rem;
  .button {
    width: 100%;
    font-size: 1.8rem;
    font-family: $font_sans_sb;
  }
  &__content {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-width: 28rem;
    min-height: 200px;
    margin-bottom: 1.5rem;
    background-size: auto auto;
    //background: #223b99 url(../images/college_icon_nophoto.png) no-repeat 50% calc(50% - 16px);
    border-radius: 6px;
    overflow: hidden;

    .school_rating {
      position: absolute;
      bottom: 0.7rem;
      right: 1rem;
      z-index: 2;
      width: auto;
    }
  }
}
.gallery-photo-show {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  width: 100%;
  padding-top: (1080 / 1920) * 100%;
}
.college-about__gallery-wrapper {
  //margin-bottom: 1.5rem;
  position: relative;
  .__item {
    display: block;
    height: 24rem;
    width: 100%;
    @include sbg($size: cover);
    border-radius: 6px;
    &.__nophoto {
      @include sbg($color: $c_blue, $position: 50% calc(50% - 16px), $size: auto auto);
      background-image: url(../images/college_icon_nophoto.png);
    }
  }
  .owl-nav {
    position: static;
    .owl-prev, .owl-next {
      position: absolute;
      z-index: 2;
      top: 50%;
      margin-top: -1.4rem;
      &:after {
        font: 2.8rem/1em $font_themify;
        color: $c_white;
        text-shadow: 0 0 5px rgba($c_black, .5);
      }
    }
    .owl-prev {
      left: 1.5rem;
      &:after {
        content: '\E64A';
      }
    }
    .owl-next {
      right: 1.5rem;
      &:after {
        content: '\E649';
      }
    }
  }
  .owl-dots {
    position: absolute;
    z-index: 2;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    text-align: center;
    .owl-dot {
      display: inline-block;
      vertical-align: middle;
      width: 1.1rem;
      height: 1.1rem;
      margin-right: 1.1rem;
      background-color: $c_white;
      border-radius: 50%;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background-color: $c_green;
      }
    }
  }
}
.college-about__menu-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  a {
    flex: 1;
    max-width: 28rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 1.8rem/1.2em $font_sans_sb;
    color: $c_blue;
    text-align: center;
    padding: 1rem 2rem;
    margin-right: 2rem;
    border-radius: 6px;
    border: 1px solid darken($c_blue, 1%);
    &:last-child {
      margin-right: 0;
    }
    &.is-active {
      background-color: $c_blue;
      color: $c_white;
    }
  }
}
.college-about__menu-select {
  display: none;
}
.college-about__body-wrapper {
  padding: 4.5rem 0 0;
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
  p {
    margin: 0 0 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &--no-padding {
    padding: 0;
  }
}
.college-about__text {
  padding-right: 5rem;
  padding-left: 5rem;
}
.college-about__header4 {
  margin: 0;
}

$menu-element: profile__menu-element;
.profile__menu-wrapper-new {
  color: black;
  width: 24em;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  margin-right: 2em;
  margin-bottom: 1.5em;
  margin-top: 13.1em;
  .profile__menu-element:nth-child(1) {
    border-radius: 5px 5px 0 0;
    &.#{$menu-element}__is-active {
      border-radius: 1px 5px 0 0;
    }
  }
  .profile__menu-element:last-child {
    border-radius: 0 0 5px 5px;
    &.#{$menu-element}__is-active {
      border-radius: 0 0 1px 1px;
    }
  }
}
.profile__menu-element {
  border-bottom: 1px solid #eceef5;
  padding: 0;
  background: white;
  transition: all .2s ease-in;
  &:hover {
    transition: all .2s ease-in;
    background-color: #eceef5
  }
  .profile__menu-link {
    display: block;
    padding: .8em 1.9em;
    .notifications-counter {
      margin-left: 3.5em;
      background: #ff6600;
      font-size: 1em;
      color: white;
      border-radius: 15px;
      padding: 0 .5em;
      font-style: normal;
      font-weight: bold;
    }
  }
  &__is-active {
    background: #eceef5;
    box-shadow: inset 2px 0 0 #3045a7;
    &:hover {
      background-color: darken(#eceef5, 6%);;
    }
    .profile__menu-link {
      font-weight: 600;
    }
  }
}
.need-help-link {
  margin-left: 2.3em;
  margin-top: 2em;
  color: #6dbefc;
  font-size: 1.3em;
  font-weight: bold;
}
.profile__menu-link {
  color: black;
  font-size: 1.6em;
  &:visited {
    color: black;
  }
}
.college-about__menu-select {
  display: none;
}
.college-about__body-wrapper {
  padding: 4.5rem 0 0;
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
  p {
    margin: 0 0 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.college-about__text {
  padding-right: 5rem;
  padding-left: 5rem;
}


@media screen and (max-width: 999px) {
  .college-about-wrapper {
    .table__simple {
      display: block;
      width: 100%;
      tbody {
        display: block;
        width: 100%;
        tr {
          display: block;
          width: 100%;
          td {
            display: block;
            width: 100% !important;
            border-right: 0 !important;
            font-size: 1.5rem;
            text-align: left !important;
            padding: 1rem 2rem !important;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
              font-family: $font_default;
            }
            &:last-child {
              font-family: $font_sans_sb;
              padding-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .college-about-wrapper {
    flex-direction: column;
    .table__simple {
      order: 2;
    }
  }
  .college-about__right-wrapper {
    width: 100%;
    min-width: unset;
    order: 1;
    margin-bottom: 2rem;
  }
  .college-about__menu-wrapper {
    display: none;
    a {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  .college-about__menu-select {
    display: block;
    width: 100%;
  }

}
@media screen and (max-width: 1320px) {
  .content-top-inner-new {
    padding-left: 0 !important;
  }
}
@media screen and (max-width: 999px) {
  .content-top-inner-new {
    flex-direction: row !important;
  }
}
@media screen and (max-width: 620px) {
  .content-top-outer-new {
    background: none
  }
}

.s-main-header__burger {
  @include sflx($d: none, $juscont: center, $alitems: center);
  width: 4rem;
  height: 4rem;
  margin-left: auto;

  &::after {
    content: map-get($listOfIcons, 'menu');
    font: 4rem $fIcons;
    color: $cWhite;
  }
}

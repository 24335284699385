.s-test-process__navigation-result {
  @include pos();
  @include sflx($juscont: center, $alitems: center);
  width: 18rem;
  color: $cWhite;
  text-align: center;
  padding: 1rem 2rem;
  background-color: $cBlue;
  border-radius: $gBorderRadius;
  cursor: pointer;
  transition: background-color $gDuration ease-in;
  user-select: none;

  &:hover {
    background-color: darken($cBlue, 5%);
    transition: background-color $gDuration ease-in;
  }
}

.sn--non-selectable {
  user-select: none !important;
}

.sn--scroll {
  overflow: auto;
}

.sn--scroll, .sn--scroll-x, .sn--scroll-y {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.sn--scroll-x {
  overflow-x: auto;
}

.sn--scroll-y {
  overflow-y: auto;
}

.sn--no-scroll {
  overflow: hidden !important;
}


.sn--no-pointer-events {
  pointer-events: none !important;
}
.sn--all-pointer-events {
  pointer-events: all !important;
}

.sn--cursor {
  &-pointer {
    cursor: pointer !important;
  }
  &-not-allowed {
    cursor: not-allowed !important;
  }
  &-inherit {
    cursor: inherit !important;
  }
  &-none {
    cursor: none !important;
  }
  &-wait {
    cursor: wait !important;
  }
}

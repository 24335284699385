.test-page-outer {
  position: relative;
  z-index: 1;
  .test-page-inner {
    padding-bottom: 4.5rem;
    .section-title {
      text-align: left;
    }
    .section-print {
      display: block;
      margin-top: -3rem;
      padding: 0 10px;
      text-align: right;
      font-size: 2rem;
      color: #000;
      float: right;
      &:after {
        content: '\E681';
        margin-left: 1rem;
        font-size: 2rem;
        font-family: $font_themify;
      }
      &:focus,
      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
  }
}

.test-blur-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  + .test-page-outer {
    z-index: 100;
  }
}

.test-container {
  width: 100%;
  background-color: $c_white;
  box-shadow: 0 0 10px rgba(#687c8f, .28);
  border-radius: 6px;
  position: relative;
}

.test-container__wrapper-hidden {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}

.test-container__study-subject {
  width: 154 / 1200 * 100%;
  min-width: 15.4rem;
  padding: 25px;
  text-align: center;
  .study-block {
    cursor: pointer;
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .study-block__image {
    margin-bottom: 0;
  }
  .study-block__image-wrapper {
    padding: 15px 20px;
    img {
      max-height: 55px;
    }
    span {
      font: 1rem/1em $font_default;
      margin-top: 10px;
    }
  }
}

.test-container__process {
  flex: 1 1 auto;
  padding-top: 35px;
}
.test-container__process-head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 50px 25px 30px;
  .button-wrapper {
    @include clear();
    .button, .button-radius, .button-func {
      display: block;
      float: left;
      font-size: 1.6rem;
      line-height: 1em;
      margin-left: 12px;
      padding-top: 16px;
      padding-bottom: 17px;
      &:first-child {
        margin-left: 0;
      }
    }
    .button_theme_blue {
      background-color: #0f90e5;
      &:hover {
        background-color: darken(#0f90e5, 5%);
      }
    }
  }
}
.process-head__timer {
  font: 1.6rem $font_default;
  color: $c_black;
  &:before {
    content: '';
    font: 1.6rem/1em $font_themify;
    color: #a0b5c2;
    margin-right: 5px;
  }
}

.test-container__process-body {
  width: 100%;
  padding: 0 50px 40px 30px;
}

table.test-body {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  thead {
    tr {
      &:first-child {
        td {
          border-top: 0;
          padding-top: 0;
          &:last-child {
            padding-top: 0;
          }
        }
      }
      &:last-child {
        td {
          padding-top: 15px;
        }
      }
      td:last-child {
        padding: 10px 90px 10px 22px;
      }
    }
  }
  tbody {
    tr {
        &:hover, &:nth-child(odd) {
            background-color: $c_white;
        }
      td:last-child {
        padding: 20px 0 20px 22px;
      }
    }
  }
  tfoot {
    tr {
      td:last-child {
        padding: 11px 0 0 22px;
      }
    }
  }
  tr {
    td {
      border-top: 1px solid #d1d8dc;
      text-align: left;
      &:first-child {
        width: 90px;
        border-right: 1px solid #d1d8dc;
        font: 1.4rem $font_default;
        color: $c_grey;
        text-align: right;
        padding: 15px 15px 15px 2px;
        vertical-align: top;
        -ms-transform: rotate(0deg) translateX(0);
        -webkit-transform: rotate(0deg) translateX(0);
        -o-transform: rotate(0deg) translateX(0);
        -moz-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
        transition: all .3s ease-in;
      }
    }
  }
}

.test-body__theme {
  min-height:60px;
  font: 1.6rem/1.2em $font_default;
  color: $c_black;
}
.test-body__question {
  min-height: 100px;
  font: 2.2rem/1.2em $font_default;
  color: $c_black;
  text-transform: uppercase;
}

//fix paragraphs
.test-body__theme, .test-body__question, .test-body__solution {
  word-break: break-word;
  //Фикс от скачков формул во время отрисовки
  .math-tex {
    opacity: 0;
    transition: opacity .2s ease-in;
  }
  a {
    color: $c_blue;
    &:hover {
      border-bottom: 1px solid darken($c_blue, 1%);
    }
  }
  p {
    margin: 0 !important;
    &:last-child {
      margin: 0 !important;
    }
  }
}
.test-body__solution {
  word-break: break-all;
  word-break: break-word;
}

.test-container__wrapper {
  @include cl_w(1200 - 154 - 817, 1200);
  border-left: 1px solid #e2ecf2;
}

.list-of-answers {
  width: 100%;
  display: block;
  li {
    display: block;
    width: 100%;
    border-top: 1px solid #d4dbde;
    font: 1.8rem/1.33em $font_default;
    color: $c_black;
    padding: 15px 15px 15px 80px;
    position: relative;
    cursor: pointer;
    background-color: $c_white;
    transition: background-color .2s ease-in;
    &:before {
      content: attr(data-var-answer);
      position: absolute;
      z-index: 1;
      top: -1px;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      background-color: #f3f6f8;
      font: bold 1.8rem/.9em $font_default;
      color: $c_black;
      text-transform: uppercase;
      border: 1px solid #d4dbde;
      border-bottom: 1px solid $c_white;
      box-sizing: border-box;
    }
    &:last-child {
      border-bottom: 1px solid #f3f6f8;
      &:before {
        border-bottom: 1px solid #f3f6f8;
      }
      &.is-done {
        &:before {
          border-bottom: 1px solid $c_green;
        }
      }
      &.is-undone {
        &:before {
          border-bottom: 1px solid $c_red;
        }
      }
    }
    &.is-active {
      z-index:2;
      box-shadow: 0 0 21px 7px rgba(#18242b, .07);
      cursor: default;
      &:hover {
        background-color: $c_white;
      }
      &:before {
        color: $c_white;
        background-color: $c_green;
      }
    }
    &:hover {
      background-color: lighten(#e2ecf2, 5%);
      transition: background-color .2s ease-in;
    }
    &.is-done {
      &:before {
        color: $c_white;
        background-color: $c_green;
      }
    }
    &.is-undone {
      &:before {
        color: $c_white;
        background-color: $c_red;
      }
    }
    p {
      margin: 0 !important;
    }
  }
}
.test-body__solution {
  min-height: 80px;
  font: 1.6rem $font_default;
  color: $c_black;
  strong {
    font-weight: 600;
  }
  p {
    margin: 0;
  }
}

.test-container__process-footer {
  padding: 0 50px 40px 120px;
}
.process-footer__navigation-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.process-footer__navigation-num {
  font: 4.8rem $font_default;
  color: $c_grey;
}

.button-nav {
  width: 137px;
  display: table;
  font: 1.6rem $font_default;
  color: $c_white;
  text-align: center;
  position: relative;
  padding: 16px 17px;
  background-color: $c_aqua;
  transition: background-color .2s ease-in;
  border-radius: 5px;
  cursor: pointer;
  &:before {
    position: absolute;
    z-index: 3;
    top: 50%;
    margin-top: -1.9rem;
    font: 3.8rem/1em $font_themify;
    color: $c_aqua;
  }
  &:hover {
    background-color: darken($c_aqua, 5%);
    transition: background-color .2s ease-in;
  }
  &.__disabled {
    background-color: $c_grey;
    color: $c_white;
    cursor: default;
  //   visibility: hidden;
    &:before {
        color: #d1d8dc;
    }
  }
  &.__end {
    &:before {
      display: none;
    }
  }
}
.button-nav_theme_prev {
    margin-left: 7.5rem;
  &:before {
    content: '\E64A';
    left: -7.5rem;
  }
}
.button-nav_theme_next {
    margin-right: 7.5rem;
  &:before {
    content: '\E649';
    right: -7.5rem;
  }
}

.test-container__bottom {
  padding: 2rem;
  border-top: 1px solid #d1d8dc;
}
.test-container__copyright {
  font: 1.4rem/1.2em $font_default;
  color: $c_grey;
  text-align: right;
}

//result
.test-container__result-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8rem 3rem;
}
.test-container__congratulation-title {
    font: 2.8rem/1.2em $font_sans_sb;
    color: $c_black;
    text-align: center;
    margin-bottom: 3.5rem;
}
.test-container__result-description {
    font: 1.8rem/1.2em $font_sans_l;
    color: $c_black;
    text-align: center;
    margin-bottom: 3.5rem;
}
.test-end-block-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}
.test-end-result__column {
    width: 100%;
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $c_black;
    text-align: center;
    span {
        display: block;
        width: 100%;
        font: 1.6rem/1.2em $font_default;
    }
    div {
        font: 2.2rem/1.2em $font_sans_sb;
    }
}
.test-end-result__diagram {
  width: 100%;
  max-width: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    span {
        display: inline-block;
        margin-bottom: 3rem;
        font: 1.6rem/1.2em $font_default;
        color: $c_black;
        text-align: center;
    }
}
.test-end-bottom {
    width: 100%;
    padding-top: 6rem;
    text-align: center;
}
.test-end-button {
    display: inline-block;
    font: 1.6rem/1.2em $font_default;
    color: $c_white;
    border-radius: 6px;
    padding: 2.5rem;
    background-color: $c_aqua;
    transition: background-color .2s ease-in;
    cursor: pointer;
    &:hover {
      background-color: darken($c_aqua, 5%);
      transition: background-color .2s ease-in;
    }
}

@media screen and (max-width: 1240px) {
  .test-container__study-subject {
    min-width: 146px;
  }
  .test-container__process {
    width: 100%;
  }
}
@media screen and (max-width: 1240px) {
  .test-container__process {
    width: 100%;
  }
}
@media screen and (max-width: 999px) {
  .test-container {
    &.__end {
      .test-container__process {
        > .button-radius {
          top: 0;
          right: 0;
        }
      }
      table.test-body {
        thead {
          tr {
            td:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .test-container__process-head, .test-container__process-body, .test-container__process-footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .test-container__process-footer {
    padding-left: 50px;
  }
  table.test-body {
    display: block;
    thead, tbody, tfoot {
      display: block;
      width: 100%;
    }
    thead {
      tr {
        td:last-child {
          padding-top: 15px !important;
          padding-right: 0;
          padding-bottom: 15px;
        }
      }
    }
    tr {
      display: block;
      width: 100%;
      position: relative;
      td {
        display: block;
        &:first-child {
          display: inline-block;
          width: auto;
          position: absolute;
          z-index: 1;
          top: 0;
          left: -10px;
          padding: 5px 20px !important;
          border: 0;
          transform: rotate(-90deg) translateX(-100%);
          transform-origin: left top;
          text-align: left;
          transition: all .3s ease-in;
        }
        &:last-child {
          width: 100% !important;
          padding-left: 30px !important;
        }
      }
    }
  }
  .test-body__question {
    min-height: 60px;
    font-size: 2rem;
  }
  .process-footer__navigation-num {
    font-size: 4rem;
  }
  .button-nav {
    span {
      display: none;
    }
  }
  .button-nav_theme_prev, .button-nav_theme_next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 10px 20px;
    &:before {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      margin-top: 0;
      color: $c_white;
    }
  }
}
@media screen and (max-width: 800px) {
  .test-body__question {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 768px) {
  .test-container__process {
    padding-top: 0;
    width: 52%;
  }
}
@media screen and (max-width: 767px) {
  .test-page-outer, .test-page-inner, .test-container, .test-container__wrapper-hidden {
    //height: 100%;
    overflow: visible;
  }
  .test-page-outer {
    .test-page-inner {
      padding: 0;
      .section-title {
        padding-left: 2rem;
      }
    }
  }
  .test-container {
    border: 0;
    box-shadow: none;
    &.__end {
      table.test-body {
        thead {
          tr {
            td:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  .test-container__process {
    width: 100%;
    padding-top: 30px;
    padding-left: 40px;
    .test-container__process-head, .test-container__process-body, .test-container__process-footer {
      padding-right: 0;
      padding-left: 0;
    }
    .button-radius.button_theme_blue {
      display: none;
    }
  }
  .test-container__process-footer {
    margin-left: -40px;
    .button-nav_theme_prev, .button-nav_theme_next {
      padding: 10px 30px;
    }
    .button-nav_theme_prev {
      margin-left: 4rem;
    }
    .button-nav_theme_next {
      margin-right: 4rem;
    }
  }

  //table
  table.test-body {
    thead {
      tr {
        padding-right: 20px;
        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-top: 0;
          padding-bottom: 0 !important;
        }
      }
    }
    tfoot {
      tr {
        td:last-child {
          padding-top: 20px;
        }
      }
    }
    tr {
      td {
        &:first-child {
          left: -35px;
        }
        &:last-child {
          padding-left: 0 !important;
        }
      }
    }
  }
  .test-body__question {
    max-width: 500px;
  }
}
@media screen and (max-width: 547px) {
  .test-container__process-footer {
    .button-nav_theme_prev, .button-nav_theme_next {
      padding: 10px;
    }
    .button-nav_theme_prev {
      margin-left: 0;
    }
    .button-nav_theme_next {
      margin-right: 0;
    }
  }
}
@media screen and (max-width: 479px) {
  .test-body__question {
    font-size: 1.7rem;
  }
  .test-end-block-wrapper {
    flex-direction: column;
  }
  .test-end-result__column {
    max-width: 100%;
    order: 2;
  }
  .test-end-result__diagram {
    max-width: 100%;
    order: 1;
    margin-bottom: 3rem;
  }
  .test-end-result__block {
    margin-bottom: 2rem;
  }
}

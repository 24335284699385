.vs__dropdown-toggle {
  padding: 0.3rem 0.6rem 0.7rem !important;
  border-color: #DFE2ED !important;
}

.vs__open-indicator {
  //fill: ;
}

.vs__clear {
  //fill: ;
}

.s-form__error {
  .vs__dropdown-toggle {
    border-color: $cError !important;
  }
}

.sn-timeline__entry {
  position: relative;
  line-height: 22px;

  &:last-child {
    padding-bottom: 0 !important;

    .sn-timeline__dot {
      &::after {
        content: none;
      }
    }
  }

  &--icon {
    .sn-timeline__dot {
      width: 31px;

      &::before {
        height: 31px;
        width: 31px;
      }

      &::after {
        top: 41px;
        left: 14px;
      }
    }

    .sn-timeline__subtitle {
      padding-top: 8px;
    }
  }
}

$transition-easing: cubic-bezier(0.215, 0.61, 0.355, 1); // easeOutCubic

.sn-transition {
  &--slide-right,
  &--slide-left,
  &--slide-up,
  &--slide-down,
  &--jump-right,
  &--jump-left,
  &--jump-up,
  &--jump-down,
  &--fade,
  &--scale,
  &--rotate,
  &--flip {
    &-leave-active {
      position: absolute;
    }
  }
  &--slide-right,
  &--slide-left,
  &--slide-up,
  &--slide-down {
    &-enter-active, &-leave-active {
      transition: transform .3s $transition-easing;
    }
  }
  &--slide-right {
    &-enter {
      transform: translate3d(-100%, 0, 0);
    }
    &-leave-to {
      transform: translate3d(100%, 0, 0);
    }
  }

  &--slide-left {
    &-enter {
      transform: translate3d(100%, 0, 0);
    }
    &-leave-to {
      transform: translate3d(-100%, 0, 0);
    }
  }

  &--slide-up {
    &-enter {
      transform: translate3d(0, 100%, 0);
    }
    &-leave-to {
      transform: translate3d(0, -100%, 0);
    }
  }

  &--slide-down {
    &-enter {
      transform: translate3d(0, -100%, 0);
    }
    &-leave-to {
      transform: translate3d(0, 100%, 0);
    }
  }

  &--jump-right,
  &--jump-left,
  &--jump-up,
  &--jump-down {
    &-enter-active, &-leave-active {
      transition: opacity .3s, transform .3s;
    }
    &-enter, &-leave-to {
      opacity: 0;
    }
  }

  &--jump-right {
    &-enter {
      transform: translate3d(-15px, 0, 0);
    }
    &-leave-to {
      transform: translate3d(15px, 0, 0);
    }
  }


  &--jump-left {
    &-enter {
      transform: translate3d(15px, 0, 0);
    }
    &-leave-to {
      transform: translateX(-15px);
    }
  }

  &--jump-up {
    &-enter {
      transform: translate3d(0, 15px, 0);
    }
    &-leave-to {
      transform: translate3d(0, -15px, 0);
    }
  }

  &--jump-down {
    &-enter {
      transform: translate3d(0, -15px, 0);
    }
    &-leave-to {
      transform: translate3d(0, 15px, 0);
    }
  }

  &--fade {
    &-enter-active, &-leave-active {
      transition: opacity .3s ease-out;
    }
    &-enter, &-leave, &-leave-to {
      opacity: 0;
    }
  }

  &--scale {
    &-enter-active, &-leave-active {
      transition: opacity .3s, transform .3s $transition-easing;
    }
    &-enter, &-leave, &-leave-to {
      opacity: 0;
      transform: scale3d(0, 0, 1);
    }
  }

  &--rotate {
    &-enter-active, &-leave-active {
      transition: opacity .3s, transform .3s $transition-easing;
      transform-style: preserve-3d;
    }
    &-enter, &-leave, &-leave-to {
      opacity: 0;
      transform: scale3d(0, 0, 1) rotate3d(0, 0, 1, 90deg);
    }
  }

  &--flip-right,
  &--flip-left,
  &--flip-up,
  &--flip-down {
    &-enter-active, &-leave-active {
      transition: transform .3s;
      backface-visibility: hidden;
    }

    &-enter-to, &-leave {
      transform: perspective(400px) rotate3d(1, 1, 0, 0deg);
    }
  }

  &--flip-right {
    &-enter {
      transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
    }
    &-leave-to {
      transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
    }
  }

  &--flip-left {
    &-enter {
      transform: perspective(400px) rotate3d(0, 1, 0, 180deg);
    }
    &-leave-to {
      transform: perspective(400px) rotate3d(0, 1, 0, -180deg);
    }
  }

  &--flip-up {
    &-enter {
      transform: perspective(400px) rotate3d(1, 0, 0, -180deg);
    }
    &-leave-to {
      transform: perspective(400px) rotate3d(1, 0, 0, 180deg);
    }
  }

  &--flip-down {
    &-enter {
      transform: perspective(400px) rotate3d(1, 0, 0, 180deg);
    }
    &-leave-to {
      transform: perspective(400px) rotate3d(1, 0, 0, -180deg);
    }
  }
}

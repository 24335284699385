.sn-slider {
  position: relative;
  width: 100%;
  color: $cBlue;
  outline: 0;

  &--editable {
    cursor: grab;
  }

  &--focus {
    .sn-slider__thumb {
      transform: scale3d(.571, .571, .571);
    }
  }

  &--focus, body.desktop &.sn-slider--editable:hover {
    .sn-slider__focus-ring {
      background: currentColor;
      transform: scale3d(1.55, 1.55, 1.55);
      opacity: .25;
    }
  }

  &--inactive {
    .sn-slider__thumb-container {
      transition: left .28s;
    }

    .sn-slider__track {
      transition: width .28s, left .28s;
    }
  }

  &--active {
    cursor: grabbing;

    .sn-slider__thumb {
      transform: scale3d(1, 1, 1);
    }

    .sn-slider__focus-ring, .sn-slider--label{
      transform: scale3d(0, 0, 0) !important;
    }
  }

  body.desktop &.sn-slider--editable:hover .sn-slider__pin {
    transform: rotate(-45deg) scale3d(1, 1, 1) translate3d(19px, -20px, 0);
  }

  &--label {
    &.sn-slider--active, .sn-slider--focus, &.sn-slider--label-always {
      .sn-slider__pin {
        transform: rotate(-45deg) scale3d(1, 1, 1) translate3d(19px, -20px, 0);
      }
    }
  }

  &--dark {
    .sn-slider__track-container {
      background: rgba(255,255,255,0.3);
    }

    .sn-slider__track-markers {
      color: white;
    }
  }

  &--dense {
    height: 20px;
  }

  &--disabled {
    .sn-slider__focus-ring,
    .sn-slider__pin-value-marker-bg {
      background-color: $cPlaceholder
    }
    color: $cPlaceholder
  }
}

.s-balabaqsha-catalog__blockquote-text {
  font-size: 1.4rem;

  ul {
    margin: 1em 0;

    li {
      margin-bottom: 0.5rem;

      &::before {
        content: '•';
        margin-right: 0.5rem;
      }
    }
  }
}

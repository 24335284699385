.mx-datepicker {
  width: 100% !important;
  font: 1.6rem $fDef !important;
}

.mx-input {
  height: 4rem !important;
  font-size: 1.6rem !important;
  border-radius: $gBorderRadius !important;
  padding: 0 1.5rem !important;
  border-color: $cBorder !important;

  &:disabled {
    opacity: unset !important;
    color: $cDarkPlaceholder !important;
  }
}

.mx-input-append {
  right: 0.5rem !important;
}

.s-form__error {
  .mx-input {
    border-color: $cError !important;
  }
}

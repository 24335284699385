.s-test-process__center-username {
  flex: 1 1 auto;
  width: 100%;
  max-width: 30rem;
  font-weight: 600;
  padding-right: 2rem;
  padding-left: 2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and ( max-width: 1008px ) {
    display: none;
  }
}

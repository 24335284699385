.link {
  font-weight: 600;
  font-size: 1.5rem;
  border-bottom: 1px solid transparent;
  transition: border-color .2s ease-in;

  &:hover {
    transition: border-color .2s ease-in;
  }
}

$listOflinks: (
  ('aqua', #3ca8fb),
);

@each $name, $color in $listOflinks {
  .link_thm_#{$name} {
    color: $color;

    &:hover {
      border-color: $color;
    }
  }
}

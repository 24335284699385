.sn--rounded-borders {
  border-radius: $gBorderRadius;
}

.sn--no-transition {
  transition: none !important;
}

.sn--transition-0 {
  transition: 0s !important;
}

.sn-placeholder {
  &::-webkit-input-placeholder {
    color: inherit;
    opacity: .7;
  }

  &::-moz-placeholder {
    color: inherit;
    opacity: .7;
  }

  &:-ms-input-placeholder {
    color: inherit !important;
    opacity: .7 !important;
  }

  &::-ms-input-placeholder {
    color: inherit;
    opacity: .7;
  }

  &::placeholder {
    color: inherit;
    opacity: .7;
  }
}

/* body */
.sn-body--fullscreen-mixin, .sn-body--prevent-scroll {
  overflow: hidden !important;
}

.sn-no-input-spinner {
  -moz-appearance: textfield !important;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.sn-link {
  outline: 0;
  text-decoration: none;
}


.s-steps {
  @include sflx($juscont: space-between);
  flex-direction: row-reverse;
  overflow: hidden;
  padding: 3rem 0;

  &--wider {
    width: 100%;
  }
}

.s-balabaqsha-catalog__search {
  padding: 0;

  .sn-field__append {
    .sn-btn--squire {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

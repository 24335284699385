.s-timer {
  &.is-level1 {
    .s-str-ico {
      &::before {
        color: $cGreen;
        transition: color $gDuration ease;
      }
    }
  }

  &.is-level2 {
    .s-str-ico {
      &::before {
        color: $cOrange;
        transition: color $gDuration ease;
      }
    }
  }

  &.is-level3 {
    .s-str-ico {
      &::before {
        color: $cRed;
        animation: a-shake-margin $gDuration ease;
        transition: color $gDuration ease;
      }
    }
  }

  .s-str-ico {
    &::before {
      color: #A0B5C2;
      transition: color $gDuration ease;
    }
  }

  span {
    &:not( :last-child ) {
      &::after {
        margin-left: 0.5rem;
        content: ':';
      }
    }
  }
}

.interest-block__desc-title {
  @include pos();
  @include sflx($dir: column, $alitems: center, $juscont: center);
  width: 100%;
  height: 100%;
  font-size: 1.9rem;
  line-height: 1.3em;
  font-weight: 600;
  color: $cWhite;
  text-align: center;
  transition: transform $animateSpeed ease-in;
  will-change: contents;
}

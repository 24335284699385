.faq-cont__section-block {
  @include pos();
  display: block;
  width: 100%;
  min-height: 9rem;
  background-color: $cWhite;
  padding: 2rem 2rem 2rem 6rem;
  border-radius: $gBorderRadius;
  margin-bottom: 2rem;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);
  cursor: pointer;
  transition: box-shadow .2s ease-in;

  &::before {
    @include pos(absolute, $t: 2rem, $l: 2rem);
    font-family: $fIcons;
    font-size: 2.2rem;
    color: #3045a7;
  }

  &:hover {
    box-shadow: 0 0 29px rgba(74, 102, 126, 0.28);
    transition: box-shadow .2s ease-in;
  }

  &.is-active {
    background-color: #3a53b6;
    color: $cWhite;
    cursor: default;

    &::before {
      color: $cWhite;
    }
  }
}

.s-info-card--title {
  border-bottom: 1px solid #CCD1E9;
  padding-bottom: 1.5rem;

  > span {
    font-size: 20px;
    line-height: 27px;
  }

  > span::before {
    color: #3145A8;
    font-weight: 700;
  }

  &-light-icon {
    > span::before {
      font-weight: 400;
    }
  }

  &-bold-icon {
    > span::before {
      font-weight: 600;
    }
  }

  &-lighter-icon {
    > span::before {
      font-weight: 300;
    }
  }
}

%s-test-process__navigation-buttons {
  @include pos();
  width: 14rem;
  color: $cWhite;
  text-align: center;
  padding: 1rem;
  background-color: $cAqua;
  border-radius: $gBorderRadius;
  cursor: pointer;
  transition: background-color $gDuration ease-in;
  user-select: none;

  &::before {
    @include pos(absolute, $t: 50%);
    font-weight: 700;
    font-size: 2rem;
    font-family: $fIcons;
    color: $stpGreyDark;
    transform: translateY(-50%);
  }

  &:hover {
    background-color: darken($cAqua, 5%);
    transition: background-color $gDuration ease-in;
  }

  &.is-disabled {
    color: $cBlack;
    background-color: $stpGrey;
    cursor: not-allowed;

    &::before {
      color: $stpGrey;
    }
  }

  @media screen and ( max-width: 1264px ) {
    &::before {
      display: none;
    }
  }

  @media screen and ( max-width: 599px ) {
    @include sflx($alitems: center, $juscont: center);
    width: 6rem;
    height: 6rem;
    overflow: hidden;

    &::before {
      position: static;
      display: block;
      font-size: 3rem;
      color: $cWhite;
      transform: translateY(0);
    }

    span {
      display: none;
    }
  }
}

.s-test-process__navigation {
  @include sflx($alitems: stretch, $juscont: space-between);
  width: 100%;

  @media screen and ( max-width: 479px ) {
    flex-wrap: wrap;

    .s-test-process__navigation-backward {
      order: 1;
    }

    .s-test-process__navigation-forward {
      order: 2;
    }

    .s-test-process__navigation-backward,
    .s-test-process__navigation-forward {
      margin-bottom: 2rem;
    }

    .s-test-process__navigation-result,
    .s-test-process__navigation-end {
      order: 3;
      width: 100%;
      min-height: 6rem;
    }
  }
}

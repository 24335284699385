@media screen and ( max-width: $gMinWidthDesktop - 1px ) {
  .s-fields {
    flex-direction: column;

    .s-field {
      width: 100%;
    }
  }
}


@media screen and ( max-width: $gMinWidthTablet - 1px ) {
  .s-queue-item-content {
    flex-wrap: wrap;

    .s-queue-item-person {
      order: 1;
      margin-bottom: 3rem;
    }

    .s-queue-item-data {
      order: 3;
      width: 100%;
      padding: 0;
    }

    .s-queue-item-statement {
      order: 2;
      margin-bottom: 3rem;
    }
  }
}


@media screen and ( max-width: 479px ) {
  .s-queue-item-content {
    flex-direction: column;
  }
}

.picture-panel__mini-block {
  @include sbg($s: cover);
  flex-shrink: 0;
  display: block;
  width: 7.7rem;
  height: 7.7rem;
  background-color: #f7f9fc;
  border-radius: $gBorderRadius;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &.is-active {
    @include pos();
    cursor: default;

    &::after {
      @include pos(absolute, 1, 0, 0, 0, 0);
      border: 3px solid $cGreen;
      border-radius: $gBorderRadius;
      content: '';
    }
  }
}
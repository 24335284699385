.s-notify-count {
  @include pos();

  &::after {
    @include pos(absolute);
    display: inline-block;
    font-weight: 700;
    font-size: 1.2rem;
    color: $cWhite;
    background-color: $cOrangeDark;
    padding: 0.2rem 0.5rem;
    border-radius: $gBorderRadius;
    content: attr(data-notify-count);
  }
}

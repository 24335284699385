@media screen and ( max-width: 1000px ) {
  [ class*='mr-' ] {
    margin-right: 0;
  }

  [ class*='ml-' ] {
    margin-left: 0;
  }

  [ class*='pr-' ] {
    padding-right: 0;
  }

  [ class*='pl-' ] {
    padding-left: 0;
  }
}

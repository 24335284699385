.home-news-block {
  @include pos();
  @include sflx($dir: column);
  @include sbg($s: cover);
  color: $cWhite;
  padding: 2.5rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 5px rgba(104, 124, 143, 0.17);
  overflow: hidden;

  &::before {
    @include pos(absolute, 1, 0, 0, 0, 0);
    background-color: $cBlack;
    opacity: .4;
    content: '';
  }
}
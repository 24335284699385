.faq-answear__body {
  //display: none;
  padding: 3rem;

  p {
    margin: 0 0 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }


  @media screen and (max-width: 480px) {
    padding: 2rem;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-in;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.page__additional-information {
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .additional-information__inner {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 900px;
    padding: 50px 20px;

    .additional-information__title {
      margin-bottom: 50px;
      text-align: center;
      font: bold 2rem/1.4em $font_default;
    }
    .additional-information__wrapper {
      .additional-information__text {
        margin-bottom: 25px;
        font: 1.6rem/1.4em $font_default;
      }
      .additional-information__doc {
        display: inline-flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin: 25px 0;
      }
    }
  }
  .doc__block {
    max-width: 30%;
    padding: 25px 1%;
    text-align: center;
    .doc__image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .doc__title {
      margin-top: 10px;
      text-align: center;
      font: bold 1.4rem/1.4em $font_default;
    }
    .doc__text {
      margin-top: 20px;
      text-align: center;
      font: 1.2rem/1.2em $font_default;
    }
    &.doc--resume {
      .doc__title {
        font: bold 1.6rem/1.4em $font_default;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .page__additional-information {
    .additional-information__inner {
      max-width: unset;
      padding: 30px 0;
      .additional-information__wrapper {
        .additional-information__doc {
          justify-content: space-around;
        }
      }
    }
    .doc__block {
      max-width: 49%;
    }
  }
}
@media screen and (max-width: 600px) {
  .page__additional-information {
    .additional-information__inner {
      .additional-information__wrapper {
        .additional-information__doc {
          justify-content: center;
        }
      }
    }
    .doc__block {
      max-width: 99%;
    }
  }
}
.s-test-process__right {
  flex-shrink: 0;
  width: 20rem;
  border-left: 1px solid $stpGrey;

  @media screen and ( max-width: 1008px ) {
    @include pos(absolute, $t: 0, $r: 0);
    width: 0;
    border-left: unset;
  }
}

.kindergarten-head-inform__pictures {
  flex-shrink: 0;
  order: 1;
  width: 29.5rem;


  @media screen and (max-width: 359px) {
    .picture-panel {
      margin-left: -.75rem;
    }
  }
}
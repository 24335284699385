$stpGrey: #E2ECF2;
$stpGreyDark: #A0B5C2;
$stpDark: #333333;
$stpBorderWrapper: #ACB1C1;

@import 's-list-of-answers';
@import 's-questions-map';
@import 's-test-process__result';
@import 's-test-process';
@import 's-test-process__loading';
@import 's-test-process__loading-process';
@import 's-test-process__loading-label';
@import 's-test-process__wrapper';
@import 's-test-process__left';
@import 's-test-process__center';
@import 's-test-process__right';
@import 's-test-process__button';
@import 's-test-process__status';
@import 's-test-process__status-label';
@import 's-test-process__status-value';
@import 's-test-process__subjects-list';
@import 's-test-process__subject';
@import 's-test-process__navigation';
@import 's-test-process__navigation-backward';
@import 's-test-process__navigation-count-steps';
@import 's-test-process__navigation-forward';
@import 's-test-process__navigation-end';
@import 's-test-process__navigation-result';
@import 's-test-process__center-header';
@import 's-test-process__center-username';
@import 's-test-process__center-body';
@import 's-test-process__center-footer';
@import 's-test-process__center-row';
@import 's-test-process__center-label';
@import 's-test-process__center-value';
@import 's-test-process__questions-map';
@import 's-test-process__overlay';

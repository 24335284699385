.s-test-process__result-column {
  align-self: center;
  padding: 3rem;

  &:nth-child( odd ) {
    width: 50%;
    border-right: 1px solid $stpBorderWrapper;
  }

  &:nth-child( even ) {
    width: 35%;
  }

  @media screen and ( max-width: 599px ) {
    &:nth-child( odd ),
    &:nth-child( even ) {
      width: 100%;
    }

    &:nth-child( odd ) {
      border-right: unset;
    }

    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 1;
      text-align: center;
    }
  }

  @media screen and ( max-width: 479px ) {
    padding: 2rem;

    &:first-child {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.s-table-with-filters {
  &__row-btn {
    display: flex;
    align-items: center;
    position: absolute;
    left: -15px;
    top: 0;
    z-index: 2;

    .s-ico {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      min-height: 3rem;
      background-color: #3045A7;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        font-size: 14px;
        color: white;
      }
    }
  }
}

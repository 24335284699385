.sn-slider__pin-value-marker {
  transform: rotate(45deg);

  &-text {
    position: relative;
    color: white;
    font-size: 12px;
    white-space: nowrap;
  }

  &-bg {
    position: absolute;
    min-width: 30px;
    width: 150%;
    height: 30px;
    left: 50%;
    top: 30%;
    transform: translate3d(-50%, -50%, 0);
    background-color: currentColor;
    border-radius: $gBorderRadius;
  }
}

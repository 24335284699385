@media screen and ( max-width: map-get($grid-breakpoints, 'sm') ) {
  .s-no-results {
    flex-direction: column;
  }

  .s-no-results__image {
    width: 100%;
    margin-bottom: 2rem;
  }

  .s-no-results__message {
    width: 100%;
    text-align: center;
  }
}

$backgroundColor: #fff;
$strokeColor    : #223b99;
// Animation --------------------------------------------------- //
$duration       : 2s;
$timingEase     : cubic-bezier(.51, .92, .24, 1);
$rotationStart  : -360deg;
$dashStart      : 1200;

.preloader-wrapper {
  position: absolute;
  left:0;
  top:0;
  width:100%;
  background: $backgroundColor;
  height:100%;
  z-index: 99;
  margin-left:auto;
  margin-right:auto;
  visibility: visible;

    &.done{
        display: none;
    }

    .svg {
        display: block;
        margin: auto;
        width: 10%;
        height: 100%;
        transform: rotate(0);
        animation: rotate $duration $timingEase forwards infinite;
    }
    .path {
        stroke-dasharray: 590;
        stroke-dashoffset: 0;
        stroke: $strokeColor;
        fill: none;
        animation: dash $duration $timingEase forwards infinite;
    }

    @keyframes dash {
        from {
            stroke-dashoffset: $dashStart;
        }
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate($rotationStart);
        }
        to {
            transform: rotate(0);
        }
    }
}


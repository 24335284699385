.sn-timeline__dot {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15px;

  &::before,
  &::after {
    content: '';
    background: currentColor;
    display: block;
    position: absolute;
  }

  &::before {
    border: 3px solid transparent;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 4px;
    left: 0;
    transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
  }

  &::after {
    width: 3px;
    opacity: 0.4;
    top: 24px;
    bottom: 0;
    left: 6px;
  }

  .s-ico {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 16px;
    height: 38px;
    line-height: 38px;
    width: 100%;
    color: #fff;
  }
}

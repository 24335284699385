.table-on-fire {

}

.table-on-fire--score {
  table-layout: fixed;
  width: auto;

  .table-on-fire__head {
    th {
      &.tof-grid__index,
      &.tof-grid__spec,
      &.tof-grid__score {
        height: 8rem;
      }

      &.tof-grid__score {
        span {
          display: inline-block;
          text-align: center;
          //transform: rotate(90deg);

          &.tof-grid__score-add {
            @include sflx($d: inline-flex, $juscont: center, $alitems: center);
            width: 5rem;
            height: 5rem;
            font-size: 2rem;
            font-family: $fIcons;
            cursor: pointer;

            &::after {
              content: '\E61A';
            }
          }
        }
      }

      &.score_day_delete {
        .destroy_day {
          display: none;
        }

        &:hover {
          span {
            display: none;
          }

          .destroy_day {
            display: inline-flex;

          }
        }
      }
    }
  }

  .table-on-fire__body {
    td {
      &.score_edit {
        .edit {
          display: none;
        }

        .score_point {
          min-height: 100%;
          min-width: 100%;
          justify-content: center;
          display: flex;
          align-items: center;
        }

        &:hover {
          .score_point {
            display: none;
          }

          .edit {
            display: inline-flex;
          }
        }
      }
    }
  }
}

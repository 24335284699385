.s-test-process__result-desc {
  font-size: 2.4rem;
  text-align: center;

  @media screen and ( max-width: 479px ) {
    font-size: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.s-blog__feedback {
  &-avatar {
    @include sbg($c: cover);
    background-color: #F7F9FC;
    border-radius: 50%;
    flex-shrink: 0;
    width: 7rem;
    height: 7rem;
    margin-left: auto;
    margin-right: auto;
  }

  &-details {
    padding-left: 1.5rem;
    width: 100%;
  }

  &-reply {
    align-self: flex-end;
    color: currentColor;
    font-weight: 600;
  }

  &-name {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  &-date {
    @extend .s-blog__info;
    margin-bottom: 2rem;
  }

  &-license {
    @extend .s-blog__info;
    max-width: 35rem;
    padding: 0.5rem 1rem;
  }

}

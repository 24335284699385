@mixin setRatingThemes($list, $hover) {
  @each $name, $color in $list {
    &.rating-block_thm_#{$name} {
      .rating-block__star {
        @if $hover == true {
          &:hover {
            color: $color;

            ~ .rating-block__star {
              color: $color;
            }
          }
        }
        &.is-active {
          color: $color;

          ~ .rating-block__star {
            color: $color;
          }
        }
      }
    }
  }
}

.rating-block {
  @include sflx($dir: row-reverse, $alitems: center);
  flex: 1 0 auto;
  font-size: 2.2rem;
  color: #a9b9c2;

  &.rating-block--full {
    justify-content: space-between;

    .rating-block__star {
      padding-right: 0;
    }
  }

  &:not(.rating-block--static) {
    $listOfRating: (
      ('red', #ff6600),
    );

    @include setRatingThemes($listOfRating, true);
  }

  &.rating-block--static {
    .rating-block__star {
      cursor: default;
      user-select: none;
    }

    $listOfRating: (
      ('red', #ff6600),
    );

    @include setRatingThemes($listOfRating, false);
  }
}

[class*=rating-block_thm_] {
  &:hover {
    ~ .rating-block__star {
      transition: background-color .2s ease-in;
    }
  }
}

.container-content-head__inform-wrapper {
  @include sflx($wrap: wrap, $alitems: stretch);
  flex: 1 0 auto;
  margin-top: 2.5rem;

  .inform-block {
    //width: 18.2rem;
    flex: 1;
    margin-bottom: 2rem;

    &--wider {
      //flex: 1 1 auto;
      flex: 2;
      margin-bottom: 2rem;
    }

    &:not( :last-child ) {
      margin-right: 2rem;
    }
  }
}

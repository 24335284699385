@media screen and ( max-width: 1008px ) {
  .interest-block {
    transition: unset;

    &:hover {
      box-shadow: 0 0 1.6rem rgba(54, 76, 167, 0.59);
      transition: unset;
    }

    .interest-block__description {
      background-size: 120% 120%;
      transition: unset;
    }

    .interest-block__desc-title {
      transform: translateY(-4rem);
      transition: unset;
    }

    .interest-block__desc-link {
      transform: translateY(-1.5rem);
      opacity: 1;
      transition: unset;
    }
  }

  .interest-block_t_first,
  .interest-block_t_second,
  .interest-block_t_third {
    &:hover {
      .interest-block__image {
        background-size: cover;
        transition: unset;
      }

      .interest-block__image-float {
        transform: unset;
        transition: unset;
      }

      .interest-block__image-float-mini {
        transform: unset;
        transition: unset;
      }
    }

    .interest-block__image {
      background-size: cover;
    }
  }

  .interest-block__school {
    &:hover {
      box-shadow: none;
    }

    &:not( :nth-child( 3 ) ) {
      .interest-block__desc-title {
        transform: translateY(2rem);
      }

      .interest-block__desc-image-center {
        transform: translateY(-2rem);
      }

      .interest-block__desc-link {
        transform: translateY(-2.2rem);
      }
    }

    &:first-child {
      opacity: 1;
    }

    &:nth-child( 2 ) {
      order: 2;
      opacity: 1;
    }

    &:nth-child( 3 ) {
      order: 3;

      .interest-block__desc-title {
        transform: unset;
      }
    }
  }
}


@media screen and ( max-width: 479px ) {
  .interest-block__image {
    display: none;
  }

  .interest-block__description {
    width: 100%;
  }
}

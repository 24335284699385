.site-body__right-column {
  flex: 1 1 auto;
  max-width: calc(100% - 27rem); // 27rem is left-column size parameters
  padding-top: 3rem;

  .container-content-head {
    margin-top: -7.1rem;
  }

  &--half_padding {
    padding-top: 2rem;
  }
}

.test-cont__left {
  flex-shrink: 0;
  width: 36rem;
  padding-right: 6rem;

  @media screen and (max-width: 1279px) {
    width: 33rem;
    padding-right: 3rem;
  }

  @media screen and (max-width: 1008px) {
    width: 100%;
    padding-right: 0;
  }
}

/*
  Комбинированная кнопка для таблиц
*/

.s-btn-table {
  @extend .s-btn, .s-btn--thm-white, .s-btn--border;
  @include sflx($d: inline-flex, $alitems: center, $juscont: center);
  color: $cBlack;
  background-color: $cWhite;
  border-color: $cBorder;
  transition: all $gDuration ease-in;

  &:hover {
    background-color: $cGrey;
    color: $cWhite;
    transition: all $gDuration ease-in;
  }

  &--mini {
    min-height: 4rem;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
  }

  &--empty {
    @extend .s-btn--empty;

    &:hover {
      background-color: $cWhite;
      border-color: $cBorder;
      transition: none;
    }

    &:focus {
      outline: none;
    }
  }
}

.tof-button-wrapper {
  display: none;
  position: absolute !important;
  right: .5rem;
  bottom: 1.5rem;

  .btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.course-spoiler-head__button {
  @include sflx($alitems: center, $juscont: center);
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  font-size: 1.8rem;
  font-family: $fIcons;
  color: #3045a7;
  cursor: pointer;
  transform: rotate(0);
  transition: all .2s ease-in;

  &::after {
    content: '\E64B';
  }
}

.form-error {
  .form-label {
    color: $cError;
    animation: errorShake .2s ease-in 1;
  }

  .form-input {
    color: $cError;
    border-color: $cError;
    box-shadow: inset 0 0 10px rgba(255, 0, 0, 0.35);
    transition: all .2s ease-in;
  }

  *:focus {
    outline-color: $cError;
  }

  .form-error__message {
    margin-top: .5rem;
  }
}

.s-blog__body {
  @extend .s-plain-text;

  .image {
    margin: 0;
    text-align: center;

    img {
      max-width: 100%;
      border-radius: $gBorderRadius;
      max-height: 50vh;
    }
  }
}

.s-w-max {
  width: max-content;
  max-width: 100%;
}

.s-w-min {
  width: min-content;
  max-width: 100%;
}

.s-w-10rem {
  width: 10rem;
}

.s-w-100 {
  width: 100%;
}

.s-w-400 {
  width: 40rem;
  max-width: 100%;
}

.s-w-800 {
  width: 80rem;
  max-width: 100%;
}

.s-mw-200 {
  min-width: 20rem;
}

.s-maxw-728 {
  max-width: 72.8rem !important;
}

.s-maxw-150 {
  max-width: 15rem !important;
}

.s-maxw-200 {
  max-width: 20rem !important;
}

.s-maxw-950 {
  max-width: 95rem !important;
}

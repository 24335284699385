.container-content-head__picture {
  @include sbg($s: contain);
  background-color: #29479B;
  flex-shrink: 0;
  width: 12rem;
  height: 12rem;
  border-radius: $gBorderRadius;
  margin-right: 2rem;
  overflow: hidden;


  @media screen and ( max-width: 479px ) {
    margin-right: 0;
  }
}

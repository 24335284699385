.s-pos-float-left {
  float: left;
}

.s-pos-float-right {
  float: right;
}

.s-pos-relative-position {
  position: relative;
}

.s-pos-fixed {
  position: fixed;
}

.s-pos-fixed-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-pos-fullscreen {
  position: fixed;
  z-index: $z-fullscreen;
  border-radius: 0 !important;
  max-width: 100vw;
  max-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-pos-fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.s-pos-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-pos-fixed-left {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

.s-pos-fixed-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

.s-pos-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.s-pos-fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

.s-pos-fixed-top-right {
  position: fixed;
  top: 0;
  right: 0;
}

.s-pos-fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
}

.s-pos-fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.s-pos-absolute {
  position: absolute;
}

.s-pos-absolute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-pos-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.s-pos-absolute-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.s-pos-absolute-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.s-pos-absolute-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.s-pos-absolute-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.s-pos-absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.s-pos-absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.s-pos-absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.s-pos-absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.s-pos-vertical-top {
  vertical-align: top !important;
}

.s-pos-vertical-middle {
  vertical-align: middle !important;
}

.s-pos-vertical-bottom {
  vertical-align: bottom !important;
}

.s-pos-on-right {
  margin-left: 1rem;
}

.s-pos-on-left {
  margin-right: 1rem;
}

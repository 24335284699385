.s-notification__wrapper {
  max-height: 100%;
  min-width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  overflow-y: auto;
  @include media-breakpoint-up (lg) {
    top: 7%;
    right: 0;
    bottom: auto;
    left: auto;
    max-width: 42rem;
    min-width: 30rem;
  }
}

.s-photo-album__add-photo {
  @include pos(absolute, 2, $b: 0, $l: 0);
  font-size: 2.4rem;
  color: $cWhite;
  padding: 1rem;
  transition: transform $gDuration ease-in;

  &:hover {
    transform: scale(1.2);
  }
}

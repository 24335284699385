.s-mobile-menu__wrapper {
  @include pos(fixed, 6000, 0, $r: 0);
  @include sflx($dir: column);
  width: 100%;
  max-width: 32rem;
  height: 100vh;
  background-color: #3045a7;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  &.is-active {
    transform: translateX(0);
    box-shadow: 0.5rem 0.3rem 1rem 0.2rem rgba($cBlack, 0.5);
  }
}

.s-mobile-menu__page {
  @include pos(absolute, $t: 0, $l: 0);
  @include sflx($dir: column);
  height: 100%;
  width: 100%;
}

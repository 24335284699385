.sn-notification {
  z-index: $z-notify;
  display: inline-flex;
  min-width: 30rem;
  max-width: 95vw;
  font-size: 1.4rem;
  color: $cWhite;
  background: #323232;
  border-radius: $gBorderRadius;
  box-shadow: $shadow-2;
  margin: 1rem 1rem 0;
  pointer-events: all;
  transition: transform 1s, opacity 1s;

  &--standard {
    padding: 0 1.6rem;
    min-height: 4.8rem;

    .sn-notification__actions {
      padding: 0.6rem 0 0.6rem 0.8rem;
      margin-right: -0.8rem;
    }
  }

  &--multi-line {
    min-height: 6.8rem;
    padding: 0.8rem 1.6rem;

    .sn-notification__actions {
      padding: 0;
    }
  }

  &--top-left-enter,
  &--top-left-leave-to,
  &--top-enter,
  &--top-leave-to,
  &--top-right-enter,
  &--top-right-leave-to {
    opacity: 0;
    transform: translateY(-5rem);
    z-index: ($z-notify - 1);
  }

  &--left-enter,
  &--left-leave-to,
  &--center-enter,
  &--center-leave-to,
  &--right-enter,
  &--right-leave-to,
  &--bottom-left-enter,
  &--bottom-left-leave-to,
  &--bottom-enter,
  &--bottom-leave-to,
  &--bottom-right-enter,
  &--bottom-right-leave-to {
    opacity: 0;
    transform: translateY(5rem);
    z-index: ($z-notify - 1);
  }

  &--top-left-leave-active,
  &--top-leave-active,
  &--top-right-leave-active,
  &--left-leave-active,
  &--center-leave-active,
  &--right-leave-active,
  &--bottom-left-leave-active,
  &--bottom-leave-active,
  &--bottom-right-leave-active {
    @include pos(absolute, $z-notify - 1);
    margin-left: 0;
    margin-right: 0;
  }

  &--top-leave-active,
  &--center-leave-active {
    top: 0;
  }

  &--bottom-left-leave-active,
  &--bottom-leave-active,
  &--bottom-right-leave-active {
    bottom: 0;
  }
}

.s-insta-block__body-play {
  @include pos(absolute, 1, 0, 0, 0, 0);
  @include sflx($alitems: center, $juscont: center);
  width: 8rem;
  height: 8rem;
  background: $cWhite;
  border-radius: 50%;
  box-shadow: 0 0 1rem $cGrey;
  margin: auto;

  .s-ico--thm-control-play {
    font-size: 4rem;
    color: $cGrey;
  }
}

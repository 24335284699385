.faq-cont__sections-select {
  @include sflx($dir: column);
  flex-shrink: 0;
  width: 40rem;
  margin-right: 2.5rem;


  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.section-interest-block {
  @include sflx($dir: column, $alitems: center, $juscont: center);
  @include sbg($i: '../images/block_bg.png', $s: cover);
  height: 24rem;
  background-color: #3a53b6;
  padding: 3rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 16px rgba(54, 76, 167, 0.59);
  transition: box-shadow .2s ease-in;

  &:hover {
    box-shadow: 0 0 36px rgba(54, 76, 167, 0.59);
    transition: box-shadow .2s ease-in;
  }
}

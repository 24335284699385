.s-no-results {
  @include sflx($juscont: center, $alitems: center);
  color: $cBlueDark;

  &--bordered {
    box-shadow: 0 0 10px rgba(104, 124, 143, 0.28);
    border-radius: 10px;
    border: 0.5px solid rgba(50, 72, 165, 0.3);
  }
}

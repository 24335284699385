.table__monitoring-school-list,
.table__monitoring-school-vacancies,
.table__monitoring-school-activity {
    thead {
        tr {
            td {
                border-bottom: 1px solid #d1d8dc;
                vertical-align: bottom;
                font-family: $font_sans_sb;
            }
        }
    }
    tr {
        td {
            padding-top: 1rem;
            padding-right: 2rem;
            padding-bottom: 1rem;
            padding-left: 2rem;
            font: 1.6rem/1.2em $font_default;
            color: $c_black;
            border-right: 1px solid #d1d8dc;
        }
    }
}

.table__monitoring-school-list {
    tr {
        td {
            &:nth-child(1) {
                width: 380 / 1210 * 100%;
            }
            &:last-child {
                width: 330 / 1210 * 100%;

                text-align: right;
                .button-group {
                    text-align: right;
                }
            }
        }
    }
    &.subject-list {
        & > thead > tr > td,
        & > tbody > tr > td {
            &:nth-child(2) {
                text-align: center;
            }
        }
        & > tbody > tr > td {
            font-weight: bold;
        }
    }
    &.table-collapse {
        .tr-head {
            background-color: #fff;

            &.tr-colored {
                background-color: #f3f6f8;
            }

            td:last-child {
                text-align: right;
            }
        }
        .tr-collapsed {
            display: none;

            &.open {
                display: table-row;
            }
            & > td {
                padding: 0;

                &:last-child {
                    border-right: 0;
                }
            }
            tr {
                background: #fff;
                border-top: 1px solid #d1d8dc;

                td:first-child {
                    text-align: left;
                }
                &:last-child {
                    border-bottom: 1px solid #d1d8dc;
                }
            }
        }
        .table-nested {
            width: calc(100% + 1px);
        }
    }
}

.table__monitoring-school-vacancies {
    tr {
        td {
            &:nth-child(1) {
                width: 380 / 1210 * 100%;
            }
            &:last-child {
                width: 200 / 1210 * 100%;
            }
        }
    }
}

.table__monitoring-school-activity {
    tr {
        td {
            &:nth-child(1) {
                width: 250 / 1210 * 100%;
            }
            &:nth-child(2) {
                width: 300 / 1210 * 100%;
            }
            &:nth-child(3) {
                width: 200 / 1210 * 100%;
            }
            &:nth-last-child(2) {
                width: 180 / 1210 * 100%;
            }
            &:last-child {
                width: 180 / 1210 * 100%;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .table__monitoring-school-list {
        tr {
            td {
                &:last-child {
                    width: 300px;
                }
                .button-group {
                    .button {
                        min-width: 10rem;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 999px){
    .table__monitoring-school-list {
        &.subject-list {
            tr td:last-child {
                width: 140px;
            }
            .button {
                font-size: 1.2rem;
                min-width: 10rem;
                padding: 1rem 1rem;
            }
        }
        &.table-collapse {
            .tr-collapsed {
                display: none;

                &.open {
                    display: table-row;
                }
            }
            td:before {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .table__monitoring-school-list {
        tbody {
            tr:after {
                left: 36%!important;
            }
            tr td:before {
                min-width: 30%!important;
                width: 30%!important;
                max-width: 30%!important;
            }
        }
    }
}

@media screen and (max-width: 479px) {

}

@media screen and (max-width: 400px) {

}
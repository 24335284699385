.s-u-dashed {
  border-bottom: 1px dashed currentColor;
}

.s-u-solid {
  border-bottom: 1px solid currentColor;
}

.s-u-dotted {
  border-bottom: 1px dotted currentColor;
}

.s-u-hover-dashed:hover {
  border-bottom: 1px dashed currentColor;
}

.s-u-hover-solid:hover {
  border-bottom: 1px solid currentColor;
}

.s-u-hover-dotted:hover {
  border-bottom: 1px dotted currentColor;
}

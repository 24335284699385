.report__line {
  @include sflx($alitems: center, $juscont: center);
  width: 100%;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

}

$bgReportCommon: #f7f9fc;
$bReportCommon: #c9d2d8;
$cReportCommon: #546472;

.report__common {
  @include sflx($alitems: stretch);
  width: 100%;
  background-color: $bgReportCommon;
  border: 1px solid $bReportCommon;
  border-radius: 6px;
  margin-bottom: auto;
  margin-left: auto;
}

.report__common-item {
  flex: 1;
  @include sflx($alitems: center, $juscont: center, $wrap: wrap);
  font: 2.4rem/1.2em $fSansSB;
  color: $cReportCommon;
  text-align: center;
  padding: 2.4rem 2rem;
  border-right: 1px solid $bReportCommon;

  &:last-child {
    border-right: 0;
  }

  span {
    margin-right: 1rem;
    margin-left: 1rem;
  }

}

@media screen and (max-width: 767px) {
  .report__common {
    flex-direction: column;
  }

  .report__common-item {
    font-size: 1.6rem;
    padding: 1.5rem 2rem;
    border-right: 0;
    border-bottom: 1px solid $bReportCommon;

    &:last-child {
      border-bottom: 0;
    }

  }
}

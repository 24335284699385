.sn-table__top {
  padding: 1.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sn-table__control {
    flex-wrap: wrap;
  }
}

@media (max-width: $gMinWidthTablet) {
  .sn-table__top {
    justify-content: flex-end;
  }
}

.sn-table__container {
  @include pos();

  > div:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  > div:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  &.s-pos-fullscreen {
    padding: 2rem;
  }
}

.s-main-footer__address {
  color: $cWhite;

  a {
    color: $cWhite;
    border-bottom: 1px solid transparent;

    &[ href^='tel:' ],
    &[ href^='mailto:' ] {
      border-bottom-style: dotted;
    }

    &:hover {
      border-bottom-color: $cWhite;
    }
  }

  p {
    margin: 0 0 0.3rem;
  }
}

.sn-item {
  @include pos();
  @include sflx($wrap: nowrap);
  min-height: 4rem;
  padding: 8px 16px;
  color: inherit;
  transition: color 0.3s, background-color 0.3s;

  &--border-bottom {
    &:not( :last-child) {
      border-bottom: 1px solid $cBorder;
    }
  }

  &--active {
    color: $cAqua;
  }

  &--not-active {
    color: $cBlueDark;
  }

  &--dark {
    color: #fff;
    border-color: rgba(255, 255, 255, .48);

    .sn-item__label {
      &--header {
        color: rgba(255,255,255,0.64);
      }

      &--overline {
        color: rgba(255,255,255,0.8);
      }

      &--caption {
        color: rgba(255,255,255,0.8);
      }
    }

    .sn-item__section {
      &--side {
        &:not( .sn-item__section--avatar ) {
          color: rgba(255,255,255,0.7);
        }
      }
    }
  }

  &.sn-router-link {
    &--active {
      color: #027BE3;
    }
  }

  > .sn-item__section {
    &--thumbnail {
      &:first-child {
        margin-left: -16px;
      }

      &:last-of-type {
        margin-right: -16px;
      }
    }
  }

  > .sn-focus-helper {
    + .sn-item__section--thumbnail {
      margin-left: -16px;
    }
  }
}

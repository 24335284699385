@media screen and ( max-width: 479px ) {
  .container-head-wrapper {
    min-height: unset;
    padding-top: 0;
    padding-bottom: 0;

    .s-title {
      text-align: center;
    }
  }
}

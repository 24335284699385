.kindergarten-block {
  @include sflx($alitems: stretch);
  color: $cBlack;
  background-color: $cWhite;
  padding: 1rem;
  border-radius: $gBorderRadius;
  box-shadow: 0 0 9px rgba(74, 102, 126, 0.28);


  @media screen and (max-width: 479px) {
    flex-direction: column;
  }
}
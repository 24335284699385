.report {
  @include pos($z: 1);
}

.report_type_pie {
  width: 50%;
}

.report_type_bar {
  width: 100%;
}

.s-process {
  @include pos();
  overflow: hidden;

  &::after {
    @include sbg($r: repeat-x);
    @include pos(absolute, 1, 0, 0);
    display: block;
    width: 200%;
    height: 100%;
    background-image: url(../images/s-process.svg);
    overflow: hidden;
    animation: a-move-hor 1s linear infinite;
    content: '';
  }
}

%s-table-schedule__card-tag {
  font-weight: 700;
  font-size: 1.3rem;
  color: $cWhite;
  padding: 0.6rem 1rem;
  border-radius: $gBorderRadius;
}

$listOfCardTagColors: (
  ('blue', $cBlue),
  ('green', $cGreen),
);

@each $name, $color in $listOfCardTagColors {
  .s-table-schedule__card-tag-#{$name} {
    @extend %s-table-schedule__card-tag;
    background-color: $color;
  }
}

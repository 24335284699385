.tbl-cont__buttons-wrapper {
  @include sflx($juscont: center, $wrap: wrap);
  width: 100%;

  > * {
    margin: .5rem;
  }


  @media screen and (max-width: 999px) {
    justify-content: flex-start;
  }
}
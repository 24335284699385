.s-social-icons__instagram {
  @extend %s-social-icons__;

  &::after {
    content: map_get($listOfIcons, 'instagram');
  }
}
.s-social-icons__instagram-fa {
  @extend %s-social-icons-fa__;
  &::after {
    content: map_get($listOfIconsFA, 'instagram');
  }
}

.tippy-tooltip {
  font-size: 1.4rem;
  box-shadow: 0 0 10px 1px rgba(104, 124, 143, 0.55);
  background-color: rgba(#0d1620, .8);
}

.tippy-roundarrow {
  fill: #0d1620;
  opacity: .8;
}

.tippy-backdrop {
  background-color: rgba(#0d1620, .8);
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  border-top: 8px solid #0d1620;
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  border-bottom: 8px solid #0d1620;
}

.tippy-popper[x-placement^=left] .tippy-arrow {
  border-left: 8px solid #0d1620;
}

.tippy-popper[x-placement^=right] .tippy-arrow {
  border-right: 8px solid #0d1620;
}
